import React from 'react';
import './padding.scss';

export type PaddingProps = {
    left?: number;
    top?: number;
    right?: number;
    bottom?: number;
    compact?: boolean;
    factor?: number;
    style?: React.CSSProperties;
};

export const Padding: React.FC<PaddingProps> = ({ left = 16, top = 16, right = 16, bottom = 16, compact = false, factor = 1, style = {}, ...otherProps }) => {
    const theFactor = compact ? 0.5 : factor;
    return (
        <div
            style={{
                paddingLeft: `${left * theFactor}px`,
                paddingTop: `${top * theFactor}px`,
                paddingRight: `${right * theFactor}px`,
                paddingBottom: `${bottom * theFactor}px`,
                ...style,
            }}
            {...otherProps}
        />
    );
};

export default Padding;
