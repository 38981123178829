import { getTheme, sharedHighchartsSettings } from '@capasystems/utils';
import H from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import addHighchartsMore from 'highcharts/highcharts-more'; /** Support for bubble series */
import drilldown from 'highcharts/modules/drilldown';
import addNoDataModule from 'highcharts/modules/no-data-to-display';
import PropTypes from 'prop-types';
import React from 'react';
import './highcharts.scss';

addHighchartsMore(H); /** Support for bubble series */
addNoDataModule(H);
drilldown(H);

const { capasystems } = getTheme();
const fontSize = {
    small: '10px',
    medium: '13px',
    large: '14px',
};
H.theme = {
    ...sharedHighchartsSettings,
    capasystems,
    fontSize,
    navigation: {
        buttonOptions: {
            enabled: false,
        },
    },
    title: {
        style: {
            color: capasystems.palette.typography.light,
            fontSize: fontSize.medium,
            fontWeight: 'bold',
        },
    },
    subtitle: {
        style: {
            color: capasystems.palette.typography.light,
            fontSize: fontSize.small,
            fontWeight: 'bold',
        },
    },
    plotOptions: {
        arearange: {
            fillOpacity: 0.25,
            lineWidth: 0,
            connectNulls: false,
            states: {
                hover: {
                    lineWidth: 0,
                    lineWidthPlus: 0,
                },
            },
            marker: {
                enabled: true,
                fillColor: null,
                lineWidth: 1,
                lineColor: null, // inherit from series
                radius: 1,
                symbol: 'circle',
            },
        },
        line: {
            lineWidth: 1,
            connectNulls: false,
            states: {
                hover: {
                    lineWidth: 3,
                    halo: {
                        size: 1,
                    },
                },
            },
            marker: {
                fillColor: null,
                lineWidth: 1,
                lineColor: null, // inherit from series
                enabled: true,
                radius: 1,
                symbol: 'circle',
            },
        },

        series: {
            shadow: false,
            marker: {
                symbol: 'circle',
            },
        },
        bubble: {
            states: {
                hover: {
                    halo: {
                        size: 5,
                    },
                },
            },
            cursor: 'pointer',
            point: {
                events: {},
            },
            zones: [], // Let widgets decide the zone colors and values.
            marker: {
                lineColor: capasystems.borderColor,
                fillOpacity: 1,
            },
            dataLabels: {
                style: {
                    textOutline: '0px',
                },
                enabled: true, // Show values inside the bubbles.
            },
        },
    },
    yAxis: {
        tickmarkPlacement: 'on',
        gridLineWidth: 1,
        gridLineColor: capasystems.borderColor,
        labels: {
            style: {
                fontSize: fontSize.small,
            },
        },
        title: {
            style: {
                fontSize: fontSize.small,
            },
        },
    },
    xAxis: {
        tickColor: capasystems.borderColor,
        lineColor: capasystems.borderColor,
        labels: {
            align: 'right',
            style: {
                color: capasystems.palette.typography.light,
                fontSize: fontSize.small,
            },
        },
    },
    legend: {
        itemStyle: {
            fontSize: fontSize.small,
        },
    },
    tooltip: {
        useHTML: true,
        borderWidth: 1,
        borderColor: capasystems.borderColor,
        backgroundColor: capasystems.background.paper,
        shadow: false,
    },
};
H.theme.plotOptions.spline = H.theme.plotOptions.line;
H.theme.plotOptions.areasplinerange = H.theme.plotOptions.arearange;
H.setOptions(H.theme);

/** Highcharts - timelines etc. */
export const Highcharts = React.forwardRef(
    (
        {
            options,
            allowChartUpdate = true,
            onInit = () => null,
            containerProps = {
                className: 'cs-highcharts',
            },
            immutable = false,
            updateArgs = [true, true, true],
        },
        chartRef
    ) => {
        return (
            <HighchartsReact
                highcharts={H}
                constructorType="chart"
                options={options}
                allowChartUpdate={allowChartUpdate}
                immutable={immutable}
                callback={onInit}
                ref={chartRef}
                updateArgs={updateArgs}
                containerProps={containerProps}
            />
        );
    }
);

Highcharts.propTypes = {
    /** Highcharts chart configuration object. Please refer to the Highcharts API documentation. */
    options: PropTypes.shape({}).isRequired,
    /** Control chart updates */
    allowChartUpdate: PropTypes.bool,
    /** Reinitialises the chart on prop update (as oppose to chart.update()) - useful in some cases but slower than a regular update. */
    immutable: PropTypes.bool,
    /** The props object passed to the chart container. Useful for adding styles, id or class. */
    containerProps: PropTypes.shape({}),
    /** Callback is called when the chart has been initialized. The chart instance is passed to onInit. Alternatively use ref. */
    onInit: PropTypes.func,
    /**
     * Array of the update function optional arguments. Parameters should be defined in the same order like in native Highcharts function: [redraw, oneToOne, animation].
     * Here is a more specific description of the parameters -
     * https://api.highcharts.com/class-reference/Highcharts.Chart#update
     * */
    updateArgs: PropTypes.arrayOf(PropTypes.bool),
};

export default Highcharts;
