import { isUndefined } from '@capasystems/utils';
import { useState, useEffect } from 'react';

const STORAGE_KEY = 'capaone';

export const useLocalStorageState = (localStorageId, defaultValue = '', urlGetMethod = () => null, urlSetMethod = () => null, urlKey = null) => {
    const [value, setValue] = useState(() => {
        if (window.localStorage.getItem(STORAGE_KEY) === null) {
            window.localStorage.setItem(STORAGE_KEY, JSON.stringify({}));
        }
        const urlValue = urlGetMethod(urlKey || localStorageId, null);
        if (urlValue !== null) {
            return urlValue;
        }
        const currentValue = JSON.parse(window.localStorage.getItem(STORAGE_KEY))[localStorageId];
        if (isUndefined(currentValue)) {
            return defaultValue;
        }
        return currentValue;
    });

    useEffect(() => {
        const storageObject = JSON.parse(window.localStorage.getItem(STORAGE_KEY));
        storageObject[localStorageId] = value;
        window.localStorage.setItem(STORAGE_KEY, JSON.stringify(storageObject));
        urlSetMethod(urlKey || localStorageId, value);
    }, [value]);

    return [value, setValue];
};

export default useLocalStorageState;
