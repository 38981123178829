import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent } from '@capasystems/ui';
import { Heading } from '../heading/heading';
import { BUTTON } from '@capasystems/constants';

export const ComingSoonDialog = ({ children, ...props }) => {
    return (
        <Dialog {...props}>
            <DialogContent>
                <Heading>Coming Soon...</Heading>
            </DialogContent>
            <DialogContent>
                <Heading subheading>{children}</Heading>
            </DialogContent>
            <DialogActions>
                <Button
                    noMargin
                    color={BUTTON.PRIMARY}
                    onClick={props.onClose}
                >
                    Gotcha
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ComingSoonDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ComingSoonDialog;
