import { SORT_DIRECTION } from '@capasystems/constants';
import { Avatar, Column, Ellipsis, EmptyState, VirtualizedTable } from '@capasystems/ui';
import { noop } from '@capasystems/utils';
import { Portal } from '@mui/base';
import { QueryBuilder, TwoLineCellRenderer, WidgetPaper, useApi, useColumnPicker } from '@thirdparty/ui';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import React, { useEffect, useRef, useState } from 'react';

const defaultQueryBuilderConfiguration = getDefaultQueryBuilderConfiguration({
    placeholder: 'Search for models',
});

const DriversSupportedList = ({ portalContainer }) => {
    const api = useApi();
    const columnPicker = useColumnPicker({ id: 'windows-drivers-supported-models-list', lockedColumns: ['Model'] });
    const queryBuilderRef = useRef({});
    const [drivers, setDrivers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [sort, setSort] = useState({ by: 'modelName', direction: SORT_DIRECTION.ASC });

    const onSort = ({ sortBy, sortDirection }) => {
        setSort({
            by: sortBy,
            direction: sortDirection,
        });
    };

    const onSubmit = ([activeLeaf]) => {
        setSearchTerm(activeLeaf ? activeLeaf.value : '');
    };

    const cellRenderer = ({ cellData }) => {
        return cellData?.length === 0 ? '-' : <Ellipsis>{cellData}</Ellipsis>;
    };

    useEffect(() => {
        setIsLoading(true);
        api.getDevicesDriverList({
            search: searchTerm,
            orderBy: `${sort.by},${sort.direction}`,
        })
            .then((devices) => {
                setDrivers(devices);
            })
            .catch(noop)
            .finally(() => setIsLoading(false));

        return () => {
            api.cancel();
        };
    }, [api, sort, searchTerm]);

    return (
        <>
            <Portal container={portalContainer}>
                <QueryBuilder
                    defaultConfiguration={defaultQueryBuilderConfiguration}
                    onInit={onSubmit}
                    onSubmit={onSubmit}
                    ref={queryBuilderRef}
                />
            </Portal>
            <div className="tw-grid-rows-auto tw-grid tw-h-full tw-w-full tw-p-0.5 tw-py-4">
                <WidgetPaper headerless>
                    <VirtualizedTable
                        sortBy={sort.by}
                        sortDirection={sort.direction}
                        sort={onSort}
                        items={drivers}
                        totalRowCount={drivers.length}
                        showRowCount
                        entity="model"
                        isLoading={isLoading}
                        noRowsRenderer={() => (
                            <EmptyState
                                isSearching
                                searchTerm={searchTerm}
                                entity="model"
                                onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                            />
                        )}
                        columnPicker={columnPicker}
                    >
                        <Column
                            key="thumbnail"
                            dataKey="thumbnail"
                            label=""
                            minWidth={32}
                            maxWidth={32}
                            cellRenderer={({ rowData }) => (
                                <Avatar
                                    alt={rowData.vendor}
                                    src={rowData.thumbnail}
                                    variant="square"
                                    className="tw-h-8 tw-w-8"
                                />
                            )}
                        />
                        <Column
                            key="vendor"
                            dataKey="vendor"
                            label="Vendor"
                            minWidth={96}
                            maxWidth={96}
                        />
                        <Column
                            key="modelName"
                            dataKey="modelName"
                            label="Model"
                            minWidth={320}
                        />
                        <Column
                            key="os"
                            dataKey="os"
                            label="Operating System"
                            minWidth={160}
                            cellRenderer={({ cellData, rowData }) => (
                                <TwoLineCellRenderer
                                    main={<div className="tw-text-sm">{cellData?.join(', ')}</div>}
                                    secondary={rowData.osReleaseVersion?.join(', ')}
                                />
                            )}
                        />
                        <Column
                            key="baseBoardProduct"
                            dataKey="baseBoardProduct"
                            label="Baseboard Product"
                            minWidth={160}
                            cellRenderer={cellRenderer}
                        />
                        <Column
                            key="smBiosName"
                            dataKey="smBiosName"
                            label="SMBios"
                            minWidth={160}
                            cellRenderer={cellRenderer}
                        />
                        <Column
                            key="systemSku"
                            dataKey="systemSku"
                            label="SystemSKU"
                            minWidth={160}
                            cellRenderer={cellRenderer}
                        />
                    </VirtualizedTable>
                </WidgetPaper>
            </div>
        </>
    );
};

export { DriversSupportedList };
