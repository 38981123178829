import dayjs from 'dayjs';
import colors from 'tailwindcss/colors';
import pluralize from 'pluralize';
import { inRange, numberFormat } from '@capasystems/utils';

const toLockedPeriod = (unitCount, unit) => () => {
    const dayjsObject = dayjs();
    if (unit === 'month' && unitCount === 3) {
        return `${dayjsObject.subtract(unitCount, unit).startOf('week').startOf('day').add(1, 'day').utc().valueOf()}|${dayjsObject.valueOf()}`;
    } else {
        return `${dayjsObject.subtract(unitCount, unit).startOf('day').utc().valueOf()}|${dayjsObject.valueOf()}`;
    }
};

export const PERIODS = [
    {
        id: 'CD',
        name: 'Today',
        sampleInterval: 'hour',
        toLockedPeriod: toLockedPeriod(0, 'days'),
        mongoPeriod: '1|day',
        minRetentionDays: 1,
    },
    {
        id: '3D',
        name: '3D',
        sampleInterval: 'day',
        toLockedPeriod: toLockedPeriod(3, 'days'),
        mongoPeriod: '3|day',
        minRetentionDays: 3,
    },
    {
        id: '7D',
        name: '7D',
        sampleInterval: 'day',
        toLockedPeriod: toLockedPeriod(1, 'week'),
        mongoPeriod: '1|week',
        minRetentionDays: 7,
    },
    {
        id: '1M',
        name: '1M',
        sampleInterval: 'day',
        toLockedPeriod: toLockedPeriod(1, 'month'),
        mongoPeriod: '1|month',
        minRetentionDays: 28,
    },
    {
        id: '3M',
        name: '3M',
        sampleInterval: 'week',
        toLockedPeriod: toLockedPeriod(3, 'month'),
        mongoPeriod: '3|month',
        minRetentionDays: 28 * 3,
    },
];

export const EXPERIENCE_DASHBOARD_PERIODS = [
    {
        id: 'CD',
        name: 'Today',
        getBucketParams: () => {
            const binSizes = [1, 2, 5, 15, 30];
            let idealBinSize = 60;
            const dayjsObject = dayjs();
            const minutesDiff = dayjsObject.diff(dayjsObject.startOf('day'), 'minutes');
            for (let binSize of binSizes) {
                if (minutesDiff / 31 < binSize) {
                    idealBinSize = binSize;
                    break;
                }
            }
            return {
                binSize: idealBinSize,
                bin: 'MINUTE',
                interval: 'CD',
            };
        },
    },
    {
        id: '3D',
        name: '3D',
        getBucketParams: () => {
            return {
                binSize: 4,
                bin: 'HOUR',
                interval: '3D',
            };
        },
    },
    {
        id: '7D',
        name: '7D',
        getBucketParams: () => {
            return {
                binSize: 12,
                bin: 'HOUR',
                interval: '7D',
            };
        },
    },
    {
        id: '1M',
        name: '1M',
        getBucketParams: () => {
            return {
                binSize: 1,
                bin: 'DAY',
                interval: '1M',
            };
        },
    },
];

export const RELIABILITY = {
    SEVERITY: {
        CRITICAL: {
            id: 'critical',
            name: 'Critical events',
            colorRepresentation: 'red',
        },
        ERROR: {
            id: 'error',
            name: 'Errors',
            colorRepresentation: 'orange',
        },
        WARNING: {
            id: 'warning',
            name: 'Warnings',
            colorRepresentation: 'amber',
        },
    },
    SCORE: {
        UNACCEPTABLE: {
            NAME: 'Unacceptable',
            COLOR: colors.red,
            RANGE: {
                FROM: 1,
                TO: 5,
            },
        },
        POOR: {
            NAME: 'Poor',
            COLOR: colors.orange,
            RANGE: {
                FROM: 5,
                TO: 7,
            },
        },
        FAIR: {
            NAME: 'Fair',
            COLOR: colors.yellow,
            RANGE: {
                FROM: 7,
                TO: 8.5,
            },
        },
        GOOD: {
            NAME: 'Good',
            COLOR: colors.emerald,
            RANGE: {
                FROM: 8.5,
                TO: 10.1, // Use 10.1 to make sure the perfect 10 scores are matched in inRange.
            },
        },
    },
};

export const chartDefaults = {
    pie: {
        noData: {
            style: {
                fontWeight: 600,
                fontSize: '12px',
                color: 'inherit',
            },
        },
        chart: {
            type: 'pie',
            spacingTop: 0,
            spacingBottom: 8,
            spacingLeft: 0,
            spacingRight: 16,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            // spacing: [0, 16, 8, 0]
        },
        legend: {
            align: 'right', // or center, right
            verticalAlign: 'middle',
            layout: 'vertical',
            x: 0,
            y: 0,
            itemMarginTop: 4,
            itemMarginBottom: 4,
            navigation: {
                activeColor: colors.blue[400],
                inactiveColor: colors.neutral[100],
            },
            /* 
            itemStyle: {
                fontSize: 12,
                lineHeight: 16,
            }, 
            */
            enabled: true,
            floating: false,
            symbolPadding: 8,
            reversed: false,
            width: null,
        },
        tooltip: {
            borderRadius: 8,
            padding: 16,
            enabled: true,
            outside: true,
            shadow: false,
        },
        plotOptions: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false,
            },
            showInLegend: true,
            innerSize: '65%',
            borderWidth: 0,
        },
    },
};

export const chartFormatters = {
    pie: {
        tooltip: {
            formatter: function (unit) {
                function tooltipFormatter() {
                    const percentage = numberFormat(this.percentage, inRange(this.percentage, 10, 90) ? 0 : 1); // Ignore decimals in 10 - 90 range.
                    return `
                        <b class="tw-text-sm tw-block tw-mb-4 tw-font-bold tw-text-slate-800">${this.key}</b>
                        <div class="tw-flex tw-justify-between">
                            <div class="tw-mr-4">
                                <b class="tw-text-lg tw-block tw-text-slate-800">${this.y}</b>
                                <b class="tw-text-xs tw-block tw-text-slate-800">${pluralize(unit, this.y)}</b>
                            </div>
                            <div class="tw-ml-4 tw-text-right">
                                <b class="tw-text-lg tw-block tw-text-slate-800">${percentage}</b>
                                <b class="tw-text-xs tw-block tw-text-slate-800">%</b>
                            </div>
                        </div>
                    `;
                }
                return tooltipFormatter;
            },
        },

        legend: {
            labelFormatter: function (unit, compact = true, limit) {
                function legendLabelFormatter() {
                    if (compact) {
                        if (limit) {
                            return `<b style="font-size:1.2em;">${
                                this.name.length > 35 ? this.name.substring(0, 35) + '...' : this.name
                            }</b><br /><span style="font-weight: 600; font-size: 1.1em;">${pluralize(unit, this.y, true)}</span>`;
                        }
                        return `<b style="font-size:1.2em;">${this.name}</b><br /><span style="font-weight: 600; font-size: 1.1em;">${pluralize(
                            unit,
                            this.y,
                            true
                        )}</span>`;
                    }
                    if (limit) {
                        return `<b style="font-size:1.4em;">${
                            this.name.length > 35 ? this.name.substring(0, 35) + '...' : this.name
                        }</b><br /><span style="font-weight: 600; font-size: 1.2em;">${pluralize(unit, this.y, true)}</span>`;
                    }
                    return `<b style="font-size:1.4em;">${this.name}</b><br /><span style="font-weight: 600; font-size: 1.2em;">${pluralize(
                        unit,
                        this.y,
                        true
                    )}</span>`;
                }
                return legendLabelFormatter;
            },
            labelFormatterCentered: function (unit, compact = true) {
                function legendLabelFormatterCentered() {
                    return `${this.name}<br /><span style="font-weight: 600; font-size: 0.85em;">${this.y.toLocaleString()} ${pluralize(unit, this.y)}</span>`;
                }
                return legendLabelFormatterCentered;
            },
        },
    },
};
