// @ts-noCheck
import MUIButton, { ButtonProps as MUIButtonProps } from '@mui/material/Button';
import React from 'react';

import {
    DISABLED_CLASS,
    END_ICON_CLASS,
    FOCUSED_CLASS,
    FULL_WIDTH_CLASS,
    getRootClasses,
    LABEL_CLASS,
    ROOT_CLASS_BUTTON,
    START_ICON_CLASS,
} from './button.constants';

export type TButtonClasses = {
    root: string;
    disabled: string;
    label: string;
    fullWidth: string;
    focusVisible: string;
};

const getButtonClasses = (color, variant, size, noMargin): TButtonClasses => ({
    root: getRootClasses(ROOT_CLASS_BUTTON, color, variant, size, noMargin),
    disabled: DISABLED_CLASS,
    label: LABEL_CLASS,
    fullWidth: FULL_WIDTH_CLASS,
    focusVisible: FOCUSED_CLASS,
    startIcon: START_ICON_CLASS,
    endIcon: END_ICON_CLASS,
});

export type ButtonProps = Omit<MUIButtonProps, 'color'> & {
    /** default or one of BUTTON.PRIMARY, BUTTON.DANGER, BUTTON.SUCCESS, BUTTON.INHERIT */
    color?: 'primary' | 'danger' | 'default' | 'success' | 'inherit';
    /** One of BUTTON.SMALL, BUTTON.LARGE */
    size?: 'small' | 'medium' | 'large';
    /** One of BUTTON.OUTLINED, BUTTON.RAISED, BUTTON.TEXT */
    variant?: 'outlined' | 'contained' | 'text';
    noMargin?: boolean;
    /** Display button as a block */
    fullWidth?: boolean;
    disableRipple?: boolean;
    disableFocusRipple?: boolean;
    component?: React.ElementType;
};

/** Button component. Ref must be forwarded to work with tooltip. */
export const Button = React.forwardRef<any, ButtonProps>(
    (
        {
            variant = 'text',
            color = 'default',
            fullWidth = false,
            noMargin = false,
            size = 'medium',
            onClick,
            disableRipple = false,
            disableFocusRipple = false,
            component = 'button',
            ...otherProps
        },
        ref
    ) => (
        <MUIButton
            onClick={onClick}
            disableRipple={disableRipple}
            disableFocusRipple={disableFocusRipple}
            component={component}
            classes={getButtonClasses(color, variant, size, noMargin)}
            fullWidth={fullWidth}
            variant={variant}
            size={size}
            disableElevation
            ref={ref}
            {...otherProps}
        />
    )
);

export default Button;
