import { api, getUniqueId, now } from '@capasystems/utils';

let expirationTimestamps = {};

class ManagementConnection {
    constructor(enterpriseId) {
        this.baseUrl = `${api.defaults.baseURL}/management`;
        this.abortController = new AbortController();
        this.connectionID = getUniqueId('ManagementConnectionID');
    }

    /** GET */
    getUsers = (params) => this.get(`user`, params);

    getUser = (userID) => this.get(`user/${userID}`);

    getEndpointsAssignedToUser = (userID) => this.get(`user/${userID}/endpoint`);

    getIntegrationServices = (params) => this.get('integration', params);

    getGroups = () => this.get('group');

    getGroup = (groupId) => this.get(`group/${groupId}`);

    getWindowsEndpointsAssignedToGroup = (groupId, pageNumber, filter = {}) =>
        this.get(`group/${groupId}/windows/endpoint/assigned`, {
            pageNumber,
            pageSize: 100,
            filter,
        });

    getAvailableWindowsEndpointsForGroup = (groupId, pageNumber, filter = {}) =>
        this.get(`group/${groupId}/windows/endpoint/available`, {
            pageNumber,
            pageSize: 100,
            filter,
        });

    /* getAndroidEndpointsAssignedToGroup = (groupId, pageNumber, filter = {}) => this.get(`group/${groupId}/windows/endpoint/assigned`, {
        pageNumber,
        pageSize: 100,
        filter
    });

    getAvailableAndroidEndpointsForGroup = (groupId, pageNumber) => this.get(`group/${groupId}/android/endpoint/available`, {
        pageNumber,
        pageSize: 100,
    }) */

    getWindowsConfigurationsForGroup = (groupId) => this.get(`group/${groupId}/windows/configuration`);

    getWindowsApplicationsForGroup = (groupId) => this.get(`group/${groupId}/windows/application`);

    getAzureGroups = (filter, pageNumber, pageSize) =>
        this.get('externalgroup', {
            filter,
            pageNumber,
            pageSize,
        });

    get = (endpoint, params = {}, overrides = {}) => {
        const requestedTimestamp = now();
        return new Promise((resolve, reject) => {
            api.get(endpoint, {
                signal: this.abortController.signal,
                cache: false,
                withCredentials: true,
                baseURL: this.baseUrl,
                ...overrides,
                params,
            })
                .then((response) => {
                    if (requestedTimestamp >= (expirationTimestamps[this.connectionID] || 0)) {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    if (error.status?.cancelled === false) {
                        // Always send the error.
                        reject(error);
                    }
                });
        });
    };

    /** DELETE */
    deleteGroup = (groupId) => this.delete(`group/${groupId}`);

    deleteUser = (userID) => this.delete(`user/${userID}`);

    deleteIntegrationService = (integrationServiceId) => this.delete(`integration/${integrationServiceId}`);

    removeWindowsEndpointsFromGroup = (groupId, endpointIds, filter = {}, excludeList, includeList) =>
        this.delete(`group/${groupId}/windows/endpoint`, {
            endpointRefIds: endpointIds,
            filter,
            excludeList,
            includeList,
        });

    removeAndroidEndpointsFromGroup = (groupId, endpointIds) =>
        this.delete(`group/${groupId}/android/endpoint`, {
            endpointRefIds: endpointIds,
        });

    removeConfigurationsFromGroup = (groupId, configurationIds, type) =>
        this.delete(`group/${groupId}/${type}/configuration`, {
            configurationRefIds: configurationIds,
        });

    removeApplicationsFromGroup = (groupId, applicationIds, type) =>
        this.delete(`group/${groupId}/${type}/application`, {
            applicationRefIds: applicationIds,
        });

    removeEndpointFromGroups = (endpointId, groupIds, type) =>
        this.delete(`endpoint/${endpointId}/${type}/group`, {
            groupRefIds: groupIds,
        });

    removeConfigurationFromGroups = (configurationId, groupIds, type) =>
        this.delete(`configuration/${configurationId}/${type}/group`, {
            groupRefIds: groupIds,
        });

    removeApplicationFromGroups = (applicationId, groupIds, type) =>
        this.delete(`application/${applicationId}/${type}/group`, {
            groupRefIds: groupIds,
        });

    delete = (endpoint, data = {}, overrides = {}) =>
        api.delete(endpoint, {
            baseURL: this.baseUrl,
            ...overrides,
            data,
        });

    updateUser = (userID, userObject) => this.update(`user/${userID}`, userObject);

    updateIntegrationService = (integrationServiceId, dataObject) => this.update(`integration/${integrationServiceId}`, dataObject);

    updateGroup = (groupId, dataObject) => this.update(`group/${groupId}`, dataObject);

    addWindowsEndpointsToGroup = (groupId, endpointIds, filter = {}, excludeList, includeList) =>
        this.update(`group/${groupId}/windows/endpoint`, {
            endpointRefIds: endpointIds,
            filter,
            excludeList,
            includeList,
        });

    addAndroidEndpointsToGroup = (groupId, endpointIds) =>
        this.update(`group/${groupId}/android/endpoint`, {
            endpointRefIds: endpointIds,
        });

    addAppleEndpointsToGroup = (groupId, endpointIds) =>
        this.update(`group/${groupId}/apple/endpoint`, {
            endpointRefIds: endpointIds,
        });

    addWindowsConfigurationsToGroup = (groupId, configurationIds) =>
        this.update(`group/${groupId}/windows/configuration`, {
            configurationRefIds: configurationIds,
        });

    addAndroidConfigurationsToGroup = (groupId, configurationIds) =>
        this.update(`group/${groupId}/android/configuration`, {
            configurationRefIds: configurationIds,
        });

    addAppleConfigurationsToGroup = (groupId, configurationIds) =>
        this.update(`group/${groupId}/apple/configuration`, {
            configurationRefIds: configurationIds,
        });

    addApplicationsToGroup = (groupId, applicationIds, type) =>
        this.update(`group/${groupId}/${type}/application`, {
            applicationRefIds: applicationIds,
        });

    addAndroidEndpointToGroups = (endpointId, groupIds) =>
        this.update(`endpoint/${endpointId}/android/group`, {
            groupRefIds: groupIds,
        });

    addAppleEndpointToGroups = (endpointId, groupIds) =>
        this.update(`endpoint/${endpointId}/apple/group`, {
            groupRefIds: groupIds,
        });

    addWindowsEndpointToGroups = (endpointId, groupIds) =>
        this.update(`endpoint/${endpointId}/windows/group`, {
            groupRefIds: groupIds,
        });

    addAndroidConfigurationToGroups = (configurationId, groupIds) =>
        this.update(`configuration/${configurationId}/android/group`, {
            groupRefIds: groupIds,
        });

    addAppleConfigurationToGroups = (configurationId, groupIds) =>
        this.update(`configuration/${configurationId}/apple/group`, {
            groupRefIds: groupIds,
        });
    addWindowsConfigurationToGroups = (configurationId, groupIds) =>
        this.update(`configuration/${configurationId}/windows/group`, {
            groupRefIds: groupIds,
        });

    addApplicationToGroups = (applicationId, groupIds, type) =>
        this.update(`application/${applicationId}/${type}/group`, {
            groupRefIds: groupIds,
        });

    update = (endpoint, data, overrides = {}) => {
        return new Promise((resolve, reject) => {
            api.put(endpoint, data, {
                baseURL: this.baseUrl,
                ...overrides,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /** POST */
    createUser = (userData = {}) => this.post('user', userData);

    createGroup = (dataObject) => this.post('group', dataObject);

    createIntegrationService = (data) => this.post('integration', data);

    performIntegrationServiceSync = (integrationServiceId) => this.post(`integration/${integrationServiceId}/sync`);

    contactCapaSystems = ({ subject, message, firstName, lastName, email, parentOrganizationId, organizationName }) =>
        this.post('mail/contact', { subject, message, firstName, lastName, email, parentOrganizationId, organizationName }, { baseURL: api.defaults.baseURL });

    post = (endpoint, data, overrides = {}) => {
        return new Promise((resolve, reject) => {
            api.post(endpoint, data, {
                baseURL: this.baseUrl,
                ...overrides,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /** MISC */
    cancel = () => {
        expirationTimestamps[this.connectionID] = now();
    };

    abort = () => {
        this.abortController.abort();
        delete expirationTimestamps[this.connectionID];
    };
}

export default ManagementConnection;
