/* eslint-disable react-hooks/exhaustive-deps */
import { SORT_DIRECTION, TOOLTIP } from '@capasystems/constants';
import { Avatar, Badge, Ellipsis, Icon, Tooltip, virtualizedTableColumnPropTypes } from '@capasystems/ui';
import { formatDate, formatTimestamp, isDefined } from '@capasystems/utils';
import { NOT_AVAILABLE } from '@thirdparty/constants';
import { useAuthContext } from '@thirdparty/ui';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import { BooleanBadge, CapaOneLink, DeviceAgentState, DeviceTypeIcon, TwoLineCellRenderer, UnitConverter, WithExperimentalFeatures } from '../../../index';

const DeviceDetails = ({ device, osTime }) => {
    const TooltipField = ({ children, label }) => {
        return (
            <div>
                <div className="tw-mb-1 tw-text-xs tw-font-medium tw-text-gray-200">{label}</div>
                <div className="tw-text-xs tw-font-semibold">{children || NOT_AVAILABLE}</div>
            </div>
        );
    };

    return (
        <Tooltip
            noPadding
            dark
            position={TOOLTIP.POSITION.LEFT_START}
            content={
                <div className="tw-grid tw-justify-between tw-gap-4 tw-p-4">
                    <TooltipField label="OS Version">{device.osVersion}</TooltipField>

                    <TooltipField label="Latest OS Startup">
                        <TwoLineCellRenderer
                            main={formatTimestamp(osTime)}
                            callToAction
                            secondary={<span className="tw-font-semibold">{dayjs(osTime).fromNow()}</span>}
                        />
                    </TooltipField>
                </div>
            }
        >
            <Icon
                type="infoOutlined"
                className="tw-text-black/60"
            />
        </Tooltip>
    );
};

const useEndpointSoftwareInventoryColumns = () => {
    const { permissions } = useAuthContext();

    const availableColumns = useMemo(() => {
        const allColumns = [
            {
                key: 'name',
                dataKey: 'Name',
                label: 'Name',
                minWidth: 300,
                maxWidth: 900,
                cellRenderer: ({ cellData, rowData }) => (
                    <TwoLineCellRenderer
                        main={cellData}
                        callToAction
                        secondary={rowData.Publisher}
                    />
                ),
            },
            {
                key: 'IsSystemComponent',
                dataKey: 'IsSystemComponent',
                label: 'System component',
                disableSort: true,
                minWidth: 120,
                maxWidth: 120,
                cellRenderer: ({ cellData }) => <BooleanBadge value={cellData} />,
            },
            {
                key: 'version',
                dataKey: 'Version',
                label: 'Version',
                minWidth: 160,
                maxWidth: 400,
                disableSort: true,
                cellRenderer: ({ cellData, rowData: { Language, Platform, platformName, languageName } }) => (
                    <TwoLineCellRenderer
                        main={cellData}
                        secondary={
                            <>
                                <span>{languageName}</span>
                                {languageName && <span>&nbsp;&middot;&nbsp;</span>}
                                <span>{platformName}</span>
                            </>
                        }
                    />
                ),
            },
            {
                key: 'installDate',
                dataKey: 'InstallDate',
                label: 'Install date',
                minWidth: 96,
                maxWidth: 96,
                defaultSortDirection: SORT_DIRECTION.DESC,
                cellRenderer: ({ cellData }) => (cellData ? formatDate(cellData) : cellData),
            },
            {
                key: 'size',
                dataKey: 'Size',
                label: 'Size',
                minWidth: 96,
                maxWidth: 96,
                justify: virtualizedTableColumnPropTypes.justify.end,
                cellRenderer: ({ cellData }) => (cellData ? <UnitConverter data={{ unit: 'bytes', value: cellData }} /> : null),
                defaultSortDirection: SORT_DIRECTION.DESC,
            },
            {
                key: 'userAccount',
                dataKey: 'UserAccount',
                label: 'User account',
                minWidth: 160,
                maxWidth: 400,
            },
            {
                key: 'Upgrade code',
                dataKey: 'UpgradeCode',
                label: 'Upgrade code',
                minWidth: 280,
                maxWidth: 400,
                disableSort: true,
            },
            {
                key: 'AppCode',
                dataKey: 'AppCode',
                label: 'Product code',
                minWidth: 280,
                maxWidth: 400,
                disableSort: true,
            },
        ];
        if (permissions.canViewExperimentalFeatures) {
            allColumns.splice(2, 0, {
                key: 'CpeMatchString',
                dataKey: 'CpeMatchString',
                label: 'Cpe Match',
                minWidth: 240,
                maxWidth: 400,
                disableSort: false,
                cellRenderer: ({ rowData }) => {
                    return (
                        <WithExperimentalFeatures tooltipPosition={TOOLTIP.POSITION.LEFT}>
                            {isDefined(rowData.CpeMatchString) ? (
                                <a
                                    target="_BLANK"
                                    href={`https://nvd.nist.gov/vuln/search/results?query=${rowData.CpeMatchString}`}
                                    rel="noreferrer"
                                >
                                    <Ellipsis>{rowData.CpeMatchString}</Ellipsis>
                                </a>
                            ) : (
                                <span className="tw-text-gray-300">{NOT_AVAILABLE}</span>
                            )}
                        </WithExperimentalFeatures>
                    );
                },
            });
        }
        return allColumns;
    }, []);
    return availableColumns;
};

const useWindowsEndpointListColumns = (organizations, permissions, reporting) => {
    const [organizationsIdNameObject] = useState(
        organizations.reduce(function (acc, cur, i) {
            acc[cur.id] = cur.name;
            return acc;
        }, {})
    );

    const availableColumns = useMemo(() => {
        const columns = [
            {
                key: 'name',
                dataKey: 'name',
                label: 'Name',
                minWidth: 300,
                cellRenderer: ({ rowData }) => {
                    if (reporting) {
                        return (
                            <TwoLineCellRenderer
                                main={rowData.name}
                                callToAction
                                secondary={rowData.osName}
                            />
                        );
                    }
                    return (
                        <CapaOneLink to={`windows/device/${rowData.id || rowData['_id']}`}>
                            <TwoLineCellRenderer
                                main={rowData.name}
                                callToAction
                                secondary={rowData.osName}
                            />
                        </CapaOneLink>
                    );
                },
            },
            {
                key: 'online',
                dataKey: 'online',
                label: 'Agent Status',
                minWidth: 95,
                maxWidth: 140,
                justify: virtualizedTableColumnPropTypes.justify.start,
                cellRenderer: ({ cellData, rowData }) => <DeviceAgentState device={rowData} />,
            },
            {
                key: 'deviceType',
                dataKey: 'deviceType',
                label: '',
                minWidth: 75,
                maxWidth: 95,
                justify: virtualizedTableColumnPropTypes.justify.start,
                cellRenderer: ({ rowData }) => (
                    <Avatar className="tw-h-10 tw-w-10 tw-bg-slate-100 tw-text-sm tw-font-semibold tw-uppercase tw-text-slate-600">
                        <DeviceTypeIcon
                            device={rowData}
                            className="tw-text-lg"
                        />
                    </Avatar>
                ),
            },
            {
                key: 'serviceStartUp',
                dataKey: 'serviceStartUp',
                label: 'Agent Startup',
                minWidth: 120,
                justify: virtualizedTableColumnPropTypes.justify.start,
                cellRenderer: ({ rowData }) => dayjs(rowData.serviceStartUp).fromNow(),
            },
            {
                key: 'software.failedPatchCount',
                dataKey: 'software.failedPatchCount',
                label: 'Failed Patch Count',
                minWidth: 136,
                maxWidth: 136,
                justify: virtualizedTableColumnPropTypes.justify.end,
                defaultSortDirection: SORT_DIRECTION.DESC,
                cellRenderer: ({ rowData, cellData }) => {
                    if (rowData.software) {
                        return rowData.software.failedPatchCount;
                    }
                    return 'Unknown';
                },
                accessDenied: !permissions.canManageSoftwarePatching,
            },
            {
                key: 'software.patchCount',
                dataKey: 'software.patchCount', // Use patchCount for sorting.
                secondary: 'driver.patchCount',
                label: 'Updates',
                minWidth: 96,
                maxWidth: 96,
                justify: virtualizedTableColumnPropTypes.justify.start,
                defaultSortDirection: SORT_DIRECTION.DESC,
                accessDenied: !permissions.canManageSoftwarePatching && !permissions.canManageDrivers,
                cellRenderer: ({ rowData }) => {
                    const { software, driver } = rowData;
                    return (
                        <div className="tw-flex tw-gap-x-4">
                            {permissions.canManageSoftwarePatching && software && isDefined(software.patchCount) && (
                                <PatchCountBadge
                                    count={software.patchCount}
                                    iconType="capaoneUpdater"
                                    tooltipContent={<div>{pluralize('software update', software.patchCount, true)} available</div>}
                                    upToDateTooltipContent="Software is up-to-date"
                                />
                            )}
                            {permissions.canManageDrivers && driver && isDefined(driver.patchCount) && (
                                <PatchCountBadge
                                    count={driver.patchCount}
                                    iconType="capaDrivers"
                                    tooltipContent={<div>{pluralize('driver update', driver.patchCount, true)} available</div>}
                                    upToDateTooltipContent="Drivers are up-to-date"
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                key: 'osStartUp',
                dataKey: 'osStartUp', // To pass the OS startup data
                label: '',
                minWidth: 64,
                maxWidth: 64,
                justify: virtualizedTableColumnPropTypes.justify.end,
                cellRenderer: ({ cellData, rowData }) => {
                    return (
                        <DeviceDetails
                            device={rowData}
                            osTime={cellData}
                        />
                    );
                },
            },
        ];
        return columns.filter((c) => !c.accessDenied);
    }, []);

    return availableColumns;
};

const PatchCountBadge = ({ count = null, iconType, tooltipContent, upToDateTooltipContent }) => {
    if (count === 0) {
        return (
            <Tooltip
                position={TOOLTIP.POSITION.LEFT}
                bold
                dark
                extraPadding
                content={upToDateTooltipContent}
            >
                <div>
                    <Badge
                        overlap="circular"
                        small
                        color="secondary"
                        badgeContent={
                            <div className="tw-relative">
                                <Icon
                                    type="checkmark"
                                    className="tw-absolute tw--left-2 tw--top-2 tw-h-4 tw-w-4"
                                />
                            </div>
                        }
                    >
                        <Icon
                            type={iconType}
                            color="primary"
                        ></Icon>
                    </Badge>
                </div>
            </Tooltip>
        );
    }
    return (
        <Tooltip
            position={TOOLTIP.POSITION.LEFT}
            bold
            dark
            extraPadding
            content={tooltipContent}
        >
            <div>
                <Badge
                    overlap="circular"
                    badgeContent={count}
                    small
                    color="error"
                >
                    <Icon
                        type={iconType}
                        color="primary"
                    ></Icon>
                </Badge>
            </div>
        </Tooltip>
    );
};

export { useEndpointSoftwareInventoryColumns, useWindowsEndpointListColumns };
