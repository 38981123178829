export const windowsSecuritySeverityRatings = [
    {
        id: 'CRITICAL',
        name: 'Critical',
        classNames: 'tw-bg-red-100 tw-text-red-900',
        range: {
            min: '9.0',
            max: '10.0',
        },
        description:
            'Critical severity vulnerabilities are extremely easy to exploit with devastating impact.\nThese vulnerabilities require immediate attention.',
    },
    {
        id: 'HIGH',
        name: 'High',
        classNames: 'tw-bg-orange-100 tw-text-orange-900',
        range: {
            min: '7.0',
            max: '8.9',
        },
        description:
            'High severity vulnerabilities are relatively easy to exploit with significant impact.\nThese vulnerabilities should be addressed promptly.',
    },
    {
        id: 'MEDIUM',
        name: 'Medium',
        classNames: 'tw-bg-amber-100 tw-text-yellow-900',
        range: {
            min: '4.0',
            max: '6.9',
        },
        description:
            'Medium severity vulnerabilities require specific conditions to exploit with moderate impact.\n These vulnerabilities should be addressed when possible.',
    },
    {
        id: 'LOW',
        name: 'Low',
        classNames: 'tw-bg-blue-100 tw-text-blue-900',
        range: {
            min: '0.1',
            max: '3.9',
        },
        description:
            'Low severity vulnerabilities require specific conditions to exploit with minimal impact.\nThese vulnerabilities should be addressed when convenient.',
    },
];

export const windowsSecurityVulnerabilityManagementFocus = {
    endpoint: {
        id: 'endpoint',
        tabName: 'Endpoints',
        apiIdentifier: 'byDevice',
        iconType: 'computer',
        entity: 'vulnerable endpoint',
        defaultSortBy: 'highestCVSS',
    },
    software: {
        id: 'software',
        tabName: 'Software',
        apiIdentifier: 'bySoftware',
        iconType: 'apps',
        entity: 'vulnerable software',
        defaultSortBy: 'highestCVSS',
    },
    cveList: {
        id: 'cve',
        tabName: 'Detected CVEs',
        apiIdentifier: 'byCve',
        iconType: 'badgeExclamation',
        entity: 'vulnerability',
        defaultSortBy: 'riskScore',
    },
};

export const windowsSecurityPortalContainerId = 'security-portal-container';
