import { TOOLTIP } from '@capasystems/constants';
import { Icon, Tooltip } from '@capasystems/ui';
import pluralize from 'pluralize';

const QueryBuilderEndAdornment = ({ value, onClick, showMongoHints, placeholder }) => {
    if (value) {
        return (
            <Tooltip
                content="Clear"
                position={TOOLTIP.POSITION.LEFT}
                dark
                bold
            >
                <Icon
                    onClick={onClick}
                    type="clear"
                    size="small"
                />
            </Tooltip>
        );
    } else if (showMongoHints) {
        return (
            <Tooltip
                position={TOOLTIP.POSITION.LEFT}
                fullWidth
                dark
                bold
                extraPadding
                content={
                    <div className="tw-leading-loose">
                        <div className="tw-text-sm ">Tip</div>
                        <div className="tw-mt-1">
                            Use a comma-separated list to search for multiple {pluralize(placeholder)}.<br />
                            For example: First condition, Second condition
                        </div>
                    </div>
                }
            >
                <Icon
                    onClick={onClick}
                    type="tipsAndUpdatesOutlined"
                    size="small"
                />
            </Tooltip>
        );
    }
    return null;
};

export { QueryBuilderEndAdornment };
