import { BUTTON, TOOLTIP } from '@capasystems/constants';
import {
    Button,
    CircularProgressWithLabel,
    Collapse,
    Column,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Ellipsis,
    EmptyState,
    Icon,
    IconButton,
    Input,
    LayoutCentered,
    LayoutRow,
    Loading,
    Page,
    Select,
    Tab,
    Tabs,
    Tooltip,
    VirtualizedTable,
    virtualizedTableColumnPropTypes,
} from '@capasystems/ui';
import { getSortingFunction } from '@capasystems/utils';
import { AZURE, CRON_DAY_OF_MONTH, CRON_HOURS_WITH_MINUTES, CRON_WEEK_DAYS } from '@thirdparty/constants';
import { cronExtractor } from '@thirdparty/utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import ConfirmDialog from '../confirm-dialog/confirm-dialog';
import { useColumnPicker } from '../hooks/thirdparty-hooks';
import { useManagementApi } from '../hooks/useApi/useApi';
import { useManagementIntegrationsSocket } from '../hooks/useSocket/useSocket';
import { PageTitle } from '../page/page-title';
import { ActionsDialog, WidgetPaper } from '../thirdparty-components/thirdparty-components';
import { userPropertiesConstant } from './integration-management-components-constants';

const availableTabs = {
    azure: {
        id: 'AZURE',
        name: 'Azure AD',
    },
};

const itemsSortingFunction = getSortingFunction({
    sortBy: 'name',
});

const monthSortingFunction = getSortingFunction(
    {
        sortBy: 'name',
    },
    true
);

const weekDaysSortingFunction = getSortingFunction(
    {
        sortBy: 'sortingIndex',
    },
    true
);

const CONFIRM_TEXT_LENGTH = 6;

const SYNC_FREQUENCY = {
    NEVER: 'NEVER',
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
};

const IntegrationManagementInputField = forwardRef(({ label, className, hasError, name, onChange, ...props }, ref) => {
    const [showLastSync, setShowLastSync] = useState(false);
    const [isValid, setIsValid] = useState(ref.current[name] !== '');

    return (
        <div
            className={classNames(className, {
                'tw-mb-6': true,
            })}
        >
            <h2 className="tw-mb-2 tw-text-xs">{label}</h2>
            <Input
                callToAction
                light
                endAdornment={
                    isValid ? (
                        <Icon
                            type="checkmark"
                            size="small"
                            className="tw-mr-1 tw-text-emerald-500"
                        />
                    ) : (
                        <span className="tw-mr-1 tw-text-xs tw-text-neutral-300">Required</span>
                    )
                }
                onChange={(e) => {
                    ref.current[name] = e.target.value.trim();
                    setIsValid(ref.current[name] !== '');
                    onChange();
                }}
                name={name}
                {...props}
            />
        </div>
    );
});

const IntegrationManagementAzureForm = ({ open, onCancel, onSubmit, data = null }) => {
    const managementApi = useManagementApi();
    const [isProcessing, setIsProcessing] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [formIsInvalid, setFormIsInvalid] = useState(true);
    const [showContent, setShowContent] = useState(false);
    const [syncFrequency, setSyncFrequency] = useState(SYNC_FREQUENCY.NEVER);
    const [showAddNewSecret, setShowAddNewSecret] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [selectedHour, setSelectedHour] = useState([]);
    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState([]);
    const [selectedDaysOfMonth, setSelectedDaysOfMonth] = useState([]);
    const [selectedUserProperties, setSelectedUserProperties] = useState([]);

    const formRef = useRef({});

    const validateForm = () => {
        setFormIsInvalid(Object.values(formRef.current).some((value) => value.trim() === ''));
    };

    const onTabChange = (_, tabValue) => {
        setSyncFrequency(tabValue);
        validateForm();
    };

    const onCreate = () => {
        setIsProcessing(true);
        setErrorMessage(null);

        const errorHandler = (errorResponse) => {
            setErrorMessage(errorResponse.data.message || 'An unknown error occurred');
            console.log(errorResponse);
            setIsProcessing(false);
        };

        const syncConfiguration = {
            cronExpression: null,
            tz: window.Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        if (syncFrequency !== SYNC_FREQUENCY.NEVER) {
            const hourPart =
                selectedHour
                    .map(({ id }) => id)
                    .sort((a, b) => a - b)
                    .join() || '0';
            if (syncFrequency === SYNC_FREQUENCY.MONTHLY && selectedDaysOfMonth.length !== CRON_DAY_OF_MONTH.length) {
                const monthPart =
                    selectedDaysOfMonth
                        .map(({ id }) => id)
                        .sort((a, b) => a - b)
                        .join() || '*';
                syncConfiguration.cronExpression = `0 ${hourPart} ${monthPart} * *`;
            } else if (syncFrequency === SYNC_FREQUENCY.WEEKLY && selectedDaysOfWeek.length !== CRON_WEEK_DAYS.length) {
                const weekPart =
                    selectedDaysOfWeek
                        .map(({ id }) => id)
                        .sort((a, b) => a - b)
                        .join() || '*';
                syncConfiguration.cronExpression = `0 ${hourPart} * * ${weekPart}`;
            } else {
                syncConfiguration.cronExpression = `0 ${hourPart} * * *`;
            }
        }

        const userProperties = selectedUserProperties.map(({ id }) => id);

        if (data) {
            managementApi
                .updateIntegrationService(data.id, {
                    name: formRef.current.name,
                    syncConfiguration,
                    userProperties,
                    clientSecret: formRef.current.newClientSecret, // Might be undefined which is fine.
                    clientSecretExpiration: formRef.current.clientSecretExpiration,
                })
                .then(() => {
                    onSubmit();
                })
                .catch(errorHandler);
        } else {
            managementApi
                .createIntegrationService({
                    ...formRef.current,
                    syncConfiguration,
                    userProperties,
                })
                .then(() => {
                    onSubmit();
                })
                .catch(errorHandler);
        }
    };
    const onEnter = () => {
        setFormIsInvalid(true);
        setIsProcessing(false);
        setHasError(false);
        formRef.current = {
            name: data?.name || '',
            clientId: data?.clientId || '',
            tenantId: data?.tenantId || '',
            clientSecret: data?.obfuscatedClientSecret || '',
            type: data?.type || 'AZURE',
            clientSecretExpiration: data?.clientSecretExpiration || '',
        };

        if (data?.userProperties) {
            const tempSelectedUserProperties = userPropertiesConstant.filter((up) => data.userProperties.indexOf(up.id) > -1);
            setSelectedUserProperties(tempSelectedUserProperties);
        } else {
            setSelectedUserProperties([]);
        }

        if (data?.syncConfiguration.cronExpression) {
            const [, hoursOfDay, dayOfMonth, , dayOfWeek] = data.syncConfiguration.cronExpression.split(' ').map((v) => v.split(','));
            const tempSelectedHour = CRON_HOURS_WITH_MINUTES.filter((h) => hoursOfDay.indexOf(h.id) > -1);
            const tempSelectedDaysOfWeek = CRON_WEEK_DAYS.filter((wd) => dayOfWeek.indexOf(wd.id) > -1);
            const tempSelectedDaysOfMonth = CRON_DAY_OF_MONTH.filter((md) => dayOfMonth.indexOf(md.id) > -1);
            setSelectedHour(tempSelectedHour);
            setSelectedDaysOfWeek(tempSelectedDaysOfWeek);
            setSelectedDaysOfMonth(tempSelectedDaysOfMonth);
            if (tempSelectedDaysOfMonth.length > 0) {
                setSyncFrequency(SYNC_FREQUENCY.MONTHLY);
            } else if (tempSelectedDaysOfWeek.length > 0) {
                setSyncFrequency(SYNC_FREQUENCY.WEEKLY);
            } else {
                setSyncFrequency(SYNC_FREQUENCY.DAILY);
            }
        } else {
            setSelectedHour([]);
            setSelectedDaysOfWeek([]);
            setSelectedDaysOfMonth([]);
            setSyncFrequency(SYNC_FREQUENCY.NEVER);
        }
        setShowContent(true);
    };

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            onEnter={onEnter}
            onExited={() => {
                setShowAddNewSecret(false);
                setErrorMessage(null);
                setShowContent(false);
            }}
            unmountOnExit
            confirm
        >
            <DialogTitle>
                <div className="tw-grid tw-grid-cols-auto-1fr-auto tw-items-center tw-gap-4">
                    <Icon
                        type="azure"
                        className="tw-h-12 tw-w-12 tw-text-azure"
                    />
                    <PageTitle category={data ? 'Edit' : 'New'}>Azure AD integration</PageTitle>
                    <Button
                        noMargin
                        href="https://capasystems.atlassian.net/wiki/spaces/CP/pages/19499810840/CapaOne+-+Azure+AD+intergration"
                        target="_BLANK"
                        rel="noreferrer"
                        size="small"
                    >
                        Need help?
                    </Button>
                </div>
                <Collapse
                    in={errorMessage !== null}
                    unmountOnExit
                >
                    <div className="tw-mt-4 tw-rounded tw-bg-red-50 tw-py-2 tw-pl-4 tw-pr-1 tw-text-red-600 tw-ring-1 tw-ring-red-100">
                        <div className="tw-grid tw-grid-cols-1fr-auto tw-items-center tw-gap-4">
                            <b className="tw-text-sm">{errorMessage}</b>
                            <IconButton
                                noMargin
                                color={BUTTON.INHERIT}
                                onClick={() => setErrorMessage(null)}
                            >
                                <Icon
                                    type="remove"
                                    size="small"
                                />
                            </IconButton>
                        </div>
                    </div>
                </Collapse>
            </DialogTitle>

            {showContent && (
                <DialogContent>
                    {data !== null && (
                        <div className="tw-mb-4 tw-rounded tw-bg-slate-50 tw-px-4 tw-py-4 tw-pb-2 tw-pr-2 tw-text-slate-900 tw-ring-1 tw-ring-slate-100">
                            <div className="tw-grid tw-grid-cols-1fr-auto tw-items-center tw-gap-2">
                                <div className="tw-mb-2 tw-text-xs tw-font-semibold">Application (client) ID</div>
                                <code className="tw-mb-2 tw-mr-2 tw-text-right tw-text-xs tw-font-semibold">{formRef.current.clientId}</code>
                                <div className="tw-text-xs tw-font-semibold">Directory (tenant) ID</div>
                                <code className="tw-mr-2 tw-text-right tw-text-xs tw-font-semibold">{formRef.current.tenantId}</code>
                                <div className="tw-text-xs tw-font-semibold">Client secret value</div>
                                <div className="tw-text-right">
                                    <code className="tw-font-semibold">{formRef.current.clientSecret}</code>
                                    <IconButton
                                        noMargin
                                        className="tw-ml-2"
                                        color={BUTTON.INHERIT}
                                        onClick={() => {
                                            if (showAddNewSecret) {
                                                delete formRef.current.newClientSecret;
                                            } else {
                                                formRef.current.newClientSecret = '';
                                            }
                                            setShowAddNewSecret(!showAddNewSecret);
                                            validateForm();
                                        }}
                                    >
                                        <Tooltip
                                            content={showAddNewSecret ? 'Keep using current client secret' : 'Update your client secret...'}
                                            dark
                                            extraPadding
                                            bold
                                            position={TOOLTIP.POSITION.LEFT}
                                        >
                                            {showAddNewSecret ? (
                                                <Icon
                                                    type="block"
                                                    size="small"
                                                />
                                            ) : (
                                                <Icon
                                                    type="edit"
                                                    size="small"
                                                />
                                            )}
                                        </Tooltip>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    )}
                    <IntegrationManagementInputField
                        label="Name"
                        defaultValue={formRef.current.name}
                        disabled={isProcessing}
                        onChange={validateForm}
                        ref={formRef}
                        name="name"
                        hasError={hasError}
                        autoFocus
                    />
                    {data === null && (
                        <>
                            <IntegrationManagementInputField
                                label="Application (client) ID"
                                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                                defaultValue={formRef.current.clientId}
                                disabled={isProcessing}
                                onChange={validateForm}
                                ref={formRef}
                                name="clientId"
                                hasError={hasError}
                            />
                            <IntegrationManagementInputField
                                label="Directory (tenant) ID"
                                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                                defaultValue={formRef.current.tenantId}
                                disabled={isProcessing}
                                onChange={validateForm}
                                ref={formRef}
                                name="tenantId"
                                hasError={hasError}
                            />

                            <IntegrationManagementInputField
                                label="Client secret value"
                                placeholder="Client secret value"
                                defaultValue={formRef.current.clientSecret}
                                type="password"
                                disabled={isProcessing}
                                onChange={validateForm}
                                name="clientSecret"
                                ref={formRef}
                                hasError={hasError}
                                autoComplete="new-password"
                            />
                        </>
                    )}
                    {data !== null && (
                        <Collapse
                            in={showAddNewSecret}
                            unmountOnExit
                        >
                            <IntegrationManagementInputField
                                label="New client secret value"
                                placeholder="New client secret value"
                                defaultValue={formRef.current.newClientSecret}
                                type="password"
                                disabled={isProcessing}
                                onChange={validateForm}
                                name="newClientSecret"
                                ref={formRef}
                                hasError={hasError}
                                autoComplete="new-password"
                            />
                        </Collapse>
                    )}
                    <IntegrationManagementInputField
                        label="Client secret expiration date"
                        defaultValue={dayjs(formRef.current.clientSecretExpiration).format('YYYY-MM-DD') || ''}
                        disabled={isProcessing}
                        onChange={validateForm}
                        ref={formRef}
                        name="clientSecretExpiration"
                        hasError={hasError}
                        autoFocus
                        type="date"
                    />

                    <div className="tw-mb-6">
                        <div className="tw-flex tw-space-x-1">
                            <h2 className="tw-mb-2 tw-text-xs">User Properties</h2>

                            <Tooltip
                                content="We keep the right to store the user properties ID and Email"
                                fullWidth
                                position={TOOLTIP.POSITION.RIGHT}
                                dark
                                extraPadding
                                bold
                            >
                                <Icon
                                    type="infoOutlined"
                                    size="small"
                                />
                            </Tooltip>
                        </div>
                        <Select
                            selectedOptions={selectedUserProperties}
                            options={userPropertiesConstant}
                            multiple
                            onChange={(v) => {
                                setSelectedUserProperties(v);
                                validateForm();
                            }}
                            callToAction
                            light
                        />
                    </div>

                    <h2 className="tw-mb-2 tw-text-xs">Automatic synchronization</h2>
                    <Tabs
                        value={syncFrequency}
                        onChange={onTabChange}
                        pills
                        variant="fullWidth"
                        className="tw-mb-4"
                    >
                        <Tab
                            key={SYNC_FREQUENCY.NEVER}
                            disableRipple
                            label="Never"
                            value={SYNC_FREQUENCY.NEVER}
                            className="tw-text-xs"
                        />
                        <Tab
                            key={SYNC_FREQUENCY.DAILY}
                            disableRipple
                            label="Daily"
                            value={SYNC_FREQUENCY.DAILY}
                            className="tw-text-xs"
                        />
                        <Tab
                            key={SYNC_FREQUENCY.WEEKLY}
                            disableRipple
                            label="Weekly"
                            value={SYNC_FREQUENCY.WEEKLY}
                            className="tw-text-xs"
                        />
                        <Tab
                            key={SYNC_FREQUENCY.MONTHLY}
                            disableRipple
                            label="Monthly"
                            value={SYNC_FREQUENCY.MONTHLY}
                            className="tw-text-xs"
                        />
                    </Tabs>
                    <Collapse in={syncFrequency !== SYNC_FREQUENCY.NEVER}>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                            {syncFrequency === SYNC_FREQUENCY.MONTHLY && (
                                <div>
                                    <h2 className="tw-mb-2 tw-text-xs">At day(s) of month</h2>
                                    <Select
                                        selectedOptions={selectedDaysOfMonth}
                                        options={CRON_DAY_OF_MONTH}
                                        multiple
                                        onChange={(v) => {
                                            setSelectedDaysOfMonth(v.sort(monthSortingFunction));
                                            validateForm();
                                        }}
                                        placeholder="Every day of the month"
                                        callToAction
                                    />
                                </div>
                            )}
                            {syncFrequency === SYNC_FREQUENCY.WEEKLY && (
                                <div>
                                    <h2 className="tw-mb-2 tw-text-xs">At day(s) of the week</h2>
                                    <Select
                                        selectedOptions={selectedDaysOfWeek}
                                        options={CRON_WEEK_DAYS}
                                        multiple
                                        onChange={(v) => {
                                            setSelectedDaysOfWeek(v.sort(weekDaysSortingFunction));
                                            validateForm();
                                        }}
                                        placeholder="Every day of the week"
                                        callToAction
                                    />
                                </div>
                            )}
                            <div
                                className={classNames({
                                    'tw-mb-4': true,
                                    'tw-col-span-2': syncFrequency === SYNC_FREQUENCY.DAILY,
                                })}
                            >
                                <h2 className="tw-mb-2 tw-text-xs">At hour</h2>
                                <Select
                                    selectedOptions={selectedHour}
                                    options={CRON_HOURS_WITH_MINUTES}
                                    onChange={(v) => {
                                        setSelectedHour(v);
                                        validateForm();
                                    }}
                                    placeholder="00:00"
                                    callToAction
                                    required
                                />
                            </div>
                        </div>
                    </Collapse>
                </DialogContent>
            )}
            <DialogActions>
                {isProcessing ? (
                    <Loading
                        size={16}
                        className="tw-text- tw-mr-4"
                    />
                ) : (
                    <Button
                        color={BUTTON.PRIMARY}
                        variant={BUTTON.RAISED}
                        onClick={onCreate}
                        disabled={formIsInvalid}
                    >
                        {data ? 'Save' : 'Create'}
                    </Button>
                )}
                <Button
                    onClick={onCancel}
                    disabled={isProcessing}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const IntegrationManagementLandingPage = () => {
    const [showAzureDialog, setShowAzureDialog] = useState(false);

    const showIntegrationOptions = () => {
        setShowAzureDialog(true);
    };

    const closeAzureDialog = () => {
        setShowAzureDialog(false);
    };

    const onSubmit = () => {
        closeAzureDialog();
    };

    return (
        <Page title="Integrations">
            <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-cols-1 tw-grid-rows-auto-1fr tw-gap-4 tw-p-4">
                <div className="tw-flex tw-items-end tw-justify-end">
                    <Button
                        noMargin
                        color={BUTTON.PRIMARY}
                        variant={BUTTON.RAISED}
                        onClick={showIntegrationOptions}
                    >
                        New
                    </Button>
                    <IntegrationManagementAzureForm
                        open={showAzureDialog}
                        onCancel={closeAzureDialog}
                        onSubmit={onSubmit}
                    />
                </div>
                <IntegrationManagementAzureTable showIntegrationOptions={showIntegrationOptions} />
            </div>
        </Page>
    );
};

const syncAction = {
    id: 'sync',
    icon: 'cloudSync',
    name: 'Sync now',
};
const editAction = {
    id: 'edit',
    icon: 'editOutlined',
    color: BUTTON.DEFAULT,
    name: 'Edit',
};
const deleteAction = {
    id: 'delete',
    name: 'Delete',
};
const actions = [editAction, syncAction, deleteAction];

const IntegrationManagementAzureTable = ({ showIntegrationOptions }) => {
    const columnPicker = useColumnPicker({
        id: 'management-integration-list',
        lockedColumns: ['Name'],
    });
    const [showLastSync, setShowLastSync] = useState(false);
    const managementApi = useManagementApi();
    const [deleteState, setDeleteState] = useState({
        open: false,
        isProcessing: true,
        anErrorOccurred: false,
    });
    const [items, setItems] = useState([]);
    const [showIntegrationManagementAzureForm, setShowIntegrationManagementAzureForm] = useState(false);
    const [widgetPaperProps, setWidgetPaperProps] = useState({
        loading: true,
        errorMessage: null,
    });

    const showLastSyncDialog = () => {
        setShowLastSync(true);
    };

    const closeLastSyncDialog = () => {
        setShowLastSync(false);
    };

    useManagementIntegrationsSocket(
        useCallback(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
            if (updateOperation) {
                setItems((currentItems) => {
                    return currentItems
                        .map((item) => {
                            if (item.id === documentId) {
                                return {
                                    ...fullDocument,
                                    id: documentId,
                                };
                            }
                            return item;
                        })
                        .sort(itemsSortingFunction);
                });
            } else if (insertOperation) {
                setItems((currentItems) => {
                    return [
                        ...currentItems,
                        {
                            ...fullDocument,
                            id: documentId,
                        },
                    ].sort(itemsSortingFunction);
                });
            } else if (deleteOperation) {
                setItems((currentItems) => {
                    return currentItems.filter((item) => item.id !== documentId);
                });
            }
        }, [])
    );

    useEffect(() => {
        managementApi
            .getIntegrationServices()
            .then((response) => {
                setItems(response.sort(itemsSortingFunction));
                setWidgetPaperProps({});
            })
            .catch(() => {
                setWidgetPaperProps({
                    loading: false,
                    errorMessage: 'Could not load Azure AD integration services',
                });
            });
    }, [managementApi]);

    const [menuState, setMenuState] = useState({
        open: false,
        rowData: {
            name: '',
        },
    });

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onActionClick = (action) => {
        closeMenu();
        switch (action.id) {
            case editAction.id:
                setShowIntegrationManagementAzureForm(true);
                break;
            case syncAction.id:
                if (menuState.rowData.syncStatus !== AZURE.SYNC_STATUS.SYNC_IN_PROGRESS) {
                    managementApi
                        .performIntegrationServiceSync(menuState.rowData.id)
                        .then((response) => {
                            // TODO
                        })
                        .catch((e) => {
                            // TODO
                        });
                } else {
                    alert('Sync is already in progress');
                }
                break;
            case deleteAction.id:
                onDelete();
                break;
        }
    };

    const closeDeleteDialog = () => {
        setDeleteState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onDelete = () => {
        if (deleteState.open) {
            setDeleteState({
                open: true,
                isProcessing: true,
            });
            managementApi
                .deleteIntegrationService(menuState.rowData.id)
                .then(() => {
                    closeDeleteDialog();
                    setItems((currentItems) => {
                        return currentItems.filter((item) => item.id !== menuState.rowData.id);
                    });
                })
                .catch(() => {
                    setDeleteState({
                        open: true,
                        anErrorOccurred: true,
                        confirmText: menuState.rowData.id.substr(0, CONFIRM_TEXT_LENGTH).toUpperCase(),
                    });
                });
        } else {
            closeMenu();
            setDeleteState({
                open: true,
                disabled: true,
                confirmText: menuState.rowData.id.substr(0, CONFIRM_TEXT_LENGTH).toUpperCase(),
            });
        }
    };

    const syncStatusCellRenderer = ({ rowData }) => {
        if (rowData.syncStatus === AZURE.SYNC_STATUS.SYNC_IN_PROGRESS) {
            return (
                <LayoutRow
                    verticalAlign="center"
                    align="end"
                >
                    <div className="tw-w-24">
                        <CircularProgressWithLabel
                            size={45}
                            value={Math.trunc(rowData?.syncProgress) || 0}
                        />
                    </div>
                </LayoutRow>
            );
        }
        if (rowData.syncStatus === AZURE.SYNC_STATUS.SUCCESS) {
            return (
                <>
                    <div
                        onClick={showLastSyncDialog}
                        className="tw-cursor-pointer"
                    >
                        <LayoutRow
                            verticalAlign="center"
                            align="end"
                            className="tw-text-emerald-500"
                        >
                            <span>Synchronization completed</span>
                            <Icon
                                type="badgeCheckmark"
                                className="tw-ml-1 tw-h-5 tw-w-5 tw-text-teal-400"
                            />
                        </LayoutRow>
                    </div>
                    <LastSyncDialog rowData={rowData} />
                </>
            );
        }
        if (rowData.syncStatus === AZURE.SYNC_STATUS.FAILED) {
            return (
                <>
                    <div
                        onClick={showLastSyncDialog}
                        className="tw-cursor-pointer"
                    >
                        <LayoutRow
                            verticalAlign="center"
                            align="end"
                            className="tw-text-red-500"
                        >
                            <span>Synchronization failed</span>
                            <Icon
                                className="tw-ml-1 tw-h-5 tw-w-5 tw-text-red-500"
                                type="error"
                                color="error"
                            />
                        </LayoutRow>
                    </div>
                    <LastSyncDialog rowData={rowData} />
                </>
            );
        }
        if (rowData.syncStatus === AZURE.SYNC_STATUS.NEVER_SYNCED) {
            return (
                <LayoutRow
                    verticalAlign="center"
                    align="end"
                    className="tw-text-tiny tw-text-neutral-300"
                >
                    Never synchronized
                </LayoutRow>
            );
        }

        return rowData.syncStatus;
    };
    const LastSyncDialog = ({ rowData }) => {
        return (
            <Dialog
                open={showLastSync}
                onClose={() => closeLastSyncDialog()}
                size={'md'}
            >
                <DialogTitle>Latest Synchronization Log</DialogTitle>
                <div className="tw-grid tw-max-h-full tw-overflow-auto">
                    <div className="tw-max-h-full tw-overflow-auto tw-whitespace-pre-wrap tw-break-words tw-bg-slate-800 tw-p-4 tw-font-mono tw-text-tiny tw-leading-loose tw-text-white">
                        {rowData.lastSyncLog}
                    </div>
                </div>
                <DialogActions>
                    <Button onClick={() => closeLastSyncDialog()}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const lastSyncCellRenderer = ({ rowData }) => {
        if (rowData.lastSyncStop) {
            return <Ellipsis>Synchronized {dayjs(rowData.lastSyncStop).fromNow()}</Ellipsis>;
        }
        if (rowData.syncStatus === AZURE.SYNC_STATUS.NEVER_SYNCED) {
            // return <b className="tw-text-xs tw-text-red-300">Awaiting initial sync.</b>;
        }
        if (rowData.syncStatus === AZURE.SYNC_STATUS.SYNC_IN_PROGRESS) {
            // return "First sync. in progress";
        }
        return null;
    };

    const clientSecretExpirationCellRenderer = ({ rowData }) => {
        if (rowData.clientSecretExpiration) {
            return dayjs(rowData.clientSecretExpiration).format('MM-DD-YYYY');
        } else {
            return 'No expiration date set';
        }
    };

    const closeAzureDialog = () => {
        setShowIntegrationManagementAzureForm(false);
    };

    return (
        <WidgetPaper
            className="tw-h-full"
            headerless
            {...widgetPaperProps}
        >
            <ActionsDialog
                open={menuState.open}
                anchorEl={menuState.anchorEl}
                onClose={closeMenu}
                category="Azure AD integration"
                title={menuState.rowData.name}
                actions={actions}
                onActionClick={onActionClick}
            />
            <IntegrationManagementAzureForm
                open={showIntegrationManagementAzureForm}
                onCancel={closeAzureDialog}
                onSubmit={closeAzureDialog}
                data={menuState.rowData}
            />
            <ConfirmDialog
                onConfirm={onDelete}
                disabled={deleteState.disabled}
                onCancel={closeDeleteDialog}
                open={deleteState.open}
                isProcessing={deleteState.isProcessing}
                title={
                    <div className="tw-grid tw-grid-cols-auto-1fr-auto tw-items-center tw-gap-4">
                        <Icon
                            type="azure"
                            className="tw-h-12 tw-w-12 tw-text-azure"
                        />
                        <PageTitle category="Delete">Azure AD integration</PageTitle>
                    </div>
                }
            >
                <h2 className="tw-mb-1 tw-text-xs tw-text-slate-700">Name</h2>
                <h2 className="tw-text-base">{menuState.rowData.name}</h2>
                <div className="tw-text-center">
                    <div className="tw-mt-12 tw-text-sm tw-font-medium tw-text-slate-700">Enter the following text to confirm</div>
                    <div className="tw-mb-6 tw-mt-3 tw-text-lg tw-font-semibold tw-leading-none tw-tracking-widest">{deleteState.confirmText}</div>
                    <LayoutCentered>
                        <div className="tw-mb-8 tw-w-96">
                            <Input
                                callToAction
                                rounded
                                naked
                                inputClassName="tw-text-center tw-text-lg tw-h-12 tw-tracking-widest"
                                autoFocus
                                maxLength={CONFIRM_TEXT_LENGTH}
                                disabled={deleteState.isProcessing}
                                onChange={(e) => {
                                    if (deleteState.disabled) {
                                        if (e.target.value === deleteState.confirmText) {
                                            setDeleteState((c) => ({
                                                ...c,
                                                disabled: false,
                                            }));
                                        }
                                    } else {
                                        setDeleteState((c) => ({
                                            ...c,
                                            disabled: true,
                                        }));
                                    }
                                }}
                            />
                        </div>
                    </LayoutCentered>
                </div>
                {deleteState.anErrorOccurred && (
                    <b className="tw-mb-4 tw-mt-8 tw-block tw-text-red-700">Could not delete "{menuState.rowData.name}". Please try again.</b>
                )}
            </ConfirmDialog>
            <VirtualizedTable
                items={items}
                disableHeader={items.length === 0}
                noRowsRenderer={() => (
                    <EmptyState
                        entity="integration"
                        onClick={showIntegrationOptions}
                    />
                )}
                columnPicker={columnPicker}
            >
                <Column
                    label=""
                    minWidth={32}
                    maxWidth={32}
                    dataKey="name"
                    cellRenderer={({ rowData }) => (
                        <IconButton
                            onClick={(e) => {
                                setMenuState({
                                    open: true,
                                    anchorEl: e.currentTarget,
                                    rowData,
                                });
                            }}
                            noMargin
                        >
                            <Icon type="moreVert" />
                        </IconButton>
                    )}
                />
                <Column
                    minWidth={32}
                    maxWidth={32}
                    dataKey="type"
                    label=""
                    cellRenderer={() => (
                        <Icon
                            type="azure"
                            className="tw-h-8 tw-w-8 tw-text-azure"
                        />
                    )}
                />
                <Column
                    label="Name"
                    minWidth={160}
                    dataKey="name"
                />
                <Column
                    label="Latest sync"
                    minWidth={160}
                    dataKey="lastSync"
                    cellRenderer={lastSyncCellRenderer}
                />
                <Column
                    key="type"
                    dataKey="type"
                    label="Automatic synchronization"
                    minWidth={240}
                    cellRenderer={({ rowData }) => {
                        if (rowData.syncConfiguration.cronExpression) {
                            const cronExtract = cronExtractor(rowData.syncConfiguration.cronExpression);
                            return cronExtract.text;
                        }
                        return <span className="tw-italic tw-text-neutral-300">Disabled</span>;
                    }}
                />
                <Column
                    label="Secret expiration date"
                    minWidth={160}
                    maxWidth={160}
                    dataKey="clientSecretExpiration"
                    cellRenderer={clientSecretExpirationCellRenderer}
                />
                <Column
                    label="Status"
                    minWidth={220}
                    maxWidth={220}
                    dataKey="status"
                    justify={virtualizedTableColumnPropTypes.justify.end}
                    cellRenderer={syncStatusCellRenderer}
                />
            </VirtualizedTable>
        </WidgetPaper>
    );
};

export { IntegrationManagementLandingPage };
