import { BUTTON, TOOLTIP } from '@capasystems/constants';
import {
    Avatar,
    Button,
    Icon,
    Input,
    LayoutCentered,
    LayoutRow,
    Loading,
    Page,
    Paper,
    SavingChanges,
    Select,
    Tooltip,
    UploadButton,
    useParams,
} from '@capasystems/ui';
import { isDefined, isUndefined, isValidHttpUrl, useWindowSize } from '@capasystems/utils';
import { AndroidApplication, AndroidWebAppConfiguration, coreAndroidManagement } from '@thirdparty/constants';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { JsonViewer, SchemaBuilder, SchemaBuilderDescription, SchemaBuilderTitle, useAndroidApi, useForceRefresh, useNavigate } from '../../../index';

const WIDTH_THRESHOLD = 1440;
const installTypeProperties = {
    ...AndroidApplication.schema.properties.applications.items.properties.installType,
};

const AndroidApplicationCreateAndEdit = () => {
    const forceRefresh = useForceRefresh();
    const { applicationId, packageName } = useParams();
    const propertiesBuilderRef = useRef({});
    const androidApi = useAndroidApi();
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const [loading, setLoading] = useState(true);
    const [selectedWebappInstallType, setSelectedWebappInstallType] = useState(installTypeProperties.options.find((option) => option.id === 'FORCE_INSTALLED'));
    const [savingChangesState, setSavingChangesState] = useState({
        loading: false,
    });

    const application = useRef({
        name: '',
        iconUrl: '',
        configurationType: null,
    });

    const navigateToApplicationList = () => {
        navigate.to('android/application/list');
    };

    const navigateToApplicationMembership = (response) => {
        navigate.to(`android/application/${applicationId || response.id}/membership`);
    };

    const onIconChange = (fileDetails) => {
        if (fileDetails) {
            const [file] = fileDetails.files;
            if (file) {
                setSavingChangesState({
                    loading: true,
                    loadingMessage: 'Processing image',
                    successMessage: 'Validated',
                });
                if (file.size > 1024 * 1024) {
                    setTimeout(() => {
                        setSavingChangesState((currentProps) => ({
                            ...currentProps,
                            errorTitle: 'File is too big',
                            errorMessage: 'The maximum allowed file size is 1 MB',
                            loading: false,
                        }));
                    });
                } else {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file);
                    fileReader.onload = function () {
                        application.current.iconUrl = fileReader.result;
                        const imageData = fileReader.result.split('base64,')[1].replace(/\+/g, '-').replace(/\//g, '_');
                        propertiesBuilderRef.current.icons = [
                            {
                                imageData,
                            },
                        ];
                        setSavingChangesState((currentProps) => ({
                            ...currentProps,
                            loading: false,
                        }));
                        forceRefresh();
                    };
                    fileReader.onerror = function (error) {
                        setSavingChangesState({
                            errorMessage: 'Could not read image',
                        });
                        console.log('Error: ', error);
                    };
                }
            } else {
                propertiesBuilderRef.current.icons = [];
            }
        }
    };

    const onCreateApplication = () => {
        setErrorMessage(null);
        if (application.current.name.trim() === '') {
            setErrorMessage('You must provide an application name');
        } else if (isDefined(packageName)) {
            setSavingChangesState({
                loading: true,
            });
            androidApi
                .createAndroidApplication({
                    name: application.current.name,
                    iconUrl: application.current.iconUrl,
                    configurationType: coreAndroidManagement.configurationType.application,
                    data: {
                        applications: [{ ...propertiesBuilderRef.current }],
                    },
                })
                .then(navigateToApplicationMembership)
                .catch((response) => {
                    setSavingChangesState({
                        errorMessage: response.data?.message || 'Failed to create application',
                    });
                });
        } else {
            if (isUndefined(propertiesBuilderRef.current.title) || propertiesBuilderRef.current.title.trim() === '') {
                setErrorMessage('You must provide a title for your web app');
            } else if (
                isUndefined(propertiesBuilderRef.current.startUrl) ||
                propertiesBuilderRef.current.startUrl.trim() === '' ||
                !isValidHttpUrl(propertiesBuilderRef.current.startUrl)
            ) {
                setErrorMessage('You must provide a valid start URL for your web app');
            } else if (isUndefined(propertiesBuilderRef.current.icons) || propertiesBuilderRef.current.icons.length !== 1) {
                setErrorMessage('You must provide an icon for your web app');
            } else {
                setSavingChangesState({
                    loading: true,
                });
                androidApi
                    .createAndroidApplication({
                        name: application.current.name,
                        iconUrl: application.current.iconUrl,
                        configurationType: coreAndroidManagement.configurationType.webapp,
                        installType: selectedWebappInstallType.id,
                        data: {
                            ...propertiesBuilderRef.current,
                        },
                    })
                    .then(navigateToApplicationMembership)
                    .catch((response) => {
                        setSavingChangesState({
                            errorMessage: response.data?.message || 'Failed to create web app',
                        });
                    });
            }
        }
    };

    const onSaveApplication = () => {
        setErrorMessage(null);
        if (application.current.name.trim() === '') {
            setErrorMessage('You must provide an application name');
        } else if (application.current.configurationType === coreAndroidManagement.configurationType.application) {
            setSavingChangesState({
                loading: true,
            });
            androidApi
                .updateAndroidApplication(applicationId, {
                    name: application.current.name,
                    data: {
                        applications: [{ ...propertiesBuilderRef.current }],
                    },
                })
                .then(navigateToApplicationMembership)
                .catch((response) => {
                    setSavingChangesState({
                        errorMessage: response.data?.message || 'Failed to update android application',
                    });
                });
        } else if (application.current.configurationType === coreAndroidManagement.configurationType.webapp) {
            if (isUndefined(propertiesBuilderRef.current.title) || propertiesBuilderRef.current.title.trim() === '') {
                setErrorMessage('You must provide a title for your web app');
            } else if (
                isUndefined(propertiesBuilderRef.current.startUrl) ||
                propertiesBuilderRef.current.startUrl.trim() === '' ||
                !isValidHttpUrl(propertiesBuilderRef.current.startUrl)
            ) {
                setErrorMessage('You must provide a valid start URL for your web app');
            } else {
                setSavingChangesState({
                    loading: true,
                });
                androidApi
                    .updateAndroidApplication(applicationId, {
                        name: application.current.name,
                        data: {
                            ...propertiesBuilderRef.current,
                        },
                        installType: selectedWebappInstallType.id,
                    })
                    .then(navigateToApplicationMembership)
                    .catch((response) => {
                        setSavingChangesState({
                            errorMessage: response.data?.message || 'Failed to update web app',
                        });
                    });
            }
        }
    };

    useEffect(() => {
        if (isDefined(applicationId)) {
            androidApi
                .getSingleApplication(applicationId)
                .then((foundApplication) => {
                    if (foundApplication.configurationType === coreAndroidManagement.configurationType.application) {
                        const [applicationsProperties] = foundApplication.data.applications || [];
                        if (isDefined(applicationsProperties)) {
                            propertiesBuilderRef.current = applicationsProperties;
                        }
                    } else if (foundApplication.configurationType === coreAndroidManagement.configurationType.webapp) {
                        propertiesBuilderRef.current = foundApplication.data;
                        setSelectedWebappInstallType(installTypeProperties.options.find((option) => option.id === foundApplication.installType));
                    }
                    application.current.iconUrl = foundApplication.iconUrl || '';
                    application.current.name = foundApplication.name;
                    application.current.configurationType = foundApplication.configurationType;
                    setLoading(false);
                })
                .catch(() => {
                    setErrorMessage('The supplied application ID is unknown');
                });
        } else if (isDefined(packageName)) {
            androidApi
                .getGooglePlayStoreApplicationInfo(packageName)
                .then((response) => {
                    propertiesBuilderRef.current.packageName = packageName;
                    application.current.name = response.title;
                    application.current.iconUrl = response.iconUrl;
                    application.current.configurationType = coreAndroidManagement.configurationType.application;
                    setLoading(false);
                })
                .catch(() => {
                    setErrorMessage('The supplied package name is unknown');
                });
        } else {
            application.current.configurationType = coreAndroidManagement.configurationType.webapp;
            setLoading(false);
        }
    }, [androidApi, applicationId, packageName]);

    if (loading) {
        if (errorMessage) {
            return (
                <LayoutCentered direction="column">
                    <div>
                        <h2 className="tw-mb-4">{errorMessage}</h2>
                        <Button
                            noMargin
                            onClick={navigateToApplicationList}
                            variant={BUTTON.RAISED}
                            color={BUTTON.PRIMARY}
                        >
                            Back
                        </Button>
                    </div>
                </LayoutCentered>
            );
        }
        return (
            <LayoutCentered>
                <Loading thickness={2} />
            </LayoutCentered>
        );
    }

    return (
        <Page title={isDefined(applicationId) ? 'Application · Edit' : 'Application · Create'}>
            <div
                className={classNames({
                    'tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-cols-1fr-auto tw-gap-4 tw-p-4': true,
                    'tw-grid-rows-auto-auto-1fr': errorMessage !== null,
                    'tw-grid-rows-auto-1fr': errorMessage === null,
                })}
            >
                <div className="tw-col-span-2">
                    <LayoutRow
                        align="space-between"
                        verticalAlign="center"
                    >
                        <div>
                            <LayoutRow verticalAlign="center">
                                {application.current.iconUrl ? (
                                    <Avatar
                                        src={application.current.iconUrl}
                                        alt={application.current.name}
                                        variant="rounded"
                                        className="tw-h-16 tw-w-16"
                                    />
                                ) : (
                                    <Tooltip
                                        content={
                                            <div
                                                className="tw-leading-relaxed"
                                                dangerouslySetInnerHTML={{ __html: AndroidWebAppConfiguration.schema.properties.icons.description }}
                                            />
                                        }
                                        interactive
                                        dark
                                        extraPadding
                                        position={TOOLTIP.POSITION.BOTTOM_START}
                                    >
                                        <div>
                                            <UploadButton
                                                variant={BUTTON.OUTLINED}
                                                color={BUTTON.PRIMARY}
                                                htmlId="upload-icon-button"
                                                accept=".jpeg,.png"
                                                onChange={onIconChange}
                                                noMargin
                                                hideSelectedFiles
                                                className="tw-h-16 tw-w-16 tw-rounded-xl tw-border-2 tw-border-dashed"
                                            >
                                                <Icon
                                                    type="add"
                                                    className="tw-h-8 tw-w-8"
                                                />
                                            </UploadButton>
                                        </div>
                                    </Tooltip>
                                )}
                                <Input
                                    value={application.current.name}
                                    placeholder="Application name"
                                    fullWidth
                                    callToAction
                                    light
                                    className="tw-ml-4 tw-w-88 lg:tw-w-144"
                                    onChange={(e) => {
                                        setErrorMessage(null);
                                        application.current.name = e.target.value;
                                    }}
                                    containerClassName="tw-py-1 tw-text-sm"
                                    autoFocus
                                />
                            </LayoutRow>
                        </div>

                        <div className="tw-ml-16">
                            {isDefined(applicationId) && (
                                <Button
                                    color={BUTTON.PRIMARY}
                                    variant={BUTTON.RAISED}
                                    onClick={onSaveApplication}
                                >
                                    {application.current.configurationType === coreAndroidManagement.configurationType.application ? 'Save' : 'Save'}
                                </Button>
                            )}
                            {isUndefined(applicationId) && (
                                <Button
                                    color={BUTTON.PRIMARY}
                                    variant={BUTTON.RAISED}
                                    onClick={onCreateApplication}
                                >
                                    {application.current.configurationType === coreAndroidManagement.configurationType.application ? 'Create' : 'Create'}
                                </Button>
                            )}

                            <Button
                                noMargin
                                onClick={isDefined(applicationId) ? navigateToApplicationMembership : navigateToApplicationList}
                                variant={BUTTON.OUTLINED}
                            >
                                Cancel
                            </Button>
                        </div>
                    </LayoutRow>
                </div>
                {errorMessage !== null && (
                    <div className="tw-col-span-2 tw-rounded tw-bg-red-100 tw-px-6 tw-py-4 tw-font-bold tw-text-red-700">{errorMessage}</div>
                )}
                <Paper
                    className={classNames({
                        'tw-h-full tw-overflow-auto tw-py-2 tw-shadow-sm': true,
                        'tw-col-span-2': width < WIDTH_THRESHOLD,
                    })}
                >
                    {application.current.configurationType === coreAndroidManagement.configurationType.application && (
                        <SchemaBuilder
                            key="applicationSchemaBuilder"
                            properties={AndroidApplication.schema.properties.applications.items.properties}
                            schema={AndroidApplication.schema.properties.applications.items}
                            ref={propertiesBuilderRef.current}
                        />
                    )}

                    {application.current.configurationType === coreAndroidManagement.configurationType.webapp && (
                        <>
                            <SchemaBuilder
                                key="webappSchemaBuilder"
                                properties={AndroidWebAppConfiguration.schema.properties}
                                schema={AndroidWebAppConfiguration.schema}
                                ref={propertiesBuilderRef.current}
                            />
                            <div className="tw-px-6 tw-py-2">
                                <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-4">
                                    <div>
                                        <SchemaBuilderTitle>{installTypeProperties.title}</SchemaBuilderTitle>
                                        <SchemaBuilderDescription>{installTypeProperties.description}</SchemaBuilderDescription>
                                    </div>
                                    <div className="tw-w-96">
                                        <Select
                                            options={installTypeProperties.options}
                                            selectedOptions={[selectedWebappInstallType]}
                                            onChange={(options) => {
                                                setSelectedWebappInstallType(options[0]);
                                            }}
                                            callToAction
                                            fullWidth
                                            placeholder="Required"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="tw-px-6 tw-pt-4">
                                <LayoutRow
                                    verticalAlign="center"
                                    align="space-between"
                                >
                                    <div>
                                        <SchemaBuilderTitle required={application.current.iconUrl ? false : true}>
                                            {AndroidWebAppConfiguration.schema.properties.icons.title}
                                        </SchemaBuilderTitle>
                                        <SchemaBuilderDescription>{AndroidWebAppConfiguration.schema.properties.icons.description}</SchemaBuilderDescription>
                                    </div>
                                    <LayoutRow verticalAlign="center">
                                        <UploadButton
                                            variant={application.current.iconUrl ? BUTTON.OUTLINED : BUTTON.RAISED}
                                            color={BUTTON.PRIMARY}
                                            htmlId="upload-icon-button"
                                            accept=".jpeg,.png"
                                            onChange={onIconChange}
                                            noMargin
                                            hideSelectedFiles
                                            size="large"
                                        >
                                            {application.current.iconUrl ? 'Change icon' : 'Select icon'}
                                        </UploadButton>
                                    </LayoutRow>
                                </LayoutRow>
                            </div>
                        </>
                    )}
                    <SavingChanges
                        minLoadingTime={1000}
                        {...savingChangesState}
                    />
                </Paper>
                {width >= WIDTH_THRESHOLD && (
                    <JsonMiniMap
                        json={propertiesBuilderRef.current}
                        className={classNames({
                            'tw-w-112 tw-text-tiny tw-leading-loose': width >= 1620,
                            'tw-w-72 tw-text-tiny': width < 1620,
                        })}
                    />
                )}
            </div>
        </Page>
    );
};

const JsonMiniMap = (props) => {
    const forceRefresh = useForceRefresh();

    useEffect(() => {
        const interval = setInterval(forceRefresh, 1000);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <JsonViewer {...props} />;
};

export { AndroidApplicationCreateAndEdit };
