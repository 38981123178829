import { DriverCompliance, DriverUpdateCategory, WidgetsContainer, useAuthContext } from '../../index';

export const DriverHome = ({ children }) => {
    const { permissions } = useAuthContext();

    if (!permissions.canManageDrivers) {
        return null;
    }

    return (
        <WidgetsContainer>
            {children}
            <div className="tw-grid tw-grid-cols-8 tw-gap-4">
                <DriverCompliance
                    legendWidth={null}
                    className="tw-col-span-8 lg:tw-col-span-4 2xl:tw-col-span-4"
                />
                <DriverUpdateCategory
                    legendWidth={null}
                    className="tw-col-span-8 lg:tw-col-span-4 2xl:tw-col-span-4"
                />
            </div>
        </WidgetsContainer>
    );
};

DriverHome.propTypes = {};
