const CRON_PRIMARY_OPTIONS = [
    {
        id: 'hour',
        name: 'Hour',
    },
    {
        id: 'day',
        name: 'Day',
    },
    {
        id: 'week',
        name: 'Week',
    },
    {
        id: 'month',
        name: 'Month',
    },
];

const CRON_MINUTES = [0, 15, 30, 45].map((m) => ({
    id: m.toString(),
    name: `0${m}`.slice(-2),
}));

const CRON_HOURS = [];
const CRON_HOURS_WITH_MINUTES = [];

for (let i = 0; i <= 23; i += 1) {
    CRON_HOURS.push({
        id: i.toString(),
        name: `0${i}`.slice(-2),
    });
    CRON_HOURS_WITH_MINUTES.push({
        id: i.toString(),
        name: `0${i}`.slice(-2) + ':00',
    });
}

const CRON_WEEK_DAYS = [
    {
        id: '1',
        sortingIndex: 0,
        name: 'Monday',
    },
    {
        id: '2',
        sortingIndex: 1,
        name: 'Tuesday',
    },
    {
        id: '3',
        sortingIndex: 2,
        name: 'Wednesday',
    },
    {
        id: '4',
        sortingIndex: 3,
        name: 'Thursday',
    },
    {
        id: '5',
        sortingIndex: 4,
        name: 'Friday',
    },
    {
        id: '6',
        sortingIndex: 5,
        name: 'Saturday',
    },
    {
        id: '0',
        sortingIndex: 6,
        name: 'Sunday',
    },
];

const CRON_DAY_OF_MONTH = [];

for (let i = 1; i <= 31; i += 1) {
    CRON_DAY_OF_MONTH.push({
        id: i.toString(),
        name: i.toString(),
    });
}

export { CRON_PRIMARY_OPTIONS, CRON_MINUTES, CRON_HOURS, CRON_HOURS_WITH_MINUTES, CRON_WEEK_DAYS, CRON_DAY_OF_MONTH };
