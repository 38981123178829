import { BUTTON } from '@capasystems/constants';
import { Button, ContextDialog, Icon, LayoutCenter, LayoutRow, Link, LoadingLinear } from '@capasystems/ui';
import { isUndefined } from '@capasystems/utils';
import { PRODUCT_NAME } from '@thirdparty/constants';
import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CoreContext } from '../contexts/core-context/core.context';
import useAuthContext from '../hooks/useAuthContext/useAuthContext';
import useNavigate from '../hooks/useNavigate/useNavigate';

const GettingStarted = () => {
    const { user, permissions } = useAuthContext();
    const { userProfile, updateUserProfile, setGettingStartedIsVisible, gettingStartedIsVisible } = useContext(CoreContext);
    const { firstName, lastName } = user;
    const [selectedGuide, setSelectedGuide] = useState({});
    const navigate = useNavigate();
    const [richTooltipProps, setRichTooltipProps] = useState({
        open: false,
        anchorEl: null,
    });

    const [percentage, setPercentage] = useState(null);

    const onClose = () => {
        setRichTooltipProps({ ...richTooltipProps, open: false });
    };

    const onActivateGuide = (guide) => () => {
        setSelectedGuide(guide);
        if (!userProfile.completedGettingStartedSteps.includes(guide.stepId)) {
            updateUserProfile({
                completedGettingStartedSteps: [...userProfile.completedGettingStartedSteps, guide.stepId],
            });
        }
        navigate.to(guide.to + `?_gs=${guide.stepId}`);
    };

    const onClick = (e) => {
        setRichTooltipProps({
            open: true,
            anchorEl: e.currentTarget,
        });
    };

    const guides = useMemo(() => {
        const tempGuides = [
            {
                to: 'windows/enrollment',
                title: `${PRODUCT_NAME} agent`,
                text: `Install your first ${PRODUCT_NAME} agent`,
                description: `Details about how to create your first enrollment configuration`,
                icon: 'download',
                done: true,
                stepId: 'agentInstall',
            },
            {
                to: 'windows/reliability/dashboard',
                title: 'Reliability',
                text: 'Get insights into endpoint events',
                description: 'Details about the charts and event types.',
                icon: 'capaoneReliability',
                stepId: 'reliability',
                accessDenied: !permissions.canViewReliability,
            },
            {
                to: 'windows/adminOnDemand',
                title: 'AdminOnDemand',
                text: 'Learn about Privileged Access Management',
                description: 'Learn about Privileged Access Management and how to set it up.',
                icon: 'capaoneAdminOnDemand',
                stepId: 'adminOnDemand',
                accessDenied: !permissions.canViewAdminOnDemand,
            },
            {
                to: 'windows/updater/dashboard',
                title: 'Updater',
                text: 'Always up to date on software',
                description: 'Explaination of software management and available software.',
                icon: 'capaoneUpdater',
                stepId: 'updaterDashboard',
                accessDenied: !permissions.canManageSoftwarePatching,
            },
            {
                to: 'help/tutorials',
                title: 'Tutorials',
                text: 'A brief look at our services',
                description: `A brief look at the services available in ${PRODUCT_NAME}`,
                icon: 'play',
                stepId: 'tutorials',
            },
        ];
        return tempGuides.filter(({ accessDenied }) => !accessDenied).map(({ accessDenied, ...rest }) => ({ ...rest }));
    }, [permissions]);

    const onCompleteAll = () => {
        updateUserProfile({
            completedGettingStartedSteps: guides.map((guide) => guide.stepId),
        });
    };

    const onExited = () => {
        setSelectedGuide({});
        setGettingStartedIsVisible(percentage < 100);
    };

    useEffect(() => {
        const completedGuides = guides.filter((guide) => userProfile.completedGettingStartedSteps.includes(guide.stepId));
        const completedPercentage = parseInt((completedGuides.length / guides.length) * 100, 10);
        setPercentage(completedPercentage);
        setGettingStartedIsVisible(completedPercentage < 100 || richTooltipProps.open);
    }, [userProfile, guides, setGettingStartedIsVisible, richTooltipProps]);

    useEffect(() => {
        if (gettingStartedIsVisible && percentage === 100) {
            setSelectedGuide({});
            const anchorEl = document.querySelectorAll(`[data-getting-started-container='true']`)[0] || document.body;
            setRichTooltipProps({
                open: true,
                anchorEl,
            });
        }
    }, [gettingStartedIsVisible, percentage]);

    if (gettingStartedIsVisible) {
        return (
            <div
                className={classNames('tw-z-100 tw-fixed tw-bottom-4 tw-right-0')}
                style={{ zIndex: 1200 }}
            >
                <Button
                    color={BUTTON.INHERIT}
                    variant={BUTTON.RAISED}
                    onClick={onClick}
                    className={classNames({
                        'tw-h-10 tw-rounded-full tw-bg-purple-600 tw-text-xs tw-text-white tw-shadow': true,
                        'tw-opacity-0': percentage === 100,
                    })}
                    data-getting-started-container="true"
                >
                    <Icon
                        type="notificationsActive"
                        className="tw-mr-2 tw-animate-pulse"
                        size="small"
                    />
                    Let's get started&nbsp;
                </Button>
                <ContextDialog
                    open={richTooltipProps.open}
                    onExited={onExited}
                    anchorEl={richTooltipProps.anchorEl}
                    onClose={onClose}
                    paperProps={{ style: { width: 340 } }}
                    position={'top-end'}
                    backgroundColor="#FFF"
                    arrow={false}
                    spacing={[0, 0]}
                    visibleBackdrop={isUndefined(selectedGuide.title)}
                >
                    {selectedGuide.title ? (
                        <div className="tw-bg-emerald-300 tw-p-4 tw-text-emerald-900">
                            <h2 className="tw-font-regular tw-text-sm">{PRODUCT_NAME}</h2>
                            <h2 className="tw-mb-0 tw-text-lg tw-font-bold">{selectedGuide.title}</h2>
                            <p className="tw-mt-2 tw-text-sm tw-font-semibold">{selectedGuide.description}</p>
                        </div>
                    ) : (
                        <div className="tw-bg-indigo-100 tw-p-4 tw-text-indigo-900">
                            <h2 className="tw-font-regular tw-text-sm">Welcome,</h2>
                            <h2 className="tw-mb-0 tw-text-lg tw-font-bold">
                                {firstName} {lastName}
                            </h2>
                            <p className="tw-mt-2 tw-text-sm tw-font-semibold">
                                Let's take you on a short tour to demonstrate the main features of {PRODUCT_NAME}.
                            </p>
                        </div>
                    )}
                    {guides.map((guide) => (
                        <LayoutRow
                            verticalAlign="center"
                            align="space-between"
                            className="tw-cursor-pointer tw-px-4 tw-py-3 hover:tw-bg-slate-100"
                            onClick={onActivateGuide(guide)}
                        >
                            <div>
                                <div className="tw-mr-4 tw-h-8 tw-w-8 tw-rounded tw-border tw-border-neutral-100 tw-bg-gradient-to-br tw-from-neutral-100 tw-to-neutral-50">
                                    <LayoutCenter>
                                        <Icon
                                            type={guide.icon}
                                            size="small"
                                            color="primary"
                                        />
                                    </LayoutCenter>
                                </div>
                            </div>
                            <div className="tw-flex-auto">
                                <p className="tw-text-md tw-font-semibold">{guide.title}</p>
                                <p className="tw-text-xs">{guide.text}</p>
                            </div>
                            <div>
                                {userProfile.completedGettingStartedSteps.includes(guide.stepId) || percentage === 100 ? (
                                    <div className="tw-rounded-full tw-bg-emerald-400 tw-p-1 tw-text-emerald-100">
                                        <LayoutCenter>
                                            <Icon
                                                type="checkmark"
                                                size="small"
                                            ></Icon>
                                        </LayoutCenter>
                                    </div>
                                ) : (
                                    <div className="tw-p-1">
                                        <LayoutCenter>
                                            <Icon
                                                type="arrowRight"
                                                size="small"
                                            />
                                        </LayoutCenter>
                                    </div>
                                )}
                            </div>
                        </LayoutRow>
                    ))}
                    <div className="tw-p-4">
                        <LayoutRow verticalAlign="center">
                            <LoadingLinear
                                variant="determinate"
                                value={percentage}
                            />
                        </LayoutRow>
                    </div>
                    <div className="tw-p-4">
                        <LayoutRow align="space-between">
                            {percentage < 100 && (
                                <Link
                                    className="tw-mr-4 tw-italic tw-text-amber-700"
                                    color={BUTTON.INHERIT}
                                    onClick={onCompleteAll}
                                >
                                    Mark all as completed
                                </Link>
                            )}
                            <span />
                            <Button
                                noMargin
                                className="tws-w-16"
                                color={BUTTON.PRIMARY}
                                variant={BUTTON.RAISED}
                                onClick={onClose}
                            >
                                Close
                            </Button>
                        </LayoutRow>
                    </div>
                </ContextDialog>
            </div>
        );
    }
    return null;
};

export { GettingStarted };
