import { BUTTON } from '@capasystems/constants';
import { Button, Icon, Tooltip } from '@capasystems/ui';
import { formatDate } from '@capasystems/utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { PageTitle } from '../../page/page-title';
import { WidgetPaper } from '../../thirdparty-components/thirdparty-components';

export const AppleCertificatePaper = ({ expireDate = null, onUpdate, secondaryAction, children, ...props }) => {
    const hasExpired = expireDate ? dayjs().isAfter(dayjs(expireDate)) : false;
    let expiresSoon = false;
    let certificateTooltipContent;
    let certificateIcon;
    if (expireDate) {
        if (hasExpired) {
            certificateIcon = (
                <Icon
                    type="badgeExclamation"
                    className="tw-h-8 tw-w-8 tw-text-red-400"
                />
            );
            certificateTooltipContent = 'Certificate is expired';
        } else if (dayjs(expireDate).diff(dayjs(), 'month') === 0) {
            certificateIcon = (
                <Icon
                    type="badgeCheckmark"
                    className="tw-h-8 tw-w-8 tw-text-amber-400"
                />
            );
            expiresSoon = true;
            certificateTooltipContent = 'Expires in less than a month';
        } else {
            certificateIcon = (
                <Icon
                    type="badgeCheckmark"
                    className="tw-h-8 tw-w-8 tw-text-emerald-400"
                />
            );
            certificateTooltipContent = 'Certificate is up-to-date';
        }
    } else {
        certificateIcon = (
            <Icon
                type="dashOutlined"
                className="tw-h-8 tw-w-8 tw-text-gray-400"
            />
        );
        certificateTooltipContent = 'No certificate used';
    }

    return (
        <WidgetPaper
            truncateHeaderElements
            {...props}
        >
            <div className="tw-grid tw-h-full tw-grid-rows-auto-1fr-auto">
                <div>
                    <Tooltip
                        content={certificateTooltipContent}
                        extraPadding
                        dark
                        bold
                    >
                        <div
                            className={classNames({
                                'tw-grid tw-grid-cols-1fr-auto tw-items-center tw-p-4': true,
                                'tw-bg-emerald-50': !hasExpired && expireDate !== null && !expiresSoon,
                                'tw-bg-amber-50': !hasExpired && expireDate !== null && expiresSoon,
                                'tw-bg-red-50': hasExpired,
                                'tw-bg-gray-50': expireDate === null,
                            })}
                            style={{ minHeight: 80 }}
                        >
                            <div>
                                {expireDate ? (
                                    <PageTitle category={hasExpired ? `Expired ${dayjs(expireDate).fromNow()}` : `Expires ${dayjs(expireDate).fromNow()}`}>
                                        {formatDate(expireDate, true)}
                                    </PageTitle>
                                ) : (
                                    <PageTitle>{certificateTooltipContent}</PageTitle>
                                )}
                            </div>
                            <div>{certificateIcon}</div>
                        </div>
                    </Tooltip>
                </div>
                <div className="tw-overflow-auto tw-p-4 tw-text-sm tw-font-medium tw-leading-relaxed">{children}</div>
                <div className="tw-flex tw-justify-end tw-gap-2 tw-p-4">
                    <div>{secondaryAction}</div>

                    <div>
                        <Button
                            color={BUTTON.PRIMARY}
                            variant={BUTTON.RAISED}
                            noMargin
                            onClick={onUpdate}
                        >
                            {expireDate === null ? 'Select' : 'Update'}
                        </Button>
                    </div>
                </div>
            </div>
        </WidgetPaper>
    );
};
