import { Page, Paper, Tab, Tabs, useParams } from '@capasystems/ui';
import { Url } from '@capasystems/utils';
import { QueryBuilder, useAuthContext, useNavigate } from '@thirdparty/ui';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import { useMemo, useRef, useState } from 'react';
import { AppleEndpointListDEP } from '../components/endpointList/AppleEndpointListDEP';
import { AppleEndpointListMac } from '../components/endpointList/AppleEndpointListMac';
import { AppleEndpointListMobile } from '../components/endpointList/AppleEndpointListMobile';
const queryBuilderConfigurationMac = getDefaultQueryBuilderConfiguration({ placeholder: 'Name, User or Serial Number' });
const queryBuilderConfigurationMobile = getDefaultQueryBuilderConfiguration({ placeholder: 'Name, User, Serial Number or IMEI' });
const queryBuilderConfigurationDep = getDefaultQueryBuilderConfiguration({ placeholder: 'Serial Number' });

const MODEL_TYPE = {
    MOBILE: { name: 'Mobile Devices', id: 'mobile' },
    MAC: { name: 'Mac', id: 'mac' },
    DEP: { name: 'DEP', id: 'dep' },
    // IPADOS: "iPadOS",
};

const AppleEndpointList = () => {
    const { modelType } = useParams();
    const navigate = useNavigate();
    const { permissions } = useAuthContext();
    const [searchTerm, setSearchTerm] = useState(Url.getString('search') || '');
    const queryBuilderRef = useRef({});

    const onSubmit = ([activeLeaf]) => {
        setSearchTerm(activeLeaf ? activeLeaf.value : '');
    };

    const [allowedTypes] = useMemo(() => {
        const types = [];
        if (permissions.canViewAppleManagement) {
            types.push(MODEL_TYPE.MOBILE);
            types.push(MODEL_TYPE.MAC);
            types.push(MODEL_TYPE.DEP);
        }
        return [types];
    }, []);

    const onChangeTab = (e, tabvalue) => {
        queryBuilderRef.current.clearFiltersAndApplyChanges();
        navigate.to(`apple/endpoint/list/${tabvalue}`);
    };

    return (
        <Page>
            <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-p-4">
                <div className="tw-grid tw-grid-cols-1fr-auto tw-items-center tw-gap-4">
                    <Tabs
                        value={modelType}
                        onChange={onChangeTab}
                        onRails
                    >
                        {allowedTypes.map((endpointType) => (
                            <Tab
                                key={endpointType.id}
                                value={endpointType.id}
                                disableRipple
                                label={
                                    <div className="tw-w-full tw-items-center">
                                        <div>{endpointType.name}</div>
                                    </div>
                                }
                            />
                        ))}
                    </Tabs>
                    <QueryBuilder
                        defaultConfiguration={
                            modelType === MODEL_TYPE.MAC.id
                                ? queryBuilderConfigurationMac
                                : modelType === MODEL_TYPE.MOBILE.id
                                ? queryBuilderConfigurationMobile
                                : queryBuilderConfigurationDep
                        }
                        onInit={onSubmit}
                        onSubmit={onSubmit}
                        className="tw-mx-autotw-max-w-screen-md tw-w-88"
                        ref={queryBuilderRef}
                        key={modelType}
                    />
                </div>

                <div className="tw-mt-4">
                    <Paper className="tw-h-full tw-shadow-sm">
                        {modelType === MODEL_TYPE.MOBILE.id && <AppleEndpointListMobile searchTerm={searchTerm} />}
                        {modelType === MODEL_TYPE.MAC.id && <AppleEndpointListMac searchTerm={searchTerm} />}
                        {modelType === MODEL_TYPE.DEP.id && <AppleEndpointListDEP searchTerm={searchTerm} />}
                    </Paper>
                </div>
            </div>
        </Page>
    );
};
export { AppleEndpointList };
