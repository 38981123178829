import { BUTTON, coreMessage, SORT_DIRECTION } from '@capasystems/constants';
import { isDefined, isFunction, isString, useElementSize } from '@capasystems/utils';
import { NOT_AVAILABLE } from '@thirdparty/constants';
import classnames from 'classnames';
import pluralize from 'pluralize';
import propTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import {
    Button,
    Checkbox,
    ContextDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Ellipsis,
    EmptyState,
    LayoutCentered,
    Loading,
    LoadingLinear,
} from '../../index';
import IconButton from '../icon-button/icon-button';
import Icon from '../icon/icon';
import Tooltip from '../tooltip/tooltip';
import './virtualized-table.scss';

// Ensure that virtuosoTableComponents stays out of the component. Otherwise the components will remount with each render due to new component instances.
const virtuosoTableComponents = {
    Table: React.forwardRef(({ context: { fontSize }, ...props }, ref) => {
        return (
            <table
                {...props}
                key="table-element"
                ref={ref}
                className={classnames('capaone-virtualized-table tw-w-full tw-table-fixed tw-border-collapse tw-font-medium', {
                    'tw-text-xs': fontSize === virtualizedTablePropTypes.fontSize.small,
                    'tw-text-sm': fontSize === virtualizedTablePropTypes.fontSize.default,
                    'tw-text-base': fontSize === virtualizedTablePropTypes.fontSize.large,
                })}
            />
        );
    }),
    TableRow: React.forwardRef(({ item, context: { onRowClick, rowClassName, striped, borderless }, ...props }, ref) => {
        const index = props['data-index'];
        const rowIsClickable = isFunction(onRowClick);
        const rowClassNames = isFunction(rowClassName)
            ? rowClassName({
                  index,
                  rowData: item,
              })
            : rowClassName;
        return (
            <tr
                ref={ref}
                {...props}
                key={index}
                onClick={(event) => {
                    if (rowIsClickable) {
                        onRowClick({
                            event,
                            rowData: item,
                            index,
                        });
                    }
                }}
                className={classnames('tw-w-full', rowClassNames, {
                    'tw-cursor-pointer': rowIsClickable,
                    'even:tw-bg-gray-50': striped,
                    'tw-border-b tw-border-solid tw-border-gray-100': !borderless,
                    'capaone-virtualized-table-row-selected': item.selected,
                })}
            />
        );
    }),
};

const defaultNoRowsRenderer = ({ message }) => {
    return (
        <EmptyState
            title={message}
            iconType="lightbulbOutlined"
            description={null}
        />
    );
};

const virtualizedTablePropTypes = {
    density: {
        compact: 'compact',
        default: 'default',
        spacious: 'spacious',
    },
    fontSize: {
        small: 'small',
        default: 'default',
        large: 'large',
    },
};

const virtualizedTableColumnPropTypes = {
    justify: {
        end: 'end',
        start: 'start',
        center: 'center',
    },
};

const DEFAULT_PADDING_VERTICAL = 32; // 16px padding on both sides

const VirtualizedTable = ({
    columnPicker,
    items = [],
    onScrollToBottom = () => null,
    onRowClick,
    sortBy = null,
    sort,
    sortDirection,
    totalRowCount = 0,
    isLoading = false,
    showRowCount = false,
    striped = false,
    actions,
    entity = 'row',
    density = virtualizedTablePropTypes.density.default,
    fontSize = virtualizedTablePropTypes.fontSize.default,
    isScrolling,
    // resetScroll = false, // TODO: Any need to implement resetScroll?
    rowClassName = '',
    onResize = () => null,
    borderless = false,
    disableHeader = false,
    noRowsRenderer = defaultNoRowsRenderer,
    children,
}) => {
    const virtuosoRef = useRef(null);
    const [hiddenColumns, setHiddenColumns] = useState(() => {
        if (columnPicker && columnPicker.hiddenColumns) {
            return columnPicker.hiddenColumns;
        }
        return [];
    });
    const [hoveringLabelDescription, setHoveringLabelDescription] = useState(false);
    const [elementRef, element] = useElementSize(onResize);

    let minWidthOfTableColumns = DEFAULT_PADDING_VERTICAL; // 16px padding on each side initially
    const childrenArray = React.Children.toArray(children);

    const filteredChildrenArray = childrenArray.filter((column) => {
        const { shouldRender = () => true, minWidth, width, label } = column.props;
        if (shouldRender({ tableWidth: element.width }) && !hiddenColumns.includes(label)) {
            minWidthOfTableColumns += (minWidth || width || 0) + DEFAULT_PADDING_VERTICAL;
            return true;
        }
        return false;
    });

    const rowCount = items.length;

    const endReached = () => {
        if (totalRowCount > rowCount && !isLoading) {
            onScrollToBottom();
        }
    };

    if (rowCount === 0) {
        if (isLoading) {
            return (
                <LayoutCentered>
                    <Loading />
                </LayoutCentered>
            );
        }
        return (
            <div className="tw-h-full">
                {noRowsRenderer({
                    message: `No ${pluralize(entity !== 'row' ? entity : 'data', 0)} to display`,
                })}
            </div>
        );
    }

    return (
        <div className="tw-grid tw-h-full tw-w-full tw-max-w-full tw-grid-rows-1fr-auto">
            <div
                className="tw-overflow-auto"
                ref={elementRef}
            >
                <TableVirtuoso
                    context={{ isLoading, onRowClick, rowClassName, striped, borderless, fontSize }}
                    increaseViewportBy={240}
                    totalCount={totalRowCount}
                    endReached={endReached}
                    isScrolling={isScrolling}
                    style={{
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        minWidth: minWidthOfTableColumns,
                    }}
                    data={items}
                    ref={virtuosoRef}
                    fixedHeaderContent={() => {
                        return (
                            <tr
                                className={classnames('', {
                                    'tw-border-b tw-border-solid tw-border-gray-100': !borderless && !disableHeader,
                                })}
                            >
                                {filteredChildrenArray.map((column, columnIndex) => {
                                    return (
                                        <th
                                            key={`${column.props.dataKey}_${column.props.label}_${columnIndex}`}
                                            className={classnames('tw-box-border tw-overflow-hidden tw-font-semibold', {
                                                'tw-bg-white tw-bg-opacity-95': !disableHeader,
                                            })}
                                            style={{
                                                minWidth: column.props.minWidth || 0,
                                                maxWidth: column.props.maxWidth ? column.props.maxWidth + DEFAULT_PADDING_VERTICAL : undefined,
                                            }}
                                            width={column.props.maxWidth ? column.props.minWidth + DEFAULT_PADDING_VERTICAL + 'px' : undefined} // Only set width to minWidth if maxWidth is set.
                                        >
                                            {disableHeader ? (
                                                <div />
                                            ) : (
                                                <div
                                                    className={classnames(
                                                        'tw-group tw-flex tw-h-full tw-w-full tw-max-w-full tw-items-center tw-overflow-hidden tw-px-2',
                                                        {
                                                            'tw-justify-end': column.props.justify === virtualizedTableColumnPropTypes.justify.end,
                                                            'tw-justify-center': column.props.justify === virtualizedTableColumnPropTypes.justify.center,
                                                            'tw-py-2': density === virtualizedTablePropTypes.density.compact,
                                                            'tw-py-3': density === virtualizedTablePropTypes.density.default,
                                                            'tw-py-4': density === virtualizedTablePropTypes.density.spacious,
                                                        }
                                                    )}
                                                >
                                                    {column.props.label && column.props.disableSort !== true && isFunction(sort) ? (
                                                        <Tooltip
                                                            dark
                                                            extraPadding
                                                            bold
                                                            fullWidth={hoveringLabelDescription}
                                                            content={hoveringLabelDescription ? column.props.labelDescription : `Sort by ${column.props.label}`}
                                                        >
                                                            <Button
                                                                startIcon={
                                                                    column.props.labelDescription && (
                                                                        <Icon
                                                                            type="questionMark"
                                                                            className="tw-h-4 tw-w-4"
                                                                            onMouseEnter={() => setHoveringLabelDescription(true)}
                                                                            onMouseLeave={() => setHoveringLabelDescription(false)}
                                                                        />
                                                                    )
                                                                }
                                                                endIcon={
                                                                    sortBy === column.props.dataKey && (
                                                                        <Icon
                                                                            type="arrowUp"
                                                                            className={classnames('tw-h-4 tw-w-4 tw-transition-transform tw-duration-300', {
                                                                                'tw-rotate-180':
                                                                                    sortBy !== column.props.dataKey
                                                                                        ? (column.props.defaultSortDirection || SORT_DIRECTION.ASC) ===
                                                                                          SORT_DIRECTION.DESC
                                                                                        : sortDirection === SORT_DIRECTION.DESC,
                                                                            })}
                                                                        />
                                                                    )
                                                                }
                                                                className="tw-text-xs"
                                                                onClick={() => {
                                                                    if (isFunction(sort)) {
                                                                        sort({
                                                                            sortBy: column.props.dataKey,
                                                                            sortDirection:
                                                                                sortBy !== column.props.dataKey
                                                                                    ? column.props.defaultSortDirection || SORT_DIRECTION.ASC
                                                                                    : sortDirection === SORT_DIRECTION.ASC
                                                                                    ? SORT_DIRECTION.DESC
                                                                                    : SORT_DIRECTION.ASC,
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                {column.props.label}
                                                            </Button>
                                                        </Tooltip>
                                                    ) : isFunction(column.props.headerRenderer) ? (
                                                        column.props.headerRenderer()
                                                    ) : (
                                                        <span className="tw-px-1.5 tw-py-1.5 tw-text-xs">{column.props.label}</span>
                                                    )}
                                                </div>
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                        );
                    }}
                    components={virtuosoTableComponents}
                    itemContent={(rowIndex, rowData) => {
                        return filteredChildrenArray.map((column, columnIndex) => {
                            return (
                                <MemoizedTdElement
                                    {...column.props}
                                    key={`${columnIndex}_${rowIndex}`}
                                    rowData={rowData}
                                    rowIndex={rowIndex}
                                    borderless={borderless}
                                    density={density}
                                />
                            );
                        });
                    }}
                />
            </div>
            <Footer
                showRowCount={showRowCount}
                actions={actions}
                isLoading={isLoading}
                rowCount={rowCount}
                columnPicker={columnPicker}
                totalRowCount={totalRowCount}
                entity={entity}
                childrenArray={childrenArray}
                hiddenColumns={hiddenColumns}
                setHiddenColumns={setHiddenColumns}
                borderless={borderless}
            />
        </div>
    );
};

const Footer = ({
    showRowCount,
    actions,
    isLoading,
    rowCount,
    columnPicker,
    totalRowCount,
    entity,
    childrenArray,
    hiddenColumns,
    setHiddenColumns,
    borderless,
}) => {
    if (isLoading) {
        return (
            <div
                className={classnames('tw-grid tw-h-12 tw-items-center tw-px-4', {
                    'tw-border-t tw-border-solid tw-border-gray-100': !borderless,
                })}
            >
                <LoadingLinear />
            </div>
        );
    }
    if (showRowCount || isDefined(actions) || isDefined(columnPicker)) {
        return (
            <div
                className={classnames('tw-grid tw-h-12 tw-w-full tw-grid-cols-1fr-auto tw-items-center tw-px-4', {
                    'tw-border-t tw-border-solid tw-border-gray-100': !borderless,
                })}
            >
                <div className="tw-text-xs tw-font-medium">
                    {showRowCount &&
                        totalRowCount >= rowCount &&
                        coreMessage.showingXofYEntities({
                            x: rowCount,
                            y: totalRowCount,
                            entity: pluralize(entity, totalRowCount),
                        })}
                </div>
                <div>
                    {actions}
                    {isDefined(columnPicker) && (
                        <VirtualizedTableColumnPicker
                            columnPicker={columnPicker}
                            hiddenColumns={hiddenColumns}
                            setHiddenColumns={setHiddenColumns}
                            childrenArray={childrenArray}
                        />
                    )}
                </div>
            </div>
        );
    }

    return null;
};

const defaultCellRenderer = ({ cellData = null }) => {
    if (cellData === null || cellData === NOT_AVAILABLE) {
        return <span className="tw-text-gray-300">{NOT_AVAILABLE}</span>;
    }
    return <Ellipsis>{cellData}</Ellipsis>;
};

const MemoizedTdElement = React.memo(({ dataKey, justify, rowData, density, className, columnData, rowIndex, cellRenderer = defaultCellRenderer }) => {
    const cellData = rowData ? rowData[dataKey] : undefined;
    return (
        <td className="tw-w-full tw-max-w-full tw-overflow-hidden">
            <div
                className={classnames(
                    'tw-flex tw-w-full tw-max-w-full tw-items-center tw-px-4',
                    {
                        'tw-justify-end': justify === virtualizedTableColumnPropTypes.justify.end,
                        'tw-justify-center': justify === virtualizedTableColumnPropTypes.justify.center,
                        'tw-py-1': density === virtualizedTablePropTypes.density.compact,
                        'tw-py-2': density === virtualizedTablePropTypes.density.default,
                        'tw-py-3': density === virtualizedTablePropTypes.density.spacious,
                    },
                    className
                )}
                style={{
                    minWidth: 0, // Required for ellipsis inside flex layout.
                    minHeight: density === virtualizedTablePropTypes.density.compact ? 40 : 48,
                }}
            >
                {cellRenderer({ rowData, cellData, columnData, dataKey, rowIndex })}
            </div>
        </td>
    );
});

const VirtualizedTableColumnPicker = ({ columnPicker, hiddenColumns, setHiddenColumns, childrenArray }) => {
    const [contextDialogProps, setContextDialogProps] = useState({
        open: false,
        anchorEl: null,
    });
    const [pickableColumns] = useState(() => {
        let tempPickableColumnsOfTable = [];
        const { lockedColumns } = columnPicker;
        childrenArray.forEach((column) => {
            const { label = '' } = column.props;
            if (isString(label) && label.trim() !== '' && !lockedColumns.includes(label)) {
                tempPickableColumnsOfTable.push(label);
            }
        });
        return tempPickableColumnsOfTable;
    });

    const onClose = () => {
        setContextDialogProps((c) => ({
            ...c,
            open: false,
        }));
        columnPicker.setHiddenColumns(hiddenColumns.filter((hiddenColumn) => pickableColumns.includes(hiddenColumn))); // Update the user profiles columnPicker settings
    };

    return (
        pickableColumns.length > 0 && (
            <>
                <Tooltip
                    content="Manage Columns"
                    dark
                    extraPadding
                    bold
                >
                    <IconButton
                        color={BUTTON.PRIMARY}
                        size={BUTTON.SMALL}
                        noMargin
                        onClick={(e) => {
                            setContextDialogProps({
                                anchorEl: e.currentTarget,
                                open: true,
                            });
                        }}
                    >
                        <Icon type="tableHeaderAndColumns" />
                    </IconButton>
                </Tooltip>
                <ContextDialog
                    onClose={onClose}
                    anchorOrigin={{
                        vertical: 0,
                        horizontal: -288,
                    }}
                    visibleBackdrop
                    paperProps={{ style: { width: 340 } }}
                    {...contextDialogProps}
                >
                    <DialogTitle>
                        Manage Columns
                        <div className="tw-text-sm">Columns with a checkmark are visible</div>
                    </DialogTitle>
                    <DialogContent>
                        {pickableColumns.map((columnLabel) => {
                            return (
                                <div key={columnLabel}>
                                    <Checkbox
                                        name={columnLabel}
                                        checked={!hiddenColumns.includes(columnLabel)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setHiddenColumns((c) => c.filter((col) => col !== columnLabel));
                                            } else {
                                                setHiddenColumns((c) => [...c, columnLabel]);
                                            }
                                        }}
                                        label={<span className="tw-font-medium">{columnLabel}</span>}
                                    />
                                </div>
                            );
                        })}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setHiddenColumns(columnPicker.defaultHiddenColumns);
                            }}
                        >
                            Use default settings
                        </Button>

                        <Button
                            onClick={onClose}
                            color={BUTTON.PRIMARY}
                            variant={BUTTON.RAISED}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </ContextDialog>
            </>
        )
    );
};

VirtualizedTable.propTypes = {
    density: propTypes.oneOf([
        virtualizedTablePropTypes.density.compact,
        virtualizedTablePropTypes.density.default,
        virtualizedTablePropTypes.density.spacious,
    ]),
    fontSize: propTypes.oneOf([virtualizedTablePropTypes.fontSize.small, virtualizedTablePropTypes.fontSize.default, virtualizedTablePropTypes.fontSize.large]),
};

const Column = () => null; // Column is just a placeholder for props now. MemoizedTdElement will call the column cellRenderer eventually.

Column.propTypes = {
    justify: propTypes.oneOf([
        virtualizedTableColumnPropTypes.justify.end,
        virtualizedTableColumnPropTypes.justify.start,
        virtualizedTableColumnPropTypes.justify.center,
    ]),
    dataKey: propTypes.string.isRequired,
    columnData: propTypes.any, // Add custom data to the column that can be used in the cellRenderer.
    cellRenderer: propTypes.func,
    label: propTypes.string,
    minWidth: propTypes.number.isRequired,
    maxWidth: propTypes.number,
    disableSort: propTypes.bool,
    defaultSortDirection: propTypes.oneOf([SORT_DIRECTION.ASC, SORT_DIRECTION.DESC]),
    labelDescription: propTypes.any,
};

export { Column, VirtualizedTable, virtualizedTableColumnPropTypes, virtualizedTablePropTypes };
