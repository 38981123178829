import classNames from 'classnames';

export const PreReleaseTag = ({ color, size, className }) => (
    <div
        className={classNames(`tw-rounded-full`, `tw-px-2`, `tw-py-2`, `tw-text-fuchsia-950`, 'tw-bg-fuchsia-300', className)}
        key={'pre-release-tag'}
    >
        <div className={classNames(`tw-text-${size}`, `tw-font-bold`, `tw-leading-none`)}>Beta</div>
    </div>
);
