import { truncate } from '@capasystems/utils';
import React from 'react';
import Tooltip from '../tooltip/tooltip';

import { type TooltipProps } from '@capasystems/types';

export type TruncateProps = {
    text?: string;
    maxLength: number;
    tooltipProps?: Omit<TooltipProps, 'content'>;
};

const Truncate: React.FC<TruncateProps> = ({ text = '', maxLength, tooltipProps = {} }) => {
    const truncatedText = truncate(text, maxLength);
    if (truncatedText !== text) {
        return (
            <Tooltip
                extraPadding
                dark
                content={<div className="tw-whitespace-pre-wrap">{text}</div>}
                {...tooltipProps}
            >
                <span>{truncatedText}</span>
            </Tooltip>
        );
    }
    return text;
};

export { Truncate };
