import { TooltipProps } from '@capasystems/types';
import MUITooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import React from 'react';

/** Tooltip component */
export const Tooltip: React.FC<TooltipProps> = ({
    classes = {},
    position = 'top',
    content,
    fullWidth = false,
    light = false,
    ghost = false,
    dark = false,
    noPadding = false,
    extraPadding = false,
    bold = false,
    interactive = false,
    className,
    ...muiTooltipProps
}) => {
    return (
        <MUITooltip
            {...muiTooltipProps}
            title={content}
            placement={position}
            classes={{
                popper: classNames('cs-tooltip-container', classes.popper, className),
                tooltip: classNames(
                    {
                        'cs-tooltip tw-relative': true,
                        'cs-tooltip-ghost': ghost,
                        'cs-tooltip-dark': dark,
                        'cs-tooltip-light tw-bg-white tw-text-slate-900 tw-shadow-sm': light,
                        'tw-p-0': noPadding,
                        'tw-p-2.5': extraPadding,
                        'tw-font-semibold': bold,
                        'tw-max-w-none': fullWidth,
                    },
                    classes.tooltip
                ),
                arrow: classNames('cs-tooltip-arrow', classes.arrow),
            }}
            disableInteractive={!interactive}
        />
    );
};

// Tooltip.propTypes = {
//     /** One of TOOLTIP.POSITION.* */
//     position: PropTypes.oneOf([
//         'bottom',
//         'bottom-start',
//         'bottom-end',
//         'top',
//         'top-start',
//         'top-end',
//         'left',
//         'left-start',
//         'left-end',
//         'right',
//         'right-start',
//         'right-end',
//     ]),
//     /** The content to be displayed in the tooltip */
//     content: PropTypes.oneOfType([PropTypes.string, PropTypes.node, arrayOf(PropTypes.node)]).isRequired,
//     fullWidth: PropTypes.bool,
//     light: PropTypes.bool,
//     ghost: PropTypes.bool,
//     dark: PropTypes.bool,
//     noPadding: PropTypes.bool,
//     extraPadding: PropTypes.bool,
//     bold: PropTypes.bool,
//     interactive: PropTypes.bool,
//     /**
//      * The default enterTouchDelay makes sense when the tooltip is used to explain the functionality of a button / element that does some action.
//      * However, there is a use case where the tooltip is used as a purely informational element, in this case, we should set the enterTouchDelay to 0.
//      * The tooltip appears immediately after tapping the informational element.
//      * */
//     enterTouchDelay: PropTypes.number,
//     leaveTouchDelay: PropTypes.number,
//     enterDelay: PropTypes.number,
//     enterNextDelay: PropTypes.number,
//     disableHoverListener: PropTypes.bool,
// };

export default Tooltip;
