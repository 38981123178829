import { WidgetPaper } from '../thirdparty-components/thirdparty-components';
import { useMemo, useState, useEffect, useRef } from 'react';
import useNavigate from '../hooks/useNavigate/useNavigate';
import { useApi } from '../hooks/useApi/useApi';
import { PieChart, eSize } from '../charts/pie-chart';

export const DriverUpToDateByModel = ({ className }) => {
    const api = useApi();

    const navigate = useNavigate();

    const textRef = useRef();

    const [chartData, setChartData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.getDriverPatchQueue()
            .then(setChartData)
            .catch(() => setChartData())
            .finally(() => setLoading(false));
    }, []);

    const calculatedData = useMemo(() => {
        if (!chartData) {
            return [];
        }

        const modelsWithUpdates = {};

        for (const update of chartData) {
            if ((update?.status?.code >= 200 && update?.status?.code < 300) || update.status === null) {
                if (!modelsWithUpdates[update.model]) {
                    modelsWithUpdates[update.model] = 0;
                }
                modelsWithUpdates[update.model] += 1;
            }
        }

        return Object.entries(modelsWithUpdates)
            .map(([key, value]) => ({
                name: key,
                y: value,
            }))
            .sort((a, b) => b.y - a.y);
    }, [chartData]);

    textRef.current = calculatedData.reduce((p, c) => p + c.y, 0);

    return (
        <WidgetPaper
            title="Driver Up-to-date"
            description="Percentage of devices with drivers that are up-to-date"
            className={className}
            loading={loading}
        >
            <PieChart
                data={calculatedData}
                size={eSize.large}
                onClick={(e) => {
                    navigate.to(`windows/driver/list?driver.status.tab=3&search=${e.name}`);
                }}
            />
        </WidgetPaper>
    );
};
