import { BUTTON } from '@capasystems/constants';
import {
    Button,
    Column,
    Grid,
    ICON_TYPES,
    Icon,
    Input,
    LayoutCenter,
    LayoutRow,
    Link,
    Padding,
    Paper,
    SavingChanges,
    Switch,
    Tab,
    Tabs,
    VirtualizedTable,
    useParams,
    virtualizedTableColumnPropTypes,
} from '@capasystems/ui';
import { cloneDeep } from '@capasystems/utils';
import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ConfirmDialog, CoreContext, PageTitle, TailwindBadge, TwoLineCellRenderer, useApi, useNavigate } from '../../index';
import { SingleCountWidget } from '../widgets/widget-variants';
import PackageManagement from './packageManagement';

const TAB = {
    ANALYTICS: {
        ID: 'analytics',
        NAME: 'Analytics',
    },
    ORG_CONFIG: {
        ID: 'organization-config',
        NAME: 'Organization config',
    },
    PALETTE: {
        ID: 'palette',
        NAME: 'Palette',
    },
    ICONS: {
        ID: 'icons',
        NAME: 'Icons',
    },
    PACKAGES: {
        ID: 'packages',
        NAME: 'Packages',
    },
    SETTINGS: {
        ID: 'settings',
        NAME: 'Settings',
    },
};

const ANALYTICS_API_ENDPOINT = 'admin/analytics';
const ORG_CONFIG_API_ENDPOINT = 'admin/org/config';

export const PrivateLandingPage = (props) => {
    const { tabId } = useParams();
    const navigate = useNavigate();

    const onTabChange = (e, newTabId) => {
        navigate.to(`administration/capaone/${newTabId}`);
    };

    if (!tabId) {
        return (
            <Navigate
                to={navigate.appendBaseURL(`administration/capaone/${TAB.TENANT_CONFIG.ID}`)}
                replace
            />
        );
    }

    return (
        <div className="tw-grid tw-h-full tw-grid-cols-1 tw-grid-rows-auto-auto-1fr">
            <Padding>
                <LayoutRow
                    verticalAlign="center"
                    align="space-between"
                >
                    <PageTitle category="CapaOne">Administration</PageTitle>
                    <TailwindBadge
                        color="slate"
                        dark
                        className="tw-gap-1"
                        size="small"
                    >
                        <Icon
                            type="lock"
                            size="small"
                        />
                        Restricted to CapaSystems
                    </TailwindBadge>
                </LayoutRow>
            </Padding>
            <Tabs
                value={tabId}
                onChange={onTabChange}
                onRails
            >
                {Object.values(TAB).map((tab) => (
                    <Tab
                        value={tab.ID}
                        label={tab.NAME}
                        disableRipple
                        className="tw-min-w-0 tw-font-semibold lg:tw-px-8"
                    />
                ))}
            </Tabs>

            <div className="tw-overflow-auto">
                {tabId === TAB.ANALYTICS.ID && <Analytics />}
                {tabId === TAB.ORG_CONFIG.ID && <ReleaseCandidate />}
                {tabId === TAB.PALETTE.ID && <Palette />}
                {tabId === TAB.ICONS.ID && <Icons />}
                {tabId === TAB.PACKAGES.ID && <PackageManagement />}
                {tabId === TAB.SETTINGS.ID && <SettingsTab />}
            </div>
        </div>
    );
};

const Analytics = () => {
    const api = useApi();
    const [content, setContent] = useState({ isLoading: true, items: [] });

    const getAnalytics = () => {
        setContent({ ...content, isLoading: true });
        api.get(ANALYTICS_API_ENDPOINT)
            .then((data) => {
                const sorted = data.sort(({ name: aName }, { name: bName }) =>
                    aName.toLocaleLowerCase().localeCompare(bName.toLocaleLowerCase().toLocaleLowerCase())
                );
                setContent({ items: sorted, isLoading: false });
            })
            .catch(() => {
                setContent({ ...content, isLoading: false });
            });
    };

    useEffect(getAnalytics, []);

    return (
        <Padding className="tw-h-full">
            <Paper className="tw-h-full">
                <VirtualizedTable
                    items={content.items}
                    isLoading={content.isLoading}
                    disableHeader
                >
                    <Column
                        label=""
                        minWidth={120}
                        dataKey="name"
                        cellRenderer={({ rowData }) => (
                            <Link
                                component="a"
                                color={BUTTON.PRIMARY}
                                href={rowData.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {rowData.name}
                            </Link>
                        )}
                    />
                </VirtualizedTable>
            </Paper>
        </Padding>
    );
};

const ReleaseCandidate = () => {
    const api = useApi();
    const [anErrorOccurred, setAnErrorOccurred] = useState(false);
    const [tableItems, setTableItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [savingChanges, setSavingChanges] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState({ orgId: null });
    const [searchTerm, setSearchTerm] = useState('');
    const [sort, setSort] = useState({
        sortBy: 'orgName',
        sortDirection: 'ASC',
    });
    const [initialScan, setInitialScan] = useState({
        orgId: null,
    });
    const [reconfigureDialog, setReconfigureDialog] = useState({
        orgId: null,
    });
    const [restartDialog, setRestartDialog] = useState({
        orgId: null,
    });
    const [orgCVEScanDialog, setOrgCVEScanDialog] = useState({
        orgId: null,
    });

    const fetchConfigurableOrganizations = () => {
        api.get(ORG_CONFIG_API_ENDPOINT)
            .then((items) => {
                setTableItems(items);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setAnErrorOccurred(true);
            })
            .finally(() => {
                setSavingChanges(false);
            });
    };

    useEffect(fetchConfigurableOrganizations, [api]);

    const handleDataChange = (rowData) => {
        setSelectedOrganization(rowData);
    };

    const closeConfirmDialog = () => {
        setSelectedOrganization({
            ...selectedOrganization,
            orgId: null,
        });
    };

    const onConfirm = () => {
        api.update(ORG_CONFIG_API_ENDPOINT, {
            orgId: selectedOrganization.orgId,
            releaseCandidate: selectedOrganization.config.releaseCandidate,
            peering: selectedOrganization.config.peering,
            tray: selectedOrganization.config.tray,
        })
            .then(fetchConfigurableOrganizations)
            .catch((error) => {
                console.log(error);
                setAnErrorOccurred(true);
                setSavingChanges(false);
            });
        closeConfirmDialog();
        setSavingChanges(true);
    };

    const onCloseInitialScanDialog = () => setInitialScan({ orgId: null });
    const onCloseReconfigureDialog = () => setReconfigureDialog({ orgId: null });

    const onConfirmReconfigure = () => {
        api.reconfigureOrgDevice(reconfigureDialog.orgId);
        onCloseReconfigureDialog();
    };

    const onCloseRestartDialog = () => setRestartDialog({ orgId: null });

    const onConfirmInitialScan = () => {
        api.initialScanOrgDevice(initialScan.orgId);
        onCloseInitialScanDialog();
    };

    const onConfirmRestart = (all = false) => {
        api.restartOrgDevice(restartDialog.orgId, all);
        onCloseRestartDialog();
    };

    const onConfirmOrgCVEScan = () => {
        api.performOrgCVEScan(orgCVEScanDialog.orgId);
        onCloseOrgCVEScanDialog();
    };

    const onCloseOrgCVEScanDialog = () => setOrgCVEScanDialog({ orgId: null });

    if (anErrorOccurred) {
        return <LayoutCenter className="tw-text-xl">An error occured.</LayoutCenter>;
    }

    const sorting = (a, b) => {
        const { sortBy, sortDirection } = sort;
        if (sortBy === 'releaseCandidate' || sortBy === 'peering' || sortBy === 'tray' || sortBy === 'blockConnection') {
            const aNum = a.config[sortBy];
            const bNum = b.config[sortBy];
            if (sortDirection === 'ASC') {
                return aNum - bNum;
            } else {
                return bNum - aNum;
            }
        }

        if (sortDirection === 'ASC') {
            return a[sortBy].toLocaleLowerCase().localeCompare(b[sortBy].toLocaleLowerCase());
        } else {
            return b[sortBy].toLocaleLowerCase().localeCompare(a[sortBy].toLocaleLowerCase());
        }
    };

    const handleCheckChange = (rowData, dataKey) => (e) => {
        const { checked } = e.currentTarget;
        const copy = cloneDeep(rowData);
        copy.config[dataKey] = checked;
        handleDataChange(copy);
    };

    return (
        <div className="tw-grid tw-h-full tw-grid-rows-auto-1fr">
            <Padding>
                <LayoutCenter>
                    <Input
                        callToAction
                        type="search"
                        className="tw-w-88"
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                        rounded
                        placeholder="Search org name and org id"
                    />
                </LayoutCenter>
            </Padding>
            <Padding>
                <Paper className="tw-m-auto tw-h-full tw-max-w-screen-3xl">
                    <VirtualizedTable
                        items={tableItems
                            .filter(
                                ({ orgName, orgId }) =>
                                    orgName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                                    orgId.toString().includes(searchTerm.toLocaleLowerCase())
                            )
                            .sort(sorting)}
                        isLoading={loading}
                        sort={setSort}
                        sortBy={sort.sortBy}
                        sortDirection={sort.sortDirection}
                    >
                        <Column
                            label="Organization name"
                            minWidth={160}
                            dataKey="orgName"
                            cellRenderer={({ cellData, rowData }) => (
                                <TwoLineCellRenderer
                                    main={cellData}
                                    callToAction
                                    secondary={rowData.orgId}
                                />
                            )}
                        />
                        <Column
                            label="Use release candidate agent"
                            minWidth={200}
                            maxWidth={200}
                            dataKey="releaseCandidate"
                            cellRenderer={({ rowData, dataKey }) => (
                                <Switch
                                    checked={rowData.config[dataKey]}
                                    onChange={handleCheckChange(rowData, dataKey)}
                                    className="tw-mr-0"
                                />
                            )}
                        />
                        <Column
                            label="Peering"
                            minWidth={96}
                            maxWidth={96}
                            dataKey="peering"
                            cellRenderer={({ rowData, dataKey }) => (
                                <Switch
                                    checked={rowData.config[dataKey]}
                                    onChange={handleCheckChange(rowData, dataKey)}
                                    className="tw-mr-0"
                                />
                            )}
                        />
                        <Column
                            label="BlockConn"
                            minWidth={112}
                            maxWidth={112}
                            dataKey="blockConnection"
                            labelDescription="Block the grpc connection. Is set to true if contract has been terminated."
                            cellRenderer={({ rowData, dataKey }) => (
                                <Switch
                                    checked={rowData.config[dataKey]}
                                    className="tw-mr-0"
                                    disabled
                                />
                            )}
                        />
                        <Column
                            label="Tray"
                            minWidth={96}
                            maxWidth={96}
                            dataKey="tray"
                            cellRenderer={({ rowData, dataKey }) => (
                                <Switch
                                    checked={rowData.config[dataKey]}
                                    onChange={handleCheckChange(rowData, dataKey)}
                                    className="tw-mr-0"
                                />
                            )}
                        />
                        <Column
                            label="Block Connection"
                            minWidth={112}
                            maxWidth={112}
                            dataKey="blockConnection"
                            disableSort
                            cellRenderer={({ rowData, dataKey }) => (
                                <Switch
                                    checked={rowData.config[dataKey]}
                                    onChange={handleCheckChange(rowData, dataKey)}
                                    className="tw-mr-0"
                                    disabled
                                />
                            )}
                        />
                        <Column
                            label=""
                            minWidth={440}
                            maxWidth={440}
                            justify={virtualizedTableColumnPropTypes.justify.end}
                            dataKey=" "
                            cellRenderer={({ rowData }) => (
                                <>
                                    <Button
                                        variant={BUTTON.RAISED}
                                        color={BUTTON.PRIMARY}
                                        onClick={() => setInitialScan(rowData)}
                                    >
                                        Initial Scan
                                    </Button>
                                    <Button
                                        variant={BUTTON.RAISED}
                                        color={BUTTON.PRIMARY}
                                        onClick={() => setReconfigureDialog(rowData)}
                                    >
                                        Reconfigure
                                    </Button>
                                    <Button
                                        variant={BUTTON.RAISED}
                                        color={BUTTON.PRIMARY}
                                        onClick={() => setRestartDialog(rowData)}
                                    >
                                        Restart
                                    </Button>
                                    <Button
                                        variant={BUTTON.RAISED}
                                        color={BUTTON.PRIMARY}
                                        onClick={() => setOrgCVEScanDialog(rowData)}
                                        noMargin
                                    >
                                        Scan CVE
                                    </Button>
                                </>
                            )}
                        />
                    </VirtualizedTable>
                </Paper>
                <ConfirmDialog
                    open={selectedOrganization.orgId !== null}
                    onCancel={closeConfirmDialog}
                    onConfirm={onConfirm}
                    title={
                        <div>
                            <span>Change configuration for </span>
                            <b>{selectedOrganization.orgName}</b>?
                        </div>
                    }
                />
                <ConfirmDialog
                    open={reconfigureDialog.orgId !== null}
                    onCancel={onCloseReconfigureDialog}
                    onConfirm={onConfirmReconfigure}
                    title={
                        <div>
                            <span>Re-configuration </span>
                            <b>{reconfigureDialog.orgName}</b>?
                        </div>
                    }
                />
                <ConfirmDialog
                    open={initialScan.orgId !== null}
                    onCancel={onCloseInitialScanDialog}
                    onConfirm={onConfirmInitialScan}
                    title={
                        <div>
                            <span>Initial scan for </span>
                            <b>{initialScan.orgName}</b>?
                        </div>
                    }
                />
                <ConfirmDialog
                    open={restartDialog.orgId !== null}
                    onCancel={onCloseRestartDialog}
                    onConfirm={() => onConfirmRestart()}
                    aditionalActions={
                        <Button
                            onClick={() => onConfirmRestart(true)}
                            variant={BUTTON.RAISED}
                            color={BUTTON.PRIMARY}
                        >
                            Restart All
                        </Button>
                    }
                    title={
                        <div>
                            <span>Restart not latest updated devices for </span>
                            <b>{restartDialog.orgName}</b>?
                        </div>
                    }
                />
                <ConfirmDialog
                    open={orgCVEScanDialog.orgId !== null}
                    onCancel={onCloseOrgCVEScanDialog}
                    onConfirm={() => onConfirmOrgCVEScan()}
                    title={
                        <div>
                            <span>Start a CVE scan for all of </span>
                            <b>{orgCVEScanDialog.orgName}'s</b> <span> devices</span>?
                        </div>
                    }
                />
                <SavingChanges loading={savingChanges} />
            </Padding>
        </div>
    );
};

const Palette = () => {
    return (
        <Padding>
            <Grid
                container
                spacing={4}
                className="tw-m-auto tw-h-full tw-max-w-screen-lg"
            >
                {['red', 'orange', 'amber', 'yellow', 'blue', 'emerald', 'green', 'sky', 'slate', 'cyan', 'teal', 'indigo'].map((color) => (
                    <>
                        <Grid
                            item
                            lg={4}
                        >
                            <SingleCountWidget
                                title={`Light ${color}`}
                                description="Description field"
                                color={color}
                                count="Content"
                                className="tw-capitalize"
                                light
                            />
                        </Grid>
                        <Grid
                            item
                            lg={4}
                        >
                            <SingleCountWidget
                                title={`Standard ${color}`}
                                description="Description field"
                                color={color}
                                count="Content"
                                className="tw-capitalize"
                            />
                        </Grid>
                        <Grid
                            item
                            lg={4}
                        >
                            <SingleCountWidget
                                title={`Dark ${color}`}
                                description="Description field"
                                color={color}
                                count="Content"
                                className="tw-capitalize"
                                dark
                            />
                        </Grid>
                    </>
                ))}
            </Grid>
        </Padding>
    );
};

const Icons = () => {
    return (
        <Padding factor={2}>
            <div className="tw-grid tw-grid-cols-auto-1fr tw-items-start tw-gap-6">
                {ICON_TYPES.map((iconType) => (
                    <>
                        <b>{iconType}</b>
                        <LayoutRow>
                            <Icon
                                type={iconType}
                                className="tw-mr-6"
                                color="inherit"
                            />
                            <Icon
                                type={iconType}
                                className="tw-mr-6"
                                color="primary"
                            />
                            <Icon
                                type={iconType}
                                className="tw-mr-6"
                                color="success"
                            />
                            <Icon
                                type={iconType}
                                className="tw-mr-6"
                                color="error"
                            />
                            <Icon
                                type={iconType}
                                className="tw-mr-6"
                                color="disabled"
                            />
                            <span className="tw-text-purple-500">
                                <Icon
                                    type={iconType}
                                    color="inherit"
                                />
                            </span>
                        </LayoutRow>
                    </>
                ))}
            </div>
        </Padding>
    );
};
const SettingsTab = () => {
    const { showBetaFlag, setShowBetaFlag } = useContext(CoreContext);
    return (
        <Padding factor={2}>
            <b>Show Beta badges</b>
            <Switch
                checked={showBetaFlag}
                onChange={() => setShowBetaFlag(!showBetaFlag)}
            ></Switch>
        </Padding>
    );
};
