/* eslint-disable indent */
import { isFunction } from '@capasystems/utils';
import {
    mdiAccountCircle,
    mdiAccountEdit,
    mdiAccountGroup,
    mdiAlert,
    mdiApple,
    mdiAppleIos,
    mdiApplication,
    mdiApplicationBraces,
    mdiApplicationOutline,
    mdiAppsBox,
    mdiAudioInputRca,
    mdiGateBuffer as mdiBuffer,
    mdiCameraTimer,
    mdiCellphone,
    mdiCellphone as mdiCellphoneAndroid,
    mdiCellphoneArrowDown,
    mdiCellphoneLink,
    mdiChartAreaspline,
    mdiChartLineVariant,
    mdiCheckDecagram,
    mdiCheckDecagramOutline,
    mdiClockAlertOutline,
    mdiCloud,
    mdiCloudSync,
    mdiCogSync,
    mdiCpu64Bit,
    mdiCreditCard,
    mdiDesktopTower,
    mdiDisc,
    mdiEthernetCable,
    mdiFacebook as mdiFacebookBox,
    mdiFileCertificateOutline,
    mdiFileChart,
    mdiTextBoxCheck as mdiFileDocumentBoxCheck,
    mdiFileDownloadOutline,
    mdiFingerprint,
    mdiFlash,
    mdiHelpCircle,
    mdiHelpCircleOutline,
    mdiHome,
    mdiImageBrokenVariant,
    mdiKeyboardOutline,
    mdiLan,
    mdiLinkedin as mdiLinkedinBox,
    mdiLinux,
    mdiMicrosoftAzure,
    mdiMonitor,
    mdiMonitorShare,
    mdiMouse,
    mdiOpenInNew,
    mdiPrinter,
    mdiSerialPort,
    mdiServer,
    mdiShieldLockOutline,
    mdiSpeedometer,
    mdiSync,
    mdiTablet as mdiTabletAndroid,
    mdiTapeDrive,
    mdiToolbox,
    mdiToyBrickOutline,
    mdiTrayFull,
    mdiTuneVertical,
    mdiUsbFlashDriveOutline,
    mdiUsbPort,
    mdiViewDashboard,
    mdiViewDashboardOutline,
    mdiViewGrid,
    mdiViewList,
    mdiVolumeHigh,
    mdiWeb,
    mdiWebcam,
    mdiMicrosoftWindows as mdiWindows,
    mdiWrench,
} from '@mdi/js';
import MdiIcon from '@mdi/react';
import {
    AccountBox,
    AccountBoxOutlined,
    AccountCircle,
    AccountCircleOutlined,
    AccountTree,
    AccountTreeOutlined,
    Add,
    AddBox,
    AddCircleOutline,
    AdminPanelSettings,
    AllInclusive,
    AlternateEmail,
    Android,
    Apps,
    Archive,
    ArchiveOutlined,
    ArrowBack,
    ArrowDownward,
    ArrowDropDown,
    ArrowForward,
    ArrowUpward,
    AspectRatio,
    AttachFile,
    Attachment,
    Autorenew,
    AvTimer,
    AvTimerOutlined,
    Backspace,
    BackspaceOutlined,
    BarChart,
    BarChartRounded,
    Battery20,
    Battery30,
    Battery50,
    Battery60,
    Battery80,
    Battery90,
    BatteryFull,
    BatteryStd,
    BatteryUnknown,
    Beenhere,
    BeenhereOutlined,
    Block,
    Bluetooth,
    BluetoothDisabled,
    Brightness1,
    BugReport,
    BugReportOutlined,
    Call,
    CallToAction,
    Camera,
    Category,
    CategoryOutlined,
    Check,
    CheckBox,
    CheckBoxOutlineBlank,
    CheckCircle,
    CheckCircleOutline,
    CircleOutlined,
    Clear,
    CloudUpload,
    CloudUploadOutlined,
    Code,
    CommentOutlined,
    Computer,
    ComputerOutlined,
    CrisisAlert,
    DataObject,
    DateRange,
    Delete,
    DeleteOutline,
    DeleteSweep,
    DeleteSweepOutlined,
    DescriptionOutlined,
    DesktopMac,
    DesktopWindows,
    DesktopWindowsOutlined,
    DeveloperBoard,
    DeveloperBoardOutlined,
    DeviceHub,
    Devices,
    DevicesOther,
    Diversity2,
    Dns,
    DnsOutlined,
    DonutLarge,
    DonutSmall,
    DoubleArrow,
    DownloadForOfflineOutlined,
    DriveFolderUploadOutlined,
    Edit,
    EditOutlined,
    Email,
    EmailOutlined,
    Error,
    ErrorOutline,
    Exposure,
    Factory,
    Favorite,
    FavoriteBorder,
    FileDownloadOff,
    FilterListRounded,
    Flag,
    FolderOpen,
    FontDownload,
    FontDownloadOutlined,
    Fullscreen,
    FullscreenExit,
    GetApp,
    GroupAdd,
    GroupWorkOutlined,
    Groups,
    Handyman,
    HealthAndSafety,
    HighlightOff,
    HourglassEmpty,
    HourglassEmptyOutlined,
    Http,
    Hub,
    HubOutlined,
    ImageSearch,
    IndeterminateCheckBox,
    Info,
    InfoOutlined,
    InsertPhoto,
    InsertPhotoOutlined,
    Insights,
    InstallDesktop,
    KeyboardArrowDown,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    KeyboardArrowUp,
    Language,
    LaptopMac,
    LaptopWindows,
    Layers,
    LayersRounded,
    Lightbulb,
    LightbulbOutlined,
    Link,
    LocationOn,
    Lock,
    LockClockOutlined,
    LockClockRounded,
    LockOpen,
    LockPerson,
    LockPersonOutlined,
    LowPriority,
    ManageSearch,
    Map,
    Memory,
    Menu,
    MenuOpen,
    MicRounded,
    MoreHoriz,
    MoreVert,
    NetworkCheck,
    NewReleases,
    NewReleasesOutlined,
    NoAccounts,
    Notes,
    NotificationsActive,
    NotificationsNone,
    NotificationsOff,
    NotificationsOffOutlined,
    OutlinedFlag,
    Password,
    People,
    PermDataSetting,
    Person,
    PersonAdd,
    PersonOff,
    PersonOffOutlined,
    PersonOutline,
    Phone,
    PhoneAndroid,
    PhoneIphone,
    PhoneOutlined,
    PhonelinkLock,
    PhonelinkSetupOutlined,
    PieChart,
    Pin,
    PinOutlined,
    PlayArrow,
    PlayCircleOutlined,
    Policy,
    PowerSettingsNew,
    PriorityHigh,
    PrivacyTip,
    Public,
    Publish,
    QuestionMark,
    Refresh,
    Remove,
    RemoveCircleOutlineOutlined,
    Repeat,
    Replay,
    RestartAlt,
    RocketLaunch,
    RotateLeft,
    ScatterPlot,
    Schedule,
    ScreenLockPortrait,
    Search,
    SearchOff,
    Security,
    SecurityOutlined,
    Settings,
    SettingsApplications,
    SettingsApplicationsOutlined,
    SettingsBluetooth,
    SettingsEthernet,
    SettingsEthernetOutlined,
    SettingsOutlined,
    SettingsPower,
    ShowChart,
    Sms,
    Sort,
    Source,
    SourceOutlined,
    SsidChartRounded,
    StackedBarChartRounded,
    Star,
    StarBorder,
    Storage,
    Storefront,
    SummarizeOutlined,
    SupervisedUserCircle,
    SupervisorAccount,
    SupervisorAccountOutlined,
    TableChart,
    TaskAltOutlined,
    TextFormat,
    Timelapse,
    Timeline,
    Timer,
    TimerOutlined,
    TipsAndUpdates,
    TipsAndUpdatesOutlined,
    TouchApp,
    TrendingUp,
    UnfoldLess,
    UnfoldMore,
    UploadFile,
    VerifiedUser,
    VerifiedUserOutlined,
    Videocam,
    PausePresentation as VirtualWindows,
    Visibility,
    VisibilityOff,
    VpnKey,
    VpnLock,
    Wallpaper,
    Whatshot,
    Widgets,
    WidgetsOutlined,
    Wifi,
    WifiLock,
    Work,
    WorkOutline,
} from '@mui/icons-material';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import classnames from 'classnames';
import React, { Ref } from 'react';
import './icon.scss';

import { ReactComponent as capasystems } from './custom-icons/CapaSystems_Logo.svg';
import { ReactComponent as googleColored } from './custom-icons/GoogleG.svg';
import { ReactComponent as excel } from './custom-icons/Microsoft_Office_Excel_2019–present.svg';
import { ReactComponent as appleAppStore } from './custom-icons/apple-ios-app-store.svg';
import { ReactComponent as capaDrivers } from './custom-icons/capaDrivers.svg';
import { ReactComponent as capaFactory } from './custom-icons/capaFactory.svg';
import { ReactComponent as capaForum } from './custom-icons/capaForum.svg';
import { ReactComponent as capaImages } from './custom-icons/capaImages.svg';
import { ReactComponent as capaInstaller } from './custom-icons/capaInstaller.svg';
import { ReactComponent as capaKnowledgeBase } from './custom-icons/capaKnowledgeBase.svg';
import { ReactComponent as capaMobileDeviceManagement } from './custom-icons/capaMobileDeviceManagement.svg';
import { ReactComponent as capaPacks } from './custom-icons/capaPacks.svg';
import { ReactComponent as capaServiceDesk } from './custom-icons/capaServiceDesk.svg';
import { ReactComponent as capaTechBlog } from './custom-icons/capaTechBlog.svg';
import { ReactComponent as capaZeroTouch } from './custom-icons/capaZeroTouch.svg';
import { ReactComponent as capaoneAdminOnDemand } from './custom-icons/capaoneAdminOnDemand.svg';
import { ReactComponent as capaonePortal } from './custom-icons/capaonePortal.svg';
import { ReactComponent as capaoneReliability } from './custom-icons/capaoneReliability.svg';
import { ReactComponent as capaoneSecurity } from './custom-icons/capaoneSecurityFigmaAdjusted.svg';
import { ReactComponent as capaoneUpdater } from './custom-icons/capaoneUpdater.svg';
import { ReactComponent as customApplication } from './custom-icons/customApplication.svg';
import { ReactComponent as customappsIcon } from './custom-icons/customapps_Icon.svg';
import { ReactComponent as googlePlayStore } from './custom-icons/google-play-store.svg';
import { ReactComponent as microsoftColored } from './custom-icons/microsoft-logo.svg';
import { ReactComponent as microsoftOffice365 } from './custom-icons/microsoftOffice365.svg';
import { ReactComponent as performanceGuard } from './custom-icons/performanceGuard.svg';
import { ReactComponent as csv } from './custom-icons/text_flat_csv.svg';

const CustomIconComponents = {
    capaDrivers,
    capaFactory,
    capaForum,
    capaImages,
    capaInstaller,
    capaKnowledgeBase,
    capaMobileDeviceManagement,
    capaPacks,
    capaServiceDesk,
    capasystems,
    capaZeroTouch,
    capaTechBlog,
    capaonePortal,
    capaoneUpdater,
    capaoneAdminOnDemand,
    capaoneReliability,
    customappsIcon,
    performanceGuard,
    microsoftOffice365,
    microsoftColored,
    googleColored,
    googlePlayStore,
    appleAppStore,
    capaoneSecurity,
    customApplication,
    excel,
    csv,
};

const mdiIconPaths = {
    azure: mdiMicrosoftAzure,
    lan: mdiLan,
    citrix: mdiBuffer,
    speedometer: mdiSpeedometer,
    web: mdiWeb,
    server: mdiServer,
    windows: mdiWindows,
    help: mdiHelpCircle,
    helpOutlined: mdiHelpCircleOutline,
    fileDownload: mdiFileDownloadOutline,
    accountCircle: mdiAccountCircle,
    contract: mdiFileDocumentBoxCheck,
    home: mdiHome,
    editAccount: mdiAccountEdit,
    accountGroup: mdiAccountGroup,
    arearange: mdiChartAreaspline,
    adjustYAxis: mdiTuneVertical,
    timelineChartResolution: mdiCameraTimer,
    dashboard: mdiViewDashboard,
    dashboardOutlined: mdiViewDashboardOutline,
    dashboards: mdiViewDashboard,
    analyze: mdiChartLineVariant,
    reporting: mdiFileChart,
    cloudSync: mdiCloudSync,
    alert: mdiAlert,
    externalLink: mdiOpenInNew,
    linkedin: mdiLinkedinBox,
    facebook: mdiFacebookBox,
    gridView: mdiViewGrid,
    listView: mdiViewList,
    cellphoneAndroid: mdiCellphoneAndroid,
    tabletAndroid: mdiTabletAndroid,
    devices: mdiCellphoneLink,
    config: mdiWrench,
    toolbox: mdiToolbox,
    action: mdiFlash,
    application: mdiApplication,
    applicationOutlined: mdiApplicationOutline,
    appsBox: mdiAppsBox,
    cloud: mdiCloud,
    apple: mdiApple,
    appleIos: mdiAppleIos,
    linux: mdiLinux,
    billing: mdiCreditCard,
    clockAlert: mdiClockAlertOutline,
    input_output: mdiAudioInputRca,
    disc: mdiDisc,
    drive: mdiTapeDrive,
    monitor: mdiMonitor,
    displayAdapter: mdiMonitorShare,
    firmware: mdiApplicationBraces,
    humanInterface: mdiToyBrickOutline,
    webcam: mdiWebcam,
    keyboard: mdiKeyboardOutline,
    mouse: mdiMouse,
    ports: mdiSerialPort,
    printQueue: mdiTrayFull,
    printer: mdiPrinter,
    cpu: mdiCpu64Bit,
    securityDevice: mdiShieldLockOutline,
    audio: mdiVolumeHigh,
    desktop: mdiDesktopTower,
    usb: mdiUsbPort,
    usbFlashDrive: mdiUsbFlashDriveOutline,
    sata: mdiEthernetCable,
    biometric: mdiFingerprint,
    badgeCheckmark: mdiCheckDecagram,
    badgeCheckmarkOutlined: mdiCheckDecagramOutline,
    iosEndpoint: mdiCellphone,
    sync: mdiSync,
    updateOS: mdiCellphoneArrowDown,
    configSync: mdiCogSync,
    certificate: mdiFileCertificateOutline,
};

const IconComponents = {
    phone: Phone,
    phoneOutlined: PhoneOutlined,
    email: Email,
    emailOutlined: EmailOutlined,
    alternateEmail: AlternateEmail,
    badgeExclamation: NewReleases,
    badgeExclamationOutlined: NewReleasesOutlined,
    security: Security,
    antivirus: Policy,
    firewall: Security,
    softwarePatches: HealthAndSafety,
    securityOutlined: SecurityOutlined,
    lowPriority: LowPriority,
    code: Code,
    memory: Memory,
    textFormat: TextFormat,
    category: Category,
    categoryOutlined: CategoryOutlined,
    developerBoard: DeveloperBoard,
    developerBoardOutlined: DeveloperBoardOutlined,
    camera: Camera,
    videoCam: Videocam,
    bluetooth: Bluetooth,
    bluetoothSettings: SettingsBluetooth,
    bluetoothDisabled: BluetoothDisabled,
    sms: Sms,
    microphone: MicRounded,
    call: Call,
    language: Language,
    dataRoaming: Language,
    phoneLock: PhonelinkLock,
    screenLockPortrait: ScreenLockPortrait,
    apps: Apps,
    permDataSetting: PermDataSetting,
    android: Android,
    reset: RotateLeft,
    addBox: AddBox,
    removeBox: IndeterminateCheckBox,
    battery: BatteryStd,
    battery20: Battery20,
    battery30: Battery30,
    battery50: Battery50,
    battery60: Battery60,
    battery80: Battery80,
    battery90: Battery90,
    batteryFull: BatteryFull,
    batteryUnknown: BatteryUnknown,
    aspectRatio: AspectRatio,
    addUser: PersonAdd,
    addUsers: GroupAdd,
    people: People,
    usersCircle: SupervisedUserCircle,
    userCircle: AccountCircle,
    userCircleOutlined: AccountCircleOutlined,
    accountBox: AccountBox,
    accountBoxOutlined: AccountBoxOutlined,
    supervisorAccount: SupervisorAccount,
    supervisorAccountOutlined: SupervisorAccountOutlined,
    vpnLock: VpnLock,
    wifi: Wifi,
    wifiLock: WifiLock,
    widgets: Widgets,
    widgetsOutlined: WidgetsOutlined,
    kioskMode: Storefront,
    work: Work,
    workOutlined: WorkOutline,
    powerSettings: SettingsPower,
    power: PowerSettingsNew,
    callToAction: CallToAction,
    add: Add,
    administration: SettingsApplications,
    administrationOutlined: SettingsApplicationsOutlined,
    addOutlined: AddCircleOutline,
    infoOutlined: InfoOutlined,
    barChart: Sort,
    sort: Sort,
    columnChart: BarChart,
    columnChartRounded: BarChartRounded,
    stackedColumnChartRounded: StackedBarChartRounded,
    splinesChartRounded: SsidChartRounded,
    refresh: Refresh,
    folderOutlined: FolderOpen,
    autoUpdate: Autorenew,
    remove: Clear,
    clear: Clear,
    removeOutlined: HighlightOff,
    clearOutlined: HighlightOff,
    search: Search,
    manageSearch: ManageSearch,
    delete: Delete,
    deleteOutlined: DeleteOutline,
    description: Notes,
    menuOpen: MenuOpen,
    donutChart: DonutLarge,
    donutChartBold: DonutSmall,
    commentOutlined: CommentOutlined,
    menu: Menu,
    arrowDown: ArrowDownward,
    arrowLeft: ArrowBack,
    arrowRight: ArrowForward,
    arrowUp: ArrowUpward,
    moreVert: MoreVert,
    arrowDropDown: ArrowDropDown,
    unfold: UnfoldMore,
    unfoldLess: UnfoldLess,
    chevronDown: KeyboardArrowDown,
    chevronRight: KeyboardArrowRight,
    chevronUp: KeyboardArrowUp,
    chevronLeft: KeyboardArrowLeft,
    circle: Brightness1,
    download: GetApp,
    downloadDisabled: FileDownloadOff,
    archive: Archive,
    archiveOutlined: ArchiveOutlined,
    downloadOutlined: DownloadForOfflineOutlined,
    trendingUp: TrendingUp,
    moreHoriz: MoreHoriz,
    flag: Flag,
    flagOutlined: OutlinedFlag,
    timelapse: Timelapse,
    networkCheck: NetworkCheck,
    filter: FilterListRounded,
    fire: Whatshot,
    exposure: Exposure,
    key: VpnKey,
    clone: Layers,
    configure: Settings,
    configureOutlined: SettingsOutlined,
    info: Info,
    location: LocationOn,
    pieChart: PieChart,
    play: PlayArrow,
    playCircleOutlined: PlayCircleOutlined,
    checkbox: CheckBoxOutlineBlank,
    checkboxChecked: CheckBox,
    checkmark: Check,
    dash: Remove,
    dashOutlined: RemoveCircleOutlineOutlined,
    checkmarkCircle: CheckCircle,
    checkmarkCircleOutlined: CheckCircleOutline,
    checkmarkOutlined: TaskAltOutlined,
    favorite: Favorite,
    favoriteOutlined: FavoriteBorder,
    star: Star,
    starOutlined: StarBorder,
    lock: Lock,
    lockOpen: LockOpen,
    person: Person,
    personOutlined: PersonOutline,
    inherit: DeviceHub,
    connectAllPoints: ShowChart,
    disconnectAllPoints: ScatterPlot,
    connectPointsAutomatically: Timeline,
    edit: Edit,
    editOutlined: EditOutlined,
    fullscreen: Fullscreen,
    exitFullscreen: FullscreenExit,
    tableHeaderAndColumns: TableChart,
    onDemand: TouchApp,
    clock: Schedule,
    dateRange: DateRange,
    notificationsActive: NotificationsActive,
    verifiedUser: VerifiedUser,
    verifiedUserOutlined: VerifiedUserOutlined,
    desktopWindows: DesktopWindows,
    desktopWindowsOutlined: DesktopWindowsOutlined,
    laptopWindows: LaptopWindows,
    virtualWindows: VirtualWindows,
    doubleArrow: DoubleArrow,
    http: Http,
    upload: CloudUpload,
    uploadOutlined: CloudUploadOutlined,
    publish: Publish,
    attachFile: AttachFile,
    attachment: Attachment,
    imageSearch: ImageSearch,
    public: Public,
    earth: Public,
    googlePlayWebApp: Public,
    appleWebClip: Public,
    wallpaper: Wallpaper,
    insertPhotoOutlined: InsertPhotoOutlined,
    insertPhoto: InsertPhoto,
    app: FontDownload,
    appOutlined: FontDownloadOutlined,
    phonelinkSetup: PhonelinkSetupOutlined,
    link: Link,
    androidGroup: GroupWorkOutlined,
    appleGroup: GroupWorkOutlined,
    groupWorkOutlined: GroupWorkOutlined,
    androidEndpoint: PhoneAndroid,
    appleEndpoint: PhoneIphone,
    androidConfiguration: SettingsOutlined,
    appleConfiguration: SettingsOutlined,
    androidApplication: Apps,
    appleApplication: Apps,
    block: Block,
    error: Error,
    errorOutlined: ErrorOutline,
    exclamation: PriorityHigh,
    dns: Dns,
    dnsOutlined: DnsOutlined,
    settingsEthernet: SettingsEthernet,
    settingsEthernetOutlined: SettingsEthernetOutlined,
    computer: Computer,
    computerOutlined: ComputerOutlined,
    storage: Storage,
    accountTree: AccountTree,
    accountTreeOutlined: AccountTreeOutlined,
    beenHere: Beenhere,
    beenhereOutlined: BeenhereOutlined,
    backspace: Backspace,
    backspaceOutlined: BackspaceOutlined,
    deleteSweep: DeleteSweep,
    deleteSweepOutlined: DeleteSweepOutlined,
    infinity: AllInclusive,
    noAccounts: NoAccounts,
    personOff: PersonOff,
    personOffOutlined: PersonOffOutlined,
    lockPerson: LockPerson,
    lockPersonOutlined: LockPersonOutlined,
    lockClock: LockClockRounded,
    lockClockOutlined: LockClockOutlined,
    groups: Groups,
    repeat: Repeat,
    timer: Timer,
    timerOutlined: TimerOutlined,
    avTimer: AvTimer,
    avTimerOutlined: AvTimerOutlined,
    hourglassEmpty: HourglassEmpty,
    hourglassEmptyOutlined: HourglassEmptyOutlined,
    diversity: Diversity2,
    hub: Hub,
    hubOutlined: HubOutlined,
    questionMark: QuestionMark,
    adminPanelSettings: AdminPanelSettings,
    pendingReboot: RestartAlt,
    summarizeOutlined: SummarizeOutlined,
    privacyTip: PrivacyTip,
    lightbulb: Lightbulb,
    lightbulbOutlined: LightbulbOutlined,
    tipsAndUpdates: TipsAndUpdates,
    tipsAndUpdatesOutlined: TipsAndUpdatesOutlined,
    handyman: Handyman,
    iphone: PhoneIphone,
    laptopMac: LaptopMac,
    desktopMac: DesktopMac,
    mobileDevices: DevicesOther,
    capaoneExperience: Insights,
    installDesktop: InstallDesktop,
    dataObject: DataObject,
    visibility: Visibility,
    visibilityOff: VisibilityOff,
    reload: Replay,
    circleOutlined: CircleOutlined,
    source: Source,
    sourceOutlined: SourceOutlined,
    directoryUploadOutlined: DriveFolderUploadOutlined,
    fileUpload: UploadFile,
    bugReport: BugReport,
    bugReportOutlined: BugReportOutlined,
    password: Password,
    pin: Pin,
    pinOutlined: PinOutlined,
    windowsEndpoints: Devices,
    factory: Factory,
    rocketLaunch: RocketLaunch,
    layersRounded: LayersRounded,
    searchOff: SearchOff,
    map: Map,
    crisisAlert: CrisisAlert,
    notificationsNone: NotificationsNone,
    notificationsOff: NotificationsOff,
    notificationsOffOutlined: NotificationsOffOutlined,
    descriptionOutlined: DescriptionOutlined,
};

const calculateSize: (tempSize: string | number) => string | number = (tempSize) => {
    switch (tempSize) {
        case 'small':
            return '16px';
        case 'medium':
            return '24px';
        case 'large':
            return '32px';
        default:
            return tempSize;
    }
};

type MdiIconPathsKeys = keyof typeof mdiIconPaths;
type CustomIconComponentsKeys = keyof typeof CustomIconComponents;
type IconComponentsKeys = keyof typeof IconComponents;

export type IconType = IconComponentsKeys | CustomIconComponentsKeys | MdiIconPathsKeys;

export type IconProps = (Omit<typeof MdiIcon, 'size' | 'color'> | Omit<SvgIconProps, 'size' | 'color'>) & {
    type: IconType;
    color?: 'inherit' | 'primary' | 'success' | 'error' | 'disabled';
    size?: number | string | 'small' | 'medium' | 'large' | 'inherit';
    onClick?: () => void;
    className?: string;
};

/** Available icons */
export const Icon = React.forwardRef<Ref<any>, IconProps>(({ size = 'medium', color = 'inherit', type, className, ...otherProps }, ref) => {
    const iconProps = {
        className: classnames(
            {
                'cs-icon': true,
                'tw-cursor-pointer': isFunction(otherProps.onClick),
            },
            `cs-icon-${color}`,
            `cs-icon-type-${type}`,
            className
        ),
        ref,
        ...otherProps,
    };
    const IconComponent = IconComponents[type as IconComponentsKeys];
    if (IconComponent) {
        const svgIconProps = iconProps as SvgIconProps;
        return (
            <IconComponent
                fontSize={size as SvgIconProps['fontSize']}
                color={color === 'success' ? 'secondary' : color}
                {...svgIconProps}
            />
        );
    }
    const CustomIconComponent = CustomIconComponents[type as CustomIconComponentsKeys];
    if (CustomIconComponent) {
        const svgIconProps = iconProps as SvgIconProps;
        return (
            <SvgIcon
                fontSize={size as SvgIconProps['fontSize']}
                color={color === 'success' ? 'secondary' : color}
                {...svgIconProps}
            >
                <CustomIconComponent />
            </SvgIcon>
        );
    }

    const mdiIconProps = iconProps as unknown as typeof MdiIcon;
    const path: string = mdiIconPaths[type as MdiIconPathsKeys] || mdiImageBrokenVariant;
    return (
        <MdiIcon
            path={path}
            size={calculateSize(size)}
            color={color}
            {...mdiIconProps}
        />
    );
});

export const ICON_TYPES = [...Object.keys(IconComponents), ...Object.keys(CustomIconComponents), ...Object.keys(mdiIconPaths)];

export default Icon;
