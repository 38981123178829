import React from 'react';
import ReactDOM from 'react-dom/client';

import Index from './app/index';

import { isBeta, isLocalHost } from '@capasystems/constants';
import TagManager from 'react-gtm-module';

import { clarity } from 'react-microsoft-clarity';

// Encountered Webpack issue with 'import' - solved using 'require'.
// eslint-disable-next-line @typescript-eslint/no-var-requires
const PiwikPro = require('@piwikpro/react-piwik-pro');

const piwikProKey = isBeta
    ? 'fd42a693-72da-4461-a965-dbb353ee75b3'
    : 'a444bae2-d17e-4a92-8182-f434ea82d659';

PiwikPro.default.initialize(
    piwikProKey,
    'https://capasystems.containers.piwik.pro'
);

if(!isLocalHost && !isBeta) {
    clarity.init('nj27jn1v0l');
}

const tagManagerArgs = {
    gtmId: isBeta ? 'GTM-WR2KD82' : 'GTM-5C9H4DZ',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<Index />);
