import { BUTTON } from '@capasystems/constants';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, LayoutCentered, Zoom } from '@capasystems/ui';
import { windowsApplication } from '@thirdparty/constants';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthContext from '../../hooks/useAuthContext/useAuthContext';

const WindowsAvailableCapaPacks = ({ showApplicationList, setType, initialSearchTerm = '', ...props }) => {
    const navigate = useNavigate();
    const { permissions } = useAuthContext();
    const [showConfigurationTypePicker, setShowConfigurationTypePicker] = useState(false);

    const [availableOptions] = useState(() => {
        const tempAvailableOptions = [];
        if (permissions.canManageSoftwarePatching) {
            tempAvailableOptions.push({
                iconType: windowsApplication.type.capaPacks.icon,
                name: windowsApplication.type.capaPacks.name,
                onClick: () => {
                    setType(windowsApplication.type.capaPacks.id);
                    showApplicationList(true);
                    setShowConfigurationTypePicker(false);
                },
            });
        }
        if (permissions.canManageCustomApps) {
            tempAvailableOptions.push({
                name: windowsApplication.type.customApp.name,
                iconType: windowsApplication.type.customApp.icon,
                onClick: () => {
                    navigate('../new/customapp');
                },
            });
        }
        return tempAvailableOptions;
    });

    const openConfigurationTypePicker = () => {
        if (availableOptions.length === 1) {
            availableOptions[0].onClick();
        } else {
            setShowConfigurationTypePicker(true);
        }
    };

    const closeConfigurationTypePicker = () => {
        setShowConfigurationTypePicker(false);
    };

    return (
        <>
            <Dialog
                open={showConfigurationTypePicker}
                onClose={closeConfigurationTypePicker}
                size="xs"
            >
                <DialogTitle>
                    <span>Application type</span>
                </DialogTitle>

                <DialogContent className="tw-w-full">
                    <Zoom in>
                        <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-8 tw-text-xs">
                            {availableOptions.map((option) => (
                                <div
                                    className="tw-h-44 tw-w-44 tw-cursor-pointer tw-rounded-lg tw-bg-slate-100 tw-shadow-sm tw-transition tw-duration-300 hover:tw-scale-105 hover:tw-shadow"
                                    onClick={option.onClick}
                                    key={option.name}
                                >
                                    <LayoutCentered direction="column">
                                        <Icon
                                            type={option.iconType}
                                            className="tw-mb-3 tw-text-6xl"
                                        />
                                        <strong>{option.name}</strong>
                                    </LayoutCentered>
                                </div>
                            ))}
                        </div>
                    </Zoom>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfigurationTypePicker}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export { WindowsAvailableCapaPacks };
