import { BUTTON, SORT_DIRECTION, coreMessage } from '@capasystems/constants';
import {
    Button,
    Column,
    Dialog,
    DialogActions,
    DialogTitle,
    Ellipsis,
    EmptyState,
    Icon,
    IconButton,
    LayoutCentered,
    Loading,
    Page,
    Tab,
    Tabs,
    Tooltip,
    VirtualizedTable,
    useParams,
    virtualizedTableColumnPropTypes,
} from '@capasystems/ui';
import { Url, api as baseApi, formatDate, getSortingFunction, isUndefined } from '@capasystems/utils';
import { Portal } from '@mui/base';
import { DEVICE_TYPE, LOCAL_STORAGE_ID, PRODUCT_NAME } from '@thirdparty/constants';

import classNames from 'classnames';
import pluralize from 'pluralize';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import colors from 'tailwindcss/colors';
import { PieChart } from '../charts/pie-chart';
import ConfirmDialog from '../confirm-dialog/confirm-dialog';
import { CapaOneContactDialog, PremiumFeature } from '../contact/contact.components';
import { useApi } from '../hooks/useApi/useApi';
import useAuthContext from '../hooks/useAuthContext/useAuthContext';
import useLocalStorageState from '../hooks/useLocalStorageState/useLocalStorageState';
import useNavigate from '../hooks/useNavigate/useNavigate';
import { useReportExportHistorySocket } from '../hooks/useSocket/useSocket';
import { useWindowsEndpointListColumns } from '../hooks/useVirtualizedTableColumns/useVirtualizedTableColumns';
import { PageTitle } from '../page/page-title';
import { ActionsDialog, WidgetPaper } from '../thirdparty-components/thirdparty-components';

const exportCSV = {
    id: 'exportCSV',
    name: 'Export to CSV',
    responseType: 'csv',
};

const exportExcel = {
    id: 'exportExcel',
    name: 'Export to Excel',
    responseType: 'xlsx',
};

const pattern = /\D+/g;

const TAB_ID = {
    REPORTS: 'reports',
    NIS2: 'nis2',
    ENDPOINT: 'endpoint',
};

const TEMPLATE_ID = {
    ENDPOINT: 'ENDPOINT',
    NIS2: 'NIS2',
};

const TEMPLATES = [
    {
        name: 'NIS2 report',
        description: "Summary of your endpoint's security state",
        keyContent: [
            'Missing Updates per Windows Endpoint',
            'Endpoints per OS Version',
            'System Updates for Apple & Android Endpoints',
            'Windows Antivirus',
            'Windows Encryption',
            'Windows Firewall',
            'Detected CVEs on Windows Endpoints',
        ],
        templateId: TEMPLATE_ID.NIS2,
        items: [],
        generating: false,
    },
    {
        name: 'Endpoint report',
        description: 'Summary of your endpoint details',
        keyContent: [
            `Type of Endpoint; like ${DEVICE_TYPE.DESKTOP}, ${DEVICE_TYPE.LAPTOP} or ${DEVICE_TYPE.SERVER}`,
            'Name of Endpoint',
            `${PRODUCT_NAME} Agent Version`,
            'Operating System',
            'Latest OS startup',
            'Service Startup',
            'Available Software Updates',
        ],
        templateId: TEMPLATE_ID.ENDPOINT,
        items: [],
        generating: false,
    },
];

const descriptionForActionsDialog = 'The report will be archived after download';

const SORT_BY = 'sortBy';
const SORT_DIR = 'sortDir';

const generateAndDownloadReportFromTemplate = (templateId, responseType) => {
    window.location.href = `/${baseApi.defaults.baseURL}/report/export?template=${templateId}&responseType=${responseType}`;
};

const downloadArchivedReport = (report) => () => {
    window.location.href = `/${baseApi.defaults.baseURL}/report/history/${report._id}?responseType=${report.format}`;
};

const ReportingManagementLandingPage = () => {
    const { tabId } = useParams();
    const navigate = useNavigate();
    const [latestTab, setSelectedTabId] = useLocalStorageState(LOCAL_STORAGE_ID.SELECTED_REPORTING_TAB, TAB_ID.REPORTS);

    const [portalContainer, setPortalContainer] = useState(null);

    const onRefChange = useCallback((node) => {
        setPortalContainer(node);
    }, []);

    const onTabChange = (e, newTabId) => {
        setSelectedTabId(newTabId);
        navigate.to(`management/reporting/${newTabId}`);
    };

    if (isUndefined(tabId)) {
        return (
            <Navigate
                to={Object.values(TAB_ID).includes(latestTab) ? latestTab : TAB_ID.REPORTS}
                replace
            />
        );
    }

    return (
        <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-cols-1 tw-grid-rows-auto-1fr tw-gap-y-4 tw-p-4">
            <div className="tw-grid tw-grid-cols-1fr-auto">
                <Tabs
                    value={tabId}
                    onChange={onTabChange}
                    onRails
                >
                    <Tab
                        value={TAB_ID.REPORTS}
                        label="Reports"
                    />
                    <Tab
                        value={TAB_ID.NIS2}
                        label="NIS2"
                    />
                    <Tab
                        value={TAB_ID.ENDPOINT}
                        label="Endpoint"
                    />
                </Tabs>
                <div
                    className="tw-flex tw-w-full tw-items-end"
                    ref={onRefChange}
                />
            </div>
            {tabId === TAB_ID.NIS2 && portalContainer !== null && (
                <div className="tw-overflow-auto">
                    <Page title="NIS2">
                        <NIS2Report portalContainer={portalContainer} />
                    </Page>
                </div>
            )}
            {tabId === TAB_ID.ENDPOINT && portalContainer !== null && (
                <div className="tw-overflow-auto">
                    <Page title="Endpoint">
                        <EndpointReport portalContainer={portalContainer} />
                    </Page>
                </div>
            )}
            {tabId === TAB_ID.REPORTS && portalContainer !== null && (
                <div className="tw-overflow-auto">
                    <Page title={['Reporting', 'History']}>
                        <ReportHistory portalContainer={portalContainer} />
                    </Page>
                </div>
            )}
        </div>
    );
};

const ReportHistory = () => {
    const api = useApi();
    const { permissions } = useAuthContext();

    const [reportState, setReportState] = useState(TEMPLATES);

    const [actionsDialogState, setActionsDialogState] = useState({
        open: false,
        anchorEl: null,
        actions: [exportCSV, exportExcel],
        title: '',
    });
    const [capaOneContactDialogIsOpen, setCapaOneContactDialogIsOpen] = useState(false);
    const [archiveState, setArchiveState] = useState({
        open: false,
        title: 'Archived reports',
        items: [],
    });
    const [confirmDeleteState, setConfirmDeleteState] = useState({
        open: false,
        isProcessing: false,
        reportItem: {},
    });

    const closeActionsDialog = () => {
        setActionsDialogState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onActionClick = (action) => {
        setReportState((c) =>
            c.map((report) => {
                if (report.templateId === actionsDialogState.report.templateId) {
                    return {
                        ...report,
                        generating: true,
                    };
                }
                return report;
            })
        );
        closeActionsDialog();
        generateAndDownloadReportFromTemplate(actionsDialogState.report.templateId, action.responseType);
    };

    const getReportHistory = useCallback(() => {
        api.cancel();
        api.getReportHistory()
            .then((response) => {
                setReportState(
                    TEMPLATES.map((report) => ({
                        ...report,
                        generating: false,
                        items: response
                            .filter((r) => r.template === report.templateId)
                            .sort(getSortingFunction({ sortBy: 'date', sortDirection: SORT_DIRECTION.DESC }, false, true)),
                    }))
                );
            })
            .catch((error) => {
                console.log(error);
            });
    }, [api]);

    useReportExportHistorySocket(
        useCallback(
            ({ fullDocument }) => {
                // TODO: Update state with fullDocument, but call getReportHistory for now.
                getReportHistory();
            },
            [getReportHistory]
        )
    );

    const deleteArchivedReport = (reportItem) => () => {
        if (confirmDeleteState.open) {
            setConfirmDeleteState((c) => ({
                ...c,
                isProcessing: true,
            }));
            api.deleteReportFromHistory(reportItem._id)
                .then(() => {
                    setArchiveState((c) => ({
                        ...c,
                        items: c.items.filter((item) => item._id !== reportItem._id),
                    }));
                    getReportHistory();
                    setConfirmDeleteState((c) => ({
                        ...c,
                        open: false,
                    }));
                })
                .catch(() => {
                    setConfirmDeleteState((c) => ({
                        ...c,
                        open: true,
                        isProcessing: false,
                    }));
                });
        } else {
            setConfirmDeleteState({ reportItem, isProcessing: false, open: true });
        }
    };

    const showArchivedReports = (report) => () => {
        setArchiveState({
            open: true,
            title: pluralize(report.name),
            items: report.items,
        });
    };

    const closeArchiveDialog = () => {
        setArchiveState((c) => ({
            ...c,
            open: false,
        }));
    };

    useEffect(getReportHistory, [api, getReportHistory]);

    return (
        <div className="tw-grid tw-grid-cols-1 tw-gap-4 tw-p-0.5 lg:tw-grid-cols-2">
            {reportState.map((report) => {
                const anchorElementId = `anchorElement-${report.templateId}`;
                const accessDenied = report.templateId === TEMPLATE_ID.NIS2 && !permissions.canViewSecurity;
                return (
                    <WidgetPaper
                        key={anchorElementId}
                        title={report.name}
                        description={report.description}
                        className="tw-h-full"
                    >
                        <div className="tw-grid tw-h-full tw-grid-rows-1fr-auto tw-divide-y tw-divide-gray-100">
                            <div className="tw-p-4 tw-pt-0 tw-text-xs tw-font-medium">
                                <ul className="tw-list-inside tw-list-disc">
                                    {report.keyContent.map((descriptionPart) => {
                                        return <li className="tw-leading-loose">{descriptionPart}</li>;
                                    })}
                                </ul>
                            </div>

                            <div
                                className={classNames('tw-grid tw-h-full tw-gap-1', {
                                    'tw-grid-cols-1': accessDenied,
                                    'tw-grid-cols-2': !accessDenied,
                                })}
                            >
                                <Tooltip
                                    content={
                                        accessDenied ? `You need a CapaOne Security contract to export ${pluralize(report.name)}` : 'Export to CSV or Excel'
                                    }
                                    dark
                                    extraPadding
                                    bold
                                >
                                    <Button
                                        noMargin
                                        size={BUTTON.LARGE}
                                        color={BUTTON.PRIMARY}
                                        disabled={report.generating}
                                        startIcon={
                                            <Icon
                                                type={accessDenied ? 'downloadDisabled' : 'download'}
                                                className="tw-h-6 tw-w-6"
                                                id={anchorElementId}
                                            />
                                        }
                                        onClick={(e) => {
                                            if (accessDenied) {
                                                setCapaOneContactDialogIsOpen(true);
                                            } else {
                                                setActionsDialogState((c) => ({
                                                    ...c,
                                                    open: true,
                                                    title: report.name,
                                                    anchorEl: document.getElementById(anchorElementId),
                                                    report,
                                                }));
                                            }
                                        }}
                                    >
                                        <div className="tw-flex tw-flex-col tw-gap-1 tw-px-1 tw-py-2 tw-text-left tw-text-sm tw-leading-none">
                                            {accessDenied ? (
                                                <>
                                                    Contact
                                                    <span className="tw-mt-0.5 tw-text-tiny tw-font-semibold tw-leading-none">
                                                        Contact us to get a quote or book a demo
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    {report.generating ? 'Exporting...' : 'Export'}
                                                    <span className="tw-mt-0.5 tw-text-tiny tw-font-semibold tw-leading-none">Download and archive</span>
                                                </>
                                            )}
                                        </div>
                                    </Button>
                                </Tooltip>
                                {!accessDenied && (
                                    <Tooltip
                                        content="List of archived reports"
                                        dark
                                        extraPadding
                                        bold
                                    >
                                        <Button
                                            startIcon={
                                                <Icon
                                                    type="archiveOutlined"
                                                    className="tw-h-6 tw-w-6"
                                                />
                                            }
                                            noMargin
                                            size={BUTTON.LARGE}
                                            color={BUTTON.PRIMARY}
                                            fullWidth
                                            disabled={report.items.length === 0}
                                            onClick={showArchivedReports(report)}
                                        >
                                            <div className="tw-flex tw-flex-col tw-gap-1 tw-px-1 tw-py-2 tw-text-left tw-text-sm tw-leading-none">
                                                Archived
                                                <span className="tw-mt-0.5 tw-text-tiny tw-font-semibold tw-leading-none">
                                                    {report.items.length > 0
                                                        ? `${pluralize('report', report.items.length, true)} available`
                                                        : 'No reports available'}
                                                </span>
                                            </div>
                                        </Button>
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    </WidgetPaper>
                );
            })}
            <ActionsDialog
                open={actionsDialogState.open}
                anchorEl={actionsDialogState.anchorEl}
                onClose={closeActionsDialog}
                title={actionsDialogState.title}
                description={descriptionForActionsDialog}
                actions={actionsDialogState.actions}
                onActionClick={onActionClick}
                anchorOrigin={{
                    vertical: -32,
                    horizontal: 0,
                }}
            />
            <ConfirmDialog
                title="Remove report from archive?"
                open={confirmDeleteState.open}
                onConfirm={deleteArchivedReport(confirmDeleteState.reportItem)}
                onCancel={() => setConfirmDeleteState((c) => ({ ...c, open: false }))}
                isProcessing={confirmDeleteState.isProcessing}
            >
                The report was generated by {confirmDeleteState.reportItem.exportedBy} on {formatDate(confirmDeleteState.reportItem.date)}
            </ConfirmDialog>
            <CapaOneContactDialog
                open={capaOneContactDialogIsOpen}
                defaultSubject="CapaOne NIS2 Reports"
                onClose={() => setCapaOneContactDialogIsOpen(false)}
            />
            <Dialog
                open={archiveState.open}
                onClose={closeArchiveDialog}
                size={'md'}
            >
                <DialogTitle className="tw-pb-0 tw-pl-4">
                    <PageTitle category="Archived">{archiveState.title}</PageTitle>
                </DialogTitle>
                <div
                    className="tw-h-112"
                    style={{ maxHeight: '90vh' }}
                >
                    <VirtualizedTable
                        items={archiveState.items}
                        striped
                        showRowCount={false}
                        entity="report"
                    >
                        <Column
                            key="format"
                            minWidth={40}
                            maxWidth={40}
                            dataKey="exportedBy"
                            disableSort
                            label="Format"
                            cellRenderer={({ rowData }) => {
                                if (rowData.format === 'xlsx') {
                                    return <Icon type="excel" />;
                                }
                                return <Icon type="csv" />;
                            }}
                        />
                        <Column
                            key="date"
                            minWidth={120}
                            maxWidth={120}
                            dataKey="date"
                            disableSort
                            label="Date"
                            cellRenderer={({ rowData }) => {
                                return formatDate(rowData.date);
                            }}
                        />

                        <Column
                            key="exportedBy"
                            minWidth={120}
                            dataKey="exportedBy"
                            disableSort
                            label="Exported By"
                        />

                        <Column
                            key="exportedBy"
                            minWidth={140}
                            maxWidth={140}
                            dataKey="exportedBy"
                            disableSort
                            label=""
                            justify={virtualizedTableColumnPropTypes.justify.end}
                            cellRenderer={({ rowData }) => {
                                return (
                                    <div className="tw-flex tw-gap-2">
                                        <div>
                                            <Button
                                                color={BUTTON.PRIMARY}
                                                variant={BUTTON.RAISED}
                                                size={BUTTON.SMALL}
                                                noMargin
                                                onClick={downloadArchivedReport(rowData)}
                                            >
                                                Download
                                            </Button>
                                        </div>
                                        <div>
                                            <Tooltip
                                                content="Remove from archive"
                                                dark
                                                extraPadding
                                                bold
                                            >
                                                <IconButton
                                                    color={BUTTON.DANGER}
                                                    noMargin
                                                    onClick={deleteArchivedReport(rowData)}
                                                >
                                                    <Icon
                                                        type="delete"
                                                        size="small"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </VirtualizedTable>
                </div>
                <DialogActions>
                    <Button onClick={closeArchiveDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const NIS2Report = ({ portalContainer }) => {
    const api = useApi();
    const { permissions } = useAuthContext();
    const [nisReportData, setNisReportData] = useState();
    const [loading, setLoading] = useState(true);
    const [viewType, setViewType] = useState(Url.getString('viewType', 'graphs'));
    const [errorMessage, setErrorMessage] = useState(null);
    const [actionsDialogState, setActionsDialogState] = useState({
        open: false,
        anchorEl: null,
        actions: [],
    });
    const updateViewType = (e, type) => {
        setViewType(type);
        Url.set('viewType', type);
    };

    useEffect(() => {
        setErrorMessage(null);
        api.generateReportFromTemplateId(TEMPLATE_ID.NIS2)
            .then(() => {
                api.getReportFromTemplateId(TEMPLATE_ID.NIS2)
                    .then((response) => {
                        setNisReportData(response.reportItems);
                    })
                    .finally(() => {
                        setLoading(false);
                    })
                    .catch((error) => {
                        setLoading(false);
                        setErrorMessage(error?.response?.message || coreMessage.anUnknownErrorOccurred);
                    });
            })
            .catch((error) => {
                setLoading(false);
                setErrorMessage(error?.response?.message || coreMessage.anUnknownErrorOccurred);
            });
    }, [api]);

    const convertValue = (value) => {
        if (typeof value !== 'number') {
            return parseInt(value.split(pattern)[0]);
        }
        return value;
    };

    const sortData = (data) => {
        if (data.length > 5) {
            return data.sort((a, b) => {
                const aValue = Object.values(a)[1].value;
                const bValue = Object.values(b)[1].value;

                return bValue - aValue;
            });
        }
        return data;
    };

    const closeActionsDialog = () => {
        setActionsDialogState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onActionClick = (action) => {
        generateAndDownloadReportFromTemplate(TEMPLATE_ID.NIS2, action.responseType);
        closeActionsDialog();
    };

    if (!permissions.canViewSecurity) {
        return (
            <div className="tw-h-full tw-p-0.5">
                <WidgetPaper
                    className="tw-h-full"
                    headerless
                >
                    <PremiumFeature defaultSubject="CapaOne NIS2 Reporting" />
                </WidgetPaper>
            </div>
        );
    }

    if (errorMessage) {
        return (
            <div className="tw-h-full tw-p-0.5">
                <WidgetPaper
                    className="tw-h-full"
                    headerless
                >
                    <EmptyState
                        title="Could not generate NIS2 report"
                        description={errorMessage}
                        iconType="lightbulbOutlined"
                    ></EmptyState>
                </WidgetPaper>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="tw-h-full tw-p-0.5">
                <WidgetPaper
                    className="tw-h-full"
                    headerless
                >
                    <LayoutCentered>
                        <div className="tw-flex tw-items-center tw-gap-4">
                            <Loading size={24} />
                            <PageTitle description="This might take some time">Generating NIS2 Report</PageTitle>
                        </div>
                    </LayoutCentered>
                </WidgetPaper>
            </div>
        );
    }

    if (nisReportData.length === 0) {
        return (
            <div className="tw-h-full tw-p-0.5">
                <WidgetPaper
                    className="tw-h-full"
                    headerless
                >
                    <EmptyState
                        title="No data available"
                        description="Please wait for scan job to run or manually run security scan on endpoints."
                        iconType="lightbulbOutlined"
                    ></EmptyState>
                </WidgetPaper>
            </div>
        );
    }

    return (
        <div>
            <div className="tw-mx-auto tw-h-full tw-max-w-screen-2xl tw-gap-4 tw-p-0.5 tw-pr-2">
                <Portal
                    container={portalContainer}
                    disablePortal={portalContainer === null}
                >
                    <div className="tw-flex tw-items-center tw-justify-end tw-gap-4">
                        <Tabs
                            value={viewType}
                            onChange={updateViewType}
                            pills
                            className="tw-ml-4"
                        >
                            <Tab
                                label={
                                    <Icon
                                        type="pieChart"
                                        size="small"
                                    />
                                }
                                disableRipple
                                value="graphs"
                            />
                            <Tab
                                label={
                                    <Icon
                                        type="tableHeaderAndColumns"
                                        size="small"
                                    />
                                }
                                disableRipple
                                value="tables"
                            />
                        </Tabs>
                        <IconButton
                            onClick={(e) => {
                                setActionsDialogState({
                                    open: true,
                                    actions: [exportCSV, exportExcel],
                                    anchorEl: e.currentTarget,
                                });
                            }}
                            size={BUTTON.SMALL}
                            className="tw-m-0"
                            color={BUTTON.PRIMARY}
                            variant={BUTTON.RAISED}
                        >
                            <Icon type="moreVert" />
                        </IconButton>
                        <ActionsDialog
                            open={actionsDialogState.open}
                            anchorEl={actionsDialogState.anchorEl}
                            onClose={closeActionsDialog}
                            title="NIS2 report"
                            description={descriptionForActionsDialog}
                            actions={actionsDialogState.actions}
                            onActionClick={onActionClick}
                        />
                    </div>
                </Portal>
                {viewType === 'tables' && (
                    <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                        {nisReportData.map((data) => {
                            return (
                                <WidgetPaper
                                    title={data.name}
                                    titleSize="medium"
                                    className={classNames({
                                        'tw-h-88': true,
                                        'tw-col-span-2': Object.keys(data.data[0]).length > 2,
                                    })}
                                    headerClassName="tw-pb-2"
                                    actions={<span className="tw-text-xs tw-font-medium">{formatDate(data.lastGeneratedAt)}</span>}
                                    key={data.name}
                                >
                                    <VirtualizedTable
                                        key={data.name}
                                        items={data.data}
                                        isLoading={false}
                                        showRowCount
                                        totalRowCount={data.data.length}
                                    >
                                        {Object.entries(data.data[0]).map(([key, value]) => {
                                            const isInteger = value.unit === 'integer';
                                            return (
                                                <Column
                                                    key={key}
                                                    minWidth={136}
                                                    dataKey={key}
                                                    label={key}
                                                    justify={isInteger ? virtualizedTableColumnPropTypes.justify.end : undefined}
                                                    cellRenderer={({ rowData }) => {
                                                        if (isInteger) {
                                                            return <Ellipsis>{rowData[key].value}</Ellipsis>;
                                                        }
                                                        return (
                                                            <span className={classNames('tw-flex tw-h-full tw-w-full tw-items-center')}>
                                                                {rowData[key].severity &&
                                                                    (data.name === 'Windows Missing Updates per Endpoint' ||
                                                                        data.name === 'Android System Updates' ||
                                                                        data.name === 'Apple System Updates' ||
                                                                        data.name === 'Windows Antivirus' ||
                                                                        data.name === 'Windows Encryption' ||
                                                                        data.name === 'Windows Firewall') && (
                                                                        <div className="tw-pr-4">
                                                                            <Tooltip content={rowData[key].severity}>
                                                                                <Icon
                                                                                    type="circle"
                                                                                    size="small"
                                                                                    className={classNames({
                                                                                        'tw-fill-slate-400':
                                                                                            rowData[key].severity === 'Unknown' ||
                                                                                            rowData[key].severity === 'None',
                                                                                        'tw-fill-emerald-400': rowData[key].severity === 'Good',
                                                                                        'tw-fill-amber-400': rowData[key].severity === 'Fair',
                                                                                        'tw-fill-red-400': rowData[key].severity === 'Poor',
                                                                                    })}
                                                                                    style={{ fontSize: 16 }}
                                                                                />
                                                                            </Tooltip>
                                                                        </div>
                                                                    )}
                                                                <Ellipsis>{rowData[key].value}</Ellipsis>
                                                            </span>
                                                        );
                                                    }}
                                                />
                                            );
                                        })}
                                    </VirtualizedTable>
                                </WidgetPaper>
                            );
                        })}
                    </div>
                )}
                {viewType === 'graphs' && (
                    <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                        {nisReportData.map((data) => {
                            return (
                                <WidgetPaper
                                    title={data.data.length > 5 ? `Top 5 ${data.name}` : data.name}
                                    className="tw-h-72"
                                    actions={<b>{formatDate(data.lastGeneratedAt)}</b>}
                                    key={data.name}
                                >
                                    <div id={data.name}>
                                        <PieChart
                                            id={data.name}
                                            entity={Object.values(data.data[0])[1].entity}
                                            data={sortData(data?.data)
                                                .slice(0, 5)
                                                .map((d) => {
                                                    return {
                                                        ...d,
                                                        name: Object.values(d)[0].value,
                                                        y: convertValue(Object.values(d)[1].value),
                                                        color:
                                                            data.name === 'Android System Updates' ||
                                                            data.name === 'Apple System Updates' ||
                                                            data.name === 'Windows Antivirus' ||
                                                            data.name === 'Windows Encryption' ||
                                                            data.name === 'Firewall'
                                                                ? Object.values(d)[0].severity === 'Unknown'
                                                                    ? colors.slate[400]
                                                                    : Object.values(d)[0].severity === 'None'
                                                                    ? colors.slate[400]
                                                                    : Object.values(d)[0].severity === 'Good'
                                                                    ? colors.emerald[400]
                                                                    : Object.values(d)[0].severity === 'Fair'
                                                                    ? colors.amber[400]
                                                                    : Object.values(d)[0].severity === 'Poor'
                                                                    ? colors.red[400]
                                                                    : undefined
                                                                : undefined,
                                                    };
                                                })}
                                        />
                                    </div>
                                </WidgetPaper>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export const EndpointReport = ({ portalContainer }) => {
    const api = useApi();
    const { organizations, permissions } = useAuthContext();
    const [devices, setDevices] = useState([]);
    const [pagingState, setPagingState] = useState({
        pageNumber: 1,
        pageSize: 100,
        sortBy: Url.getString(SORT_BY, 'name'),
        sortDirection: Url.getString(SORT_DIR, SORT_DIRECTION.ASC),
        filters: null,
    });
    const [isLoading, setIsLoading] = useState(true);
    const pagingRef = useRef({
        isFirstPage: true,
        isLastPage: undefined,
        totalRowCount: 0,
        isPureDeviceNameSearch: false,
    });
    const [dirtyCount, setDirtyCount] = useState(0);
    const columns = useWindowsEndpointListColumns(organizations, permissions, true);
    const [actionsDialogState, setActionsDialogState] = useState({
        open: false,
        anchorEl: null,
        actions: [],
    });

    const onActionClick = (action) => {
        generateAndDownloadReportFromTemplate(TEMPLATE_ID.ENDPOINT, action.responseType);
        closeActionsDialog();
    };

    useEffect(() => {
        setIsLoading(true);
        let orderBy = `${pagingState.sortBy},${pagingState.sortDirection}`;
        // By request from HWE, when sortby is Agentversion also sort on serviceStartUp descending
        if (pagingState.sortBy === 'agentVersion') {
            orderBy = [`${pagingState.sortBy},${pagingState.sortDirection}`, `serviceStartUp,DESC`];
        }
        if (pagingState.sortBy === 'osName') {
            orderBy = [`${pagingState.sortBy},osVersion,${pagingState.sortDirection}`];
        }
        api.getDevices({
            pageNumber: dirtyCount > 0 ? 1 : pagingState.pageNumber,
            pageSize: dirtyCount > 0 ? pagingState.pageSize * pagingState.pageNumber : pagingState.pageSize,
            orderBy,
            filter: {},
        })
            .then((response) => {
                pagingRef.current.isFirstPage = response.first;
                pagingRef.current.isLastPage = response.last;
                pagingRef.current.totalRowCount = response.totalElements;
                if (response.first) {
                    setDevices(response.content);
                } else {
                    setDevices((currentDevices) => [...currentDevices, ...response.content]);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
        setDirtyCount(0);
    }, [pagingState]);

    const onScrollToBottom = () => {
        if (!pagingRef.current.isLastPage) {
            setPagingState((currentPagingState) => ({
                ...currentPagingState,
                pageNumber: currentPagingState.pageNumber + 1,
            }));
        }
    };

    const closeActionsDialog = () => {
        setActionsDialogState((c) => ({
            ...c,
            open: false,
        }));
    };

    return (
        <div className="tw-grid tw-h-full tw-grid-rows-auto-1fr tw-p-0.5">
            <div>
                <Portal
                    container={portalContainer}
                    disablePortal={portalContainer === null}
                >
                    <IconButton
                        onClick={(e) => {
                            setActionsDialogState({
                                open: true,
                                actions: [exportCSV, exportExcel],
                                anchorEl: e.currentTarget,
                            });
                        }}
                        size={BUTTON.SMALL}
                        className="tw-ml-4 tw-mr-0"
                        color={BUTTON.DEFAULT}
                    >
                        <Icon type="moreVert" />
                    </IconButton>
                    <ActionsDialog
                        open={actionsDialogState.open}
                        anchorEl={actionsDialogState.anchorEl}
                        onClose={closeActionsDialog}
                        title="Endpoint report"
                        description={descriptionForActionsDialog}
                        actions={actionsDialogState.actions}
                        onActionClick={onActionClick}
                    />
                </Portal>
            </div>
            <WidgetPaper headerless>
                <VirtualizedTable
                    items={devices}
                    onScrollToBottom={onScrollToBottom}
                    totalRowCount={pagingRef.current.totalRowCount}
                    showRowCount
                    isLoading={isLoading}
                    entity="endpoint"
                >
                    {columns.map((columnProps) => (
                        <Column
                            key={columnProps.key}
                            {...columnProps}
                        />
                    ))}
                </VirtualizedTable>
            </WidgetPaper>
        </div>
    );
};

export { ReportingManagementLandingPage };
