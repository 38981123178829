import { SORT_DIRECTION } from '@capasystems/constants';
import { LayoutCentered } from '@capasystems/ui';
import { getSortingFunction } from '@capasystems/utils';
import { TAndroidDevice } from '@db/party';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppliedType, CapaOneLink, TransferList, useAndroidApi, useAndroidConfigurationsSocket, useGroupsSocket, useManagementApi } from '../../../../../index';

export type AndroidEndpointAssignedConfigurationsTabProps = {
    endpoint: TAndroidDevice;
    portalContainer: any;
};

export const AndroidEndpointAssignedConfigurationsTab: React.FC<AndroidEndpointAssignedConfigurationsTabProps> = ({ endpoint, portalContainer }) => {
    const [configurationsList, setConfigurationsList] = useState<any[]>([]);
    const [groupsList, setGroupsList] = useState<any[]>([]);
    const androidApi = useAndroidApi();
    const managementApi = useManagementApi();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [viewMode, setViewMode] = useState(true);
    const [transferListProps, setTransferListProps] = useState<{ loading?: boolean; savingChanges?: boolean; errorMessage?: string }>({
        loading: true,
    });

    useAndroidConfigurationsSocket(
        useCallback(
            (
                { updateDescription, documentId, fullDocument }: { updateDescription: any; documentId: string; fullDocument: any },
                { insertOperation, updateOperation, deleteOperation }: { insertOperation: any; updateOperation: any; deleteOperation: any }
            ) => {
                if (updateOperation) {
                    setConfigurationsList((currentConfigurations: any) => {
                        return currentConfigurations.map((configuration: any) => {
                            if (configuration.id === documentId) {
                                return {
                                    ...configuration,
                                    ...updateDescription.updatedFields,
                                };
                            }
                            return configuration;
                        });
                    });
                } else if (insertOperation) {
                    setConfigurationsList((currentConfigurations: any) => {
                        return [
                            ...currentConfigurations,
                            {
                                ...fullDocument,
                                id: documentId,
                            },
                        ];
                    });
                } else if (deleteOperation) {
                    setConfigurationsList((currentList) => {
                        return currentList.filter((configuration) => configuration.id !== documentId);
                    });
                }
            },
            []
        )
    );

    useGroupsSocket(
        useCallback(
            (
                { updateDescription, documentId, fullDocument }: { updateDescription: any; documentId: string; fullDocument: any },
                { insertOperation, updateOperation, deleteOperation }: { insertOperation: any; updateOperation: any; deleteOperation: any }
            ) => {
                if (updateOperation) {
                    setGroupsList((currentGroups: any) => {
                        return currentGroups.map((group: any) => {
                            if (group.id === documentId) {
                                return {
                                    ...group,
                                    ...updateDescription.updatedFields,
                                };
                            }
                            return group;
                        });
                    });
                } else if (insertOperation) {
                    setGroupsList((currentGroups: any) => {
                        return [
                            ...currentGroups,
                            {
                                ...fullDocument,
                                id: documentId,
                            },
                        ];
                    });
                } else if (deleteOperation) {
                    setGroupsList((currentList) => {
                        return currentList.filter((group) => group.id !== documentId);
                    });
                }
            },
            []
        )
    );

    const memoizedConfigurations = useMemo(() => {
        const sortingFunction = getSortingFunction({
            sortDirection: SORT_DIRECTION.ASC,
            sortBy: 'name',
        });
        const filteredGroups = groupsList.filter((group) => group.endpointRefs.some(({ refId }: { refId: any }) => refId === endpoint.id));
        return configurationsList
            .map((configuration) => {
                const appliedDirect = configuration.endpointRefIds.includes(endpoint.id);
                const appliedViaGroups = filteredGroups.filter((group) =>
                    group.configurationRefs.some(({ refId }: { refId: any }) => refId === configuration.id)
                );
                const appliedViaGroup = appliedViaGroups.length > 0;
                return {
                    ...configuration,
                    applied: viewMode ? appliedDirect || appliedViaGroup : appliedDirect,
                    appliedViaGroup,
                    appliedViaGroups,
                    appliedDirect,
                };
            })
            .sort(sortingFunction);
    }, [configurationsList, endpoint, groupsList, viewMode]);

    useEffect(() => {
        Promise.all([androidApi.getAndroidConfigurations(), managementApi.getGroups()])
            .then(([configurationsResponse, groupsResponse]) => {
                setConfigurationsList(configurationsResponse);
                setGroupsList(groupsResponse);
                setTransferListProps({});
            })
            .catch(() => {
                setErrorMessage('Could not get configurations');
            });
    }, [androidApi]);

    const onAdd = (configurationIds: any) => {
        setTransferListProps({
            savingChanges: true,
        });
        androidApi
            .addEndpointToConfigurations(endpoint.id, configurationIds)
            .then(() => {
                setTransferListProps({});
            })
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('configuration', configurationIds.length)}`,
                });
            });
    };

    const onRemove = (configurationIds: any) => {
        setTransferListProps({
            savingChanges: true,
        });
        androidApi
            .removeEndpointFromConfigurations(endpoint.id, configurationIds)
            .then(() => {
                setTransferListProps({});
            })
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not remove ${pluralize('configuration', configurationIds.length)}`,
                });
            });
    };

    const cellRenderer = ({ rowData }: { rowData: any }) => {
        if (viewMode) {
            return (
                <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto tw-items-center">
                    {/* @ts-expect-error - CapaOneLink does not support ts yet */}
                    <CapaOneLink to={`android/configuration/${rowData.id}/membership?tab=endpoints`}>
                        <b>{rowData.name}</b>
                    </CapaOneLink>
                    <AppliedType {...rowData} />
                </div>
            );
        }
        return (
            <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto tw-items-center">
                <b>{rowData.name}</b>
                <AppliedType {...rowData} />
            </div>
        );
    };

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }
    return (
        <TransferList
            // @ts-expect-error - CapaOneLink does not support ts yet
            disableHeader
            portalContainer={portalContainer.current}
            items={memoizedConfigurations}
            onAdd={onAdd}
            onRemove={onRemove}
            entity="configuration"
            viewMode={viewMode}
            setViewMode={setViewMode}
            cellRenderer={cellRenderer}
            totalRowCount={memoizedConfigurations.filter((c) => !c.applied).length}
            totalRowCountAssigned={memoizedConfigurations.filter((c) => c.applied === true).length}
            {...transferListProps}
        />
    );
};
