import { TOOLTIP } from '@capasystems/constants';
import classnames from 'classnames';
import React from 'react';
import Badge, { BadgeProps } from '../badge/badge';
import Icon, { IconType } from '../icon/icon';
import LayoutColumn from '../layout-column/layout-column';
import NavLink from '../route/nav-link';
import Tooltip from '../tooltip/tooltip';
import './navigation.scss';

export type NavigationProps = {
    links: {
        to: string;
        icon: IconType;
        text: string | React.ReactNode;
        tooltip?: string | React.ReactNode;
        badge?: {
            content: number;
            color?: BadgeProps['color'];
        };
        beta?: boolean;
        svgIcon?: boolean;
        svgSrc?: string;
    }[];
    isCollapsed?: boolean;
    minWidth?: number | string;
};

export const Navigation: React.FC<NavigationProps> = ({ links, isCollapsed = false, minWidth = 0 }) => {
    if (isCollapsed) {
        return (
            <LayoutColumn
                className="cs-nav-container"
                style={{ minWidth }}
            >
                {links.map(({ to, text, icon, badge, svgIcon, svgSrc, beta = false, ...link }) => (
                    <NavLink
                        key={to}
                        to={to}
                        noMargin
                        color="primary"
                        disableRipple
                        disableFocusRipple
                        {...link}
                    >
                        <Tooltip
                            content={
                                <div>
                                    <b>{text}</b>
                                    {beta && <b> (BETA)</b>}
                                </div>
                            }
                            position={TOOLTIP.POSITION.RIGHT}
                            dark
                            extraPadding
                        >
                            <div className="cs-navlink-content">
                                {svgIcon ? (
                                    <img
                                        src={svgSrc}
                                        className="tw-h-6 tw-w-6"
                                    />
                                ) : (
                                    <Icon
                                        type={icon}
                                        color="inherit"
                                        className="tw-ml-1"
                                    />
                                )}
                                {badge && (
                                    /* istanbul ignore next */
                                    <Badge
                                        badgeContent={badge.content}
                                        color={badge.color || 'error'}
                                    />
                                )}
                            </div>
                        </Tooltip>
                    </NavLink>
                ))}
            </LayoutColumn>
        );
    }

    return (
        <LayoutColumn
            className="cs-nav-container"
            style={{ minWidth }}
        >
            {links.map(({ to, icon, tooltip, text, badge, beta = false, ...link }) => {
                const classNames = classnames('cs-navlink-content', {
                    'cs-navlink-spacing': !!icon,
                });
                return (
                    <NavLink
                        key={to}
                        to={to}
                        noMargin
                        color="primary"
                        disableRipple
                        disableFocusRipple
                        {...link}
                    >
                        {tooltip ? (
                            <Tooltip
                                content={tooltip}
                                position={TOOLTIP.POSITION.RIGHT}
                            >
                                <div className={classNames}>
                                    {icon && (
                                        <React.Fragment>
                                            <Icon
                                                type={icon}
                                                className="tw-ml-1"
                                            />
                                        </React.Fragment>
                                    )}
                                    <span className="tw-text-xs tw-font-semibold">{text}</span>
                                    {badge && (
                                        /* istanbul ignore next */
                                        <Badge
                                            badgeContent={badge.content}
                                            color={badge.color || 'error'}
                                        />
                                    )}
                                </div>
                            </Tooltip>
                        ) : (
                            /* istanbul ignore next */
                            <div className={classNames}>
                                {icon && (
                                    <React.Fragment>
                                        <Icon
                                            type={icon}
                                            className="tw-ml-1"
                                        />
                                    </React.Fragment>
                                )}
                                <span className="tw-text-xs tw-font-semibold">{text}</span>
                                {beta && <div className="tw-ml-1 tw-rounded tw-bg-red-100 tw-px-1 tw-py-0.5 tw-text-tiny tw-text-red-900">BETA</div>}
                                {badge && (
                                    <Badge
                                        badgeContent={badge.content}
                                        color={badge.color || 'error'}
                                    />
                                )}
                            </div>
                        )}
                    </NavLink>
                );
            })}
        </LayoutColumn>
    );
};
