import { BUTTON, coreMessage, isBeta } from '@capasystems/constants';
import { Url, isFunction, useTheme } from '@capasystems/utils';
import { CircularProgress, Grow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from '../button/button';
import { Checkbox } from '../checkbox/checkbox';
import { Input } from '../input/input';
import { LayoutCenter } from '../layout-center/layout-center';
import { LayoutColumn } from '../layout-column/layout-column';
import { LayoutRow } from '../layout-row/layout-row';
import { Padding } from '../padding/padding';
import { Paper } from '../paper/paper';
import Link from '../route/link';
import { SnackbarContent } from '../snackbar-content/snackbar-content';
import { Snackbar } from '../snackbar/snackbar';
import { Toolbar } from '../toolbar/toolbar';
import { Tooltip } from '../tooltip/tooltip';
import './authentication.scss';

const snackbarAnchorOrigin = { vertical: 'top', horizontal: 'right' };

const trialUrl = `https://portal${isBeta ? 'beta' : ''}.capaone.com/trial`;

export const Authentication = ({
    showRememberMe = false,
    productName,
    onForgotPassword,
    rememberMe = true,
    onSubmit,
    additionalFeatures,
    withMicrosoft = false,
    isCapaOne,
    showForgot = true,
}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [rememberMeState, setRememberMeState] = useState(rememberMe);
    const [showForm, setShowForm] = useState(true);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        severity: 'info',
        errorMessage: null,
        email: false,
    });
    const theme = useTheme();
    const auth_error = Url.getString('error');
    if (auth_error) {
        setTimeout(() => {
            switch (auth_error) {
                case 'too_many_attempts': {
                    setSnackbarState({
                        ...snackbarState,
                        email: false,
                        open: true,
                        severity: 'error',
                        errorMessage: 'Too many attempts, please reset your password.',
                    });
                    break;
                }
                default: {
                    setSnackbarState({
                        ...snackbarState,
                        email: false,
                        open: true,
                        severity: 'error',
                        errorMessage: 'Wrong username, password or login method not allowed.',
                    });
                    break;
                }
            }
            Url.set('error', undefined);
        }, theme.capasystems.animationSpeed.exit);
    }

    const handleForgotPassword = () => {
        setShowForm(false);
        setTimeout(() => {
            setForgotPassword(!forgotPassword);
            setShowForm(true);
        }, theme.capasystems.animationSpeed.exit);
    };

    const onSubmitFunction = (event) => {
        event.preventDefault();
        if (forgotPassword) {
            handleForgotPassword();
            const email = username;
            onForgotPassword(username)
                .then(() => {
                    setSnackbarState({
                        open: true,
                        email,
                        severity: 'info',
                    });
                })
                .catch((msg) => {
                    setSnackbarState({
                        open: true,
                        errorMessage: msg,
                        severity: 'error',
                    });
                });
        } else {
            setShowLoading(true);
            onSubmit({
                username,
                password,
                rememberMeState,
            })
                .then((callback) => {
                    setShowLoading(false);
                    setShowForm(false);
                    if (isFunction(callback)) {
                        setTimeout(callback, theme.capasystems.animationSpeed.exit);
                    }
                })
                .catch((msg) => {
                    setShowLoading(false);
                    setSnackbarState({
                        open: true,
                        errorMessage: msg,
                        severity: 'error',
                    });
                });
        }
    };

    const handlePasswordChange = (event) => setPassword(event.target.value);

    const handleUsernameChange = (event) => setUsername(event.target.value);

    const handleRememberMeChange = (event) => setRememberMeState(event.target.checked);

    const onSnackbarClose = () =>
        setSnackbarState({
            ...snackbarState,
            open: false,
        });

    const spacing = theme.spacing(2);

    return (
        <LayoutColumn
            fill
            id="cs-authentication"
        >
            {!isCapaOne && (
                <Toolbar
                    position="sticky"
                    id="cs-authentication-toolbar"
                >
                    <h3 id="cs-authentication-brading">
                        {productName}
                        <span>&nbsp; by CapaSystems</span>
                    </h3>
                    {additionalFeatures}
                </Toolbar>
            )}
            <LayoutCenter
                direction="column"
                scrollContent={false}
            >
                {isCapaOne && <div className="tw-mb-12 tw-h-16 tw-w-full tw-bg-capaone-because-time-matters tw-bg-center tw-bg-no-repeat" />}
                <Grow
                    in={showForm}
                    timeout={theme.capasystems.animationSpeed}
                >
                    <Paper className="tw-w-112 tw-rounded-xl tw-shadow-none tw-drop-shadow">
                        <Padding factor={2}>
                            <form
                                id="cs-authentication-form"
                                onSubmit={onSubmitFunction}
                            >
                                {!forgotPassword && (
                                    <React.Fragment>
                                        <Input
                                            autoFocus
                                            value={username}
                                            onChange={handleUsernameChange}
                                            placeholder={coreMessage.username}
                                            style={{ marginBottom: spacing }}
                                            id="username"
                                            name="username"
                                            autoComplete="username"
                                            callToAction
                                            light
                                            inputClassName="tw-font-medium"
                                        />
                                        <Input
                                            value={password}
                                            type="password"
                                            onChange={handlePasswordChange}
                                            placeholder={coreMessage.password}
                                            name="password"
                                            id="password"
                                            autoComplete="current-password"
                                            callToAction
                                            light
                                            inputClassName="tw-font-medium"
                                        />
                                    </React.Fragment>
                                )}

                                {forgotPassword && (
                                    <React.Fragment>
                                        <p style={{ marginTop: 0, marginBottom: spacing }}>
                                            We will send you an e-mail with instructions on how to reset your password.
                                        </p>
                                        <Input
                                            label={coreMessage.email}
                                            autoFocus
                                            value={username}
                                            onChange={handleUsernameChange}
                                            style={{ marginBottom: spacing }}
                                            type="email"
                                            id="username"
                                            name="username"
                                            autoComplete="username"
                                            callToAction
                                            light
                                            labelClassName="tw--translate-y-1"
                                            inputClassName="tw-font-medium"
                                        />
                                    </React.Fragment>
                                )}

                                {showRememberMe && !forgotPassword && (
                                    <Checkbox
                                        checked={rememberMeState}
                                        onChange={handleRememberMeChange}
                                        label={coreMessage.rememberMe}
                                    />
                                )}
                                {!forgotPassword && (
                                    <LayoutRow>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant={BUTTON.RAISED}
                                            color={BUTTON.PRIMARY}
                                            disabled={showLoading || username.trim() === '' || password.trim() === ''}
                                            size={BUTTON.LARGE}
                                            style={{ marginTop: showRememberMe ? 0 : spacing }}
                                            noMargin
                                        >
                                            {!showLoading && coreMessage.signIn}
                                            {showLoading && <CircularProgress size={18} />}
                                        </Button>
                                    </LayoutRow>
                                )}
                                {forgotPassword && (
                                    <LayoutRow>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant={BUTTON.RAISED}
                                            color={BUTTON.PRIMARY}
                                            disabled={username.trim() === ''}
                                            size={BUTTON.LARGE}
                                        >
                                            {coreMessage.submit}
                                        </Button>
                                        <Button
                                            fullWidth
                                            noMargin
                                            color={BUTTON.PRIMARY}
                                            size={BUTTON.LARGE}
                                            onClick={handleForgotPassword}
                                        >
                                            {coreMessage.cancel}
                                        </Button>
                                    </LayoutRow>
                                )}
                            </form>
                            {withMicrosoft && (
                                <>
                                    <div className="tw-my-6 tw-text-center tw-text-xs tw-italic tw-text-neutral-300">OR</div>
                                    <LayoutColumn>
                                        <Tooltip
                                            content="Microsoft user must have a CapaOne account with same email"
                                            extraPadding
                                            fullWidth
                                            dark
                                            className="tw-font-semibold"
                                        >
                                            <Button
                                                fullWidth
                                                variant={BUTTON.OUTLINED}
                                                size={BUTTON.LARGE}
                                                noMargin
                                                onClick={() => {
                                                    window.location.href = '/api/oauth2/authorization/azure-client';
                                                }}
                                                className="tw-border-slate-200"
                                            >
                                                <LayoutRow
                                                    align="flex-start"
                                                    width="100%"
                                                >
                                                    <img
                                                        src="assets/img/microsoft-logo.42b61fa1.svg"
                                                        width={25}
                                                    />
                                                    <LayoutRow
                                                        align="center"
                                                        style={{ width: '100%' }}
                                                    >
                                                        Sign in with Microsoft
                                                    </LayoutRow>
                                                </LayoutRow>
                                            </Button>
                                        </Tooltip>
                                        <br />
                                        <Tooltip
                                            content="Google user must have a CapaOne account with same email"
                                            extraPadding
                                            fullWidth
                                            dark
                                            className="tw-font-semibold"
                                        >
                                            <Button
                                                fullWidth
                                                variant={BUTTON.OUTLINED}
                                                size={BUTTON.LARGE}
                                                noMargin
                                                onClick={() => {
                                                    window.location.href = '/api/oauth2/authorization/google';
                                                }}
                                                className="tw-border-slate-200"
                                            >
                                                <LayoutRow
                                                    align="flex-start"
                                                    width="100%"
                                                >
                                                    <img
                                                        src="assets/img/Google__G__Logo.svg"
                                                        width={20}
                                                    />
                                                    <LayoutRow
                                                        align="center"
                                                        style={{ width: '100%' }}
                                                    >
                                                        Sign in with Google
                                                    </LayoutRow>
                                                </LayoutRow>
                                            </Button>
                                        </Tooltip>
                                    </LayoutColumn>
                                </>
                            )}
                            {showForgot && !forgotPassword && (
                                <div>
                                    <LayoutRow
                                        align="end"
                                        className="tw-mx-1 tw-mt-8"
                                    >
                                        <Link onClick={handleForgotPassword}>Can't sign in?</Link>
                                        {isCapaOne && (
                                            <Link
                                                component="a"
                                                href={trialUrl}
                                                className="tw-ml-6"
                                            >
                                                Sign up
                                            </Link>
                                        )}
                                    </LayoutRow>
                                </div>
                            )}
                        </Padding>
                    </Paper>
                </Grow>
                <Snackbar
                    open={snackbarState.open}
                    onClose={onSnackbarClose}
                    anchorOrigin={snackbarAnchorOrigin}
                >
                    <SnackbarContent
                        severity={snackbarState.severity}
                        message={
                            <React.Fragment>
                                {snackbarState.errorMessage && <b>{snackbarState.errorMessage}</b>}
                                {snackbarState.email && (
                                    <React.Fragment>
                                        <p style={{ marginTop: 0 }}>E-mail sent to</p>
                                        <b>{snackbarState.email}</b>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        }
                    />
                </Snackbar>
            </LayoutCenter>
        </LayoutColumn>
    );
};

Authentication.propTypes = {
    showRememberMe: PropTypes.bool,
    rememberMe: PropTypes.bool,
    productName: PropTypes.string.isRequired,
    onForgotPassword: PropTypes.func,
    additionalFeatures: PropTypes.node,
    withMicrosoft: PropTypes.bool,
};

export default Authentication;
