import { SORT_DIRECTION } from '@capasystems/constants';
import { Avatar, Column, EmptyState, Icon, LayoutRow, Paper, Tooltip, VirtualizedTable, virtualizedTableColumnPropTypes } from '@capasystems/ui';
import { getSortingFunction } from '@capasystems/utils';
import { Portal } from '@mui/base';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { QueryBuilder, TwoLineCellRenderer, useAndroidApi } from '../../../../../index';

const queryBuilderEndpointSoftware = getDefaultQueryBuilderConfiguration({ placeholder: 'Name' });
const rowHeight = 56;

/* See https://developers.google.com/android/management/reference/rest/v1/enterprises.devices#ApplicationReport */
export const EndpointSoftwareTab = ({ queryBuilderContainerRef, androidData: { applicationReports } }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const queryBuilderRef = useRef({});
    const [managedApplications, setManagedApplications] = useState(null);
    const androidApi = useAndroidApi();
    useEffect(() => {
        androidApi.getApplications().then((applicationsResponse) => {
            setManagedApplications(applicationsResponse);
        });
    }, [androidApi]);

    const onSubmit = ([activeLeaf]) => {
        setSearchTerm(activeLeaf ? activeLeaf.value : '');
    };

    const installedItems = applicationReports?.filter(
        (c) => c.applicationSource !== 'SYSTEM_APP_FACTORY_VERSION' && c.applicationSource !== 'SYSTEM_APP_UPDATED_VERSION' && c.state === 'INSTALLED'
    );

    const [sortingState, setSortingState] = useState({
        sortBy: 'Name',
        sortDirection: SORT_DIRECTION.ASC,
    });
    const memoizedApplications = useMemo(() => {
        const sortingFunction = getSortingFunction(sortingState);
        const items = installedItems?.filter((c) => c.displayName?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) || [];
        return items?.sort(sortingFunction) || [];
    }, [sortingState, installedItems, searchTerm]);

    return (
        <div className="tw-h-full tw-pb-4">
            <Paper className="tw-h-full">
                <Portal container={queryBuilderContainerRef?.current}>
                    <QueryBuilder
                        defaultConfiguration={queryBuilderEndpointSoftware}
                        onInit={onSubmit}
                        onSubmit={onSubmit}
                        className="tw-w-full"
                        ref={queryBuilderRef}
                    />
                </Portal>
                <VirtualizedTable
                    items={memoizedApplications}
                    showRowCount
                    totalRowCount={memoizedApplications.length}
                    entity="application"
                    noRowsRenderer={() => (
                        <EmptyState
                            isSearching={searchTerm !== ''}
                            searchTerm={searchTerm}
                            entity="application"
                            onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                        />
                    )}
                    sort={setSortingState}
                    sortBy={sortingState.sortBy}
                    sortDirection={sortingState.sortDirection}
                >
                    <Column
                        disableSort
                        minWidth={32}
                        maxWidth={32}
                        dataKey="iconUrl"
                        label=""
                        shouldRender={({ tableWidth }) => tableWidth >= 600}
                        cellRenderer={({ rowData }) => {
                            const app = managedApplications?.find((a) => {
                                return a.configurationType === 'webApp'
                                    ? a.googlePlayId?.split('/')[3] === rowData?.packageName
                                    : a.data?.applications?.[0]?.packageName === rowData?.packageName;
                            });
                            return app?.iconUrl ? (
                                <Avatar
                                    src={app.iconUrl}
                                    alt={rowData.displayName}
                                    variant="rounded"
                                    className="tw-h-8 tw-w-8"
                                />
                            ) : (
                                <Avatar
                                    variant="rounded"
                                    className="tw-h-8 tw-w-8"
                                >
                                    {rowData.displayName.split('')[0]}
                                </Avatar>
                            );
                        }}
                    />
                    <Column
                        dataKey="displayName"
                        label="Name"
                        minWidth={240}
                        cellRenderer={({ rowData }) => (
                            <TwoLineCellRenderer
                                main={rowData.displayName}
                                secondary={rowData.packageName}
                            />
                        )}
                    />
                    <Column
                        disableSort
                        dataKey="versionName" // The app version as displayed to the user.
                        label="Version"
                        minWidth={160}
                    />
                    <Column
                        disableSort
                        dataKey="Install type"
                        label="Application type"
                        minWidth={120}
                        maxWidth={120}
                        justify={virtualizedTableColumnPropTypes.justify.end}
                        cellRenderer={({ rowData }) => {
                            const app = managedApplications?.find((a) => {
                                return a.configurationType === 'webApp'
                                    ? a.googlePlayId?.split('/')[3] === rowData?.packageName
                                    : a.data?.applications?.[0]?.packageName === rowData?.packageName;
                            });
                            return (
                                <LayoutRow align="end">
                                    {app?.configurationType === 'webApp' ? (
                                        <Tooltip
                                            position="top"
                                            content={'Installed from the Google Play Store.'}
                                        >
                                            <Icon type="googlePlayWebApp" />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            position="top"
                                            content={'Installed from the Google Play Store.'}
                                        >
                                            <Icon type="googlePlayStore" />
                                        </Tooltip>
                                    )}
                                </LayoutRow>
                            );
                        }}
                    />
                </VirtualizedTable>
            </Paper>
        </div>
    );
};
