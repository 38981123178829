import { COMMAND_IDS } from '@apple';
import { BUTTON, TOOLTIP } from '@capasystems/constants';
import {
    Avatar,
    Button,
    Checkbox,
    Collapse,
    Column,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    Input,
    LayoutCentered,
    LayoutRow,
    Loading,
    Page,
    Tab,
    Tabs,
    Tooltip,
    VirtualizedTable,
    useParams,
    virtualizedTableColumnPropTypes,
} from '@capasystems/ui';
import { noop } from '@capasystems/utils';
import { ENDPOINT_TYPE, coreAppleManagement } from '@thirdparty/constants';
import { SchemaBuilder, TailwindBadge, useAppleDeviceCommandQueueSocket, useAppleEndpointWithIdSocket } from '@thirdparty/ui';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useNavigate as useCoreNavigate } from 'react-router-dom';
import {
    ActionsDialog,
    ConfirmDialog,
    JsonViewer,
    PageTitle,
    RenamingDialog,
    TwoLineCellRenderer,
    UserManagementAssignUserToEndpoint,
    UserTypeIcon,
    useAppleApi,
    useMessageContext,
    useNavigate,
} from '../../../index';
import { AppleEndpointApplicationsTab } from '../components/endpoint/AppleEndpointApplicationsTab';
import { AppleConfigurationsWrapper } from '../components/endpoint/AppleEndpointConfigurationsWrapper';
import { AppleEndpointDashboardTab } from '../components/endpoint/AppleEndpointDashboardTab';
import { AppleEndpointGroupsTab } from '../components/endpoint/AppleEndpointGroupsTab';
import { AppleEndpointInventoryTab } from '../components/endpoint/AppleEndpointInventoryTab';

const tabClassName = 'tw-font-semibold tw-min-w-0 lg:tw-px-8';

const renameAction = {
    id: 'rename',
    name: 'Rename',
};

const viewRequestsAction = {
    id: 'viewRequests',
    name: 'View requests',
};

const manageUserAction = {
    id: 'manageUser',
    name: 'User management',
};

const resultingPolicyAction = {
    id: 'showResultingPolicy',
    name: 'Resulting policy',
    disabled: false,
};

const getDataAction = (udid) => ({
    id: 'GETDATA',
    name: 'Synchronize device',
    icon: 'autoUpdate',
    tooltipProps: {
        content: 'Get the newest data for this device',
        fullWidth: true,
    },
});

const restartDeviceAction = (isSupervised) => ({
    id: COMMAND_IDS.RESTART_DEVICE,
    name: 'Reboot',
    icon: 'reset',
    tooltipProps: {
        content: isSupervised ? 'Reboot the endpoint' : 'Device needs to be supervised to reboot',
        fullWidth: true,
    },
    disabled: !isSupervised,
});

const deviceLockAction = (isSupervised) => ({
    id: COMMAND_IDS.DEVICE_LOCK,
    name: 'Lock',
    icon: 'lock',
    tooltipProps: {
        content: isSupervised ? 'Lock the endpoint' : 'Device needs to be supervised to lock',
        fullWidth: true,
    },
    disabled: !isSupervised,
});

const deviceShutdownAction = (isSupervised) => ({
    id: COMMAND_IDS.SHUTDOWN_DEVICE,
    name: 'Shutdown',
    icon: 'power',
    tooltipProps: {
        content: isSupervised ? 'Shutdown the endpoint' : 'Device needs to be supervised to shutdown',
        fullWidth: true,
    },
    disabled: !isSupervised,
});

const wipeDeviceAction = (isSupervised) => ({
    id: COMMAND_IDS.ERASE_DEVICE,
    name: 'Wipe',
    icon: 'deleteSweepOutlined',
    tooltipProps: {
        content: isSupervised ? 'Wipe the endpoint' : 'Device needs to be supervised to wipe',
        fullWidth: true,
    },
    disabled: !isSupervised,
});

const installOsUpdateAction = (isSupervised) => ({
    id: COMMAND_IDS.SCHEDULE_OS_UPDATE,
    name: 'Update OS',
    icon: 'updateOS',
    tooltipProps: {
        content: isSupervised ? 'Install an OS update' : 'Device needs to be supervised to install an OS update',
        fullWidth: true,
    },
    disabled: !isSupervised,
});

const clearPasswordAction = (isSupervised) => ({
    id: COMMAND_IDS.CLEAR_PASSCODE,
    name: 'Clear password',
    icon: 'key',
    tooltipProps: {
        content: isSupervised ? 'Clear the password for the endpoint' : 'Device needs to be supervised to clear the password',
        fullWidth: true,
    },
    disabled: !isSupervised,
});

const deleteEndpointAction = {
    id: 'delete',
    name: 'Delete endpoint data',
    icon: 'delete',
    tooltipProps: {
        content: 'Delete the endpoint data from CapaOne',
        fullWidth: true,
    },
};

const enableLostMode = (isSupervised) => ({
    id: COMMAND_IDS.ENABLE_LOSE_MODE,
    name: 'Start lost mode',
    tooltipProps: {
        content: isSupervised ? 'Start lost mode on the endpoint' : 'Device needs to be supervised to start lost mode',
        fullWidth: true,
    },
    icon: 'search',
    disabled: !isSupervised,
});

const disableLostMode = (isSupervised) => ({
    id: COMMAND_IDS.DISABLE_LOSE_MODE,
    name: 'Stop lost mode',
    tooltipProps: {
        content: isSupervised ? 'Stop lost mode on the device' : 'Device needs to be supervised to stop lost mode',
        fullWidth: true,
    },
    icon: 'searchOff',
    disabled: !isSupervised,
});

// Available device fields: https://developers.google.com/apple/management/reference/rest/v1/enterprises.devices
const AppleEndpoint = (props) => {
    const { deviceId, '*': splat } = useParams();
    const selectedTab = splat === '' ? 'settings' : splat.split('/')[0];
    const [endpoint, setEndpoint] = useState({});
    const appleApi = useAppleApi();
    const [appliedPolicy, setAppliedPolicy] = useState({});
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const { appendBaseURL } = useNavigate();
    const coreNavigate = useCoreNavigate();

    const onTabChange = (e, selectedTabId) => {
        if (e.ctrlKey || e.metaKey) {
            window.open(appendBaseURL(`apple/device/${deviceId}/${selectedTabId}`), '_blank');
        } else {
            coreNavigate(selectedTabId);
        }
    };

    useEffect(() => {
        appleApi
            .getEndpoint(deviceId)
            .then(async (endpoint) => {
                let model;
                if (endpoint.data.ModelName?.includes('Mac')) {
                    model = endpoint.data.ModelName;
                } else {
                    model = await FindAppleDeviceName(endpoint);
                }
                endpoint.modelName = model;
                setEndpoint(endpoint);
                setLoading(false);
            })
            .catch((deviceResponseError) => {
                setErrorMessage('Could not find device');
            });
    }, [deviceId]);

    useAppleEndpointWithIdSocket(
        deviceId,
        useCallback(
            ({ fullDocument }, { updateOperation, deleteOperation }) => {
                if (updateOperation) {
                    const { _id: id, ...rest } = fullDocument;
                    setEndpoint((c) => ({
                        id,
                        ...c,
                        ...rest,
                        user: c.user,
                    }));
                } else if (deleteOperation) {
                    coreNavigate('..');
                }
            },
            [deviceId]
        )
    );

    if (errorMessage) {
        return (
            <LayoutCentered>
                <b>{errorMessage}</b>
            </LayoutCentered>
        );
    }
    if (loading) {
        return (
            <LayoutCentered>
                <Loading></Loading>
            </LayoutCentered>
        );
    }
    return (
        <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-auto-1fr tw-px-4">
            <AppleEndpointTopbar
                endpoint={endpoint}
                setEndpoint={setEndpoint}
                setLoading={setLoading}
            />
            <Tabs
                value={selectedTab}
                onChange={onTabChange}
                onRails
            >
                <Tab
                    label="Dashboard"
                    value="dashboard"
                    disableRipple
                    className={tabClassName}
                />
                <Tab
                    label="Inventory"
                    value="inventory"
                    disableRipple
                    className={tabClassName}
                />
                <Tab
                    label="Configurations"
                    value="configurations"
                    disableRipple
                    className={tabClassName}
                />
                {!endpoint.data.ModelName?.includes('Mac') && (
                    <Tab
                        label="Applications"
                        value="applications"
                        disableRipple
                        className={tabClassName}
                    />
                )}
                <Tab
                    label="Groups"
                    value="groups"
                    disableRipple
                    className={tabClassName}
                />
            </Tabs>
            <div className="tw-overflow-auto">
                <Routes>
                    <Route
                        path="dashboard"
                        element={
                            <AppleEndpointDashboardTab
                                endpoint={endpoint}
                                appliedPolicy={appliedPolicy}
                            />
                        }
                    />
                    <Route path="inventory">
                        <Route
                            path=":inventoryId"
                            element={<AppleEndpointInventoryTab endpoint={endpoint} />}
                        />
                        <Route
                            index
                            element={
                                <Navigate
                                    to="software"
                                    replace
                                />
                            }
                        />
                    </Route>

                    <Route
                        path="configurations"
                        element={<AppleConfigurationsWrapper endpoint={endpoint} />}
                    />
                    <Route
                        path="applications"
                        element={<AppleEndpointApplicationsTab endpoint={endpoint} />}
                    />
                    <Route
                        path="groups"
                        element={<AppleEndpointGroupsTab endpoint={endpoint} />}
                    />
                    <Route
                        path="*"
                        element={
                            <LayoutCentered>
                                <PageTitle>Page not found</PageTitle>
                            </LayoutCentered>
                        }
                    />
                </Routes>
            </div>
        </div>
    );
};

const FindAppleDeviceName = async (endpoint) => {
    const isAMac = endpoint.data.ModelName.includes('Mac');
    if (!isAMac) {
        return fetch('https://gist.githubusercontent.com/adamawolf/3048717/raw/07ad6645b25205ef2072a560e660c636c8330626/Apple_mobile_device_types.txt')
            .then((response) => {
                if (response.status === 200) {
                    return response.text();
                } else {
                    throw new Error('Failed to fetch iPhone model lookup table');
                }
            })
            .then((iphoneModelTex) => {
                const isValidModel = iphoneModelTex.includes(endpoint.data.ProductName);
                if (!isValidModel) {
                    return endpoint.data.ProductName;
                }
                const ProductName = `${endpoint.data.ProductName} : (.*)`;
                const match = iphoneModelTex.match(ProductName);
                if (match && match[1]) {
                    return match[1];
                }
            });
    }
};

const AppleEndpointTopbar = ({ endpoint, setEndpoint, setLoading }) => {
    const appleApi = useAppleApi();
    const { handleApiError } = useMessageContext();
    const [actionDialogProps, setActionDialogProps] = useState({
        open: false,
        anchorEl: null,
        category: '',
        title: '',
        actions: [],
    });
    const [showResultingPolicy, setShowResultingPolicy] = useState(false);
    const [isRenaming, setIsRenaming] = useState(false);
    const [viewRequests, setViewRequest] = useState(false);
    const [isManagingUser, setIsManagingUser] = useState(false);
    const [resetPasswordDialogProps, setResetPasswordDialogProps] = useState({
        open: false,
        newPassword: '',
        resetPasswordFlags: [],
    });
    const [showClearAppDataDialog, setShowClearAppDataDialog] = useState(false);
    const [restartEndpointDialog, setRestartEndpointDialog] = useState(false);
    const [lockEndpointDialog, setLockEndpointDialog] = useState(false);
    const [wipeEndpointDialog, setWipeEndpointDialog] = useState(false);
    const [osUpdateDialog, setOsUpdateDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEnableLostModeDialog, setShowEnableLostModeDialog] = useState(false);

    const { to } = useNavigate();

    const openMenu = (e) => {
        // const hasManagedWorkProfile = ManagementModeEnums[endpoint.appleData.managementMode];
        setActionDialogProps(() => {
            const data = {
                open: true,
                anchorEl: e.currentTarget,
                actions: [
                    {
                        id: 'requestAction',
                        name: 'Request',
                        actions: [
                            getDataAction(endpoint.udid),
                            restartDeviceAction(endpoint.data?.IsSupervised),
                            deviceLockAction(endpoint.data?.IsSupervised),
                            deviceShutdownAction(endpoint.data?.IsSupervised),
                            wipeDeviceAction(endpoint.data?.IsSupervised),
                            installOsUpdateAction(endpoint.data?.IsSupervised),
                            clearPasswordAction(endpoint.data?.IsSupervised),
                            enableLostMode(endpoint.data?.IsSupervised),
                            disableLostMode(endpoint.data?.IsSupervised),
                        ],
                    },
                    renameAction,
                    viewRequestsAction,
                    deleteEndpointAction,
                ],
                category: 'Endpoint',
                title: endpoint.name,
            };
            return data;
        });
    };

    const closeMenu = () => {
        setActionDialogProps((c) => ({
            ...c,
            open: false,
        }));
    };

    const onRename = () => {
        if (isRenaming) {
            setIsRenaming(false);
        } else {
            closeMenu();
            setIsRenaming(true);
        }
    };

    const onRenameSubmit = (newName) => {
        setIsRenaming(false);
        appleApi
            .renameEndpoint(endpoint.id, {
                name: newName,
            })
            .then(noop)
            .catch(() => {
                setIsRenaming(true);
            });
    };

    const onViewRequests = () => {
        setViewRequest(true);
    };

    const onRestartDevice = () => {
        setRestartEndpointDialog(true);
    };

    const onRestartEndpointSubmit = (NotifyUser) => {
        appleApi.sendCommand(endpoint.udid, { commandId: COMMAND_IDS.RESTART_DEVICE, data: { NotifyUser } }).then(noop).catch(handleApiError);
        setRestartEndpointDialog(false);
    };

    const onCloseRestartDialog = () => {
        setRestartEndpointDialog(false);
    };

    const onDeviceLock = () => {
        setLockEndpointDialog(true);
    };

    const onDeviceLockSubmit = (data) => {
        appleApi.sendCommand(endpoint.udid, { commandId: COMMAND_IDS.DEVICE_LOCK, data }).then(noop).catch(handleApiError);
        setLockEndpointDialog(false);
    };

    const onCloseLockEndpointDialog = () => {
        setLockEndpointDialog(false);
    };

    const onWipeEndpoint = () => {
        setWipeEndpointDialog(true);
    };

    const onWipeEndpointSubmit = (data) => {
        appleApi.sendCommand(endpoint.udid, { commandId: COMMAND_IDS.ERASE_DEVICE, data }).then(noop).catch(handleApiError);
        setWipeEndpointDialog(false);
    };

    const onCloseWipeEndpointDialog = () => {
        setWipeEndpointDialog(false);
    };

    const onInstallOsUpdateEndpoint = () => {
        setOsUpdateDialog(true);
    };

    const onInstallOsUpdateSubmit = (data) => {
        const newestUpdate = endpoint.availableOsUpdates?.sort((a, b) => {
            const splittetA = a.Version.split('.');
            const splittetB = b.Version.split('.');

            if (splittetA[0] !== splittetB[0]) {
                return splittetB[0].padStart(6, '0').localeCompare(splittetA[0].padStart(6, '0'));
            }

            if (splittetA[1] !== splittetB[1]) {
                return splittetB[1].padStart(6, '0').localeCompare(splittetA[1].padStart(6, '0'));
            }

            if (splittetA[2] !== splittetB[2]) {
                return splittetB[2].padStart(6, '0').localeCompare(splittetA[2].padStart(6, '0'));
            }
        })[0];
        if (newestUpdate) {
            appleApi
                .sendCommand(endpoint.udid, {
                    commandId: COMMAND_IDS.SCHEDULE_OS_UPDATE,
                    data: { Updates: [{ ...data, product_key: newestUpdate.ProductKey }] },
                })
                .then(noop)
                .catch(handleApiError);
        }
        setOsUpdateDialog(false);
    };

    const onCloseInstallOsUpdateDialog = () => {
        setOsUpdateDialog(false);
    };

    const onDeleteEndpoint = () => {
        setShowDeleteDialog(true);
    };

    const onDeleteSubmit = () => {
        appleApi
            .sendCommand(endpoint.udid, { commandId: COMMAND_IDS.DELETE_ENDPOINT_DATA })
            .then(() => {
                setShowDeleteDialog(false);
                to('apple/endpoint/list/mobile');
            })
            .catch(handleApiError);
    };

    const onEnableLostMode = () => {
        setShowEnableLostModeDialog(true);
    };

    const onEnableLostModeSubmit = (data) => {
        appleApi.sendCommand(endpoint.udid, { commandId: COMMAND_IDS.ENABLE_LOSE_MODE, data }).then(noop).catch(handleApiError);
        setShowEnableLostModeDialog(false);
    };

    const DeviceMode = () => {
        if (endpoint?.isKioskMode) {
            return (
                <Tooltip
                    content="This device is currently in Kiosk Mode"
                    position={TOOLTIP.POSITION.TOP_END}
                    extraPadding
                    className="tw-font-semibold"
                >
                    <TailwindBadge
                        size="small"
                        color="cyan"
                        className="tw-gap-1"
                        dark
                    >
                        <Icon
                            type="kioskMode"
                            className="tw-text-lg"
                        />
                        <span>Kiosk Mode </span>
                    </TailwindBadge>
                </Tooltip>
            );
        } else if (endpoint.data?.IsSupervised) {
            return (
                <Tooltip
                    content="This device is currently in Supervised Mode"
                    position={TOOLTIP.POSITION.TOP_END}
                    extraPadding
                    className="tw-font-semibold"
                >
                    <TailwindBadge
                        size="small"
                        color="indigo"
                    >
                        Supervised
                    </TailwindBadge>
                </Tooltip>
            );
        } else if (!endpoint.data?.IsSupervised) {
            return (
                <Tooltip
                    content="This device is currently in Unsupervised Mode"
                    position={TOOLTIP.POSITION.TOP_END}
                    extraPadding
                    className="tw-font-semibold"
                >
                    <TailwindBadge
                        size="small"
                        color="purple"
                    >
                        Unsupervised
                    </TailwindBadge>
                </Tooltip>
            );
        } else {
            return null;
        }
    };

    const onActionClick = (action) => {
        closeMenu();
        switch (action.id) {
            case 'GETDATA': {
                appleApi.getEndpointData(endpoint.udid).then(noop).catch(handleApiError);
                break;
            }
            case renameAction.id:
                onRename();
                break;
            case viewRequestsAction.id:
                onViewRequests();
                break;
            case resultingPolicyAction.id:
                setShowResultingPolicy(true);
                break;
            case manageUserAction.id:
                setIsManagingUser(true);
                break;
            case restartDeviceAction().id:
                onRestartDevice();
                break;
            case deviceLockAction().id:
                onDeviceLock();
                break;
            case deviceShutdownAction().id:
                appleApi.sendCommand(endpoint.udid, { commandId: COMMAND_IDS.SHUTDOWN_DEVICE }).then(noop).catch(handleApiError);
                break;
            case wipeDeviceAction().id:
                onWipeEndpoint();
                break;
            case installOsUpdateAction().id:
                onInstallOsUpdateEndpoint();
                break;
            case clearPasswordAction().id:
                appleApi.sendCommand(endpoint.udid, { commandId: COMMAND_IDS.CLEAR_PASSCODE }).then(noop).catch(handleApiError);
                break;
            case deleteEndpointAction.id:
                onDeleteEndpoint();
                break;
            case enableLostMode().id:
                onEnableLostMode();
                break;
            case disableLostMode().id:
                appleApi.sendCommand(endpoint.udid, { commandId: COMMAND_IDS.DISABLE_LOSE_MODE }).then(noop).catch(handleApiError);
                break;
        }
    };

    return (
        <>
            <Page title={endpoint.name}>
                <div className="tw-pb-3 tw-pt-4">
                    <LayoutRow
                        verticalAlign="center"
                        className="tw-gap-x-4"
                    >
                        <PageTitle>{endpoint.name}</PageTitle>
                        <EndpointUserInfo
                            endpoint={endpoint}
                            setIsManagingUser={setIsManagingUser}
                        />
                        <DeviceMode />
                        <IconButton
                            onClick={openMenu}
                            size={BUTTON.MEDIUM}
                            noMargin
                        >
                            <Icon type="moreVert" />
                        </IconButton>
                    </LayoutRow>
                    <ActionsDialog
                        {...actionDialogProps}
                        onClose={closeMenu}
                        onActionClick={onActionClick}
                    />
                    <RenamingDialog
                        onCancel={onRename}
                        onSubmit={onRenameSubmit}
                        currentName={endpoint.name}
                        open={isRenaming}
                    />
                    <ViewRequests
                        onClose={() => {
                            setViewRequest(false);
                        }}
                        open={viewRequests}
                        endpoint={endpoint}
                    />
                    <UserManagementAssignUserToEndpoint
                        open={isManagingUser}
                        onSubmit={(user) => {
                            setIsManagingUser(false);
                            setEndpoint((c) => ({
                                ...c,
                                user,
                            }));
                        }}
                        endpointType={ENDPOINT_TYPE.APPLE}
                        endpoint={endpoint}
                        onClose={() => {
                            setIsManagingUser(false);
                        }}
                    />

                    <ConfirmDialog
                        open={resetPasswordDialogProps.open}
                        title={
                            <PageTitle
                                description={endpoint.name}
                                className="tw-mb-2"
                            ></PageTitle>
                        }
                        onCancel={() => {
                            setResetPasswordDialogProps((c) => ({
                                ...c,
                                open: false,
                            }));
                        }}
                    >
                        <div className="tw-grid tw-gap-4">
                            <Input
                                value={resetPasswordDialogProps.newPassword}
                                callToAction
                                autoFocus
                                placeholder="New password"
                                type="text"
                                disableSpinner
                                onChange={(e) => {
                                    setResetPasswordDialogProps((c) => ({
                                        ...c,
                                        newPassword: e.target.value,
                                    }));
                                }}
                                className="tw-mb-2"
                                inputClassName={classNames({
                                    'tw-tracking-widest tw-font-mono': resetPasswordDialogProps.newPassword !== '',
                                })}
                            />
                            <Checkbox
                                checked={resetPasswordDialogProps.resetPasswordFlags.includes('REQUIRE_ENTRY')}
                                label={<b>Don't allow other admins to change the password again until the user has entered it</b>}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setResetPasswordDialogProps((c) => ({
                                            ...c,
                                            resetPasswordFlags: [...c.resetPasswordFlags, 'REQUIRE_ENTRY'],
                                        }));
                                    } else {
                                        setResetPasswordDialogProps((c) => ({
                                            ...c,
                                            resetPasswordFlags: c.resetPasswordFlags.filter((flag) => flag !== 'REQUIRE_ENTRY'),
                                        }));
                                    }
                                }}
                            />
                            <Checkbox
                                checked={resetPasswordDialogProps.resetPasswordFlags.includes('LOCK_NOW')}
                                label={<b>Lock the device after password reset</b>}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setResetPasswordDialogProps((c) => ({
                                            ...c,
                                            resetPasswordFlags: [...c.resetPasswordFlags, 'LOCK_NOW'],
                                        }));
                                    } else {
                                        setResetPasswordDialogProps((c) => ({
                                            ...c,
                                            resetPasswordFlags: c.resetPasswordFlags.filter((flag) => flag !== 'LOCK_NOW'),
                                        }));
                                    }
                                }}
                            />
                            <Checkbox
                                checked={resetPasswordDialogProps.resetPasswordFlags.includes('DO_NOT_ASK_CREDENTIALS_ON_BOOT')}
                                label={<b>Don't ask for user credentials on device boot</b>}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setResetPasswordDialogProps((c) => ({
                                            ...c,
                                            resetPasswordFlags: [...c.resetPasswordFlags, 'DO_NOT_ASK_CREDENTIALS_ON_BOOT'],
                                        }));
                                    } else {
                                        setResetPasswordDialogProps((c) => ({
                                            ...c,
                                            resetPasswordFlags: c.resetPasswordFlags.filter((flag) => flag !== 'DO_NOT_ASK_CREDENTIALS_ON_BOOT'),
                                        }));
                                    }
                                }}
                            />
                        </div>
                    </ConfirmDialog>

                    <ClearAppDataDialog
                        open={showClearAppDataDialog}
                        endpoint={endpoint}
                        onClose={() => {
                            setShowClearAppDataDialog(false);
                        }}
                    />

                    <Dialog
                        open={showResultingPolicy}
                        onClose={() => setShowResultingPolicy(false)}
                        size="xl"
                    >
                        <DialogTitle>Resulting policy</DialogTitle>
                        <div className="tw-grid tw-max-h-full tw-grid-cols-auto-1fr tw-overflow-auto">
                            <JsonViewer
                                json={endpoint.resultingPolicy}
                                className="tw-text-tiny"
                                square
                            />
                            <div className="tw-max-h-full tw-overflow-auto tw-whitespace-pre-wrap tw-break-words tw-bg-slate-800 tw-p-4 tw-font-mono tw-text-tiny tw-leading-loose tw-text-white">
                                {endpoint.resultingConfigurationDescription}
                            </div>
                        </div>
                        <DialogActions>
                            <Button onClick={() => setShowResultingPolicy(false)}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Page>
            <Dialog
                open={restartEndpointDialog}
                onClose={onCloseRestartDialog}
            >
                <DialogTitle>Restart Endpoint</DialogTitle>
                <DialogContent>Are you sure you want to restart the device?</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            onRestartEndpointSubmit(false);
                        }}
                        color={BUTTON.PRIMARY}
                        variant={BUTTON.RAISED}
                    >
                        Restart
                    </Button>
                    <Button
                        onClick={() => {
                            onRestartEndpointSubmit(true);
                        }}
                        color={BUTTON.PRIMARY}
                    >
                        Notify user and restart
                    </Button>
                    <Button onClick={onCloseRestartDialog}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <LockEndpointDialog
                open={lockEndpointDialog}
                onClose={onCloseLockEndpointDialog}
                onSubmit={onDeviceLockSubmit}
            />
            <WipeDeviceDialog
                open={wipeEndpointDialog}
                onClose={onCloseWipeEndpointDialog}
                onSubmit={onWipeEndpointSubmit}
            />
            <OSUpdateDialog
                open={osUpdateDialog}
                onClose={onCloseInstallOsUpdateDialog}
                onSubmit={onInstallOsUpdateSubmit}
                isMac={endpoint.data?.ModelName?.toLowerCase().includes('mac')}
            />
            <DeleteEndpointDataDialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                onSubmit={onDeleteSubmit}
            />
            <EnableLostModeDialog
                open={showEnableLostModeDialog}
                onClose={() => setShowEnableLostModeDialog(false)}
                onSubmit={onEnableLostModeSubmit}
            />
        </>
    );
};

const DeleteEndpointDataDialog = ({ open, onClose, onSubmit }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            size="md"
        >
            <DialogTitle>Delete Endpoint Data</DialogTitle>
            <DialogContent>
                <p className="tw-font-semibold">
                    This will only delete the data and relation to the device from CapaOne. It will not send any command or wipe the device. If you want to wipe
                    the device then use Wipe request
                </p>
                <p className="tw-pt-4">Are you sure you want to delete the endpoint data?</p>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onSubmit}
                    color={BUTTON.PRIMARY}
                    variant={BUTTON.RAISED}
                >
                    Delete
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const ViewRequests = ({ open, onClose, endpoint }) => {
    const appleApi = useAppleApi();
    const [requestList, setRequestList] = useState();
    const [loading, setLoading] = useState(false);
    const getEndpointCommands = () => {
        setLoading(true);
        appleApi
            .getEndpointCommands(endpoint.udid)
            .then(getReadableName)
            .then((response) => {
                setRequestList(response);
            })
            .catch(noop)
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (open) {
            getEndpointCommands();
        } else {
            setRequestList([]);
        }
    }, [open]);

    const getReadableName = (data) => {
        const readableRequestType = data?.map((requestType) => {
            const title = {
                [COMMAND_IDS.SHUTDOWN_DEVICE]: 'Shutting down Device',
                [COMMAND_IDS.DEVICEINFO]: 'Gathering device information',
                [COMMAND_IDS.RESTART_DEVICE]: 'Restarting device',
                [COMMAND_IDS.DEVICE_LOCK]: 'Locking device',
                [COMMAND_IDS.INSTALLEDAPPLICATIONLIST]: 'Getting new software inventory ',
                [COMMAND_IDS.CLEAR_PASSCODE]: 'Removing password',
                [COMMAND_IDS.ERASE_DEVICE]: 'Wiping device data',
                [COMMAND_IDS.PROFILELIST]: 'Updating applied configurations',
                [COMMAND_IDS.SECURITYINFO]: 'Gathering security infomation',
                [COMMAND_IDS.OS_UPDATE_STATUS]: 'Getting current OS status',
                [COMMAND_IDS.AVAILABLEOSUPDATES]: 'Checking for OS updates',
                [COMMAND_IDS.SCHEDULE_OS_UPDATE]: 'Installing OS Update', //TODO: Add version to this (Need a device that need to get updated)
                [COMMAND_IDS.RESTRICTIONS]: 'Updating Restrictions',
                [COMMAND_IDS.INSTALL_APPLICATION]: `Installing application ${requestType.data?.Identifier}`, //TODO: Change this to application name
                [COMMAND_IDS.REMOVE_APPLICATION]: `Uninstalling application ${requestType.data?.Identifier}`, //TODO: Change this to application name
                [COMMAND_IDS.INSTALL_PROFILE]: `Applying configuration ${requestType.command_uuid.split('_')[1]}`, //TODO: Change this to configration name
                [COMMAND_IDS.REMOVE_PROFILE]: `Removing configuration ${requestType.command_uuid.split('_')[1]}`, //TODO: Change this to configration name
                [COMMAND_IDS.SETTINGS]: 'Applying settings',
            }[requestType.request_type];
            return { ...requestType, title };
        });
        return readableRequestType;
    };

    useAppleDeviceCommandQueueSocket(
        endpoint.udid,
        useCallback(
            ({ fullDocument }) => {
                const getReadableRequestName = getReadableName(fullDocument.commands);
                setRequestList(getReadableRequestName);
            },
            [endpoint.udid]
        )
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            size="lg"
        >
            <DialogTitle>
                <LayoutRow align="space-between">
                    <p>Commands requests for {endpoint.name}</p>
                    <Tooltip content="This will clear the queue and retry installation of configurations and applications">
                        <Button
                            onClick={() => {
                                appleApi.sendCommand(endpoint.udid, { commandId: COMMAND_IDS.CLEAR_COMMAND_QUEUE }).then(() => {
                                    getEndpointCommands();
                                });
                            }}
                            color={BUTTON.PRIMARY}
                            variant={BUTTON.RAISED}
                        >
                            Clear Queue
                        </Button>
                    </Tooltip>
                </LayoutRow>
            </DialogTitle>
            <div className="tw-h-screen">
                <VirtualizedTable
                    showRowCount
                    totalRowCount={requestList?.length}
                    isLoading={loading}
                    items={requestList}
                >
                    <Column
                        key="date"
                        dataKey="date"
                        label="Date"
                        minWidth={120}
                        maxWidth={120}
                        cellRenderer={({ rowData }) => {
                            return rowData.timestamp ? dayjs(rowData?.timestamp).fromNow() : 'Never';
                        }}
                    />
                    <Column
                        key="title"
                        dataKey="title"
                        label="Request name"
                        minWidth={160}
                    />
                    <Column
                        key="retries"
                        dataKey="retries"
                        label="Retries"
                        minWidth={80}
                        maxWidth={80}
                        justify={virtualizedTableColumnPropTypes.justify.end}
                    />
                </VirtualizedTable>
            </div>
        </Dialog>
    );
};

const OSUpdateDialog = ({ open, onClose, onSubmit, isMac }) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [installAction, setInstallAction] = useState('');
    const inputRef = useRef({});

    const Schema = {
        schema: {
            title: 'Install OS Update',
            type: 'object',
            schemaId: 'ScheduleOSUpdate',
            properties: {
                install_action: {
                    title: 'Install Action',
                    description: 'How to install the OS update. This value is available in iOS 9 and later, and macOS 10.11 and later',
                    type: 'string',
                    options: [
                        { id: 'Default', name: 'Download & Install' },
                        { id: 'DownloadOnly', name: 'Download only' },
                        { id: 'InstallASAP', name: 'Install Now' },
                        ...(isMac
                            ? [
                                  { id: 'NotifyOnly', name: 'Notify only' },
                                  { id: 'InstallLater', name: 'Install later' },
                                  { id: 'InstallForeceRestart', name: 'Install and force restart' },
                              ]
                            : []),
                    ],
                    required: true,
                },
                max_user_deferrals: {
                    title: 'Max User Deferrals',
                    description:
                        'The maximum number of times the system allows the user to postpone an update before it’s installed. The system prompts the user once a day.',
                    type: 'number',
                    shouldRender: (siblings) => {
                        if (siblings.install_action === 'InstallLater') {
                            return true;
                        }
                        delete siblings.MaxUserDeferrals;
                        return false;
                    },
                },
                priority: {
                    title: 'Priority',
                    description:
                        'The scheduling priority for downloading and preparing the requested update. This is only supported for minor OS updates (macOS 12.x to 12.y).',
                    type: 'string',
                    options: [
                        { id: 'Low', name: 'Low' },
                        { id: 'High', name: 'High' },
                    ],
                },
            },
        },
    };

    const handleInstallActionChange = () => {
        const selectedAction = inputRef.current.install_action || '';
        setInstallAction(selectedAction);
        setIsButtonDisabled(selectedAction === '');
    };

    const handleClose = () => {
        inputRef.current = {};
        setIsButtonDisabled(true);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            size="md"
        >
            <SchemaBuilder
                {...Schema}
                ref={inputRef.current}
                onRefChange={handleInstallActionChange}
            />
            <DialogActions>
                <Button
                    onClick={() => {
                        onSubmit(inputRef.current);
                        setIsButtonDisabled(true);
                        inputRef.current = {};
                    }}
                    color={BUTTON.PRIMARY}
                    variant={BUTTON.RAISED}
                    disabled={isButtonDisabled}
                >
                    Install OS Update
                </Button>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const EnableLostModeDialog = ({ open, onClose, onSubmit }) => {
    const inputRef = useRef({});

    const Schema = {
        schema: {
            title: 'Start Lost Mode',
            type: 'object',
            schemaId: 'enableLostMode',
            properties: {
                Message: {
                    title: 'Message',
                    description: 'If present, display this text on the Lock screen. You must provide this value if you don’t provide a value for PhoneNumber',
                    type: 'string',
                },
                PhoneNumber: {
                    title: 'Phone Number',
                    description:
                        'If present, display this phone number on the Lock screen. You must provide this value if you don’t provide a value for Message',
                    type: 'string',
                },
                Footnote: {
                    title: 'Footnote',
                    description: 'If present, display this text in place of Slide to Unlock',
                    type: 'string',
                },
            },
        },
    };

    const handleClose = () => {
        inputRef.current = {};
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            size="md"
        >
            <SchemaBuilder
                {...Schema}
                ref={inputRef.current}
            />
            <DialogActions>
                <Button
                    onClick={() => {
                        onSubmit(inputRef.current);
                        inputRef.current = {};
                    }}
                    color={BUTTON.PRIMARY}
                    variant={BUTTON.RAISED}
                >
                    Start Lost Mode
                </Button>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const WipeDeviceDialog = ({ open, onClose, onSubmit }) => {
    const inputRef = useRef({});

    const Schema = {
        schema: {
            title: 'Wipe Endpoint',
            type: 'object',
            schemaId: 'eraseDevice',
            properties: {
                DisallowProximitySetup: {
                    title: 'Disallow Proximity Setup',
                    description:
                        'If true, disable Proximity Setup on the next reboot and skip the pane in Setup Assistant. This value is available in iOS 11 and later. Prior to iOS 14, don’t use this option with any other option',
                    type: 'boolean',
                },
                PreserveDataPlan: {
                    title: 'Preserve Data Plan',
                    description:
                        'If true, disable Proximity Setup on the next reboot and skip the pane in Setup Assistant. This value is available in iOS 11 and later. Prior to iOS 14, don’t use this option with any other option',
                    type: 'boolean',
                },
                PIN: {
                    title: 'PIN',
                    description: 'The six-character PIN for Find My',
                    type: 'string',
                },
                // deleteCapaoneData: {
                //     title: 'Delete Capaone Data',
                //     description:
                //         'Delete data for this device on CapaOne. If the device is enrolled via DEP, the device will re-enroll after the next reboot. The device will be a part of the same groups and get the same configurations and applications that it has now, if this setting is not configured or set to false',
                //     type: 'boolean',
                // },
            },
        },
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            size="md"
        >
            <SchemaBuilder
                {...Schema}
                ref={inputRef.current}
            />
            <DialogActions>
                <Button
                    onClick={() => {
                        onSubmit(inputRef.current);
                    }}
                    color={BUTTON.PRIMARY}
                    variant={BUTTON.RAISED}
                >
                    Wipe
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const LockEndpointDialog = ({ open, onClose, onSubmit }) => {
    const inputRef = useRef({});

    const Schema = {
        schema: {
            title: 'Lock Endpoint',
            type: 'object',
            schemaId: 'lockEndpoint',
            properties: {
                message: {
                    title: 'Message',
                    description:
                        'The message to display on the Lock screen of the device. This value doesn’t apply to a shared iPad device. This value is available in iOS 4 and later, and macOS 10.14 and later',
                    type: 'string',
                },
                phone_number: {
                    title: 'Phone Number',
                    description:
                        'The phone number to display on the Lock screen. This value doesn’t apply to a shared iPad device. This value is available in iOS 7 and later and macOS 11.5 and later (for Apple silicon devices only)',
                    type: 'string',
                },
                pin: {
                    title: 'PIN',
                    description: 'The six-character PIN for Find My',
                    type: 'string',
                },
            },
        },
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <SchemaBuilder
                {...Schema}
                ref={inputRef.current}
            />
            <DialogActions>
                <Button
                    onClick={() => {
                        onSubmit(inputRef.current);
                    }}
                    color={BUTTON.PRIMARY}
                    variant={BUTTON.RAISED}
                >
                    Lock
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const ClearAppDataDialog = ({ open, endpoint, onClose }) => {
    const appleApi = useAppleApi();
    const [packageNames, setPackageNames] = useState([]);
    const [applicationsList, setApplicationsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const onEnter = () => {
        setPackageNames([]);
        setIsProcessing(false);
        if (endpoint.resultingPolicy.applications && endpoint.resultingPolicy.applications.length > 0) {
            setIsLoading(true);
            appleApi
                .getApplications()
                .then((applicationsResponse) => {
                    setApplicationsList(
                        endpoint.resultingPolicy.applications?.map((application) => {
                            const appData = applicationsResponse.find((app) => {
                                if (app.configurationType === coreAppleManagement.configurationType.webapp) {
                                    return app.googlePlayId.endsWith(application.packageName);
                                }
                                return (
                                    app.configurationType === coreAppleManagement.configurationType.application &&
                                    app.data.applications.some((a) => a.packageName === application.packageName)
                                );
                            });

                            return {
                                ...application,
                                ...appData,
                            };
                        })
                    );
                })
                .catch((_) => {
                    // ignore
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    };

    const onConfirm = () => {
        setIsProcessing(true);
        appleApi
            .sendClearApplicationDataCommand(endpoint.id, packageNames)
            .then(() => {
                onClose();
            })
            .catch((e) => {
                setIsProcessing(false);
            });
    };

    return (
        <ConfirmDialog
            onEnter={onEnter}
            open={open}
            title={
                <>
                    <PageTitle
                        description={endpoint.name}
                        className="tw-mb-2"
                    ></PageTitle>
                    <p className="tw-text-sm">
                        Note that an application can store data outside of its application data, for example in external storage or in a user dictionary.
                    </p>
                </>
            }
            onConfirm={onConfirm}
            onCancel={onClose}
            dialogContentClassName="tw-px-0 tw-pt-0 tw-h-112"
            disabled={packageNames.length === 0}
            isProcessing={isProcessing}
        >
            <VirtualizedTable
                items={applicationsList}
                disableHeader
                noRowsRenderer={() => <LayoutCentered>No assigned applications</LayoutCentered>}
                isLoading={isLoading}
                onRowClick={({ rowData }) => {
                    if (!isProcessing) {
                        if (packageNames.includes(rowData.packageName)) {
                            setPackageNames((currentPackageNames) => {
                                return currentPackageNames.filter((currentPackageName) => currentPackageName !== rowData.packageName);
                            });
                        } else {
                            setPackageNames([...packageNames, rowData.packageName]);
                        }
                    }
                }}
            >
                <Column
                    minWidth={40}
                    maxWidth={40}
                    dataKey="packageName"
                    disableSort
                    label=""
                    cellRenderer={({ rowData }) => {
                        return <Checkbox checked={packageNames.includes(rowData.packageName)} />;
                    }}
                />
                <Column
                    minWidth={40}
                    maxWidth={40}
                    dataKey="iconUrl"
                    label=""
                    shouldRender={({ tableWidth }) => tableWidth >= 600}
                    cellRenderer={({ rowData }) => {
                        if (rowData.iconUrl) {
                            return (
                                <Avatar
                                    src={rowData.iconUrl}
                                    alt={rowData.name}
                                    variant="rounded"
                                    className="tw-h-8 tw-w-8"
                                />
                            );
                        }
                        return (
                            <Icon
                                type="app"
                                className="tw-h-8 tw-w-8"
                            />
                        );
                    }}
                />
                <Column
                    minWidth={160}
                    dataKey="name"
                    label="Name"
                    cellRenderer={({ rowData }) => {
                        return (
                            <TwoLineCellRenderer
                                main={rowData.name}
                                callToAction
                                secondary={rowData.installTypeName}
                            />
                        );
                    }}
                />
            </VirtualizedTable>
        </ConfirmDialog>
    );
};

const EndpointUserInfo = ({ endpoint, setIsManagingUser }) => {
    const navigate = useNavigate();

    const [mouseEntered, setMouseEntered] = useState(false);

    if (endpoint.user) {
        return (
            <Button
                variant={BUTTON.OUTLINED}
                className="tw-rounded-xl tw-border-slate-200 tw-bg-slate-100 tw-px-3 tw-py-2.5 hover:tw-bg-slate-200"
                onClick={() => navigate.to(`management/user/${endpoint.user.id}`)}
                onMouseEnter={() => setMouseEntered(true)}
                onMouseLeave={() => setMouseEntered(false)}
                disableRipple
            >
                <div className="tw-grid tw-grid-cols-auto-1fr-auto tw-items-center tw-gap-x-3 tw-text-left">
                    <div>
                        <UserTypeIcon
                            type={endpoint.user.type}
                            className="tw-h-6 tw-w-6"
                            deleted={endpoint.user.deleted}
                        />
                    </div>
                    <Tooltip
                        content="View user"
                        bold
                        disableHoverListener={endpoint.user.deleted}
                    >
                        <div>
                            <div className="tw-text-xs tw-font-semibold tw-leading-none">{endpoint.user.name}</div>
                            <div className="tw-mt-1 tw-block tw-text-xs tw-font-medium tw-leading-none">{endpoint.user.email}</div>
                        </div>
                    </Tooltip>
                    <div>
                        <Collapse
                            in={mouseEntered}
                            unmountOnExit
                            orientation="horizontal"
                        >
                            <Tooltip
                                content={manageUserAction.name}
                                bold
                            >
                                <Icon
                                    type="edit"
                                    color="inherit"
                                    className="tw-text-blue-500 hover:tw-text-sky-700"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setIsManagingUser(true);
                                    }}
                                />
                            </Tooltip>
                        </Collapse>
                    </div>
                </div>
            </Button>
        );
    }
    return (
        <Button
            variant={BUTTON.OUTLINED}
            color={BUTTON.PRIMARY}
            className="tw-py-2"
            noMargin
            onClick={() => {
                setIsManagingUser(true);
            }}
        >
            <LayoutRow verticalAlign="center">
                <Icon
                    type="addUser"
                    className="tw-mr-2 tw-text-xl"
                />
                <div className="tw-text-xs tw-font-bold">Assign user</div>
            </LayoutRow>
        </Button>
    );
};

export { AppleEndpoint };
