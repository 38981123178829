import { BUTTON } from '@capasystems/constants';
import {
    Button,
    Column,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    Ellipsis,
    Form,
    Input,
    LayoutCenter,
    LayoutRow,
    SavingChanges,
    VirtualizedTable,
} from '@capasystems/ui';
import { isDefined, noop } from '@capasystems/utils';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { ConfirmDialog, Heading, useApi } from '../../index';

const paperProps = {
    style: {
        minWidth: '20vw',
        maxWidth: '100vw',
        width: 660,
    },
};

const ManageTags = ({ device, open, query, onClose, totalAffectedByQuery, onChange = noop }) => {
    const api = useApi();
    const [isCreatingTag, setIsCreatingTag] = useState(false);
    const [availableTags, setAvailableTags] = useState([]);

    const toggleCreateTagForm = () => {
        setIsCreatingTag(!isCreatingTag);
    };

    const onCreateTagSuccess = () => {
        fetchTags();
        toggleCreateTagForm();
    };

    const fetchTags = useCallback(() => {
        api.getTags()
            .then((response) => {
                setAvailableTags(response.content);
            })
            .catch(noop);
    }, [api]);

    useEffect(() => {
        if (open) {
            fetchTags();
        }
    }, [fetchTags, open]);

    const handleClose = () => {
        onClose();
    };

    return (
        <Drawer
            open={open}
            onClose={handleClose}
            anchor="right"
            PaperProps={paperProps}
        >
            <div className="tw-bg-slate-50 tw-p-4 tw-shadow">
                <LayoutRow
                    verticalAlign="center"
                    align="space-between"
                >
                    <div>
                        <h2
                            subheading
                            regular
                            className="tw-text-sm tw-font-normal tw-text-slate-700"
                        >
                            Tag management
                        </h2>
                        {device && (
                            <Heading
                                subheading
                                bold
                                className="tw-text-slate-700"
                            >
                                {device.name}
                            </Heading>
                        )}
                        {isDefined(totalAffectedByQuery) && (
                            <Heading
                                subheading
                                bold
                                className="tw-text-slate-700"
                            >
                                {pluralize('endpoint', totalAffectedByQuery, true)}
                            </Heading>
                        )}
                    </div>
                    <Button
                        noMargin
                        color={BUTTON.PRIMARY}
                        variant={BUTTON.OUTLINED}
                        onClick={toggleCreateTagForm}
                        disabled={isCreatingTag}
                        className="tw-rounded-full"
                    >
                        <b>Create tag</b>
                    </Button>
                </LayoutRow>
            </div>
            <CreateTag
                open={isCreatingTag}
                onCancel={toggleCreateTagForm}
                onSuccess={onCreateTagSuccess}
            />
            {device && (
                <ManageDeviceTags
                    device={device}
                    availableTags={availableTags}
                />
            )}
            {query && (
                <ManageTagsViaQuery
                    query={query}
                    availableTags={availableTags}
                    totalAffectedByQuery={totalAffectedByQuery}
                    onChange={onChange}
                />
            )}
        </Drawer>
    );
};

const ManageTagsViaQuery = ({ availableTags, query, totalAffectedByQuery, onChange }) => {
    const api = useApi();
    const [confirmationState, setConfirmationState] = useState({
        open: false,
        isApplyOperation: false,
        selectedTag: {},
        title: null,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [savingChanges, setSavingChanges] = useState({
        loading: false,
        successMessage: null,
        errorMessage: null,
    });

    const onSuccess = () => {
        setSavingChanges({
            loading: false,
            successMessage: confirmationState.isApplyOperation
                ? 'Added to ' + pluralize('endpoint', totalAffectedByQuery, true)
                : 'Removed from ' + pluralize('endpoint', totalAffectedByQuery, true),
            errorMessage: null,
        });
        onChange();
    };

    const onError = () => {
        setSavingChanges({
            loading: false,
            successMessage: null,
            errorMessage: confirmationState.isApplyOperation
                ? 'Could not add tag to ' + pluralize('endpoint', totalAffectedByQuery, true)
                : 'Could not remove tag from ' + pluralize('endpoint', totalAffectedByQuery, true),
        });
    };

    const openConfirmDialog = (selectedTag, isApplyOperation) => () => {
        api.getWindowsDeviceCountBasedOnFilter(query)
            .then((count) => {
                if (count !== totalAffectedByQuery) {
                    onChange();
                }
            })
            .catch(noop);
        setConfirmationState({
            open: true,
            isApplyOperation,
            selectedTag,
        });
    };

    const onConfirm = () => {
        setSavingChanges({
            loading: true,
            errorMessage: null,
        });
        if (confirmationState.isApplyOperation) {
            api.addTagToDevices(confirmationState.selectedTag.name, query, confirmationState.selectedTag.id).then(onSuccess).catch(onError);
        } else {
            api.untagDevices(confirmationState.selectedTag.name, query, confirmationState.selectedTag.id).then(onSuccess).catch(onError);
        }

        setConfirmationState({
            ...confirmationState,
            open: false,
        });
    };

    const cancelTagging = () => {
        setConfirmationState({
            ...confirmationState,
            open: false,
        });
    };

    const onSearch = ({ target }) => setSearchTerm(target.value);

    const filteredTags = availableTags.filter(({ name }) => name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));

    return (
        <>
            <div className="tw-mb-2 tw-mt-3 tw-px-4">
                <Input
                    value={searchTerm}
                    light
                    callToAction
                    type="search"
                    autoFocus
                    rounded
                    placeholder="Search tag names"
                    onChange={onSearch}
                />
            </div>
            <DialogContent className="tw-p-0">
                <VirtualizedTable
                    items={filteredTags}
                    disableHeader
                    totalRowCount={filteredTags.length}
                    borderless
                >
                    <Column
                        minWidth={136}
                        maxWidth={136}
                        dataKey="name"
                        label=""
                        cellRenderer={({ rowData }) => (
                            <>
                                <Button
                                    color={BUTTON.PRIMARY}
                                    variant={BUTTON.OUTLINED}
                                    onClick={openConfirmDialog(rowData, true)}
                                    size="small"
                                    disabled={totalAffectedByQuery === 0}
                                >
                                    Apply
                                </Button>
                                <Button
                                    color={BUTTON.DANGER}
                                    variant={BUTTON.OUTLINED}
                                    onClick={openConfirmDialog(rowData, false)}
                                    noMargin
                                    size="small"
                                    disabled={totalAffectedByQuery === 0}
                                >
                                    Remove
                                </Button>
                            </>
                        )}
                    />
                    <Column
                        minWidth={120}
                        dataKey="name"
                        label="Available tags"
                    />
                </VirtualizedTable>
            </DialogContent>
            <ConfirmDialog
                open={confirmationState.open}
                onCancel={cancelTagging}
                onConfirm={onConfirm}
                title={
                    confirmationState.isApplyOperation ? (
                        <div>
                            Add <span className="tw-font-semibold">{confirmationState.selectedTag.name}</span> to{' '}
                            <span className="tw-font-semibold">{pluralize('endpoint', totalAffectedByQuery, true)}</span>?
                        </div>
                    ) : (
                        <div>
                            Remove <span className="tw-font-semibold">{confirmationState.selectedTag.name}</span> from{' '}
                            <span className="tw-font-semibold">{pluralize('endpoint', totalAffectedByQuery, true)}</span>?
                        </div>
                    )
                }
            />
            <SavingChanges
                successMessage={savingChanges.successMessage}
                errorMessage={savingChanges.errorMessage}
                loading={savingChanges.loading}
                minLoadingTime={0}
                minSuccessTime={1500}
            />
        </>
    );
};

const ManageDeviceTags = ({ device, availableTags }) => {
    const api = useApi();
    const [searchTerm, setSearchTerm] = useState('');

    const applyTagToDevice = (tag) => () => {
        api.tagDevice(device.id, tag.id, tag.name).then(noop).catch(noop);
    };
    const removeTagFromDevice = (tag) => () => {
        api.untagDevice(device.id, tag.name, tag.id).then(noop).catch(noop);
    };

    const onSearch = ({ target }) => setSearchTerm(target.value);

    const addableTags = availableTags.filter((tag) => !device.tags.includes(tag.name) && tag.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
    const removableTags = availableTags.filter(
        (tag) => device.tags.includes(tag.name) && tag.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );

    return (
        <>
            <div className="tw-mb-4 tw-mt-4 tw-px-4">
                <Input
                    value={searchTerm}
                    light
                    callToAction
                    type="search"
                    autoFocus
                    rounded
                    placeholder="Search tag names"
                    onChange={onSearch}
                />
            </div>
            <Divider />
            <DialogContent className="tw-p-0">
                <div className="tw-grid tw-h-full tw-grid-cols-1fr-auto-1fr">
                    <div>
                        <VirtualizedTable
                            items={addableTags}
                            disableHeader
                            totalRowCount={addableTags.length}
                            noRowsRenderer={() => (
                                <LayoutCenter className="tw-text-yellow-700">
                                    <div className="tw-text-xs tw-font-bold">{searchTerm ? 'No search results' : 'No tags available'}</div>
                                </LayoutCenter>
                            )}
                        >
                            <Column
                                maxWidth={64}
                                minWidth={64}
                                dataKey="name"
                                label=""
                                cellRenderer={({ rowData }) => (
                                    <Button
                                        color={BUTTON.PRIMARY}
                                        variant={BUTTON.OUTLINED}
                                        onClick={applyTagToDevice(rowData)}
                                        size="small"
                                        noMargin
                                    >
                                        Apply
                                    </Button>
                                )}
                            />
                            <Column
                                minWidth={96}
                                dataKey="name"
                                label="Available tags"
                            />
                        </VirtualizedTable>
                    </div>
                    <Divider
                        orientation="vertical"
                        flexItem
                        className="tw-opacity-50"
                    />
                    <div>
                        <VirtualizedTable
                            items={removableTags}
                            disableHeader
                            totalRowCount={removableTags.length}
                            noRowsRenderer={() => (
                                <LayoutCenter className="tw-text-yellow-700 ">
                                    <div className="tw-w-full tw-overflow-hidden tw-p-6 tw-text-xs">
                                        {searchTerm ? `No search results on` : 'No tags added to'}
                                        <Ellipsis className="tw-mt-2 tw-block tw-font-bold">{device.name}</Ellipsis>
                                    </div>
                                </LayoutCenter>
                            )}
                        >
                            <Column
                                maxWidth={72}
                                minWidth={72}
                                dataKey="name"
                                label=""
                                cellRenderer={({ rowData }) => (
                                    <Button
                                        color={BUTTON.DANGER}
                                        variant={BUTTON.OUTLINED}
                                        onClick={removeTagFromDevice(rowData)}
                                        noMargin
                                        size="small"
                                    >
                                        Remove
                                    </Button>
                                )}
                            />
                            <Column
                                minWidth={96}
                                dataKey="name"
                                label="Available tags"
                            />
                        </VirtualizedTable>
                    </div>
                </div>
            </DialogContent>
        </>
    );
};

const DEFAULT_FORM_STATE = {
    name: '',
    disabled: true,
    errorMessage: null,
};

const CreateTag = ({ open, onCancel, onSuccess }) => {
    const api = useApi();

    const [formState, setFormState] = useState(DEFAULT_FORM_STATE);

    const onSubmit = () => {
        api.createTag(formState.name)
            .then(onSuccess)
            .catch((error) => {
                setFormState({
                    ...formState,
                    disabled: true,
                    errorMessage: error.status.code === 400 || error.status.code === 500 ? 'This name already exists' : 'An unknown error occurred',
                });
            });
    };

    const onEnter = () => setFormState(DEFAULT_FORM_STATE);

    return (
        <Dialog
            open={open}
            onExited={onEnter}
            onClose={onCancel}
        >
            <DialogTitle>Enter a tag name</DialogTitle>
            <TagForm
                formState={formState}
                setFormState={setFormState}
                onSubmit={onSubmit}
                onCancel={onCancel}
            />
        </Dialog>
    );
};

const EditTag = ({ open, tag: { id, name }, onCancel, onSuccess }) => {
    const api = useApi();

    const [formState, setFormState] = useState(DEFAULT_FORM_STATE);

    const onSubmit = () => {
        api.updateTag(id, formState.name)
            .then(onSuccess)
            .catch((error) => {
                setFormState({
                    ...formState,
                    disabled: true,
                    errorMessage: error.status.code === 409 || error.status.code === 500 ? 'This name already exists' : 'An unknown error occurred',
                });
            });
    };

    const onEnter = () => {
        setFormState({
            ...DEFAULT_FORM_STATE,
            name,
        });
    };

    return (
        <Dialog
            open={open}
            onEnter={onEnter}
            onClose={onCancel}
        >
            <DialogTitle>
                Edit tag <span className="tw-font-bold">{name}</span>
            </DialogTitle>
            <TagForm
                formState={formState}
                setFormState={setFormState}
                onSubmit={onSubmit}
                onCancel={onCancel}
                id={id}
            />
        </Dialog>
    );
};

const TagForm = ({ onSubmit, formState: { disabled, name, errorMessage }, setFormState, onCancel, id }) => {
    const onChange = ({ target }) => {
        const specialCharacters = /[^\w\dæøåÆØÅ]/;
        if (specialCharacters.test(target.value)) {
            setFormState({
                name: target.value,
                disabled: true,
                errorMessage: 'Special characters and spaces are not allowed.',
            });
        } else {
            setFormState({
                name: target.value,
                disabled: target.value.trim() === '',
                errorMessage: null,
            });
        }
    };

    return (
        <>
            <DialogContent className="tw-mb-4">
                <Form
                    disabled={disabled}
                    onSubmit={onSubmit}
                >
                    <Input
                        autoFocus
                        callToAction
                        value={name}
                        onChange={onChange}
                        error={errorMessage !== null}
                        helperText={<b className="tw-m-1">{errorMessage}</b>}
                        placeholder="Examples: Sales &middot; Development &middot; Server &middot; Workstation &middot; Aarhus &middot; Copenhagen"
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={BUTTON.RAISED}
                    color={BUTTON.PRIMARY}
                    disabled={disabled}
                    onClick={onSubmit}
                >
                    {id ? 'Update' : 'Create'}
                </Button>
                <Button
                    onClick={onCancel}
                    className="tw-mr-4"
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
};

ManageTags.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    device: PropTypes.shape({
        name: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
    }),
    query: PropTypes.shape(),
    totalAffectedByQuery: PropTypes.number,
    onChange: PropTypes.func,
};

export { CreateTag, EditTag, ManageTags };
