import { BUTTON } from '@capasystems/constants';
import {
    Button,
    ButtonBase,
    CopyTextButton,
    Divider,
    Drawer,
    EmptyState,
    Fade,
    Grid,
    Grow,
    Icon,
    IconButton,
    LayoutCenter,
    LayoutCentered,
    LayoutRow,
    Loading,
    Page,
    Snackbar,
    Tab,
    Tabs,
    Tooltip,
} from '@capasystems/ui';
import { Url, isDefined, isUndefined, noop, now } from '@capasystems/utils';
import { AndroidEnrollmentConfiguration, OwnershipEnums, PRODUCT_NAME } from '@thirdparty/constants';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import QRCode from 'qrcode.react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    ActionsDialog,
    BooleanBadge,
    CapaOneLink,
    ConfirmDialog,
    DrawerStepRow,
    Heading,
    JsonViewer,
    PageTitle,
    QueryBuilder,
    RenamingDialog,
    SchemaBuilder,
    TailwindBadge,
    WidgetPaper,
    useAnalytics,
    useAndroidApi,
    useAuthContext,
    useCoreContext,
    useManagementApi,
    useMessageContext,
    useNavigate,
} from '../../../index';

const BUTTON_TEXT = 'Link to Google account';
const STEPS = [
    <b>{BUTTON_TEXT}</b>,
    <div>
        <b>Sign in</b> with a company Google account
    </div>,
    <div>
        Click <b>Get started</b> on the <b>Bring Android to Work</b> page
    </div>,
    <div>
        Enter a <b>Business name</b>
    </div>,
    <div>Enter details about your key contacts</div>,
    <div>
        Read and agree to the{' '}
        <a
            href="https://www.android.com/enterprise/terms"
            target="_blank"
            rel="noreferrer"
            className="tw-font-semibold"
        >
            Managed Google Play agreement
        </a>
    </div>,
    <div>Confirm and Complete Registration</div>,
];

const extraField = {
    enableSystemApplications: 'android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED',
    skipEncryption: 'android.app.extra.PROVISIONING_SKIP_ENCRYPTION',
    enrollDevicesUsingMobileData: 'android.app.extra.PROVISIONING_USE_MOBILE_DATA',
    allowOfflineEnrollment: 'android.app.extra.PROVISIONING_ALLOW_OFFLINE',
    keepScreenOnDuringProvisioning: 'android.app.extra.PROVISIONING_KEEP_SCREEN_ON',
    wifiSSID: 'android.app.extra.PROVISIONING_WIFI_SSID',
    wifiPassword: 'android.app.extra.PROVISIONING_WIFI_PASSWORD',
    wifiSecurityType: 'android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE',
};

const AndroidEnrollment = () => {
    const { androidEnterpriseAccount } = useCoreContext();
    if (androidEnterpriseAccount.id === null) {
        return <SetupAndroidEnterpriseAccount />;
    }
    return <AndroidEnrollmentConfigurationCardList />;
};

const PERSONAL_USAGE_ENUM = {
    ALLOWED: 'PERSONAL_USAGE_ALLOWED',
    DISALLOWED: 'PERSONAL_USAGE_DISALLOWED',
    UNSPECIFIED: 'ALLOW_PERSONAL_USAGE_UNSPECIFIED',
};

const USER_AUTHENTICATION_ENUM = {
    NONE: 'NONE',
    MICROSOFT: 'MICROSOFT',
};

const queryBuilderConfiguration = getDefaultQueryBuilderConfiguration({ placeholder: 'Configuration name' });

const viewAction = {
    id: 'view',
    name: 'View configuration',
};

const renameAction = {
    id: 'rename',
    name: 'Rename',
};

const deleteAction = {
    id: 'delete',
    name: 'Delete',
};

const enrollmentConfigurationActions = [viewAction, renameAction, deleteAction];

const userAuthenticationMethodInfo = {
    [USER_AUTHENTICATION_ENUM.MICROSOFT]: {
        tooltipProps: {
            content: (
                <div>
                    Microsoft authentication is enforced during the enrollment process
                    <br />
                    <br />
                    Requires{' '}
                    <Link
                        to="../../management/integration"
                        className="tw-font-bold tw-text-blue-400"
                    >
                        Management &rarr; Integrations &rarr; Azure AD
                    </Link>{' '}
                    integration
                </div>
            ),
            interactive: true,
            fullWidth: true,
        },
        iconProps: {
            type: 'microsoftColored',
        },
    },
    [USER_AUTHENTICATION_ENUM.NONE]: {
        tooltipProps: {
            content: 'No user authentication during the enrollment process',
        },
        iconProps: {
            type: 'personOffOutlined',
            className: 'tw-text-gray-300',
        },
    },
};

const AndroidEnrollmentConfigurationCardList = () => {
    const androidApi = useAndroidApi();
    const managementApi = useManagementApi();
    const [configurationList, setConfigurationList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const { showErrorMessage } = useMessageContext();
    const [searchTerm, setSearchTerm] = useState('');
    const [configurationMarkedForDelete, setConfigurationMarkedForDelete] = useState({ id: null });
    const [showDrawer, setShowDrawer] = useState(false);
    const [isRenaming, setIsRenaming] = useState(false);
    const [selectedConfiguration, setSelectedConfiguration] = useState({
        extraFields: {},
        data: {},
    });

    const [deleteEnrollmentConfigurationState, setDeleteEnrollmentConfigurationState] = useState({
        open: false,
        isProcessing: false,
        failed: false,
    });

    const [entities, setEntities] = useState({
        groups: [],
        configurations: [],
        applications: [],
        initialized: false,
    });

    const [menuState, setMenuState] = useState({
        open: false,
        anchorEl: null,
        configuration: {
            name: '',
        },
    });

    const queryBuilderRef = useRef({});

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onRename = () => {
        if (isRenaming) {
            setIsRenaming(false);
        } else {
            setIsRenaming(true);
        }
    };
    const onRenameSubmit = (newName) => {
        setIsRenaming(false);
        androidApi
            .renameAndroidEnrollmentConfiguration(menuState.configuration.id, {
                name: newName,
            })
            .then(() => {
                setIsRenaming(false);
                setConfigurationList((configurations) => {
                    return configurations.map((config) => {
                        if (config.id === menuState.configuration.id) {
                            return { ...config, name: newName };
                        }
                        return config;
                    });
                });
            })
            .catch((errorResponse) => {
                setIsRenaming(true);
                showErrorMessage(errorResponse.data.message, { autoHideDuration: 2500 });
            });
    };

    const refreshEnrollmentConfigurationsList = () => {
        setLoading(true);

        androidApi
            .getEnrollmentConfigurations()
            .then((response) => {
                setErrorMessage(null);
                setConfigurationList(
                    response.map((configuration) => {
                        const expired = dayjs(configuration.data?.expirationTimestamp).isBefore(now());
                        const neverExpires = dayjs(configuration.data?.expirationTimestamp).isAfter(dayjs('9999-12-31'));
                        const qrCodeToUse =
                            configuration.userAuthenticationMethod === USER_AUTHENTICATION_ENUM.NONE
                                ? configuration.data.qrCode
                                : configuration.signinDetails.qrCode;
                        const qrCodeAndExtraFieldsAsJSON = {
                            ...(JSON.parse(qrCodeToUse) || {}),
                            ...(configuration.extraFields || {}),
                        };
                        const tokenValue =
                            configuration.userAuthenticationMethod === USER_AUTHENTICATION_ENUM.NONE
                                ? configuration.data.value
                                : configuration.signinDetails.signinEnrollmentToken;
                        const personalUsageText = configuration.data.allowPersonalUsage === PERSONAL_USAGE_ENUM.ALLOWED ? 'Allowed' : 'Disallowed';
                        return {
                            qrCodeAndExtraFieldsAsJSON,
                            tokenValue,
                            expired,
                            neverExpires,
                            personalUsageText,
                            groupIds: [],
                            configurationIds: [],
                            applicationIds: [],
                            ...configuration,
                        };
                    })
                );
            })
            .catch((error) => {
                console.error(error);
                setErrorMessage('Could not get Enrollment configurations');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeDeleteDialog = () => {
        setConfigurationMarkedForDelete((c) => ({ ...c, id: null }));
    };

    const onConfirmDelete = () => {
        setConfigurationMarkedForDelete((c) => ({ ...c, isProcessing: true, anErrorOccurred: false }));
        androidApi
            .deleteAndroidConfiguration(configurationMarkedForDelete.id)
            .then(() => {
                setConfigurationList((c) => {
                    return c.filter((configuration) => configuration.id !== configurationMarkedForDelete.id);
                });
                closeDeleteDialog();
            })
            .catch(() => {
                setConfigurationMarkedForDelete((c) => ({ ...c, isProcessing: false, anErrorOccurred: true }));
            });
    };

    const onSubmit = ([activeLeaf]) => {
        setSearchTerm(activeLeaf ? activeLeaf.value : '');
    };

    const openDrawer = (conf) => () => {
        if (!entities.initialized) {
            Promise.all([managementApi.getGroups(), androidApi.getAndroidConfigurations(), androidApi.getApplications()])
                .then(([groupsResponse, configurationsResponse, applicationsResponse]) => {
                    setEntities({
                        groups: groupsResponse,
                        configurations: configurationsResponse,
                        applications: applicationsResponse,
                        initialized: true,
                    });
                })
                .catch(noop);
        }
        setSelectedConfiguration(conf);
        setShowDrawer(true);
    };

    const deleteEnrollmentConfiguration = (event, requestedFromDashboard = false) => {
        setShowDrawer(false);
        setDeleteEnrollmentConfigurationState({
            open: true,
            isProcessing: false,
            failed: false,
            requestedFromDashboard,
        });
    };

    const editEnrollmentConfiguration = () => {
        navigate.to(`android/enrollment/${selectedConfiguration.id}/edit`);
    };

    const onCancelDeleteEnrollmentConfiguration = () => {
        if (!deleteEnrollmentConfigurationState.requestedFromDashboard) {
            setShowDrawer(true);
        }
        setDeleteEnrollmentConfigurationState({
            open: false,
            isProcessing: false,
            failed: false,
        });
    };

    const onConfirmDeleteEnrollmentConfiguration = () => {
        setDeleteEnrollmentConfigurationState({
            open: true,
            isProcessing: true,
            failed: false,
        });
        androidApi
            .deleteEnrollmentConfiguration(selectedConfiguration.id)
            .then(() => {
                refreshEnrollmentConfigurationsList();
                setDeleteEnrollmentConfigurationState({
                    open: false,
                    isProcessing: true,
                    failed: false,
                });
            })
            .catch(() => {
                setDeleteEnrollmentConfigurationState({
                    open: true,
                    isProcessing: false,
                    failed: true,
                });
            });
    };

    const onActionClick = (action) => {
        closeMenu();
        switch (action.id) {
            case viewAction.id:
                openDrawer(menuState.configuration)();
                break;
            case renameAction.id:
                onRename();
                break;
            case deleteAction.id:
                setSelectedConfiguration(menuState.configuration);
                deleteEnrollmentConfiguration(null, true);
                break;
        }
    };

    useEffect(refreshEnrollmentConfigurationsList, [androidApi]);

    const memoizedConfigurationList = useMemo(() => {
        if (searchTerm) {
            return configurationList.filter((c) => c.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
        }
        return configurationList;
    }, [searchTerm, configurationList]);

    if (errorMessage) {
        return (
            <LayoutCentered>
                <div>
                    <Heading>{errorMessage}</Heading>
                    <Button
                        onClick={refreshEnrollmentConfigurationsList}
                        className="tw-mt-4"
                        color={BUTTON.PRIMARY}
                        variant={BUTTON.RAISED}
                        disabled={loading}
                    >
                        Retry
                    </Button>
                </div>
            </LayoutCentered>
        );
    }

    if (loading) {
        return (
            <LayoutCenter fill>
                <Loading />
            </LayoutCenter>
        );
    }

    if (configurationList.length === 0) {
        return (
            <div className="tw-m-auto tw-h-full">
                <EmptyState
                    entity="Enrollment configuration"
                    onClick={() => navigate.to('android/enrollment/create')}
                    description="An enrollment configuration represents a group of settings that control the behavior of the enrollment process of managed devices"
                />
            </div>
        );
    }

    return (
        <Page title="Enrollment">
            <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-gap-4 tw-p-4">
                <div className="tw-flex tw-justify-end">
                    <QueryBuilder
                        defaultConfiguration={queryBuilderConfiguration}
                        onInit={onSubmit}
                        onSubmit={onSubmit}
                        ref={queryBuilderRef}
                    />
                    <Button
                        noMargin
                        color={BUTTON.PRIMARY}
                        variant={BUTTON.RAISED}
                        onClick={() => navigate.to('android/enrollment/create')}
                        className="tw-ml-4"
                    >
                        New
                    </Button>
                    <RenamingDialog
                        onCancel={onRename}
                        onSubmit={onRenameSubmit}
                        currentName={menuState.configuration.name}
                        open={isRenaming}
                    />
                    <ConfirmDialog
                        onConfirm={onConfirmDelete}
                        onCancel={closeDeleteDialog}
                        open={configurationMarkedForDelete.id !== null}
                        title={`Delete ${configurationMarkedForDelete.name}?`}
                        isProcessing={configurationMarkedForDelete.isProcessing}
                    >
                        {configurationMarkedForDelete.anErrorOccurred && (
                            <b className="tw-mb-4 tw-block tw-text-red-700">Could not delete "{configurationMarkedForDelete.name}". Please try again.</b>
                        )}
                    </ConfirmDialog>
                </div>
                <div className="tw-overflow-auto">
                    {memoizedConfigurationList.length === 0 && (
                        <WidgetPaper
                            headerless
                            className="tw-h-full"
                        >
                            <EmptyState
                                searchTerm={searchTerm}
                                entity="Enrollment configuration"
                                isSearching={searchTerm !== ''}
                                onClick={() => navigate.to('android/enrollment/create')}
                                onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                            />
                        </WidgetPaper>
                    )}
                    <Grid
                        container
                        spacing={2}
                    >
                        {memoizedConfigurationList.map((configuration) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={4}
                                    key={configuration.id}
                                >
                                    <WidgetPaper
                                        className="tw-h-full tw-overflow-auto"
                                        title={configuration.name}
                                        description={configuration.description || <i className="tw-opacity-25">No description available</i>}
                                        truncateHeaderElements
                                        actions={
                                            <IconButton
                                                onClick={(e) => {
                                                    setMenuState({
                                                        open: true,
                                                        anchorEl: e.currentTarget,
                                                        configuration,
                                                        pages: [
                                                            {
                                                                name: 'Edit',
                                                                icon: 'editOutlined',
                                                                url: `android/enrollment/${configuration.id}/edit`,
                                                            },
                                                            {
                                                                name: 'Duplicate',
                                                                icon: 'clone',
                                                                url: `android/enrollment/create?duplicateId=${configuration.id}`,
                                                            },
                                                        ],
                                                    });
                                                }}
                                                noMargin
                                            >
                                                <Icon type="moreVert" />
                                            </IconButton>
                                        }
                                    >
                                        <div>
                                            <Divider light />
                                            <div
                                                className={classNames({
                                                    'tw-grid tw-bg-slate-50/50 tw-text-slate-900': true,
                                                    'tw-grid-cols-1fr-auto-1fr-auto-1fr-auto-1fr': configuration.data.oneTimeOnly,
                                                    'tw-grid-cols-1fr-auto-1fr-auto-1fr': !configuration.data.oneTimeOnly,
                                                })}
                                            >
                                                {configuration.data.allowPersonalUsage === PERSONAL_USAGE_ENUM.ALLOWED && (
                                                    <Tooltip
                                                        content="Managed work profile"
                                                        dark
                                                        extraPadding
                                                        bold
                                                    >
                                                        <div className="tw-p-4">
                                                            <LayoutCentered direction="column">
                                                                <Icon
                                                                    type="workOutlined"
                                                                    className="tw-text-amber-700"
                                                                    size="large"
                                                                />
                                                            </LayoutCentered>
                                                        </div>
                                                    </Tooltip>
                                                )}
                                                {configuration.data.allowPersonalUsage === PERSONAL_USAGE_ENUM.DISALLOWED && (
                                                    <Tooltip
                                                        content="Fully managed device"
                                                        dark
                                                        extraPadding
                                                        bold
                                                    >
                                                        <div className="tw-p-4">
                                                            <LayoutCentered direction="column">
                                                                <Icon
                                                                    type="phoneLock"
                                                                    className="tw-text-cyan-700"
                                                                    size="large"
                                                                />
                                                            </LayoutCentered>
                                                        </div>
                                                    </Tooltip>
                                                )}
                                                <Divider
                                                    orientation="vertical"
                                                    light
                                                />
                                                <Tooltip
                                                    content={<ExpirationInfo configuration={configuration} />}
                                                    dark
                                                    extraPadding
                                                    bold
                                                >
                                                    <div className="tw-px-4 tw-py-5">
                                                        <LayoutCentered direction="column">
                                                            <Icon
                                                                type={classNames({
                                                                    clockAlert: configuration.expired,
                                                                    infinity: configuration.neverExpires,
                                                                    hourglassEmpty: !configuration.neverExpires && !configuration.expired,
                                                                })}
                                                                className={classNames({
                                                                    'tw-text-red-700': configuration.expired,
                                                                    'tw-text-emerald-400': configuration.neverExpires,
                                                                    'tw-text-blue-400': !configuration.neverExpires && !configuration.expired,
                                                                })}
                                                                size="large"
                                                            />
                                                        </LayoutCentered>
                                                    </div>
                                                </Tooltip>
                                                <Divider
                                                    orientation="vertical"
                                                    light
                                                />
                                                <Tooltip
                                                    {...userAuthenticationMethodInfo[configuration.userAuthenticationMethod].tooltipProps}
                                                    dark
                                                    extraPadding
                                                    bold
                                                >
                                                    <div className="tw-px-4 tw-py-5">
                                                        <LayoutCentered direction="column">
                                                            <Icon
                                                                {...userAuthenticationMethodInfo[configuration.userAuthenticationMethod].iconProps}
                                                                size="large"
                                                            />
                                                        </LayoutCentered>
                                                    </div>
                                                </Tooltip>
                                                {configuration.data.oneTimeOnly && (
                                                    <>
                                                        <Divider
                                                            orientation="vertical"
                                                            light
                                                        />
                                                        <Tooltip
                                                            content="One-time only"
                                                            dark
                                                            extraPadding
                                                            bold
                                                        >
                                                            <div className="tw-p-4">
                                                                <LayoutCentered direction="column">
                                                                    <Icon
                                                                        type="biometric"
                                                                        className="tw-text-yellow-600"
                                                                        size="large"
                                                                    />
                                                                </LayoutCentered>
                                                            </div>
                                                        </Tooltip>
                                                    </>
                                                )}
                                            </div>
                                            <Divider light />
                                            <div
                                                className={classNames({
                                                    'tw-grid tw-bg-slate-50/50 tw-text-slate-900': true,
                                                    'tw-grid-cols-1fr-auto-1fr-auto-1fr': true,
                                                })}
                                            >
                                                <div className="tw-p-4">
                                                    <LayoutCentered direction="column">
                                                        <div className="tw-text-2xl tw-font-semibold">{configuration.groupIds.length}</div>
                                                        <div className="tw-mt-1 tw-text-tiny tw-text-slate-500">
                                                            {pluralize('Group', configuration.groupIds.length)}
                                                        </div>
                                                    </LayoutCentered>
                                                </div>
                                                <Divider
                                                    orientation="vertical"
                                                    light
                                                />
                                                <div className="tw-p-4">
                                                    <LayoutCentered direction="column">
                                                        <div className="tw-text-2xl tw-font-semibold">{configuration.configurationIds.length}</div>
                                                        <div className="tw-mt-1 tw-text-tiny tw-text-slate-500">
                                                            {pluralize('Configuration', configuration.configurationIds.length)}
                                                        </div>
                                                    </LayoutCentered>
                                                </div>
                                                <Divider
                                                    orientation="vertical"
                                                    light
                                                />
                                                <div className="tw-p-4">
                                                    <LayoutCentered direction="column">
                                                        <div className="tw-text-2xl tw-font-semibold">{configuration.applicationIds.length}</div>
                                                        <div className="tw-mt-1 tw-text-tiny tw-text-slate-500">
                                                            {pluralize('Application', configuration.applicationIds.length)}
                                                        </div>
                                                    </LayoutCentered>
                                                </div>
                                            </div>
                                            <Divider light />
                                            <Button
                                                fullWidth
                                                noMargin
                                                onClick={openDrawer(configuration)}
                                                size="large"
                                                color={BUTTON.PRIMARY}
                                                className="tw-rounded-none tw-py-4 tw-text-xs"
                                            >
                                                View
                                                <Icon
                                                    type="arrowRight"
                                                    size="small"
                                                    className="tw-ml-2"
                                                />
                                            </Button>
                                        </div>
                                    </WidgetPaper>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <ActionsDialog
                        open={menuState.open}
                        anchorEl={menuState.anchorEl}
                        onClose={closeMenu}
                        category="Endrollment configuration"
                        title={menuState.configuration.name}
                        pages={menuState.pages}
                        actions={enrollmentConfigurationActions}
                        onActionClick={onActionClick}
                    />
                    <ConfirmDialog
                        onConfirm={onConfirmDeleteEnrollmentConfiguration}
                        onCancel={onCancelDeleteEnrollmentConfiguration}
                        title={`Delete ${selectedConfiguration.name}?`}
                        isProcessing={deleteEnrollmentConfigurationState.isProcessing}
                        open={deleteEnrollmentConfigurationState.open}
                    >
                        {deleteEnrollmentConfigurationState.failed && (
                            <b className="tw-mb-4 tw-block tw-text-red-700">Could not delete "{selectedConfiguration.name}". Please try again.</b>
                        )}
                    </ConfirmDialog>

                    <Drawer
                        open={showDrawer}
                        anchor="right"
                        PaperProps={{
                            style: {
                                minWidth: '20vw',
                                maxWidth: '100vw',
                                width: 860,
                            },
                        }}
                        onClose={() => {
                            setShowDrawer(false);
                        }}
                    >
                        <div className="tw-grid tw-h-full tw-grid-rows-auto-auto-1fr">
                            <div className="tw-grid tw-grid-cols-auto-1fr-auto tw-gap-4 tw-px-4 tw-pb-2 tw-pt-4">
                                <div>
                                    <LayoutCentered>
                                        {selectedConfiguration.data.allowPersonalUsage === PERSONAL_USAGE_ENUM.ALLOWED && (
                                            <Icon
                                                type="workOutlined"
                                                className="tw-h-12 tw-w-12"
                                            />
                                        )}
                                        {selectedConfiguration.data.allowPersonalUsage === PERSONAL_USAGE_ENUM.DISALLOWED && (
                                            <Icon
                                                type="phoneLock"
                                                className="tw-h-12 tw-w-12"
                                            />
                                        )}
                                    </LayoutCentered>
                                </div>
                                <div>
                                    <PageTitle
                                        category="Enrollment configuration"
                                        description={selectedConfiguration.description}
                                    >
                                        {selectedConfiguration.name}
                                    </PageTitle>
                                </div>
                                <div>
                                    <Button
                                        noMargin
                                        variant={selectedConfiguration.expired ? BUTTON.RAISED : BUTTON.TEXT}
                                        onClick={editEnrollmentConfiguration}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        noMargin
                                        color={BUTTON.DANGER}
                                        variant={selectedConfiguration.expired ? BUTTON.RAISED : BUTTON.TEXT}
                                        onClick={deleteEnrollmentConfiguration}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                            <SelectedConfigurationContent
                                selectedConfiguration={selectedConfiguration}
                                entities={entities}
                            />
                        </div>
                    </Drawer>
                </div>
            </div>
        </Page>
    );
};

const SelectedConfigurationContent = ({ selectedConfiguration, entities }) => {
    const [selectedTab, setSelectedTab] = useState('qrcode');
    const [selectedOwnershipTab, setSelectedOwnershipTab] = useState(OwnershipEnums.COMPANY_OWNED);

    if (selectedConfiguration.expired || isUndefined(selectedConfiguration.id)) {
        return (
            <div className="tw-mt-12">
                <LayoutCentered>
                    <TailwindBadge
                        color="amber"
                        className="tw-gap-1"
                        size="small"
                    >
                        <Icon type="infoOutlined" />
                        <span>This enrollment configuration has expired</span>
                    </TailwindBadge>
                </LayoutCentered>
            </div>
        );
    }

    return (
        <>
            <div>
                <Tabs
                    value={selectedTab}
                    centered
                    onChange={(e, tabValue) => setSelectedTab(tabValue)}
                    variant="fullWidth"
                    className="tw-rounded-none"
                    onRails
                >
                    <Tab
                        disableRipple
                        label="QR code"
                        value="qrcode"
                    />
                    <Tab
                        disableRipple
                        label="Token"
                        value="token"
                    />
                    <Tab
                        disableRipple
                        label="Zero Touch"
                        value="zerotouch"
                    />
                    <Tab
                        disableRipple
                        label="Samsung Knox"
                        value="samsungknox"
                    />
                    <Tab
                        disableRipple
                        label="Summary"
                        value="summary"
                    />
                </Tabs>
                {selectedConfiguration.data.allowPersonalUsage === PERSONAL_USAGE_ENUM.ALLOWED && (selectedTab === 'qrcode' || selectedTab === 'token') && (
                    <div className="tw-mt-4 tw-px-6">
                        <Tabs
                            value={selectedOwnershipTab}
                            onChange={(e, tabValue) => setSelectedOwnershipTab(tabValue)}
                            variant="fullWidth"
                            pills
                            className="tw-bg-gray-100"
                            classes={{
                                root: 'tw-py-1.5 tw-p-2',
                            }}
                        >
                            <Tab
                                disableRipple
                                label={OwnershipEnums.COMPANY_OWNED}
                                value={OwnershipEnums.COMPANY_OWNED}
                            />
                            <Tab
                                disableRipple
                                label={OwnershipEnums.PERSONALLY_OWNED}
                                value={OwnershipEnums.PERSONALLY_OWNED}
                            />
                        </Tabs>
                    </div>
                )}
            </div>
            <div className="tw-h-full tw-overflow-auto">
                {selectedTab === 'summary' && (
                    <ConfigurationSettingsTab
                        configuration={selectedConfiguration}
                        entities={entities}
                    />
                )}
                {selectedTab === 'qrcode' && (
                    <QRCodeEnrollmentDescription
                        configuration={selectedConfiguration}
                        selectedOwnershipTab={selectedOwnershipTab}
                    />
                )}
                {selectedTab === 'token' && (
                    <TokenBasedEnrollmentDescription
                        configuration={selectedConfiguration}
                        selectedOwnershipTab={selectedOwnershipTab}
                    />
                )}
                {selectedTab === 'zerotouch' && <ZeroTouchEnrollmentDescription configuration={selectedConfiguration} />}
                {selectedTab === 'samsungknox' && <SamsungKnoxEnrollmentDescription configuration={selectedConfiguration} />}
            </div>
        </>
    );
};

const ConfigurationGridColumns = ({ title, value }) => {
    return (
        <div className="tw-flex tw-items-center tw-gap-4 tw-rounded-lg tw-px-6 tw-py-3">
            <BooleanBadge value={value || false} />
            <div className="tw-font-semibold">{title}</div>
        </div>
    );
};

const ConfigurationSettingsTab = ({ configuration, entities }) => {
    return (
        <div>
            <div className="tw-mt-6 tw-px-6">
                {configuration.data.allowPersonalUsage === PERSONAL_USAGE_ENUM.ALLOWED && (
                    <PageTitle
                        description="A separate work profile is created on the device for corporate apps and data. You have complete management control of the work profile, but do not have visibility and control of personal apps, data, and activities."
                        className="tw-mb-2"
                        descriptionClassName="tw-leading-loose tw-text-slate-700"
                        color="inherit"
                    >
                        Managed work profile
                    </PageTitle>
                )}
                {configuration.data.allowPersonalUsage === PERSONAL_USAGE_ENUM.DISALLOWED && (
                    <PageTitle
                        className="tw-mb-2"
                        description="For company-owned devices that are exclusively used for work and not for personal purposes."
                        descriptionClassName="tw-leading-loose tw-text-slate-700"
                    >
                        Fully managed device
                    </PageTitle>
                )}
            </div>

            <div>
                <ConfigurationGridColumns
                    title="Use mobile data during provisioning"
                    value={configuration.extraFields[extraField.enrollDevicesUsingMobileData]}
                />
                <ConfigurationGridColumns
                    title="Skip encryption"
                    value={configuration.extraFields[extraField.skipEncryption]}
                />
                <ConfigurationGridColumns
                    title="Enable system apps during provisioning"
                    value={configuration.extraFields[extraField.enableSystemApplications]}
                />
                <ConfigurationGridColumns
                    title="One-time only"
                    value={configuration.data.oneTimeOnly}
                />
                <ConfigurationGridColumns
                    title="Allow offline enrollment"
                    value={configuration.extraFields[extraField.allowOfflineEnrollment]}
                />
                <ConfigurationGridColumns
                    title="Keep screen on during provisioning"
                    value={configuration.extraFields[extraField.keepScreenOnDuringProvisioning]}
                />
                <ConfigurationGridColumns
                    title="Use wifi network during provisioning"
                    value={isDefined(configuration.extraFields[extraField.wifiSSID]) && configuration.extraFields[extraField.wifiSSID] !== ''}
                />
            </div>
            <div className="tw-grid tw-grid-cols-1fr-auto tw-gap-6 tw-p-6 tw-font-semibold">
                <Divider
                    light
                    className="tw-col-span-2"
                />
                <div>Groups</div>
                <div className="tw-text-right">
                    <div className="tw-grid tw-grid-cols-1 tw-gap-2">
                        {configuration.groupIds.length > 0 ? (
                            entities.groups
                                .filter((entityGroup) => configuration.groupIds.includes(entityGroup.id))
                                .map((entityGroup) => (
                                    <CapaOneLink
                                        to={`management/group/${entityGroup.id}/android/endpoints`}
                                        className="tw-block"
                                    >
                                        {entityGroup.name}
                                    </CapaOneLink>
                                ))
                        ) : (
                            <i className="tw-block tw-font-semibold tw-text-neutral-400">No groups assigned</i>
                        )}
                    </div>
                </div>
                <Divider
                    light
                    className="tw-col-span-2"
                />
                <div>Configurations</div>
                <div className="tw-text-right">
                    <div className="tw-grid tw-grid-cols-1 tw-gap-2">
                        {configuration.configurationIds.length > 0 ? (
                            entities.configurations
                                .filter((entityConfiguration) => configuration.configurationIds.includes(entityConfiguration.id))
                                .map((entityConfiguration) => (
                                    <CapaOneLink
                                        to={`android/configuration/${entityConfiguration.id}/membership`}
                                        className="tw-block"
                                    >
                                        {entityConfiguration.name}
                                    </CapaOneLink>
                                ))
                        ) : (
                            <i className="tw-block tw-font-semibold tw-text-neutral-400">No configurations assigned</i>
                        )}
                    </div>
                </div>
                <Divider
                    light
                    className="tw-col-span-2"
                />
                <div>Applications</div>
                <div className="tw-text-right">
                    <div className="tw-grid tw-grid-cols-1 tw-gap-2">
                        {configuration.applicationIds.length > 0 ? (
                            entities.applications
                                .filter((entityApplication) => configuration.applicationIds.includes(entityApplication.id))
                                .map((entityApplication) => (
                                    <CapaOneLink
                                        to={`android/application/${entityApplication.id}/membership`}
                                        className="tw-block"
                                    >
                                        {entityApplication.name}
                                    </CapaOneLink>
                                ))
                        ) : (
                            <i className="tw-block tw-font-semibold tw-text-neutral-400">No applications assigned</i>
                        )}
                    </div>
                </div>
                <Divider
                    light
                    className="tw-col-span-2"
                />
                <div>User authentication</div>
                <div
                    className={classNames('tw-block tw-text-right tw-font-semibold', {
                        'tw-text-neutral-400': configuration.userAuthenticationMethod === USER_AUTHENTICATION_ENUM.NONE,
                    })}
                >
                    {userAuthenticationMethodInfo[configuration.userAuthenticationMethod].tooltipProps.content}
                </div>
            </div>
        </div>
    );
};

const QRCodeEnrollmentDescription = ({ configuration, selectedOwnershipTab }) => {
    if (selectedOwnershipTab === OwnershipEnums.PERSONALLY_OWNED) {
        return (
            <Fade in>
                <div className="tw-p-6">
                    <PageTitle>Recommended</PageTitle>
                    <DrawerStepRow requirements>Android 12.0 and later</DrawerStepRow>
                    <PageTitle className="tw-mt-8">Requirements</PageTitle>
                    <DrawerStepRow requirements>
                        {configuration.extraFields['android.app.extra.PROVISIONING_USE_MOBILE_DATA'] ? 'Android 10.0 and later' : 'Android 8.0 and later'}
                    </DrawerStepRow>
                    <DrawerStepRow requirements>
                        Download and install the{' '}
                        <a
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.work.clouddpc"
                            target="_blank"
                            rel="noreferrer"
                            className="tw-font-bold tw-text-blue-500 hover:tw-text-blue-600"
                        >
                            &nbsp;Android Device Policy&nbsp;
                        </a>{' '}
                        app from the Google Play Store
                    </DrawerStepRow>

                    <PageTitle className="tw-mt-8">Procedure</PageTitle>
                    <DrawerStepRow badgeContent={1}>Open the Android Device Policy app</DrawerStepRow>
                    <DrawerStepRow badgeContent={2}>Allow camera access</DrawerStepRow>
                    <DrawerStepRow badgeContent={3}>Scan the QR code to enroll and provision the device</DrawerStepRow>
                    <div className="tw-mb-12 tw-mt-12">
                        <LayoutCentered>
                            <QRCode
                                value={JSON.stringify(configuration.qrCodeAndExtraFieldsAsJSON)}
                                size={200}
                            />
                        </LayoutCentered>
                    </div>
                    <DrawerStepRow badgeContent={4}>Follow the on-screen prompts to complete the enrollment process</DrawerStepRow>
                    <OneTimeOnlyInfoBox oneTimeOnly={configuration.data.oneTimeOnly} />
                </div>
            </Fade>
        );
    }
    return (
        <div className="tw-p-6">
            <Fade in>
                <div>
                    <PageTitle>Recommended</PageTitle>
                    <DrawerStepRow requirements>Android 12.0 and later</DrawerStepRow>
                    <PageTitle className="tw-mt-8">Requirements</PageTitle>
                    <DrawerStepRow requirements>
                        {configuration.extraFields['android.app.extra.PROVISIONING_USE_MOBILE_DATA'] ? 'Android 10.0 and later' : 'Android 8.0 and later'}
                    </DrawerStepRow>
                    <DrawerStepRow requirements>
                        Fresh out of the box or{' '}
                        <a
                            href="https://support.google.com/android/answer/6088915?hl=en"
                            target="_blank"
                            rel="noreferrer"
                            className="tw-underline-offset-4 hover:tw-underline"
                        >
                            reset to factory settings
                        </a>
                    </DrawerStepRow>
                    <PageTitle className="tw-mt-8">Procedure</PageTitle>
                    <DrawerStepRow badgeContent={1}>Power on your wiped device</DrawerStepRow>
                    <DrawerStepRow badgeContent={2}>
                        Wait for the Welcome screen to be displayed
                        <div className="tw-mt-2 tw-block tw-text-tiny">The welcome screen might differ from device to device</div>
                    </DrawerStepRow>
                    <DrawerStepRow badgeContent={3}>Change language if necessary</DrawerStepRow>
                    <DrawerStepRow badgeContent={4}>
                        Tap the Welcome screen <b className="tw-font-bold">repeatedly</b> until the QR reader is launched
                        <div className="tw-mt-2 tw-block tw-text-tiny">You must tap six times or more in the same spot.</div>
                        <div className="tw-mt-2 tw-block tw-text-tiny">Devices running Android 8.0, will be prompted to install a QR reader.</div>
                    </DrawerStepRow>
                    <DrawerStepRow badgeContent={5}>Scan the QR code to enroll and provision the device</DrawerStepRow>
                    <div className="tw-mb-12 tw-mt-12">
                        <LayoutCentered>
                            <QRCode
                                value={JSON.stringify(configuration.qrCodeAndExtraFieldsAsJSON)}
                                size={200}
                            />
                        </LayoutCentered>
                    </div>
                    <DrawerStepRow badgeContent={6}>Follow the on-screen prompts to complete the enrollment process</DrawerStepRow>
                    <OneTimeOnlyInfoBox oneTimeOnly={configuration.data.oneTimeOnly} />
                </div>
            </Fade>
        </div>
    );
};

const TokenBasedEnrollmentDescription = ({ configuration, selectedOwnershipTab }) => {
    if (selectedOwnershipTab === OwnershipEnums.PERSONALLY_OWNED) {
        return (
            <Fade in>
                <div className="tw-p-6">
                    <PageTitle>Recommended</PageTitle>
                    <DrawerStepRow requirements>Android 12.0 and later</DrawerStepRow>
                    <PageTitle className="tw-mt-8">Requirements</PageTitle>
                    <DrawerStepRow requirements>
                        {configuration.extraFields['android.app.extra.PROVISIONING_USE_MOBILE_DATA'] ? 'Android 10.0 and later' : 'Android 8.0 and later'}
                    </DrawerStepRow>
                    <DrawerStepRow requirements>
                        Download and install the{' '}
                        <a
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.work.clouddpc"
                            target="_blank"
                            rel="noreferrer"
                            className="tw-font-bold tw-text-blue-500 hover:tw-text-blue-600"
                        >
                            &nbsp;Android Device Policy&nbsp;
                        </a>{' '}
                        app from the Google Play Store
                    </DrawerStepRow>
                    <PageTitle className="tw-mt-8">Procedure</PageTitle>
                    <DrawerStepRow badgeContent={1}>Open the Android Device Policy app</DrawerStepRow>
                    <DrawerStepRow badgeContent={2}>Enter the following code</DrawerStepRow>
                    <LayoutCentered>
                        <div className="tw-text-center">
                            <LayoutRow
                                verticalAlign="center"
                                className="tw-mt-6 tw-rounded-full tw-bg-slate-900 tw-px-6 tw-py-3 tw-text-white"
                            >
                                <b
                                    className="tw-font-bold tw-tracking-widest"
                                    style={{ letterSpacing: 1 }}
                                >
                                    {configuration.tokenValue}
                                </b>
                            </LayoutRow>
                        </div>
                    </LayoutCentered>
                    <DrawerStepRow badgeContent={3}>Follow the on-screen prompts to complete the enrollment process</DrawerStepRow>
                    <OneTimeOnlyInfoBox oneTimeOnly={configuration.data.oneTimeOnly} />
                </div>
            </Fade>
        );
    }

    return (
        <div className="tw-p-6">
            <Fade in>
                <div>
                    <PageTitle>Recommended</PageTitle>
                    <DrawerStepRow requirements>Android 12.0 and later</DrawerStepRow>
                    <PageTitle className="tw-mt-8">Requirements</PageTitle>
                    <DrawerStepRow requirements>
                        {configuration.extraFields['android.app.extra.PROVISIONING_USE_MOBILE_DATA'] ? 'Android 10.0 and later' : 'Android 8.0 and later'}
                    </DrawerStepRow>
                    <DrawerStepRow requirements>
                        Fresh out of the box or{' '}
                        <a
                            href="https://support.google.com/android/answer/6088915?hl=en"
                            target="_blank"
                            rel="noreferrer"
                            className="tw-underline-offset-4 hover:tw-underline"
                        >
                            reset to factory settings
                        </a>
                    </DrawerStepRow>
                    <PageTitle className="tw-mt-8">Procedure</PageTitle>
                    <DrawerStepRow badgeContent={1}>Power on your wiped device</DrawerStepRow>
                    <DrawerStepRow badgeContent={2}>
                        Wait for the welcome screen to be displayed
                        <div className="tw-mt-2 tw-block tw-text-tiny">The welcome screen might differ from device to device</div>
                    </DrawerStepRow>
                    <DrawerStepRow badgeContent={3}>Select language and continue</DrawerStepRow>
                    <DrawerStepRow badgeContent={4}>Connect to your wifi</DrawerStepRow>
                    <DrawerStepRow badgeContent={5}>
                        On the Google sign-in screen, enter <b className="tw-font-bold">afw#setup</b> and continue
                    </DrawerStepRow>
                    <DrawerStepRow badgeContent={6}>Accept the Terms and conditions</DrawerStepRow>
                    <DrawerStepRow badgeContent={7}>
                        On the <b className="tw-font-bold">Enroll this device</b> screen, choose to enter the token manually
                    </DrawerStepRow>
                    <LayoutCentered>
                        <div className="tw-text-center">
                            <LayoutRow
                                verticalAlign="center"
                                className="tw-mt-6 tw-rounded-full tw-bg-slate-900 tw-px-6 tw-py-3 tw-text-white"
                            >
                                <b
                                    className="tw-font-bold tw-tracking-widest"
                                    style={{ letterSpacing: 1 }}
                                >
                                    {configuration.tokenValue}
                                </b>
                            </LayoutRow>
                        </div>
                    </LayoutCentered>
                    <DrawerStepRow badgeContent={8}>Follow the on-screen prompts to complete the enrollment process</DrawerStepRow>
                    <OneTimeOnlyInfoBox oneTimeOnly={configuration.data.oneTimeOnly} />
                </div>
            </Fade>
        </div>
    );
};

const ZeroTouchEnrollmentDescription = ({ configuration }) => {
    return (
        <div className="tw-p-6">
            <OneTimeOnlyChecker
                serviceName="Android Zero Touch"
                oneTimeOnly={configuration.data.oneTimeOnly}
            >
                <Fade in>
                    <div>
                        <PageTitle>Requirements</PageTitle>
                        <DrawerStepRow requirements>Zero-touch compatible device</DrawerStepRow>
                        <DrawerStepRow requirements>
                            Fresh out of the box or{' '}
                            <a
                                href="https://support.google.com/android/answer/6088915?hl=en"
                                target="_blank"
                                rel="noreferrer"
                                className="tw-underline-offset-4 hover:tw-underline"
                            >
                                reset to factory settings
                            </a>
                        </DrawerStepRow>
                        <PageTitle className="tw-mt-8">Procedure</PageTitle>
                        <DrawerStepRow badgeContent={1}>
                            Open the{' '}
                            <a
                                className="tw-text-sky-600"
                                href="https://partner.android.com/zerotouch"
                                target="_blank"
                                rel="noreferrer"
                            >
                                ZeroTouch portal
                            </a>
                            . You might need to sign in.
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={2}>
                            Click <b className="tw-font-bold">Configurations</b> in the navigation panel.
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={3}>
                            Click Add (the + icon) in the <b className="tw-font-bold">Configurations</b> table to create a new configuration
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={4}>
                            In <b className="tw-font-bold">"EMM DPC"</b> you must select <b className="tw-font-bold">"Android Device Policy"</b>.
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={5}>
                            Paste the following JSON snippet into the <b className="tw-font-bold">"DPC extras"</b> field
                        </DrawerStepRow>
                        <div className="tw-ml-10 tw-mt-8">
                            <WidgetPaper
                                title="DPC extras"
                                color="slate"
                                actions={
                                    <CopyTextButton
                                        text={JSON.stringify(configuration.qrCodeAndExtraFieldsAsJSON)}
                                        variant={BUTTON.RAISED}
                                        noMargin
                                        className="tw-rounded-full"
                                    >
                                        Copy JSON
                                    </CopyTextButton>
                                }
                                dark
                            >
                                <JsonViewer
                                    json={configuration.qrCodeAndExtraFieldsAsJSON}
                                    colorControlled
                                    className="tw-text-tiny"
                                    square
                                />
                            </WidgetPaper>
                        </div>
                        <DrawerStepRow badgeContent={6}>Fill in the remaining fields and Save the configuration</DrawerStepRow>
                        <DrawerStepRow badgeContent={7}>
                            Click <b className="tw-font-bold">Devices</b> in the navigation panel.
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={8}>
                            Find the devices you want to apply the configuration to — using its IMEI or serial number.
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={9}>
                            Set <b className="tw-font-bold">Configuration</b> to the configuration you want to apply.
                        </DrawerStepRow>
                    </div>
                </Fade>
            </OneTimeOnlyChecker>
        </div>
    );
};

const SamsungKnoxEnrollmentDescription = ({ configuration }) => {
    return (
        <div className="tw-p-6">
            <OneTimeOnlyChecker
                serviceName="Samsung Knox"
                oneTimeOnly={configuration.data.oneTimeOnly}
            >
                <Fade in>
                    <div>
                        <PageTitle className="tw-mt-1">Procedure</PageTitle>
                        <DrawerStepRow badgeContent={1}>
                            Open the{' '}
                            <a
                                className="tw-text-sky-600"
                                href="https://central.samsungknox.com/itadmin/kme/profiles"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Samsung Knox Admin Portal
                            </a>
                            . You might need to sign in.
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={2}>
                            Click <b className="tw-font-bold">Profiles</b> in the navigation panel.
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={3}>
                            Click <b className="tw-font-bold">CREATE PROFILE</b> above the <b className="tw-font-bold">Profiles</b> table
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={4}>
                            Select <b className="tw-font-bold">ANDROID ENTERPRISE</b> as profile type.
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={5}>
                            Select <b className="tw-font-bold">Other</b> in <b className="tw-font-bold">Pick your MDM</b>.
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={6}>
                            <WidgetPaper
                                title="MDM Agent APK"
                                color="slate"
                                actions={
                                    <CopyTextButton
                                        text={configuration.qrCodeAndExtraFieldsAsJSON['android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION']}
                                        variant={BUTTON.RAISED}
                                        noMargin
                                        className="tw-rounded-full"
                                    >
                                        Copy link
                                    </CopyTextButton>
                                }
                                dark
                            >
                                <pre className="tw-mx-4 tw-mb-4 tw-text-tiny tw-font-bold">
                                    {configuration.qrCodeAndExtraFieldsAsJSON['android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION']}
                                </pre>
                            </WidgetPaper>
                        </DrawerStepRow>

                        <DrawerStepRow badgeContent={7}>
                            Wait for application verification. Then click <b className="tw-font-bold">CONTINUE</b>.
                        </DrawerStepRow>

                        <DrawerStepRow badgeContent={8}>
                            <WidgetPaper
                                title="Custom JSON Data (as defined by MDM)"
                                color="slate"
                                actions={
                                    <CopyTextButton
                                        text={JSON.stringify(configuration.qrCodeAndExtraFieldsAsJSON['android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE'])}
                                        variant={BUTTON.RAISED}
                                        noMargin
                                        className="tw-rounded-full"
                                    >
                                        Copy JSON
                                    </CopyTextButton>
                                }
                                dark
                            >
                                <JsonViewer
                                    json={configuration.qrCodeAndExtraFieldsAsJSON['android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE']}
                                    colorControlled
                                    className="tw-text-tiny"
                                    square
                                    preElementClassName="tw-mt-0"
                                />
                            </WidgetPaper>
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={9}>
                            {configuration.extraFields[extraField?.enableSystemApplications] ? (
                                <div>
                                    This enrollment configuration recommends that you choose <b className="tw-font-bold">Leave all system apps enabled</b> to
                                    ensure all apps are enabled and available to the profile.
                                </div>
                            ) : (
                                <div>
                                    This enrollment configuration recommends that you choose <b className="tw-font-bold">Disable system applications</b>
                                </div>
                            )}
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={10}>
                            Click <b className="tw-font-bold">CREATE</b>
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={11}>
                            Click <b className="tw-font-bold">Devices</b> in the navigation panel.
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={12}>Assign the profile to devices</DrawerStepRow>
                    </div>
                </Fade>
            </OneTimeOnlyChecker>
        </div>
    );
};

const OneTimeOnlyChecker = ({ oneTimeOnly, serviceName, children }) => {
    if (oneTimeOnly) {
        return (
            <div className="tw-mt-12">
                <LayoutCentered>
                    <TailwindBadge
                        color="amber"
                        className="tw-gap-1"
                        size="small"
                    >
                        <Icon type="infoOutlined" />
                        <span>{serviceName} is not supported when using the 'One-time only' setting</span>
                    </TailwindBadge>
                </LayoutCentered>
            </div>
        );
    }
    return children;
};

const OneTimeOnlyInfoBox = ({ oneTimeOnly }) => {
    if (oneTimeOnly) {
        return (
            <div className="tw-mt-12">
                <LayoutCentered>
                    <TailwindBadge
                        color="amber"
                        className="tw-gap-1"
                        size="small"
                    >
                        <Icon type="infoOutlined" />
                        <span>This enrollment configuration can only be used once and should be deleted after the enrollment process</span>
                    </TailwindBadge>
                </LayoutCentered>
            </div>
        );
    }
    return null;
};

const ExpirationInfo = ({ configuration }) => {
    if (configuration.expired) {
        return <span>Expired {dayjs(configuration.data?.expirationTimestamp).fromNow()}</span>;
    }
    if (configuration.neverExpires) {
        return 'Never expires';
    }
    return <span>Expires {dayjs(configuration.data?.expirationTimestamp).fromNow()}</span>;
};

const SetupAndroidEnterpriseAccount = () => {
    const analytics = useAnalytics();
    const { selectedOrganization } = useAuthContext();
    const [creatingSignupUrl, setCreatingSignupUrl] = useState(false);
    const androidApi = useAndroidApi();

    const onCreateAccount = () => {
        if (!creatingSignupUrl) {
            setCreatingSignupUrl(true);
            androidApi
                .createSignupUrl()
                .then((response) => {
                    window.location.href = response.url;
                })
                .catch((error) => {
                    setCreatingSignupUrl(false);
                    console.log(`Could not create signup url:`, error);
                });
        }
    };

    return (
        <Page
            title="Enrollment"
            onInit={analytics.sendPageView}
        >
            <div className="tw-grid tw-h-full tw-grid-rows-auto-1fr tw-bg-undraw-sync tw-bg-cover tw-bg-top">
                <div className="tw-bg-gradient-to-r tw-from-indigo-500 tw-to-purple-500 tw-shadow-sm">
                    <LayoutCenter>
                        <Grow in>
                            <div className="tw-px-16 tw-py-16 tw-text-center tw-text-white ">
                                <Heading bold>
                                    Hi <span className="tw-font-bold">{selectedOrganization.name}</span> 👋
                                </Heading>
                                <Heading
                                    bold
                                    className="tw-mt-2"
                                    subheading
                                >
                                    To get started you must link your Android Enterprise account to <span className="tw-font-bold">{PRODUCT_NAME}</span>.
                                </Heading>
                                <br />

                                <Button
                                    onClick={onCreateAccount}
                                    variant={BUTTON.OUTLINED}
                                    color={BUTTON.INHERIT}
                                    size="large"
                                    className={classNames('tw-mt-4', {
                                        'tw-text-white tw-opacity-25': creatingSignupUrl,
                                    })}
                                    disabled={creatingSignupUrl}
                                >
                                    Link to Google account
                                    {creatingSignupUrl && (
                                        <Loading
                                            size={12}
                                            className="tw-ml-4 tw-text-white"
                                        />
                                    )}
                                </Button>
                            </div>
                        </Grow>
                    </LayoutCenter>
                </div>
                <div className="tw-h-full tw-bg-gradient-to-r tw-from-sky-900/99 tw-to-sky-800/99 tw-text-white tw-shadow-sm">
                    <div className="tw-mx-auto tw-w-full tw-max-w-screen-md tw-px-6 tw-py-4">
                        <div className="tw-p-16">
                            <h1 className="tw-mb-1">Get started</h1>
                            <h1 className="tw-mb-6 tw-text-sm ">Follow these steps to link your Android Enterprise account to {PRODUCT_NAME}</h1>
                            {STEPS.map((step, index) => (
                                <LayoutRow
                                    salign="space-between"
                                    className="tw-mt-6"
                                    verticalAlign="center"
                                    key={index}
                                >
                                    <div>
                                        <div className="tw-mr-6 tw-h-10 tw-w-10 tw-rounded-full tw-bg-sky-900 tw-bg-opacity-95 tw-text-sm">
                                            <LayoutCentered>{index + 1}</LayoutCentered>
                                        </div>
                                    </div>
                                    {index === 0 ? (
                                        <ButtonBase
                                            disabled={creatingSignupUrl}
                                            onClick={onCreateAccount}
                                            color={BUTTON.PRIMARY}
                                            className={classNames({
                                                'tw-text-white': true,
                                                'tw-opacity-50': creatingSignupUrl,
                                            })}
                                        >
                                            {step}
                                        </ButtonBase>
                                    ) : (
                                        step
                                    )}
                                </LayoutRow>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

const AndroidCreateEnrollmentConfiguration = () => {
    const androidApi = useAndroidApi();
    const managementApi = useManagementApi();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const propertiesBuilderRef = useRef({});
    const [failedToInitialize, setFailedToInitialize] = useState(false);
    const [isProcessingRequest, setIsProcessingRequest] = useState(false);
    const { enrollmentId } = useParams();

    const onSubmit = () => {
        const {
            name = '',
            description = '',
            groupIds = [],
            configurationIds = [],
            applicationIds = [],
            wifiSSID = '',
            wifiPassword = '',
            wifiSecurityType,
            oneTimeOnly,
            duration,
            enableSystemApplications,
            skipEncryption,
            useWifiDuringProvisioning,
            allowOfflineEnrollment,
            keepScreenOnDuringProvisioning,
            userAuthenticationMethod,
            // allowedDomains = ""
        } = propertiesBuilderRef.current;
        setErrorMessage(null);
        if (name.trim() === '') {
            setErrorMessage('Name is required');
        } else {
            setIsProcessingRequest(true);
            const configurationObject = {
                name,
                description,
                data: {
                    oneTimeOnly,
                    allowPersonalUsage: propertiesBuilderRef.current.allowPersonalUsage ? PERSONAL_USAGE_ENUM.ALLOWED : PERSONAL_USAGE_ENUM.DISALLOWED,
                    duration,
                },
                groupIds,
                configurationIds,
                applicationIds,
                extraFields: {
                    [extraField.enableSystemApplications]: enableSystemApplications,
                    [extraField.skipEncryption]: skipEncryption,
                    [extraField.allowOfflineEnrollment]: allowOfflineEnrollment,
                    [extraField.keepScreenOnDuringProvisioning]: keepScreenOnDuringProvisioning,
                },
                userAuthenticationMethod,
                // allowedDomains
            };
            if (useWifiDuringProvisioning && wifiSSID.trim() !== '') {
                configurationObject.extraFields[extraField.wifiSSID] = wifiSSID;
                configurationObject.extraFields[extraField.wifiPassword] = wifiPassword;
                configurationObject.extraFields[extraField.wifiSecurityType] = wifiSecurityType;
            }
            if (propertiesBuilderRef.current.enrollDevicesUsingMobileData) {
                configurationObject.extraFields[extraField.enrollDevicesUsingMobileData] = true;
            }
            if (isDefined(enrollmentId)) {
                androidApi
                    .updateAndroidEnrollmentConfiguration(enrollmentId, configurationObject)
                    .then(navigateToEnrollmentPage)
                    .catch((errorResponse) => {
                        setIsProcessingRequest(false);
                        setErrorMessage(errorResponse.data.message);
                        console.log(errorResponse);
                    });
            } else {
                androidApi
                    .createEnrollmentConfigurations(configurationObject)
                    .then(navigateToEnrollmentPage)
                    .catch((errorResponse) => {
                        setIsProcessingRequest(false);
                        setErrorMessage(errorResponse.data.message);
                        console.log(errorResponse);
                    });
            }
        }
    };

    const navigateToEnrollmentPage = () => {
        navigate.to(`android/enrollment`);
    };

    const fetchData = () => {
        const promises = [managementApi.getGroups(), androidApi.getAndroidConfigurations(), androidApi.getApplications()];
        const duplicateConfigurationId = Url.getString('duplicateId');
        if (duplicateConfigurationId) {
            promises.push(androidApi.getEnrollmentConfiguration(duplicateConfigurationId));
        }
        if (isDefined(enrollmentId)) {
            promises.push(androidApi.getEnrollmentConfiguration(enrollmentId));
        }
        Promise.all(promises)
            .then(([groupsResponse, configurationsResponse, applicationsResponse, duplicateResponse]) => {
                if (duplicateResponse) {
                    propertiesBuilderRef.current.name = duplicateResponse.name;
                    propertiesBuilderRef.current.description = duplicateResponse.description;
                    propertiesBuilderRef.current.duration = duplicateResponse.data.duration;
                    propertiesBuilderRef.current.allowPersonalUsage = duplicateResponse.data.allowPersonalUsage === PERSONAL_USAGE_ENUM.ALLOWED;
                    propertiesBuilderRef.current.enrollDevicesUsingMobileData = duplicateResponse.extraFields[extraField.enrollDevicesUsingMobileData];
                    propertiesBuilderRef.current.skipEncryption = duplicateResponse.extraFields[extraField.skipEncryption];
                    propertiesBuilderRef.current.enableSystemApplications = duplicateResponse.extraFields[extraField.enableSystemApplications];
                    propertiesBuilderRef.current.oneTimeOnly = duplicateResponse.data.oneTimeOnly;
                    propertiesBuilderRef.current.groupIds = duplicateResponse.groupIds;
                    propertiesBuilderRef.current.configurationIds = duplicateResponse.configurationIds;
                    propertiesBuilderRef.current.applicationIds = duplicateResponse.applicationIds;
                    propertiesBuilderRef.current.userAuthenticationMethod = duplicateResponse.userAuthenticationMethod;
                    propertiesBuilderRef.current.allowOfflineEnrollment = duplicateResponse.extraFields[extraField.allowOfflineEnrollment];
                    propertiesBuilderRef.current.keepScreenOnDuringProvisioning = duplicateResponse.extraFields[extraField.keepScreenOnDuringProvisioning];
                    if (duplicateResponse.extraFields[extraField.wifiSSID]) {
                        propertiesBuilderRef.current.useWifiDuringProvisioning = true;
                        propertiesBuilderRef.current.wifiSSID = duplicateResponse.extraFields[extraField.wifiSSID];
                        propertiesBuilderRef.current.wifiPassword = duplicateResponse.extraFields[extraField.wifiPassword];
                        propertiesBuilderRef.current.wifiSecurityType = duplicateResponse.extraFields[extraField.wifiSecurityType];
                    }
                }
                if (isDefined(enrollmentId)) {
                    AndroidEnrollmentConfiguration.schema.properties.oneTimeOnly.disabled = true;
                    AndroidEnrollmentConfiguration.schema.properties.allowPersonalUsage.disabled = true;
                    AndroidEnrollmentConfiguration.schema.properties.duration.disabled = true;
                    AndroidEnrollmentConfiguration.schema.properties.userAuthenticationMethod.disabled = true;
                } else {
                    AndroidEnrollmentConfiguration.schema.properties.oneTimeOnly.disabled = false;
                    AndroidEnrollmentConfiguration.schema.properties.allowPersonalUsage.disabled = false;
                    AndroidEnrollmentConfiguration.schema.properties.duration.disabled = false;
                    AndroidEnrollmentConfiguration.schema.properties.userAuthenticationMethod.disabled = false;
                }
                AndroidEnrollmentConfiguration.schema.properties.groupIds.items.options = groupsResponse.map(({ id, name }) => ({ id, name }));
                AndroidEnrollmentConfiguration.schema.properties.configurationIds.items.options = configurationsResponse.map(({ id, name }) => ({ id, name }));
                AndroidEnrollmentConfiguration.schema.properties.applicationIds.items.options = applicationsResponse.map(({ id, name, installTypeName }) => ({
                    id,
                    name,
                    secondaryContent: installTypeName,
                }));
                setLoading(false);
            })
            .catch(() => {
                setFailedToInitialize(true);
            });
    };

    useEffect(fetchData, [androidApi]);

    if (failedToInitialize) {
        return (
            <LayoutCentered>
                <div>
                    <PageTitle
                        category="Something went wrong"
                        className="tw-mb-4"
                    >
                        Failed to get dependencies
                    </PageTitle>
                    <Button
                        variant={BUTTON.RAISED}
                        color={BUTTON.PRIMARY}
                        onClick={() => {
                            setFailedToInitialize(false);
                            fetchData();
                        }}
                    >
                        Retry
                    </Button>
                    <Button
                        color={BUTTON.PRIMARY}
                        onClick={navigateToEnrollmentPage}
                    >
                        Cancel
                    </Button>
                </div>
            </LayoutCentered>
        );
    }

    return (
        <Page title={isDefined(enrollmentId) ? 'Enrollment · Edit' : 'Enrollment · Create'}>
            <div className="tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-rows-auto-1fr tw-gap-4 tw-p-4">
                <LayoutRow align="end">
                    <div className="tw-ml-16">
                        <Button
                            color={BUTTON.PRIMARY}
                            variant={BUTTON.RAISED}
                            onClick={onSubmit}
                            disabled={isProcessingRequest}
                        >
                            {isProcessingRequest ? (isDefined(enrollmentId) ? 'Saving' : 'Creating') : isDefined(enrollmentId) ? 'Save' : 'Create'}
                        </Button>
                        <Button
                            noMargin
                            onClick={navigateToEnrollmentPage}
                            variant={BUTTON.OUTLINED}
                        >
                            Cancel
                        </Button>
                    </div>
                </LayoutRow>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={errorMessage !== null}
                    onClose={() => setErrorMessage(null)}
                    autoHideDuration={3000}
                    message={<b>{errorMessage}</b>}
                />
                <div className="tw-overflow-auto">
                    <WidgetPaper
                        className="tw-h-full tw-overflow-auto"
                        headerless
                    >
                        <SchemaBuilder
                            key="AndroidEnrollmentConfiguration"
                            properties={AndroidEnrollmentConfiguration.schema.properties}
                            schema={AndroidEnrollmentConfiguration.schema}
                            ref={propertiesBuilderRef.current}
                            loading={loading}
                        />
                    </WidgetPaper>
                </div>
            </div>
        </Page>
    );
};

export { AndroidCreateEnrollmentConfiguration, AndroidEnrollment };
