const windowsApplication = {
    type: {
        customApp: {
            id: 'customapps',
            name: 'Custom Application',
            icon: 'customappsIcon',
        },
        capaPacks: {
            id: 'capapacks',
            name: 'Repository Application',
            icon: 'capasystems',
        },
    },
    releaseStatus: {
        testing: {
            id: 201,
            name: 'Testing',
        },
        released: {
            id: 200,
            name: 'Released',
        },
        retired: {
            id: 400,
            name: 'Retired',
        },
    },
    architecture: {
        '32-bit': {
            id: '32-bit',
            name: '32-bit',
        },
        '64-bit': {
            id: '64-bit',
            name: '64-bit',
        },
        both: {
            id: 'both',
            name: 'Auto',
        },
    },
    language: {
        danish: {
            name: 'Danish',
            id: 'Danish',
        },
        english: {
            name: 'English',
            id: 'English',
        },
        system: {
            name: 'System language',
            id: 'System',
        },
    },
    urlKey: {
        confirmCreation: 'confirmCreation',
    },
};

export { windowsApplication };
