import { BUTTON, SORT_DIRECTION, TOOLTIP } from '@capasystems/constants';
import {
    Button,
    Column,
    Ellipsis,
    EmptyState,
    Icon,
    IconButton,
    Page,
    Tooltip,
    VirtualizedTable,
    useParams,
    virtualizedTableColumnPropTypes,
} from '@capasystems/ui';
import { Url, noop } from '@capasystems/utils';
import { ENDPOINT_TYPE, NOT_AVAILABLE } from '@thirdparty/constants';
import {
    ActionsDialog,
    BatteryLevel,
    CapaOneLink,
    RenamingDialog,
    TailwindBadge,
    UserCellRenderer,
    UserManagementAssignUserToEndpoint,
    useAppleApi,
    useAppleEndpointsSocket,
    useColumnPicker,
    useManagementApi,
    useNavigate,
} from '@thirdparty/ui';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { useCallback, useEffect, useRef, useState } from 'react';

const SORT_BY = 'sortBy';
const SORT_DIR = 'sortDirection';

const AppleEndpointListMobile = ({ searchTerm }) => {
    const columnPicker = useColumnPicker({
        id: 'apple-endpoint-list-mobile',
        lockedColumns: ['Name'],
    });
    const { modelType } = useParams();
    const navigate = useNavigate();
    const appleApi = useAppleApi();
    const managementApi = useManagementApi();
    const [dirtyCount, setDirtyCount] = useState(0);

    const manageUserAction = {
        id: 'userManagement',
        name: 'User management',
    };
    const renameAction = {
        id: 'rename',
        name: 'Rename',
    };
    const [dimensions, setDimensions] = useState({});
    const [isRenaming, setIsRenaming] = useState(false);
    const [isManagingUser, setIsManagingUser] = useState(false);
    const groupActions = [renameAction, manageUserAction];

    const [menuState, setMenuState] = useState({
        open: false,
        anchorEl: null,
        rowData: { name: '' },
    });

    const pagingRef = useRef({
        isFirstPage: true,
        isLastPage: undefined,
        totalRowCount: 0,
        isPureDeviceNameSearch: false,
    });

    const queryBuilderRef = useRef({});

    const [loading, setLoading] = useState(true);

    const [endpointList, setEndpointList] = useState([]);

    const [sortingState, setSortingState] = useState({
        sortBy: Url.getString(SORT_BY, 'name'),
        sortDirection: Url.getString(SORT_DIR, SORT_DIRECTION.ASC),
        pageNumber: 0,
        pageSize: 50,
    });

    useEffect(() => {
        fetchData();
    }, [searchTerm, sortingState]);

    const fetchData = () => {
        appleApi.cancel();
        setLoading(true);
        appleApi
            .getEndpoints({
                modelType,
                search: searchTerm,
                sortBy: sortingState.sortBy,
                sortDirection: sortingState.sortDirection,
                pageNumber: sortingState.pageNumber,
                pageSize: sortingState.pageSize,
            })
            .then((response) => {
                pagingRef.current.totalRowCount = response.count;
                if (sortingState.pageNumber === 0) {
                    setEndpointList(response.content);
                } else {
                    setEndpointList((currentList) => [...currentList, ...response.content]);
                }
            })
            .catch((_) => {
                // ignore
            })
            .finally(() => {
                setLoading(false);
            });
        setDirtyCount(0);
    };

    useAppleEndpointsSocket(
        useCallback(
            ({ documentId, fullDocument, updateDescription }, { updateOperation, insertOperation, deleteOperation }) => {
                if (updateOperation) {
                    if (updateDescription.updatedFields.user && fullDocument.user) {
                        managementApi
                            .getUser(fullDocument.user)
                            .then((userResponse) => {
                                setEndpointList((currentList) =>
                                    currentList.map((device) => {
                                        if (device.id === documentId) {
                                            return {
                                                ...fullDocument,
                                                id: documentId,
                                                user: userResponse,
                                            };
                                        }
                                        return device;
                                    })
                                );
                            })

                            .catch((error) => {
                                console.error('Can not find user', error); //TODO propper respond
                            });
                    } else {
                        setEndpointList((currentList) =>
                            currentList.map((device) => {
                                if (device.id === documentId) {
                                    return {
                                        ...fullDocument,
                                        id: documentId,
                                        user: fullDocument.user ? device.user : undefined,
                                    };
                                }
                                return device;
                            })
                        );
                    }
                } else if (insertOperation) {
                    setDirtyCount((c) => c + 1);
                } else if (deleteOperation) {
                    setEndpointList((currentList) => {
                        return currentList.filter((device) => device.id !== documentId);
                    });
                }
            },
            [sortingState]
        )
    );

    const onScrollToBottom = () => {
        setSortingState((currentPagingState) => ({
            ...currentPagingState,
            pageNumber: currentPagingState.pageNumber + 1,
        }));
    };

    const closeMenu = () => {
        setMenuState((c) => ({
            ...c,
            open: false,
        }));
    };

    const onActionClick = (action) => {
        closeMenu();
        switch (action.id) {
            case renameAction.id:
                onRename();
                break;
            case manageUserAction.id:
                setIsManagingUser(true);
                break;
        }
    };

    const onRename = () => {
        if (isRenaming) {
            setIsRenaming(false);
        } else {
            closeMenu();
            setIsRenaming(true);
        }
    };

    const onRenameSubmit = (newName) => {
        setIsRenaming(false);
        appleApi
            .renameEndpoint(menuState.rowData._id, {
                name: newName,
            })
            .then(noop)
            .catch(() => {
                setIsRenaming(true);
            });
    };

    const forceRefresh = () => {
        setSortingState((currentPagingState) => ({ ...currentPagingState }));
    };

    const onOrderByChange = ({ sortBy, sortDirection }) => {
        Url.set(SORT_BY, sortBy);
        Url.set(SORT_DIR, sortDirection);
        setSortingState((currentPagingState) => ({
            ...currentPagingState,
            pageNumber: 0,
            sortBy,
            sortDirection,
        }));
    };

    return (
        <Page title="Mobile Endpoints">
            <RenamingDialog
                onCancel={onRename}
                onSubmit={onRenameSubmit}
                currentName={menuState.rowData.name}
                open={isRenaming}
            />
            <ActionsDialog
                open={menuState.open}
                anchorEl={menuState.anchorEl}
                onClose={closeMenu}
                category="Endpoint"
                title={menuState.rowData.name}
                pages={menuState.pages}
                actions={groupActions}
                onActionClick={onActionClick}
            />
            <UserManagementAssignUserToEndpoint
                open={isManagingUser}
                onSubmit={(user) => {
                    setIsManagingUser(false);
                }}
                endpointType={ENDPOINT_TYPE.APPLE}
                endpoint={menuState.rowData}
                onClose={() => setIsManagingUser(false)}
            />
            <VirtualizedTable
                entity="endpoint"
                items={endpointList}
                onResize={setDimensions}
                isLoading={loading}
                onScrollToBottom={onScrollToBottom}
                totalRowCount={pagingRef.current.totalRowCount}
                showRowCount
                sort={onOrderByChange}
                sortBy={sortingState.sortBy}
                sortDirection={sortingState.sortDirection}
                disableHeader={endpointList.length === 0 && searchTerm === ''}
                noRowsRenderer={() => {
                    const isSearching = searchTerm !== '';
                    return (
                        <EmptyState
                            searchTerm={searchTerm}
                            isSearching={isSearching}
                            entity="endpoint"
                            description="Get started by setting up an enrollment configuration."
                            onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                            onClick={isSearching ? undefined : () => navigate.to('apple/enrollment')}
                            buttonText="Enrollment"
                        />
                    );
                }}
                actions={
                    dirtyCount > 0 && (
                        <Tooltip
                            content={`${pluralize('new endpoint', dirtyCount, true)} detected`}
                            open
                            arrow
                            classes={{
                                defaults: 'tw-mb-2 tw-px-4 tw-py-2 tw-font-semibold tw-bg-rose-500',
                                arrow: 'tw-text-rose-500',
                            }}
                        >
                            <Button
                                size={BUTTON.SMALL}
                                onClick={forceRefresh}
                            >
                                Refresh
                            </Button>
                        </Tooltip>
                    )
                }
                columnPicker={columnPicker}
            >
                <Column
                    minWidth={32}
                    maxWidth={32}
                    dataKey="id"
                    label=""
                    disableSort
                    cellRenderer={({ rowData }) => {
                        return (
                            <IconButton
                                onClick={(e) => {
                                    setMenuState({
                                        open: true,
                                        anchorEl: e.currentTarget,
                                        rowData,
                                        pages: [
                                            {
                                                name: 'Software',
                                                url: `apple/endpoint/${rowData.id}/inventory/software`,
                                                icon: 'categoryOutlined',
                                            },
                                            {
                                                name: 'Hardware',
                                                url: `apple/endpoint/${rowData.id}/inventory/hardware`,
                                                icon: 'memory',
                                            },
                                            {
                                                name: 'Configurations',
                                                url: `apple/endpoint/${rowData.id}/configurations`,
                                                icon: 'androidConfiguration',
                                            },
                                            {
                                                name: 'Applications',
                                                url: `apple/endpoint/${rowData.id}/applications`,
                                                icon: 'androidApplication',
                                            },
                                            {
                                                name: 'Groups',
                                                url: `apple/endpoint/${rowData.id}/groups`,
                                                icon: 'androidGroup',
                                            },
                                        ],
                                    });
                                }}
                                noMargin
                            >
                                <Icon type="moreVert" />
                            </IconButton>
                        );
                    }}
                />
                <Column
                    minWidth={160}
                    dataKey="name"
                    label="Name"
                    cellRenderer={({ rowData }) => {
                        return <CapaOneLink to={`apple/endpoint/${rowData.id}/dashboard`}>{rowData.name}</CapaOneLink>;
                    }}
                />
                <Column
                    minWidth={160}
                    dataKey="user.name"
                    label="User"
                    cellRenderer={({ rowData }) => {
                        return (
                            <UserCellRenderer
                                user={rowData.user}
                                onAddUserClick={() => {
                                    setMenuState((c) => ({
                                        ...c,
                                        rowData,
                                    }));
                                    setIsManagingUser(true);
                                }}
                            />
                        );
                    }}
                />
                <Column
                    minWidth={160}
                    maxWidth={240}
                    dataKey="updatedAt"
                    label="Last check-in"
                    cellRenderer={({ rowData }) => {
                        return rowData.lastCheckin ? <Ellipsis>{dayjs(rowData?.lastCheckin).fromNow()}</Ellipsis> : 'Never';
                    }}
                />
                <Column
                    minWidth={96}
                    maxWidth={96}
                    dataKey="data.BatteryLevel"
                    label="Battery"
                    cellRenderer={({ rowData }) => {
                        const batteryPercentage = rowData.data.BatteryLevel * 100;
                        return <BatteryLevel percent={batteryPercentage} />;
                    }}
                />
                <Column
                    disableSort
                    minWidth={136}
                    maxWidth={160}
                    dataKey="serial_number"
                    label="Serial Number"
                />
                <Column
                    disableSort
                    minWidth={160}
                    maxWidth={160}
                    dataKey="IMEI"
                    label="IMEI"
                    cellRenderer={({ rowData }) => (
                        <Ellipsis className={rowData.data?.IMEI ? '' : 'tw-text-gray-300'}>{rowData.data?.IMEI || NOT_AVAILABLE}</Ellipsis>
                    )}
                />
                <Column
                    disableSort
                    minWidth={112}
                    maxWidth={112}
                    dataKey=""
                    justify={virtualizedTableColumnPropTypes.justify.end}
                    cellRenderer={({ rowData }) => {
                        if (rowData?.isKioskMode) {
                            return (
                                <Tooltip
                                    content="Device is in Kiosk Mode"
                                    position={TOOLTIP.POSITION.TOP_END}
                                    extraPadding
                                    bold
                                    dark
                                >
                                    <TailwindBadge
                                        size="small"
                                        color="cyan"
                                        className="tw-gap-1"
                                        dark
                                        noShadow
                                    >
                                        <Icon
                                            type="kioskMode"
                                            className="tw-text-sm"
                                        />
                                        <span>Kiosk Mode</span>
                                    </TailwindBadge>
                                </Tooltip>
                            );
                        } else if (rowData.data?.IsSupervised) {
                            return (
                                <Tooltip
                                    content="This device is currently in Supervised Mode"
                                    position={TOOLTIP.POSITION.TOP_END}
                                    extraPadding
                                    dark
                                    bold
                                >
                                    <TailwindBadge
                                        size="small"
                                        color="indigo"
                                        noShadow
                                    >
                                        Supervised
                                    </TailwindBadge>
                                </Tooltip>
                            );
                        } else if (!rowData.data?.IsSupervised) {
                            return (
                                <Tooltip
                                    content="This device is currently in Unsupervised Mode"
                                    position={TOOLTIP.POSITION.TOP_END}
                                    extraPadding
                                    dark
                                    bold
                                >
                                    <TailwindBadge
                                        size="small"
                                        color="purple"
                                        noShadow
                                    >
                                        Unsupervised
                                    </TailwindBadge>
                                </Tooltip>
                            );
                        }
                    }}
                />
            </VirtualizedTable>
        </Page>
    );
};

export { AppleEndpointListMobile };
