import { now } from '@capasystems/utils';
import { SESSION_STORAGE_KEY } from '@thirdparty/constants';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCoreContext } from './useCoreContext/useCoreContext';

const useRefState = (defaultRefValue) => {
    const [, setTimestamp] = useState(now());
    const ref = useRef(defaultRefValue);
    const delayRef = useRef(null);

    const refreshComponent = (delay = 0) => {
        clearTimeout(delayRef.current);
        if (delay > 0) {
            delayRef.current = setTimeout(() => {
                setTimestamp(now());
            }, delay);
        } else {
            setTimestamp(now());
        }
    };

    useEffect(() => () => clearTimeout(delayRef.current));

    return [ref, refreshComponent];
};

const useForceRefresh = () => {
    const [, forceRefresh] = useState(0);

    const forceRefreshCallback = useCallback(() => {
        forceRefresh((c) => c + 1);
    }, []);

    return forceRefreshCallback;
};

const useColumnPicker = ({ id, lockedColumns = [], defaultHiddenColumns = [] }) => {
    const { userProfile, updateUserProfile } = useCoreContext();
    const columnPickerRef = useRef(userProfile.columnPickers.find((columnPicker) => columnPicker.id === id) || { hiddenColumns: defaultHiddenColumns }); // If the user profile does not contain the column picker, use the default hidden columns.

    const setHiddenColumns = (hiddenColumns) => {
        columnPickerRef.current.hiddenColumns = hiddenColumns;
        updateUserProfile({
            columnPickers: [
                ...userProfile.columnPickers.filter((columnPicker) => columnPicker.id !== id),
                {
                    id,
                    hiddenColumns,
                },
            ],
        });
    };

    if (lockedColumns.length === 0) {
        // Developer must provide at least one locked column to avoid empty tables.
        return undefined;
    }

    return {
        id,
        hiddenColumns: columnPickerRef.current.hiddenColumns,
        lockedColumns, // Columns without label prop or empty labels will automatically be locked.
        defaultHiddenColumns,
        setHiddenColumns,
    };
};

const useCurrentUnsavedChanges = () => {
    const navigate = useNavigate();
    const forceRefresh = useForceRefresh();
    const nameRef = useRef(null);
    const current = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY.UNSAVED_CHANGES));
    if (current !== null && current.location.pathname !== window.location.pathname) {
        nameRef.current = current.name || 'Unknown';
        return {
            name: nameRef.current,
            showWarning: true,
            onDiscardChanges: () => {
                sessionStorage.removeItem(SESSION_STORAGE_KEY.UNSAVED_CHANGES);
                forceRefresh();
            },
            onKeepEditing: () => {
                navigate(current.location.pathname + current.location.search, { replace: true });
            },
        };
    }
    return {
        showWarning: false,
        name: nameRef.current,
        onDiscardChanges: () => null,
        onKeepEditing: () => null,
    };
};

const useUnsavedChanges = () => {
    const current = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY.UNSAVED_CHANGES));
    const [initial, setInitial] = useState(current);

    useEffect(() => {
        const beforeUnloadHandler = (event) => {
            if (sessionStorage.getItem(SESSION_STORAGE_KEY.UNSAVED_CHANGES) !== null) {
                event.preventDefault(); // Warn before closing the tab.
                event.returnValue = true; // Included for legacy support, e.g. Chrome/Edge < 119
            }
        };

        window.addEventListener('beforeunload', beforeUnloadHandler);

        return () => {
            window.removeEventListener('beforeunload', beforeUnloadHandler);
        };
    }, []);

    const [memoizedSet, memoizedClear, memoizedReset] = useMemo(() => {
        /** The name is displayed in the Unsaved Changes Detected dialog. */
        const set = (name = null, data) => {
            sessionStorage.setItem(
                SESSION_STORAGE_KEY.UNSAVED_CHANGES,
                JSON.stringify({
                    name,
                    data, // Can be any type of data, but objects are most likely.
                    location: {
                        pathname: window.location.pathname,
                        search: window.location.search,
                    },
                })
            );
        };

        /** Use when the user Cancel and after Create / Update to prevent unsaved changes warning */
        const clear = () => {
            sessionStorage.removeItem(SESSION_STORAGE_KEY.UNSAVED_CHANGES);
        };

        /** Use after using the inital data. */
        const reset = () => {
            clear();
            setInitial(null);
        };
        return [set, clear, reset];
    }, []);

    return [initial, memoizedSet, memoizedClear, memoizedReset];
};

export { useColumnPicker, useCurrentUnsavedChanges, useForceRefresh, useRefState, useUnsavedChanges };
