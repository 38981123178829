import { coreMessage } from '@capasystems/constants';
import { now } from '@capasystems/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, LayoutCenter, Loading } from '../../index';
import './saving-changes.scss';
const ICON_SIZE = 64;

export const SavingChanges = ({
    loading,
    errorMessage = null,
    loadingMessage = coreMessage.savingChanges,
    successMessage = coreMessage.saved,
    minLoadingTime = 750,
    minSuccessTime = 750,
    onExited = () => null,
    className,
    errorTitle = coreMessage.anErrorOccurred,
    errorActions,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [entered, setEntered] = useState(false);
    const loadingStarted = useRef(0);
    const timeoutRef = useRef(null);

    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    useEffect(() => {
        if (loading) {
            setIsOpen(true);
            setShowLoading(true);
            loadingStarted.current = now();
        } else if (loadingStarted.current > 0) {
            const waitingTime = Math.max(0, loadingStarted.current - now() + minLoadingTime);
            loadingStarted.current = 0;

            const onFinishedWaiting = () => {
                setShowLoading(false);
                if (errorMessage === null) {
                    timeoutRef.current = setTimeout(() => {
                        setIsOpen(false);
                    }, minSuccessTime);
                }
            };
            if (waitingTime > 0) {
                timeoutRef.current = setTimeout(onFinishedWaiting, waitingTime);
            } else {
                onFinishedWaiting();
            }
        }
    }, [loading]);

    const closeDialog = () => setIsOpen(!!showLoading); // Only allow close with Esc after loading has finished
    const onEntered = () => {
        loadingStarted.current = now();
        setEntered(true);
    };

    const handleExited = () => {
        setEntered(false);
        onExited();
    };

    return (
        <Dialog
            open={isOpen}
            onEntered={onEntered}
            onExited={handleExited}
            confirm
            onClose={closeDialog}
            fullScreen={errorMessage === null || showLoading}
            className={classNames(className, 'cs-saving-changes')}
            transitionDuration={0}
        >
            <DialogContent>
                {entered && (
                    <LayoutCenter direction="column">
                        {showLoading && (
                            <React.Fragment>
                                <Loading
                                    size={ICON_SIZE}
                                    thickness={2}
                                    color="inherit"
                                />
                                {loadingMessage && <Message>{loadingMessage}</Message>}
                            </React.Fragment>
                        )}
                        {!showLoading && (
                            <React.Fragment>
                                <Icon
                                    type={errorMessage === null ? 'checkmark' : 'info'}
                                    color={errorMessage === null ? 'inherit' : 'error'}
                                    style={{
                                        fontSize: ICON_SIZE,
                                        animation: `cs-zoom-in ${minLoadingTime > 0 ? Math.min(minSuccessTime, 750) : 0}ms`,
                                    }}
                                />
                                {errorMessage === null && <Message>{successMessage}</Message>}
                            </React.Fragment>
                        )}
                    </LayoutCenter>
                )}
            </DialogContent>
            {!showLoading && errorMessage !== null && (
                <React.Fragment>
                    <DialogTitle>{errorTitle}</DialogTitle>
                    <DialogContent className="tw-font-medium">{errorMessage}</DialogContent>
                    <DialogActions>
                        {errorActions}
                        <Button onClick={closeDialog}>{coreMessage.close}</Button>
                    </DialogActions>
                </React.Fragment>
            )}
        </Dialog>
    );
};

const Message = (props) => (
    // eslint-disable-next-line jsx-a11y/heading-has-content
    <h2
        style={{
            marginTop: ICON_SIZE / 2,
            marginBottom: 0,
        }}
        {...props}
    />
);

SavingChanges.propTypes = {
    loading: PropTypes.bool.isRequired,
    loadingMessage: PropTypes.node,
    successMessage: PropTypes.node,
    errorTitle: PropTypes.node,
    errorMessage: PropTypes.node,
    minLoadingTime: PropTypes.number,
    minSuccessTime: PropTypes.number,
    onExited: PropTypes.func,
    className: PropTypes.string,
    errorActions: PropTypes.node,
};

export default SavingChanges;
