export const COMPANY_NAME = 'CapaSystems';

export const PRODUCT_NAME = 'CapaOne';

export const BASE_ORGANIZATION_ROUTE = '/organization/:organizationId';

export const STATUS_CODE = {
    USER_PROFILE_NOT_INITIALIZED: 409,
    DEPENDENCIES: 409, // Delete not allowed, due to dependencies to other parts.
};
export const LOG_LEVEL = {
    DEBUG: 'DEBUG',
    INFO: 'INFO',
    WARN: 'WARN',
    ERROR: 'ERROR',
};

export const AZURE = {
    SYNC_STATUS: {
        NEVER_SYNCED: 'NEVER_SYNCED',
        SYNC_IN_PROGRESS: 'SYNC_IN_PROGRESS',
        FAILED: 'FAILED',
        SUCCESS: 'SUCCESS',
    },
};

export const NOT_AVAILABLE = 'N/A';

export const NONE = 'None';

export const ENDPOINT_TYPE = { ANDROID: 'ANDROID', APPLE: 'APPLE', WINDOWS: 'WINDOWS' };

export const linkingMethod = {
    direct: {
        name: 'Assigned Directly',
        id: 'Assigned direct',
    },
    group: {
        name: 'Assigned via Group',
        id: 'Assigned via group',
    },
    groupAndDirect: {
        name: 'Assigned via group and directly',
        id: 'Assigned via group and direct',
    },
};

export const SESSION_STORAGE_KEY = {
    UNSAVED_CHANGES: 'unsavedChanges',
};
