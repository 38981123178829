/* eslint-disable linebreak-style */
import MUIDialog, { DialogProps as MUIDialogProps } from '@mui/material/Dialog';
import { default as classNames, default as classnames } from 'classnames';
import React from 'react';

const dialogClasses = {
    root: 'cs-dialog',
    container: 'cs-dialog-container',
    paper: 'cs-dialog-content',
    paperFullScreen: 'cs-dialog-fullscreen',
};

type DialogProps = MUIDialogProps & {
    confirm?: boolean;
    size?: MUIDialogProps['maxWidth'];
    disableBackdropClick?: boolean;
    unmountOnExit?: boolean;
    blurBackdrop?: boolean;
    onEnter?: () => void;
    onEntering?: () => void;
    onEntered?: () => void;
    onExit?: () => void;
    onExiting?: () => void;
    onExited?: () => void;
};

/** Dialog component */
export const Dialog: React.FC<DialogProps> = ({
    open,
    confirm = false,
    size = 'sm',
    onEnter = () => null,
    onEntering = () => null,
    onEntered = () => null,
    onClose = () => null,
    onExit = () => null,
    onExiting = () => null,
    onExited = () => null,
    className,
    disableBackdropClick = false,
    unmountOnExit = false,
    blurBackdrop = false,
    fullScreen = false,
    ...otherProps
}) => {
    const handleOnClose: DialogProps['onClose'] = (event, reason) => {
        if (disableBackdropClick || (confirm && reason === 'backdropClick')) {
            // ignore
        } else {
            onClose(event, reason);
        }
    };

    return (
        <MUIDialog
            classes={dialogClasses}
            slotProps={{
                root: classNames('cs-backdrop', {
                    'tw-backdrop-blur-md': confirm || disableBackdropClick || blurBackdrop,
                }),
            }}
            fullWidth
            maxWidth={size}
            open={open}
            className={classnames(className, {
                'cs-confirm-dialog': confirm,
            })}
            onClose={handleOnClose}
            TransitionProps={{
                onEnter: onEnter,
                onEntering,
                onEntered: onEntered,
                onExit: onExit,
                onExiting,
                onExited: onExited,
                unmountOnExit,
            }}
            fullScreen={fullScreen}
            {...otherProps}
        />
    );
};

export default Dialog;
