/* eslint-disable react-hooks/exhaustive-deps */
import { BASE_ORGANIZATION_ROUTE } from '@thirdparty/constants';
import { useCallback } from 'react';
import { useNavigate as useCoreNavigate } from 'react-router-dom';
import useAuthContext from '../useAuthContext/useAuthContext';

export const useNavigate = () => {
    const navigate = useCoreNavigate();
    const { selectedOrganization } = useAuthContext();

    const to = (path, useReplace = false) => {
        navigate(appendBaseURL(path), { replace: useReplace });
    };

    const appendBaseURL = useCallback((route) => {
        const base = BASE_ORGANIZATION_ROUTE.replace(':organizationId', selectedOrganization.id);
        if (route) {
            return base + '/' + route;
        }
        return base;
    }, []);

    return {
        to,
        appendBaseURL,
    };
};

export default useNavigate;
