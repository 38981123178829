import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import React, { Ref } from 'react';
import { DISABLED_CLASS, FOCUSED_CLASS, getRootClasses, LABEL_CLASS, ROOT_CLASS_ICON_BUTTON } from '../button/button.constants';

const getIconButtonClasses = (color: string, variant: string, size: string, noMargin: boolean) => ({
    root: getRootClasses(ROOT_CLASS_ICON_BUTTON, color, variant, size, noMargin),
    disabled: DISABLED_CLASS,
    label: LABEL_CLASS,
    focusVisible: FOCUSED_CLASS,
});

export type IconButtonProps = Omit<MuiIconButtonProps, 'color'> & {
    color?: 'primary' | 'danger' | 'default' | 'success' | 'inherit';
    noMargin?: boolean;
    variant?: 'outlined' | 'contained' | 'text';
};

/** Button component. Ref must be forwarded to work with tooltip. */
export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
    ({ color = 'default', noMargin = false, size = 'medium', variant = 'text', disableRipple = false, disableFocusRipple = false, ...otherProps }, ref) => (
        <MuiIconButton
            classes={getIconButtonClasses(color, variant, size, noMargin)}
            size={size}
            ref={ref as Ref<HTMLButtonElement>}
            disableRipple={disableRipple}
            disableFocusRipple={disableFocusRipple}
            {...otherProps}
        />
    )
);

export default IconButton;
