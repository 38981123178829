import { isBeta, PRODUCT } from '@capasystems/constants';
import { formatDate } from '@capasystems/utils';
import classNames from 'classnames';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { Button, Dialog, Icon, LayoutRow, NavLink, NavLinkProps, Tooltip, TrialUpgradeDialog } from '../../index';

export type CapaOneBrandingProps = NavLinkProps & {
    contracts?: any[];
    organizationId: number;
    isParentOrganization?: boolean;
    showBetaFlag?: boolean;
};

const CapaOneBranding: React.FC<CapaOneBrandingProps> = ({ to = '/', contracts = [], isParentOrganization = false, showBetaFlag = false }) => {
    const [trialVersions] = useState<any[]>(() =>
        contracts
            .filter((contract) => contract.trial === true && contract.status === PRODUCT.STATUS.ACTIVATED)
            .sort((contractA, contractB) => new Date(contractA.endDate).getTime() - new Date(contractB.endDate).getTime())
            .map((contract) => {
                const iconType =
                    classNames({
                        capaoneReliability: contract.productId === PRODUCT.ID.RELIABILITY,
                        capaoneAdminOnDemand: contract.productId === PRODUCT.ID.ADMIN_ON_DEMAND,
                        capaoneUpdater: contract.productId === PRODUCT.ID.SOFTWARE_PATCHING,
                        capaDrivers: contract.productId === PRODUCT.ID.DRIVERS,
                        android: contract.productId === PRODUCT.ID.ANDROID_ENTERPRISE,
                    }) || 'infoOutlined';
                return {
                    ...contract,
                    iconType,
                };
            })
    );

    const [upgradeDialog, setUpgradeDialog] = useState({ open: false });

    const upgradeDialogOnClose = () => {
        setUpgradeDialog({ ...upgradeDialog, open: false });
    };

    return (
        <div className="tw-mr-1">
            <NavLink
                to={to}
                color="inherit"
                noMargin
                disableRipple
                disableFocusRipple
                className="tw-min-w-0 tw-p-0"
            >
                <LayoutRow verticalAlign="center">
                    <img
                        src="assets/branding/COLogo.svg"
                        alt="CapaOne"
                        className="tw-h-8"
                    />
                    {isBeta && showBetaFlag && <b className="tw-relative tw-bottom-2 tw-text-tiny tw-text-red-600 ">BETA</b>}
                    {trialVersions.length > 0 && (
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            className="tw-ml-2 tw-cursor-default tw-rounded-full tw-bg-rose-500 tw-px-4 tw-py-2 tw-text-xs tw-font-bold tw-text-white"
                        >
                            <Tooltip
                                dark
                                fullWidth
                                interactive
                                noPadding
                                content={
                                    <div className="tw-py-2">
                                        <div className="tw-max-h-96 tw-overflow-auto">
                                            {trialVersions.map((contract) => {
                                                return (
                                                    <div
                                                        className="tw-flex tw-items-center tw-px-6 tw-py-3"
                                                        key={contract.productLine}
                                                    >
                                                        <Icon
                                                            type={contract.iconType}
                                                            className="tw-h-10 tw-w-10"
                                                        />
                                                        <div className="tw-ml-4">
                                                            <div className="tw-mb-1 tw-text-base tw-font-bold tw-leading-none tw-tracking-tight">
                                                                {contract.productLine}
                                                            </div>
                                                            <div className="tw-text-xs tw-font-semibold tw-leading-none tw-text-neutral-300">
                                                                Valid until {formatDate(contract.endDate)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {isParentOrganization && (
                                            <div className="tw-my-2 tw-px-6">
                                                <Button
                                                    fullWidth
                                                    color="primary"
                                                    variant="contained"
                                                    size="large"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setUpgradeDialog({
                                                            open: true,
                                                        });
                                                    }}
                                                    noMargin
                                                >
                                                    <LayoutRow
                                                        fill
                                                        align="space-between"
                                                        verticalAlign="center"
                                                    >
                                                        <span>Upgrade</span>
                                                        <Icon type="arrowRight" />
                                                    </LayoutRow>
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                }
                            >
                                <span>{pluralize('active trial', trialVersions.length, true)}</span>
                            </Tooltip>
                        </div>
                    )}
                </LayoutRow>
            </NavLink>
            <Dialog
                open={upgradeDialog.open}
                onClose={upgradeDialogOnClose}
            >
                <TrialUpgradeDialog onClose={upgradeDialogOnClose}></TrialUpgradeDialog>
            </Dialog>
        </div>
    );
};

export { CapaOneBranding };
