import { BUTTON } from '@capasystems/constants';
import { Avatar, Button, Dialog, Ellipsis, Icon, LayoutCenter, Loading, Tab, Tabs, Tooltip } from '@capasystems/ui';
import { default as classNames } from 'classnames';
import React, { useEffect, useState } from 'react';
import { ActionsDialog, useAppleApi } from '../../../index';
import { useForceRefresh } from './../../../index';

const SYSTEM_APPS = [
    { identifier: 'com.apple.AppStore', iconUrl: 'assets/images/apple/com.apple.AppStore.svg', name: 'App Store' },
    { identifier: 'com.apple.calculator', iconUrl: 'assets/images/apple/com.apple.calculator.png', name: 'Calculator' },
    { identifier: 'com.apple.mobilecal', iconUrl: 'assets/images/apple/com.apple.mobilecal.png', name: 'Calendar' },
    { identifier: 'com.apple.camera', iconUrl: 'assets/images/apple/com.apple.camera.png', name: 'Camera' },
    { identifier: 'com.apple.clips', iconUrl: 'assets/images/apple/com.apple.clips.png', name: 'Clips' },
    { identifier: 'com.apple.mobiletimer', iconUrl: 'assets/images/apple/com.apple.mobiletimer.png', name: 'Clock' },
    { identifier: 'com.apple.MobileAddressBook', iconUrl: 'assets/images/apple/com.apple.MobileAddressBook.png', name: 'Contacts' },
    { identifier: 'com.apple.facetime', iconUrl: 'assets/images/apple/com.apple.facetime.png', name: 'FaceTime' },
    { identifier: 'com.apple.findmy', iconUrl: 'assets/images/apple/com.apple.findmy.png', name: 'Find My' },
    { identifier: 'com.apple.DocumentsApp', iconUrl: 'assets/images/apple/com.apple.DocumentsApp.png', name: 'Files' },
    { identifier: 'com.apple.Fitness', iconUrl: 'assets/images/apple/com.apple.Fitness.png', name: 'Fitness' },
    { identifier: 'com.apple.mobilegarageband', iconUrl: 'assets/images/apple/com.apple.mobilegarageband.png', name: 'GarageBand' },
    { identifier: 'com.apple.Health', iconUrl: 'assets/images/apple/com.apple.Health.png', name: 'Health' },
    { identifier: 'com.apple.Home', iconUrl: 'assets/images/apple/com.apple.Home.png', name: 'Home' },
    { identifier: 'com.apple.iBooks', iconUrl: 'assets/images/apple/com.apple.iBooks.png', name: 'Books' },
    { identifier: 'com.apple.iMovie', iconUrl: 'assets/images/apple/com.apple.iMovie.png', name: 'iMovie' },
    { identifier: 'com.apple.MobileStore', iconUrl: 'assets/images/apple/com.apple.MobileStore.png', name: 'iTunes Store' },
    { identifier: 'com.apple.Keynote', iconUrl: 'assets/images/apple/com.apple.Keynote.png', name: 'Keynote' },
    { identifier: 'com.apple.mobilemail', iconUrl: 'assets/images/apple/com.apple.mobilemail.png', name: 'Mail' },
    { identifier: 'com.apple.Maps', iconUrl: 'assets/images/apple/com.apple.Maps.png', name: 'Maps' },
    { identifier: 'com.apple.measure', iconUrl: 'assets/images/apple/com.apple.measure.png', name: 'Measure' },
    { identifier: 'com.apple.MobileSMS', iconUrl: 'assets/images/apple/com.apple.MobileSMS.png', name: 'Messages' },
    { identifier: 'com.apple.Music', iconUrl: 'assets/images/apple/com.apple.Music.png', name: 'Music' },
    { identifier: 'com.apple.news', iconUrl: 'assets/images/apple/com.apple.news.png', name: 'News' },
    { identifier: 'com.apple.mobilenotes', iconUrl: 'assets/images/apple/com.apple.mobilenotes.png', name: 'Notes' },
    { identifier: 'com.apple.Numbers', iconUrl: 'assets/images/apple/com.apple.Numbers.png', name: 'Numbers' },
    { identifier: 'com.apple.Pages', iconUrl: 'assets/images/apple/com.apple.Pages.png', name: 'Pages' },
    { identifier: 'com.apple.mobilephone', iconUrl: 'assets/images/apple/com.apple.mobilephone.png', name: 'Phone' },
    { identifier: 'com.apple.Photo-Booth', iconUrl: 'assets/images/apple/com.apple.Photo-Booth.png', name: 'Photo Booth' },
    { identifier: 'com.apple.mobileslideshow', iconUrl: 'assets/images/apple/com.apple.mobileslideshow.png', name: 'Photos' },
    { identifier: 'com.apple.podcasts', iconUrl: 'assets/images/apple/com.apple.podcasts.png', name: 'Podcasts' },
    { identifier: 'com.apple.reminders', iconUrl: 'assets/images/apple/com.apple.reminders.png', name: 'Reminders' },
    { identifier: 'com.apple.mobilesafari', iconUrl: 'assets/images/apple/com.apple.mobilesafari.png', name: 'Safari' },
    { identifier: 'com.apple.shortcuts', iconUrl: 'assets/images/apple/com.apple.shortcuts.png', name: 'Shortcuts' },
    { identifier: 'com.apple.stocks', iconUrl: 'assets/images/apple/com.apple.stocks.png', name: 'Stocks' },
    { identifier: 'com.apple.tips', iconUrl: 'assets/images/apple/com.apple.tips.png', name: 'Tips' },
    { identifier: 'com.apple.Translate', iconUrl: 'assets/images/apple/com.apple.Translate.png', name: 'Translate' },
    { identifier: 'com.apple.tv', iconUrl: 'assets/images/apple/com.apple.tv.png', name: 'TV' },
    { identifier: 'com.apple.VoiceMemos', iconUrl: 'assets/images/apple/com.apple.VoiceMemos.png', name: 'Voice Memos' },
    { identifier: 'com.apple.Passbook', iconUrl: 'assets/images/apple/com.apple.Passbook.png', name: 'Wallet' },
    { identifier: 'com.apple.Bridge', iconUrl: 'assets/images/apple/com.apple.Bridge.png', name: 'Watch' },
    { identifier: 'com.apple.weather', iconUrl: 'assets/images/apple/com.apple.weather.png', name: 'Weather' },
];

export const AppleHomeScreenLayoutEdit = ({ schema, propertiesRef, onRefChange }) => {
    const PAGE_ROWS_MAX = 4 * 6;
    const propertyKey = Object.keys(schema.properties)[0];
    const [selectedPage, setSelectedPage] = useState(1);
    const [pages, setPages] = useState(propertiesRef[propertyKey]?.Pages || []);
    const [dock, setDock] = useState(propertiesRef[propertyKey]?.Dock || []);
    const [configurationApplications, setConfigurationApplications] = useState([]);
    const [addDialog, setAddDialog] = useState({ open: false, addFunction: null });
    const [selectedTab, setSelectedTab] = useState('custom_apps');
    const [menu, setMenu] = useState({ open: false, anchorEl: null, category: '', title: '', actions: [] });
    const api = useAppleApi();
    const [internalLoading, setInternalLoading] = useState(false);
    const refresh = useForceRefresh();

    const getConfigurationApplications = async () => {
        const apps = await api.getConfigurationApplications();

        setConfigurationApplications(apps);
    };

    useEffect(() => {
        getConfigurationApplications();
    }, []);

    const addToPageRow = (item) => {
        const tmpPage = pages[selectedPage - 1];
        tmpPage.push(item);
        if (tmpPage.length === PAGE_ROWS_MAX) {
            pages.push([]);
            setSelectedPage((prev) => prev + 1);
        }
        setPages([...pages]);
    };

    const replaceOnPageRow = (i) => (item) => {
        const tmpPage = pages[selectedPage - 1];
        tmpPage[i] = item;
        setPages([...pages]);
    };

    const removeFromPageRow = (i) => () => {
        const tmpPage = pages[selectedPage - 1];
        tmpPage.splice(i, 1);

        if (tmpPage.length === 0 && selectedPage !== 1) {
            pages.splice(selectedPage - 1, 1);
            setSelectedPage((prev) => prev - 1);
        }

        setPages([...pages]);
    };

    const addPage = () => {
        pages.push([]);
        setSelectedPage(pages.length);
        setPages([...pages]);
    };

    const removePage = () => {
        pages.splice(selectedPage - 1, 1);
        setSelectedPage((prev) => (prev - 1 > 0 ? prev - 1 : 1));
        setPages([...pages]);
    };

    const addToDock = (item) => {
        setDock([...dock, item]);
    };

    const removeFromDock = (i) => () => {
        // remove from dock
        const tmpDock = dock;
        tmpDock.splice(i, 1);
        setDock([...dock]);
    };

    const replaceOnDock = (i) => (item) => {
        const tmpDock = dock;
        tmpDock[i] = item;
        setDock([...dock]);
    };

    const openAddDialog = (addFunction) => () => {
        setAddDialog({ open: true, addFunction });
    };

    const closeAddDialog = () => {
        setAddDialog({ open: false, addFunction: null });
    };

    const onClearSchemaRef = () => {
        setInternalLoading(true);

        delete propertiesRef[propertyKey];
        setPages([]);
        setDock([]);

        if (onRefChange) {
            onRefChange();
        }
        refresh();
        setTimeout(() => {
            setInternalLoading(false);
        });
    };

    const onOpenMenu = (name, addFunction, removeFunction) => (event) => {
        setMenu((prev) => ({
            ...prev,
            open: true,
            anchorEl: event.currentTarget,
            title: name,
            actions: [
                {
                    id: 'change',
                    name: 'Change',
                    icon: 'edit',
                    function: openAddDialog(addFunction),
                },
                {
                    id: 'remove',
                    name: 'Remove',
                    icon: 'remove',
                    function: removeFunction,
                },
            ],
        }));
    };

    const onActionClick = (action) => {
        action.function();
        setMenu({ open: false, anchorEl: null, index: null });
    };

    const onCloseMenu = () => {
        setMenu({ open: false, anchorEl: null, index: null });
    };

    useEffect(() => {
        if (pages.length === 0 && dock.length === 0) {
            delete propertiesRef[propertyKey];
        } else {
            propertiesRef[propertyKey] = {
                Pages: pages.length === 1 && pages[0].length === 0 ? [] : pages,
                Dock: dock,
            };
        }
    }, [pages, dock]);

    if (configurationApplications.length === 0 || internalLoading) {
        return (
            <LayoutCenter>
                <Loading />
            </LayoutCenter>
        );
    }

    return (
        <>
            <div className="tw-relative tw-h-full">
                <div className="tw-absolute tw-right-2 tw-top-2 tw-flex">
                    <Tooltip content="Note that the layout will only be applied to the apps linked to the device">
                        <Icon
                            type="helpOutlined"
                            className="tw-mt-1 tw-text-gray-400"
                        />
                    </Tooltip>
                    <Button
                        className="tw-ml-2"
                        color={BUTTON.PRIMARY}
                        onClick={onClearSchemaRef}
                    >
                        Clear
                    </Button>
                </div>
                <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center">
                    <div className="tw-flex tw-h-auto tw-w-[325px] tw-flex-col tw-rounded tw-shadow-lg tw-outline tw-outline-1 tw-outline-slate-400/50">
                        <div className="tw-flex tw-h-[587px] tw-flex-wrap tw-content-start tw-gap-x-6 tw-gap-y-4 tw-overflow-auto tw-p-4">
                            {pages[selectedPage - 1]?.map((item, i) => {
                                const tmpApp =
                                    configurationApplications.find((app) =>
                                        app.configurationType === 'webclip'
                                            ? app.data['com.apple.webClip.managed'].URL === item.URL
                                            : app.data?.Identifier === item.BundleID
                                    ) || SYSTEM_APPS.find((app) => app.identifier === item.BundleID);
                                return (
                                    <ItemViewer
                                        item={tmpApp}
                                        index={i}
                                        onClick={onOpenMenu(tmpApp.name, replaceOnPageRow(i), removeFromPageRow(i))}
                                    />
                                );
                            })}
                            {pages[selectedPage - 1]?.length < PAGE_ROWS_MAX && <AddButton onAdd={openAddDialog(addToPageRow)} />}
                        </div>
                        <div className="tw-mb-2 tw-mt-2 tw-flex tw-justify-center tw-gap-4">
                            {pages.map((page, i) => (
                                <div
                                    key={i}
                                    onClick={() => {
                                        if (selectedPage !== i + 1) {
                                            setSelectedPage(i + 1);
                                        } else {
                                            removePage();
                                        }
                                    }}
                                    className={classNames('tw-flex tw-h-5 tw-w-5 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full', {
                                        'tw-bg-blue-400 tw-text-blue-400 hover:tw-text-gray-600': selectedPage === i + 1,
                                        'tw-bg-slate-400': selectedPage !== i + 1,
                                    })}
                                >
                                    {selectedPage === i + 1 && (
                                        <Icon
                                            type="remove"
                                            size="small"
                                        />
                                    )}
                                </div>
                            ))}
                            {pages.length < 8 && (
                                <div
                                    className="tw-flex tw-h-5 tw-w-5 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-bg-slate-200 tw-text-blue-500"
                                    onClick={addPage}
                                >
                                    <Icon
                                        type="add"
                                        size="small"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="tw-flex tw-justify-center tw-gap-4 tw-rounded tw-bg-slate-100 tw-p-4 tw-backdrop-blur-sm">
                            {dock.map((item, i) => {
                                const tmpApp =
                                    configurationApplications.find((app) =>
                                        app.configurationType === 'webclip'
                                            ? app.data['com.apple.webClip.managed'].URL === item.URL
                                            : app.data.Identifier === item.BundleID
                                    ) || SYSTEM_APPS.find((app) => app.identifier === item.BundleID);
                                return (
                                    <ItemViewer
                                        key={i}
                                        item={tmpApp}
                                        index={i}
                                        onClick={onOpenMenu(tmpApp.name, replaceOnDock(i), removeFromDock(i))}
                                        withoutText
                                    />
                                );
                            })}
                            {dock.length < 4 && <AddButton onAdd={openAddDialog(addToDock)} />}
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                open={addDialog.open}
                onClose={closeAddDialog}
                size="sm"
            >
                <div className="tw-flex tw-flex-wrap tw-gap-6 tw-p-4">
                    <Tabs
                        onRails
                        value={selectedTab}
                        onChange={(e, value) => setSelectedTab(value)}
                    >
                        <Tab
                            label="Custom Apps"
                            value="custom_apps"
                        />
                        <Tab
                            label="System Apps"
                            value="system_apps"
                        />
                    </Tabs>
                    {selectedTab === 'custom_apps' &&
                        configurationApplications
                            .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))
                            .map((app) => (
                                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
                                    <div
                                        key={app.id}
                                        onClick={() => {
                                            const toAdd = {
                                                Type: app.configurationType === 'webclip' ? 'WebClip' : 'Application',
                                                ...(app.configurationType === 'webclip'
                                                    ? { URL: app.data['com.apple.webClip.managed'].URL }
                                                    : { BundleID: app.data.Identifier }),
                                            };
                                            if (addDialog.addFunction) {
                                                addDialog.addFunction(toAdd);
                                            }
                                            closeAddDialog();
                                        }}
                                        className="tw-flex tw-h-[55px] tw-w-[55px] tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-xl"
                                    >
                                        {app.iconUrl ? (
                                            <img
                                                src={app.iconUrl}
                                                alt={app.name}
                                            />
                                        ) : (
                                            <Avatar
                                                src={`data:image/png;base64,${app.iconUrl}`}
                                                alt={app.name}
                                                variant="rounded"
                                                className="tw-h-[55px] tw-w-[55px]"
                                            />
                                        )}
                                    </div>
                                    <p className="tw-max-w-[50px]">
                                        <Ellipsis>{app.name}</Ellipsis>
                                    </p>
                                </div>
                            ))}
                    {selectedTab === 'system_apps' &&
                        SYSTEM_APPS.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())).map((app) => (
                            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
                                <div
                                    key={app.identifier}
                                    onClick={() => {
                                        const toAdd = {
                                            Type: 'Application',
                                            BundleID: app.identifier,
                                        };
                                        if (addDialog.addFunction) {
                                            addDialog.addFunction(toAdd);
                                        }
                                        closeAddDialog();
                                    }}
                                    className="tw-flex tw-h-[55px] tw-w-[55px] tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-xl"
                                >
                                    <img
                                        src={app.iconUrl}
                                        alt={app.name}
                                    />
                                </div>
                                <p className="tw-max-w-[50px]">
                                    <Ellipsis>{app.name}</Ellipsis>
                                </p>
                            </div>
                        ))}
                </div>
            </Dialog>
            <ActionsDialog
                {...menu}
                onActionClick={onActionClick}
                onClose={onCloseMenu}
            />
        </>
    );
};

export const AppleHomeScreenLayoutView = ({ schema, propertiesRef }) => {
    const propertyKey = Object.keys(schema.properties)[0];
    const [selectedPage, setSelectedPage] = useState(1);
    const [pages] = useState(propertiesRef[propertyKey]?.Pages || [[]]);
    const [dock] = useState(propertiesRef[propertyKey]?.Dock || []);

    const [configurationApplications, setConfigurationApplications] = useState([]);

    const api = useAppleApi();

    const getConfigurationApplications = async () => {
        const apps = await api.getConfigurationApplications();

        setConfigurationApplications(apps);
    };

    useEffect(() => {
        getConfigurationApplications();
    }, []);

    useEffect(() => {
        propertiesRef[propertyKey] = {
            Pages: pages,
            Dock: dock,
        };
    }, [pages, dock]);

    if (configurationApplications.length === 0) {
        return (
            <LayoutCenter>
                <Loading />
            </LayoutCenter>
        );
    }

    return (
        <div className="tw-relative tw-h-full">
            <div className="tw-absolute tw-right-2 tw-top-2">
                <Tooltip content="Note that the layout will only be applied to the apps linked to the device">
                    <Icon type="helpOutlined" />
                </Tooltip>
            </div>
            <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
                <div className="tw-flex tw-h-auto tw-w-[325px] tw-flex-col tw-rounded tw-shadow-lg tw-outline tw-outline-1 tw-outline-slate-400/50">
                    <div className="tw-flex tw-h-[587px] tw-flex-wrap tw-content-start tw-gap-x-6 tw-gap-y-4 tw-overflow-auto tw-p-4">
                        {pages[selectedPage - 1]?.map((item, i) => (
                            <ItemViewer
                                item={
                                    configurationApplications.find((app) =>
                                        app.configurationType === 'weblcip'
                                            ? app.data['com.apple.webClip.managed'].URL === item.URL
                                            : app.data.Identifier === item.BundleID
                                    ) || SYSTEM_APPS.find((app) => app.identifier === item.BundleID)
                                }
                                index={i}
                            />
                        ))}
                    </div>
                    <div className="tw-mb-2 tw-mt-2 tw-flex tw-justify-center tw-gap-4">
                        {pages.map((page, i) => (
                            <div
                                key={i}
                                onClick={() => {
                                    setSelectedPage(i + 1);
                                }}
                                className={classNames('tw-flex tw-h-5 tw-w-5 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full', {
                                    'tw-bg-blue-400 tw-text-blue-400 hover:tw-text-gray-600': selectedPage === i + 1,
                                    'tw-bg-slate-400': selectedPage !== i + 1,
                                })}
                            ></div>
                        ))}
                    </div>
                    <div className="tw-flex tw-justify-center tw-gap-4 tw-rounded tw-bg-slate-100 tw-p-4 tw-backdrop-blur-sm">
                        {dock.map((item, i) => (
                            <ItemViewer
                                item={
                                    configurationApplications.find((app) =>
                                        app.configurationType === 'weblcip'
                                            ? app.data['com.apple.webClip.managed'].URL === item.URL
                                            : app.data.Identifier === item.BundleID
                                    ) || SYSTEM_APPS.find((app) => app.identifier === item.BundleID)
                                }
                                index={i}
                                withoutText
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const AddButton = ({ onAdd }) => {
    return (
        <div
            onClick={onAdd}
            className="tw-flex tw-h-[55px] tw-w-[55px] tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-xl tw-outline-dashed tw-outline-blue-400"
        >
            <Icon
                className="tw-text-blue-400"
                type="add"
            />
        </div>
    );
};

const ItemViewer = ({ item, index, onClick, withoutText }) => {
    if (!item) {
        return;
    }

    return (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-1">
            <div
                key={index}
                className={classNames('tw-flex tw-h-[55px] tw-w-[55px] tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-xl', {
                    'tw-cursor-pointer': !!onClick,
                })}
                onClick={onClick}
            >
                {item?.iconUrl ? (
                    <img
                        src={item.type === 'webApp' ? `data:image/png;base64,${item.iconUrl}` : item.iconUrl}
                        alt={item.name}
                    />
                ) : (
                    <Avatar
                        src={`data:image/png;base64,${item.iconUrl}`}
                        alt={item.name}
                        variant="rounded"
                        className="tw-h-[55px] tw-w-[55px]"
                    />
                )}
            </div>
            {!withoutText && (
                <p className="tw-max-w-[50px]">
                    <Ellipsis>{item?.name}</Ellipsis>
                </p>
            )}
        </div>
    );
};
