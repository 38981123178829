import { LayoutRow, Tab, Tabs, useParams } from '@capasystems/ui';
import { useEffect, useRef, useState } from 'react';
import { useNavigate as useCoreNavigate } from 'react-router-dom';
import { CollectedOnTimestamp, useNavigate } from '../../../../../index';
import { HardwareInfoTab } from './AndroidEndpointHardwareTab';
import { EndpointSoftwareTab } from './AndroidEndpointSoftwareTab';

const tabClassName = 'tw-font-semibold tw-min-w-0 lg:tw-px-8';

export const EndpointInventoryTab = ({ endpoint }) => {
    const { deviceId, inventoryId } = useParams();
    const navigate = useNavigate();
    const queryBuilderContainerRef = useRef(null);
    const [initialized, setInitialized] = useState(false);
    const coreNavigate = useCoreNavigate();

    const onTabChange = (e, selectedInventoryId) => {
        if (e.ctrlKey || e.metaKey) {
            window.open(navigate.appendBaseURL(`android/device/${deviceId}/inventory/${selectedInventoryId}`), '_blank');
        } else {
            coreNavigate(`../${selectedInventoryId}`);
        }
    };
    useEffect(() => {
        setInitialized(true); // queryBuilderContainerRef is set now.
    }, []);

    return (
        <div className="tw-grid tw-h-full tw-grid-rows-auto-1fr tw-gap-4">
            <div className="tw-pt-4">
                <LayoutRow
                    verticalAlign="center"
                    align="space-between"
                >
                    <div className="tw-flex tw-items-center tw-gap-6">
                        <Tabs
                            value={inventoryId}
                            onChange={onTabChange}
                            pills
                        >
                            <Tab
                                label="Software"
                                value="software"
                                disableRipple
                                className={tabClassName}
                            />
                            <Tab
                                label="Hardware"
                                value="hardware"
                                disableRipple
                                className={tabClassName}
                            />
                        </Tabs>
                    </div>
                    <div className="tw-flex">
                        <CollectedOnTimestamp timestamp={endpoint.androidData.lastStatusReportTime} />
                        <div
                            ref={queryBuilderContainerRef}
                            className={inventoryId === 'software' && 'tw-ml-4 tw-w-72'}
                        />
                    </div>
                </LayoutRow>
            </div>
            {initialized && (
                <div className="tw-overflow-auto">
                    {inventoryId === 'software' && (
                        <EndpointSoftwareTab
                            androidData={endpoint.androidData}
                            queryBuilderContainerRef={queryBuilderContainerRef}
                        />
                    )}
                    {inventoryId === 'hardware' && <HardwareInfoTab androidData={endpoint.androidData} />}
                </div>
            )}
        </div>
    );
};
