import { api, getUniqueId, now } from '@capasystems/utils';
import { coreAppleManagement } from '@thirdparty/constants';

let expirationTimestamps = {};

class AppleConnection {
    constructor() {
        this.baseUrl = `${api.defaults.baseURL}/apple`;
        this.abortController = new AbortController();
        this.connectionID = getUniqueId('AppleConnectionID');
    }

    //*GET*//

    getEndpoints = (params) => this.get(`endpoint`, params);

    getEndpoint = (id) => this.get(`endpoint/${id}`);

    getEndpointSettings = (id) => this.get(`endpoint/${id}/settings`);

    getEndpointCommands = (id) => this.get(`endpoint/${id}/command`);

    getDepEndpoints = (params = {}) => this.get(`endpoint/dep`, { pageSize: 50, ...params });

    getAppleConfigurations = (params) => this.get(`configuration`, params);

    getAppleConfiguration = (id) => this.get(`configuration/${id}`);

    getAppleApplications = (params) => this.get(`application`, params);

    getAppleVppLicenses = (params) => this.get(`vpp`, params);

    getAppleVppAssignments = (id) => this.get(`vpp/${id}/assignments`);

    getAppleSingleVppLicense = (id) => this.get(`vpp/${id}`);

    getSingleAppleApplication = (id) => this.get(`application/${id}`);

    getEnrollmentConfigurations = (params) => this.get(`enrollmentConfiguration`, params);

    getEnrollmentConfiguration = (id) => this.get(`enrollmentConfiguration/${id}`);

    getEndpointData = (id) => this.get(`endpoint/${id}/update`);

    getAppleModels = () =>
        fetch('https://gist.githubusercontent.com/adamawolf/3048717/raw/07ad6645b25205ef2072a560e660c636c8330626/Apple_mobile_device_types.txt').then(
            (response) => response.text()
        );

    getItunesApplications = (search, deviceType, countryId) =>
        fetch(
            `https://itunes.apple.com/search?term=${search.toLocaleLowerCase()}${deviceType ? '&entity=' + deviceType : ''}${
                countryId ? '&country=' + countryId : ''
            }`
        ).then(async (response) => {
            if (response.ok) {
                return response.json();
            } else {
                const error = await response.json();
                throw new Error(error.errorMessage);
            }
        });

    getItunesApplicationLookup = (trackId, query) => this.get('application/itunes/' + trackId, query);

    getConfigurationApplications = () => this.get('configuration/application');

    get = (endpoint, params = {}, overrides = {}) => {
        const requestedTimestamp = now();
        return new Promise((resolve, reject) => {
            api.get(endpoint, {
                signal: this.abortController.signal,
                cache: false,
                withCredentials: true,
                baseURL: this.baseUrl,
                ...overrides,
                params,
            })
                .then((response) => {
                    if (requestedTimestamp >= (expirationTimestamps[this.connectionID] || 0)) {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    if (error.status?.cancelled === false) {
                        // Always send the error.
                        reject(error);
                    }
                });
        });
    };

    //*POST*//
    createEnrollmentConfiguration = (data) => this.post(`enrollmentConfiguration`, data);

    appleSignup = (data) => this.post(`signup`, data);

    updatePushRequest = () => this.post(`pushrequest`);

    updateDepRequest = () => this.post(`deprequest`);

    createAppleConfiguration = (name, configurationObject) =>
        this.post('configuration', {
            name,
            configurationType: coreAppleManagement.configurationType.apple,
            data: configurationObject,
        });

    createAppleApplication = (name, iconUrl, supportedPlatforms, configurationType, applicationObject) =>
        this.post('application', {
            name,
            iconUrl,
            supportedPlatforms,
            configurationType,
            data: applicationObject,
        });

    uploadCertificate = (data) => this.post(`upload/certificate`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
    uploadDepCertificate = (data) => this.post(`upload/depcertificate`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
    uploadVppToken = (data) => this.post(`upload/vpp`, data, { headers: { 'Content-Type': 'multipart/form-data' } });

    //Endpoint side linking
    addEndpointToConfigurations = (endpointId, configurationIds) =>
        this.post(`endpoint/${endpointId}/configurations`, {
            configurationRefIds: configurationIds,
        });

    //Configuration side linking
    addEndpointsToConfiguration = (configurationId, endpointRefIds) =>
        this.post(`configuration/${configurationId}/endpoint`, {
            endpointRefIds,
        });

    //Endpoint side linking
    addEndpointToApplications = (endpointId, applicationIds) =>
        this.post(`endpoint/${endpointId}/applications`, {
            applicationRefIds: applicationIds,
        });

    //Application side linking
    addEndpointsToApplication = (applicationId, endpointRefIds) =>
        this.post(`application/${applicationId}/endpoint`, {
            endpointRefIds,
        });

    sendCommand = (udid, command) => this.post(`endpoint/${udid}/command`, command);

    post = (endpoint, data, overrides = {}) => {
        return new Promise((resolve, reject) => {
            api.post(endpoint, data, {
                baseURL: this.baseUrl,
                ...overrides,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    //*PUT*//
    updateEndpoint = (id, data) => this.update(`endpoint/${id}`, data);

    renameEndpoint = (id, data) => this.update(`endpoint/${id}/rename`, data);

    linkUserEndpoint = (id, data) => this.update(`endpoint/${id}/user`, data);

    /** Do not provide user if delete */
    updateOrDeleteEndpointUser = (endpointId, userId = null) => {
        if (userId === null) {
            return this.delete(`endpoint/${endpointId}/user`);
        } else {
            return this.update(`endpoint/${endpointId}/user`, { user: userId });
        }
    };

    updateEnrollmentConfiguration = (id, data) => this.update(`enrollmentConfiguration/${id}`, data);

    updateAppleConfiguration = (id, configurationObject) => this.update(`configuration/${id}`, configurationObject);

    updateAppleApplication = (id, applicationObject) => this.update(`application/${id}`, applicationObject);

    updateAppleDepEnrollmentlinking = (enrollmentId, data) => this.update(`endpoint/dep/${enrollmentId}/link`, data);

    updateAppleConfigurationPriority = (updatedConfigurations = []) =>
        this.update(
            'configuration',
            updatedConfigurations.map(({ id, priority }) => ({ id, priority }))
        );

    updateAppleApplicationPriority = (updatedApplications = []) =>
        this.update(
            'application',
            updatedApplications.map(({ id, priority }) => ({ id, priority }))
        );

    update = (endpoint, data, overrides = {}) => {
        return new Promise((resolve, reject) => {
            api.put(endpoint, data, {
                baseURL: this.baseUrl,
                ...overrides,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    //*DELETE*//
    deleteEndpoint = (id) => this.delete(`endpoint/${id}`);

    deleteEnrollmentConfiguration = (id) => this.delete(`enrollmentConfiguration/${id}`);

    unlinkUserEndpoint = (id) => this.delete(`endpoint/${id}/user`);

    deleteMdmStack = () => this.delete(`removeMDM`);

    deleteAppleConfiguration = (id) => this.delete(`configuration/${id}`);

    deleteAppleApplication = (id) => this.delete(`application/${id}`);

    //Endpoint side linking
    removeEndpointFromConfigurations = (endpointId, configurationIds) =>
        this.delete(`endpoint/${endpointId}/configurations`, {
            configurationRefIds: configurationIds,
        });

    //Configuration side linking
    removeEndpointsFromConfiguration = (configurationId, endpointRefIds) =>
        this.delete(`configuration/${configurationId}/endpoint`, {
            endpointRefIds,
        });

    //Endpoint side linking
    removeEndpointFromApplications = (endpointId, applicationIds) =>
        this.delete(`endpoint/${endpointId}/applications`, {
            applicationRefIds: applicationIds,
        });

    //Application side linking
    removeEndpointsFromApplication = (applicationId, endpointRefIds) =>
        this.delete(`application/${applicationId}/endpoint`, {
            endpointRefIds,
        });

    delete = (endpoint, data = {}, overrides = {}) =>
        api.delete(endpoint, {
            baseURL: this.baseUrl,
            ...overrides,
            data,
        });

    //*PATCH*//

    //* MISC*//
    cancel = () => {
        expirationTimestamps[this.connectionID] = now();
    };

    abort = () => {
        this.abortController.abort();
        delete expirationTimestamps[this.connectionID];
    };
}

export default AppleConnection;
