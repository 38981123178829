import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const Heading = ({ children, className = '', subheading = false, bold = false, regular = false, style, ...rest }) => (
    <h2
        className={classnames({
            'tw-font-thin': !bold && !regular,
            'tw-font-semibold': bold,
            'tw-font-normal': regular,
            'tw-text-2xl': !subheading,
            'tw-text-lg': subheading,
            [className]: true,
        })}
        style={style}
        {...rest}
    >
        {children}
    </h2>
);

Heading.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    subheading: PropTypes.bool,
    bold: PropTypes.bool,
    regular: PropTypes.bool,
};

export default Heading;
