import { BUTTON } from '@capasystems/constants';
import { Button, Grow, Icon, Input, LayoutCentered, LayoutRow, Loading, Page, Paper, Tab, Tabs, Tooltip, useParams } from '@capasystems/ui';
import { Url, isDefined, useWindowSize } from '@capasystems/utils';
import {
    AndroidApplicationRulesConfiguration,
    AndroidBluetoothConfiguration,
    AndroidCellularConfiguration,
    AndroidConnectivityManagementConfiguration,
    AndroidCrossProfileConfiguration,
    AndroidKioskConfiguration,
    AndroidLocationConfiguration,
    AndroidNetworkConfiguration,
    AndroidPasswordConfiguration,
    AndroidPersistentPreferredActivitiesConfiguration,
    AndroidPersonalUsageConfiguration,
    AndroidPlaystoreConfiguration,
    AndroidReportingConfiguration,
    AndroidRestrictionsConfiguration,
    AndroidSecurityConfiguration,
    AndroidSystemConfiguration,
    AndroidVpnConfiguration,
    AndroidWifiConfiguration,
} from '@thirdparty/constants';
import { getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import { default as classNames, default as classnames } from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { JsonViewer, QueryBuilder, SchemaBuilder, WithExperimentalFeatures, useAndroidApi, useAuthContext, useForceRefresh, useNavigate } from '../../../index';

const configurationTypes = [
    AndroidApplicationRulesConfiguration.schema,
    AndroidBluetoothConfiguration.schema,
    AndroidCellularConfiguration.schema,
    AndroidCrossProfileConfiguration.schema,
    AndroidKioskConfiguration.schema,
    AndroidLocationConfiguration.schema,
    AndroidNetworkConfiguration.schema,
    AndroidPasswordConfiguration.schema,
    AndroidPersistentPreferredActivitiesConfiguration.schema,
    AndroidPersonalUsageConfiguration.schema,
    AndroidPlaystoreConfiguration.schema,
    AndroidReportingConfiguration.schema,
    AndroidRestrictionsConfiguration.schema,
    AndroidSecurityConfiguration.schema,
    AndroidSystemConfiguration.schema,
    AndroidVpnConfiguration.schema,
    AndroidWifiConfiguration.schema,
    AndroidConnectivityManagementConfiguration.schema,
].sort((a, b) => {
    const categoryCalc = a.category.toLocaleLowerCase().localeCompare(b.category.toLocaleLowerCase());
    if (categoryCalc === 0) {
        return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
    } else {
        return categoryCalc;
    }
});

const queryBuilderConfiguration = getDefaultQueryBuilderConfiguration({ placeholder: 'Category or Configuration' });

const WIDTH_THRESHOLD = 1440;

const AndroidConfigurationCreateAndEdit = () => {
    const { permissions } = useAuthContext();
    const { configurationId } = useParams();
    const [selectedTab, setSelectedTab] = useState(Url.getString('schemaId', configurationTypes[0].schemaId));
    const [schemaBuilderSchema, setSchemaBuilderSchema] = useState(
        configurationTypes.find((cc) => cc.schemaId === selectedTab) || { title: 'Not found', properties: {} }
    );
    const [searchTerm, setSearchTerm] = useState('');
    const propertiesBuilderRef = useRef({});
    const [configurationName, setConfigurationName] = useState('');
    const androidApi = useAndroidApi();
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const [loading, setLoading] = useState(isDefined(configurationId));
    const queryBuilderRef = useRef({});

    const changeTab = (_, tab) => {
        const configurationType = configurationTypes.find((cc) => cc.schemaId === tab);
        setSchemaBuilderSchema(configurationType);
        Url.set('schemaId', tab);
        setSelectedTab(tab);
    };

    const navigateToConfigurationList = () => {
        navigate.to('android/configuration/list');
    };

    const navigateToConfigurationMembership = (response) => {
        navigate.to(`android/configuration/${configurationId || response.id}/membership`);
    };

    const onSubmitSearch = ([activeLeaf]) => {
        setSearchTerm(activeLeaf ? activeLeaf.value : '');
    };

    const onSubmit = () => {
        setErrorMessage(null);
        if (configurationName.trim() === '') {
            setErrorMessage('You must provide a configuration name');
        } else {
            if (isDefined(configurationId)) {
                androidApi
                    .updateAndroidConfiguration(configurationId, {
                        name: configurationName,
                        data: { ...propertiesBuilderRef.current },
                    })
                    .then(navigateToConfigurationMembership)
                    .catch((response) => {
                        setErrorMessage(response.data?.message || 'Failed to update android configuration');
                    });
            } else {
                androidApi
                    .createAndroidConfiguration(configurationName, { ...propertiesBuilderRef.current })
                    .then(navigateToConfigurationMembership)
                    .catch((response) => {
                        setErrorMessage(response.data?.message || 'Failed to create android configuration');
                    });
            }
        }
    };

    useEffect(() => {
        if (isDefined(configurationId)) {
            androidApi
                .getAndroidConfiguration(configurationId)
                .then((foundConfiguration) => {
                    propertiesBuilderRef.current = foundConfiguration.data || {};
                    setConfigurationName(foundConfiguration.name);
                    setLoading(false);
                })
                .catch(() => {
                    setErrorMessage('The supplied configuration ID is unknown');
                });
        }
    }, [androidApi, configurationId]);

    const propertiesBuilderRefKeys = Object.keys(propertiesBuilderRef.current);

    const memorizedTabs = useMemo(() => {
        const groupedByCategory = Object.groupBy(configurationTypes, (ct) => ct.category);

        const filteredGroupedByCategory = Object.entries(groupedByCategory)
            .filter(([categoryName, ctArray]) => {
                const categoryMatches = categoryName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());

                if (categoryMatches) {
                    return true;
                }

                return ctArray.some((ct) => ct.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
            })
            .reduce((acc, [categoryName, ctArray]) => {
                if (categoryName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                    acc[categoryName] = ctArray;
                } else {
                    acc[categoryName] = ctArray.filter((ct) => ct.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
                }
                return acc;
            }, {});

        return [].concat(
            ...Object.entries(filteredGroupedByCategory)
                .sort(([keyA], [keyB]) => keyA.toLocaleLowerCase().localeCompare(keyB.toLocaleLowerCase()))
                .map(([key, ctArray], i) => {
                    const sortedCtArray = ctArray.sort((a, b) => a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase()));

                    const catTabs = [
                        <Tab
                            value={key}
                            disabled
                            className={classNames('tw-mb-1 tw-min-h-0 tw-py-0 tw-opacity-100', { 'tw-mt-2': i === 0, 'tw-mt-6': i !== 0 })}
                            label={<div className="tw-w-full tw-text-left tw-text-xs tw-text-neutral-500">{key}</div>}
                            key={'cat - ' + key}
                        />,
                    ];

                    sortedCtArray.forEach((ct) => {
                        const inUse = Object.keys(ct.properties).some((propertiesKey) => propertiesBuilderRefKeys.includes(propertiesKey));
                        catTabs.push(
                            <Tab
                                value={ct.schemaId}
                                classes={{ root: 'tw-pl-4' }}
                                label={
                                    <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto tw-items-center tw-gap-x-6">
                                        <div className="tw-mr-2 tw-text-left">{ct.title}</div>
                                        <Grow in={inUse}>
                                            <Icon
                                                type="checkmarkCircle"
                                                color="success"
                                                size="small"
                                            />
                                        </Grow>
                                    </div>
                                }
                                key={'tab - ' + ct.title}
                                disableRipple
                            />
                        );
                    });

                    return catTabs;
                })
        );
    }, [configurationTypes, propertiesBuilderRefKeys, searchTerm]);

    if (loading) {
        if (errorMessage) {
            return (
                <LayoutCentered direction="column">
                    <div>
                        <h2 className="tw-mb-4">{errorMessage}</h2>
                        <Button
                            noMargin
                            onClick={navigateToConfigurationList}
                            variant={BUTTON.RAISED}
                            color={BUTTON.PRIMARY}
                        >
                            Back
                        </Button>
                    </div>
                </LayoutCentered>
            );
        }
        return (
            <LayoutCentered>
                <Loading thickness={2} />
            </LayoutCentered>
        );
    }

    const currentUrl = window.location.href;
    let baseUrl;
    if (currentUrl.includes('android') && currentUrl.includes('configuration')) {
        baseUrl = 'https://developers.google.com/android/management/reference/rest/v1/enterprises.policies';
    } else {
        return null;
    }
    const androidDoc = 'Android documentation';
    const linkText = 'Learn More';

    const tooltipContentWithLink = (
        <div>
            <p>{androidDoc}</p>
            <a
                href={baseUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
            >
                {linkText}
            </a>
        </div>
    );
    const showJsonMiniMap = width >= WIDTH_THRESHOLD && permissions.canViewExperimentalFeatures;

    return (
        <Page title={isDefined(configurationId) ? 'Configuration · Edit' : 'Configuration · Create'}>
            <div
                className={classNames({
                    'tw-mx-auto tw-grid tw-h-full tw-max-w-screen-2xl tw-grid-cols-auto-1fr-auto tw-gap-4 tw-p-4': true,
                    'tw-grid-rows-auto-auto-1fr': errorMessage !== null,
                    'tw-grid-rows-auto-1fr': errorMessage === null,
                })}
            >
                <div className="tw-col-span-3">
                    <LayoutRow
                        align="space-between"
                        verticalAlign="center"
                    >
                        <div className="tw-flex tw-justify-start">
                            <Input
                                value={configurationName}
                                placeholder="Configuration name"
                                fullWidth
                                callToAction
                                light
                                className="tw-w-96 "
                                onChange={(e) => {
                                    setErrorMessage(null);
                                    setConfigurationName(e.target.value);
                                }}
                                containerClassName="tw-py-1 tw-text-sm"
                                autoFocus
                            />
                            <div className="tw-ml-4 tw-flex tw-items-center">
                                <Tooltip
                                    fullWidth
                                    interactive
                                    dark
                                    extraPadding
                                    content={
                                        <>
                                            <MacroInfoSection heading="Device IMEI number">$hwi.imei$</MacroInfoSection>
                                            <MacroInfoSection heading="Device serial number">$hwi.serialNumber$</MacroInfoSection>
                                            <MacroInfoSection heading="Device user full name">$user.fullName$</MacroInfoSection>
                                            <MacroInfoSection heading="Device user principal name">$user.userPrincipalName$</MacroInfoSection>
                                            <MacroInfoSection heading="Device user email">$user.email$</MacroInfoSection>
                                        </>
                                    }
                                >
                                    <h2 className="tw-cursor-pointer tw-text-sm tw-text-gray-500">Supported Macros</h2>
                                </Tooltip>
                            </div>
                            <div className="tw-ml-4 tw-flex tw-items-center">
                                <Tooltip
                                    fullWidth
                                    interactive
                                    dark
                                    extraPadding
                                    content={tooltipContentWithLink}
                                >
                                    <Icon type="capaKnowledgeBase">
                                        <div
                                            className={classNames({
                                                'tw-mb-1 tw-mt-1 tw-block tw-text-xs tw-font-normal tw-italic': true,
                                            })}
                                        />
                                    </Icon>
                                </Tooltip>
                            </div>
                        </div>
                        <div>
                            <Button
                                color={BUTTON.PRIMARY}
                                variant={BUTTON.RAISED}
                                onClick={onSubmit}
                            >
                                {isDefined(configurationId) ? 'Save' : 'Create'}
                            </Button>
                            <Button
                                noMargin
                                onClick={isDefined(configurationId) ? navigateToConfigurationMembership : navigateToConfigurationList}
                            >
                                Cancel
                            </Button>
                        </div>
                    </LayoutRow>
                </div>
                {errorMessage !== null && (
                    <div className="tw-col-span-3 tw-rounded tw-bg-red-100 tw-px-6 tw-py-4 tw-font-bold tw-text-red-700">{errorMessage}</div>
                )}
                <div className="tw-grid tw-grid-rows-auto-1fr tw-gap-4 tw-overflow-auto">
                    <QueryBuilder
                        defaultConfiguration={queryBuilderConfiguration}
                        onInit={onSubmitSearch}
                        onSubmit={onSubmitSearch}
                        className="tw-mx-auto tw-w-full"
                        ref={queryBuilderRef}
                    />
                    <div className="tw-w-[260px] tw-overflow-auto">
                        <Tabs
                            value={selectedTab}
                            onChange={changeTab}
                            orientation="vertical"
                            pills
                            variant="scrollable"
                        >
                            {memorizedTabs}
                        </Tabs>
                    </div>
                </div>
                <Paper
                    className={classNames({
                        'tw-h-full tw-overflow-auto tw-shadow-sm': true,
                        'tw-col-span-2': !showJsonMiniMap,
                    })}
                >
                    <SchemaBuilder
                        key={selectedTab}
                        schema={schemaBuilderSchema}
                        ref={propertiesBuilderRef.current}
                    />
                </Paper>

                {showJsonMiniMap && (
                    <WithExperimentalFeatures>
                        <JsonMiniMap
                            json={propertiesBuilderRef.current}
                            className="tw-w-80 tw-text-tiny"
                        />
                    </WithExperimentalFeatures>
                )}
            </div>
        </Page>
    );
};

const MacroInfoSection = ({ heading, last = false, children }) => (
    <>
        <p className="tw-mb-1 tw-mt-0">{heading}</p>
        <p
            className={classnames({
                'tw-mt-0 tw-text-xs tw-font-semibold': true,
                'tw-mb-4': !last,
                'tw-mb-0': last,
            })}
        >
            {children}
        </p>
    </>
);

const JsonMiniMap = React.forwardRef((props, ref) => {
    const forceRefresh = useForceRefresh();

    useEffect(() => {
        const interval = setInterval(forceRefresh, 1000);
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <JsonViewer
            {...props}
            ref={ref}
        />
    );
});

export { AndroidConfigurationCreateAndEdit };
