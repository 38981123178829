import { BUTTON, TOOLTIP } from '@capasystems/constants';
import {
    Button,
    ContextDialog,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    Ellipsis,
    EmptyState,
    Highlighter,
    Icon,
    IconButton,
    Input,
    LayoutCenter,
    LayoutCentered,
    LayoutColumn,
    LayoutRow,
    Loading,
    LoadingLinear,
    NavLink,
    Paper,
    RippleBadge,
    Tooltip,
    useParams,
} from '@capasystems/ui';
import { formatDate, formatPeriodDetailed, formatTimestamp, isArray, isBoolean, isNumber, isObject, isString, isUndefined, now } from '@capasystems/utils';
import {
    DEVICE_STATE,
    DEVICE_TYPE,
    LOCAL_STORAGE_ID,
    MONGO_DB,
    NONE,
    NOT_AVAILABLE,
    QUERY_BUILDER_EDIT_MODE_URL_KEY,
    QUERY_BUILDER_REF_DEVICE,
    SECURITY_SCORE_NAME,
    SECURITY_SCORE_NAME_BASED_STYLING,
} from '@thirdparty/constants';
import {
    formatBitsPerSecond,
    formatBytes,
    formatBytesPerSecond,
    formatHertz,
    formatMilliseconds,
    formatPercentage,
    getLanguageName,
    queryBuilderQueryStringToUrlParams,
} from '@thirdparty/utils';
import classnames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactGridLayout, { WidthProvider } from 'react-grid-layout';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import colors from 'tailwindcss/colors';
import { Heading, PageTitle, useApi, useAuthContext, useForceRefresh, useLocalStorageState, useNavigate } from './../../index';

const GridLayout = WidthProvider(ReactGridLayout);

const drawerPaperProps = {
    style: {
        minWidth: '20vw',
        maxWidth: '100vw',
        width: 360,
    },
};

const KEEP_FILTERS_COUNT = 5;

export const TwoLineCellRenderer = ({
    main,
    secondary,
    secondaryOnTop = false,
    callToAction = false,
    disableEllipsis = false,
    secondaryProps = {},
    tooltipProps = {},
    ...rest
}) => {
    return (
        <LayoutColumn {...rest}>
            {secondary && secondaryOnTop && (
                <span
                    className={classnames(
                        {
                            'tw-mb-0.25': true,
                        },
                        secondaryProps.className
                    )}
                    style={{ fontSize: '0.9em' }}
                >
                    {disableEllipsis ? secondary : <Ellipsis tooltipProps={tooltipProps}>{secondary}</Ellipsis>}
                </span>
            )}
            <div
                className={classnames({
                    'tw-block': true,
                    'tw-font-semibold': true,
                })}
            >
                {disableEllipsis ? main : <Ellipsis tooltipProps={tooltipProps}>{main}</Ellipsis>}
            </div>
            {secondary && !secondaryOnTop && (
                <span
                    className={classnames(
                        {
                            'tw-mt-0.25': true,
                        },
                        secondaryProps.className
                    )}
                    style={{ fontSize: '0.9em' }}
                >
                    {disableEllipsis ? secondary : <Ellipsis tooltipProps={tooltipProps}>{secondary}</Ellipsis>}
                </span>
            )}
        </LayoutColumn>
    );
};

const deviceTypeIcons = {
    [DEVICE_TYPE.VIRTUAL]: 'virtualWindows',
    [DEVICE_TYPE.DESKTOP]: 'desktopWindowsOutlined',
    [DEVICE_TYPE.LAPTOP]: 'laptopWindows',
    [DEVICE_TYPE.SERVER]: 'storage',
};

export const DeviceTypeIcon = ({ device: { deviceType }, ...rest }) => {
    return (
        <Tooltip
            dark
            extraPadding
            bold
            content={deviceType}
        >
            <Icon
                type={deviceTypeIcons[deviceType] || 'questionMark'}
                {...rest}
            />
        </Tooltip>
    );
};

export const DeviceAgentState = ({
    device: { serviceStartUp, online, state, id, lastSetOffline = null, agentVersion },
    icon,
    hideLatestAgentStartup = false,
    ...rest
}) => {
    const api = useApi();
    const [pingState, setPingState] = useState({
        latestRequest: 0,
        message: null,
    });
    const onOpen = () => {
        // Always request on first open, but there's no need to fetch new data on every open afterwards.
        if (now() - pingState.latestRequest > 5000) {
            setPingState((c) => ({
                message: null,
                latestRequest: now(),
            }));
            api.getEndpointPing(id)
                .then(({ ping }) => {
                    setPingState((c) => ({
                        ...c,
                        message: ping !== 'Endpoint not connected' ? ping : lastSetOffline !== null ? dayjs(lastSetOffline).fromNow() : NOT_AVAILABLE,
                    }));
                })
                .catch(() => {
                    setPingState((c) => ({
                        ...c,
                        message: NOT_AVAILABLE,
                    }));
                });
        }
    };

    const memoizedValue = useMemo(() => {
        const latestAgentStartup = serviceStartUp ? `${dayjs(serviceStartUp).fromNow()} @ ${formatTimestamp(serviceStartUp)}` : 'Unknown';

        const rippleBadgeClasses = { badge: 'tw-bg-yellow-500', dot: 'tw-h-1.5 tw-w-1.5 tw-min-h-0 tw-min-w-0' };

        if (online) {
            const busy = state === DEVICE_STATE.BUSY ? 1 : 0;
            return {
                style: { backgroundColor: colors.emerald[100], color: colors.emerald[800] },
                latestAgentStartup,
                busy,
                text: 'Online',
                rippleBadgeClasses,
                agentVersion,
            };
        } else {
            return {
                style: { backgroundColor: colors.red[100], color: colors.red[800] },
                latestAgentStartup,
                busy: 0,
                text: 'Offline',
                rippleBadgeClasses,
                agentVersion,
            };
        }
    }, [serviceStartUp, online, state, agentVersion]);

    return (
        <Tooltip
            dark
            extraPadding
            onOpen={onOpen}
            content={
                <>
                    <DeviceInfoSection heading="Status">
                        {online ? `Online and ${state}` : lastSetOffline ? `Offline for ${dayjs(lastSetOffline).fromNow(true)}` : 'Offline'}
                    </DeviceInfoSection>
                    {agentVersion && <DeviceInfoSection heading="Agent version">{agentVersion}</DeviceInfoSection>}
                    {!hideLatestAgentStartup && <DeviceInfoSection heading="Latest agent startup">{memoizedValue.latestAgentStartup}</DeviceInfoSection>}
                    <DeviceInfoSection
                        heading="Latest heartbeat from agent"
                        last
                    >
                        {pingState.message || (
                            <span className="tw-flex tw-items-center tw-gap-2 tw-text-tiny tw-text-slate-300">
                                <Loading size={10} /> Processing request...
                            </span>
                        )}
                    </DeviceInfoSection>
                </>
            }
        >
            {icon ? (
                <div>
                    <RippleBadge
                        badgeContent={memoizedValue.busy}
                        color="default"
                        classes={memoizedValue.rippleBadgeClasses}
                    >
                        <Icon
                            type="circle"
                            size="small"
                            color={online ? 'success' : 'error'}
                            style={{ fontSize: 16 }}
                            {...rest}
                        />
                    </RippleBadge>
                </div>
            ) : (
                <div {...rest}>
                    <RippleBadge
                        badgeContent={memoizedValue.busy}
                        color="default"
                        classes={memoizedValue.rippleBadgeClasses}
                    >
                        <TailwindBadge
                            size="small"
                            color="custom"
                            noShadow
                            style={memoizedValue.style}
                        >
                            {memoizedValue.text}
                        </TailwindBadge>
                    </RippleBadge>
                </div>
            )}
        </Tooltip>
    );
};

export const TailwindBadge = React.forwardRef(
    ({ className, roundedFull = false, compact = false, size = 'default', color = 'neutral', dark = false, noShadow = false, ...rest }, ref) => {
        const memoizedProps = useMemo(() => {
            return {
                classNames: classnames({
                    'tw-inline-flex tw-items-center tw-justify-center tw-font-semibold tw-leading-none tw-rounded-full': true,
                    'tw-shadow-sm': !noShadow,
                    'tw-px-2 tw-py-2 tw-text-tiny': size === 'tiny',
                    'tw-px-2.5 tw-py-1.5 tw-text-xs': size === 'small',
                    'tw-px-4 tw-py-3 tw-text-xs': size === 'default',
                    'tw-bg-amber-100 tw-shadow-amber-600/25 tw-text-amber-800': color === 'amber',
                    'tw-bg-yellow-100 tw-shadow-yellow-600/25 tw-text-yellow-800': color === 'yellow',
                    'tw-bg-blue-100 tw-shadow-blue-600/25 tw-text-blue-800': color === 'blue',
                    'tw-bg-sky-100 tw-shadow-sky-600/25 tw-text-sky-800': color === 'sky',
                    'tw-bg-emerald-100 tw-shadow-emerald-600/25 tw-text-emerald-800': color === 'emerald',
                    'tw-bg-red-100 tw-shadow-red-600/25 tw-text-red-800': color === 'red' && !dark,
                    'tw-bg-red-700 tw-shadow-red-600/25 tw-text-red-50': color === 'red' && dark,
                    'tw-bg-indigo-100 tw-shadow-indigo-600/25 tw-text-indigo-800': color === 'indigo',
                    'tw-bg-purple-100 tw-shadow-purple-600/25 tw-text-purple-800': color === 'purple',
                    'tw-bg-rose-100 tw-shadow-rose-600/25 tw-text-rose-800': color === 'rose',
                    'tw-bg-teal-100 tw-shadow-teal-600/25 tw-text-teal-800': color === 'teal',
                    'tw-bg-cyan-100 tw-shadow-cyan-600/25 tw-text-cyan-800': color === 'cyan' && !dark,
                    'tw-bg-cyan-800 tw-shadow-cyan-600/25 tw-text-cyan-50': color === 'cyan' && dark,
                    'tw-bg-slate-100 tw-shadow-slate-600/25 tw-text-slate-800': color === 'slate' && !dark,
                    'tw-bg-slate-700 tw-shadow-slate-600/25 tw-text-slate-50': color === 'slate' && dark,
                    'tw-bg-neutral-100 tw-shadow-neutral-600/25 tw-text-neutral-800': color === 'neutral',
                    'tw-bg-gray-300 tw-shadow-gray-600/25 tw-text-gray-800': color === 'gray',
                    'tw-bg-white tw-text-stone-800': color === 'white',
                }),
            };
        }, [color, size, dark, noShadow]);

        return (
            <div
                ref={ref}
                className={classnames(memoizedProps.classNames, className)}
                {...rest}
            />
        );
    }
);

TailwindBadge.propTypes = {
    color: PropTypes.oneOf([
        'amber',
        'yellow',
        'blue',
        'sky',
        'emerald',
        'red',
        'indigo',
        'purple',
        'rose',
        'teal',
        'cyan',
        'slate',
        'neutral',
        'white',
        'custom',
    ]),
    size: PropTypes.oneOf(['tiny', 'small', 'default']),
    dark: PropTypes.bool,
    noShadow: PropTypes.bool,
};

export const DeviceInfoSection = ({ heading, last = false, children }) => (
    <>
        <p className="tw-mb-1 tw-mt-0">{heading}</p>
        <p
            className={classnames({
                'tw-mt-0 tw-text-xs tw-font-semibold': true,
                'tw-mb-4': !last,
                'tw-mb-0': last,
            })}
        >
            {children}
        </p>
    </>
);

export const DeviceInventoryErrorHandler = ({ online, isUnsupportedFormat, category = '', action, agentVersion }) => {
    return (
        <div className="tw-grid tw-h-full tw-p-0.5">
            <WidgetPaper headerless>
                <EmptyState
                    title={isUnsupportedFormat ? 'Unsupported data format' : 'An error occurred'}
                    description={
                        isUnsupportedFormat ? (
                            <div className="tw-flex tw-flex-col tw-gap-1">
                                <span>Please wait for next agent delivery</span>
                                <strong>Endpoint is {online ? 'online' : 'offline'}</strong>
                            </div>
                        ) : (
                            `Could not find any ${category} data. ${agentVersion ? `Or the agent might not be on a version ${agentVersion} or higher.` : ''}`
                        )
                    }
                    iconType="exclamation"
                    color="error"
                >
                    {action}
                </EmptyState>
            </WidgetPaper>
        </div>
    );
};

const flattenQueryObject = (obj = {}, res = {}, extraKey = '') => {
    for (const key in obj) {
        if (typeof obj[key] !== 'object') {
            res[extraKey + key] = obj[key];
        } else if (obj[key][MONGO_DB.ELEM_MATCH]) {
            flattenQueryObject(obj[key][MONGO_DB.ELEM_MATCH], res, `${extraKey}${key}.`);
        } else {
            res[extraKey + key] = obj[key];
        }
    }
    return res;
};

export const DeviceFilterAction = ({ editMode }) => {
    const api = useApi();
    const { filterId, organizationId } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [, setRecentlyUsedDeviceFilters] = useLocalStorageState(LOCAL_STORAGE_ID.RECENTLY_USED_DEVICE_FILTERS(organizationId), []);

    const beginDestructureOfFilter = ({ query }) => {
        setRecentlyUsedDeviceFilters((current) => [filterId, ...current.filter((id) => id !== filterId)].slice(0, KEEP_FILTERS_COUNT)); // Keep last 5 only.

        const urlParams = queryBuilderQueryStringToUrlParams(query, QUERY_BUILDER_REF_DEVICE);
        if (editMode) {
            navigate.to(`windows/device/list?${urlParams}&${QUERY_BUILDER_EDIT_MODE_URL_KEY}=${filterId}`, true);
        } else {
            navigate.to(`windows/device/list?${urlParams}`, true);
        }
    };

    useEffect(() => {
        api.getDeviceFilter(filterId)
            .then(beginDestructureOfFilter)
            .catch(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (
            <LayoutCenter>
                <Loading />
            </LayoutCenter>
        );
    }
    return (
        <LayoutCenter
            direction="column"
            className="tw-bg-red-50"
        >
            <Heading bold>Unknown filter ID</Heading>
            <Icon
                type="arrowDown"
                size="small"
            />
            <Heading
                bold
                subheading
            >
                {filterId}
            </Heading>
        </LayoutCenter>
    );
};

const BaseWidgetPaper = ({
    className,
    headerless,
    headerClassName,
    truncateHeaderElements,
    title,
    description,
    icon,
    actions,
    children,
    color,
    light,
    dark,
    descriptionClassName,
    titleSize,
    dropShadow,
    ...rest
}) => {
    const memoizedClasses = useMemo(() => {
        const colorVariant = light ? `light-${color}` : dark ? `dark-${color}` : color;
        return {
            paper: classnames(
                {
                    'tw-overflow-auto tw-shadow-none tw-transition-colors	tw-duration-700': true,
                    'tw-drop-shadow-sm': dropShadow === 'small',
                    'tw-drop-shadow-none': dropShadow === 'none',
                    'tw-text-slate-900': color === 'default',

                    'tw-bg-red-100 tw-text-red-900': colorVariant === 'light-red',
                    'tw-bg-red-400 tw-text-red-900': colorVariant === 'red',
                    'tw-bg-red-700 tw-text-red-100': colorVariant === 'dark-red',

                    'tw-bg-orange-100 tw-text-orange-900': colorVariant === 'light-orange',
                    'tw-bg-orange-400 tw-text-orange-900': colorVariant === 'orange',
                    'tw-bg-orange-600 tw-text-orange-100': colorVariant === 'dark-orange',

                    'tw-bg-amber-100 tw-text-amber-900': colorVariant === 'light-amber',
                    'tw-bg-amber-400 tw-text-amber-900': colorVariant === 'amber',
                    'tw-bg-amber-600 tw-text-amber-100': colorVariant === 'dark-amber',

                    'tw-bg-yellow-100 tw-text-yellow-900': colorVariant === 'light-yellow',
                    'tw-bg-yellow-400 tw-text-yellow-900': colorVariant === 'yellow',
                    'tw-bg-yellow-600 tw-text-yellow-100': colorVariant === 'dark-yellow',

                    'tw-bg-blue-100 tw-text-blue-900': colorVariant === 'light-blue',
                    'tw-bg-blue-400 tw-text-blue-900': colorVariant === 'blue',
                    'tw-bg-blue-700 tw-text-blue-100': colorVariant === 'dark-blue',

                    'tw-bg-emerald-100 tw-text-emerald-900': colorVariant === 'light-emerald',
                    'tw-bg-emerald-400 tw-text-emerald-900': colorVariant === 'emerald',
                    'tw-bg-emerald-600 tw-text-emerald-100': colorVariant === 'dark-emerald',

                    'tw-bg-green-100 tw-text-green-900': colorVariant === 'light-green',
                    'tw-bg-green-400 tw-text-green-900': colorVariant === 'green',
                    'tw-bg-green-700 tw-text-green-100': colorVariant === 'dark-green',

                    'tw-bg-sky-100 tw-text-sky-900': colorVariant === 'light-sky',
                    'tw-bg-sky-400 tw-text-sky-900': colorVariant === 'sky',
                    'tw-bg-sky-700 tw-text-sky-100': colorVariant === 'dark-sky',

                    'tw-bg-slate-100 tw-text-slate-900': colorVariant === 'light-slate',
                    'tw-bg-slate-400 tw-text-slate-900': colorVariant === 'slate',
                    'tw-bg-slate-800 tw-text-slate-100': colorVariant === 'dark-slate',

                    'tw-bg-gray-100 tw-text-gray-900': colorVariant === 'light-gray',
                    'tw-bg-gray-400 tw-text-gray-900': colorVariant === 'gray',
                    'tw-bg-gray-800 tw-text-gray-100': colorVariant === 'dark-gray',

                    'tw-bg-cyan-100 tw-text-cyan-900': colorVariant === 'light-cyan',
                    'tw-bg-cyan-400 tw-text-cyan-900': colorVariant === 'cyan',
                    'tw-bg-cyan-800 tw-text-cyan-100': colorVariant === 'dark-cyan',

                    'tw-bg-indigo-100 tw-text-indigo-900': colorVariant === 'light-indigo',
                    'tw-bg-indigo-400 tw-text-indigo-900': colorVariant === 'indigo',
                    'tw-bg-indigo-800 tw-text-indigo-100': colorVariant === 'dark-indigo',

                    'tw-bg-teal-100 tw-text-teal-900': colorVariant === 'light-teal',
                    'tw-bg-teal-400 tw-text-teal-900': colorVariant === 'teal',
                    'tw-bg-teal-800 tw-text-teal-100': colorVariant === 'dark-teal',
                },
                className
            ),
            header: classnames(
                {
                    'tw-p-4 tw-flex tw-justify-between tw-gap-2 tw-overflow-hidden': true,
                },
                headerClassName
            ),
            description: classnames(
                {
                    'tw-text-xs tw-font-semibold tw-leading-relaxed': true,
                    'tw-text-neutral-700': color === 'default',
                },
                descriptionClassName
            ),
            grid: classnames({
                'tw-grid tw-h-full': true,
                'tw-grid tw-grid-rows-1': headerless,
                'tw-grid tw-grid-rows-auto-1fr': !headerless,
            }),
        };
    }, [color, light, className, headerless, descriptionClassName, headerClassName, dark, dropShadow]);

    return (
        <Paper
            className={memoizedClasses.paper}
            {...rest}
        >
            <div className={memoizedClasses.grid}>
                {!headerless && (
                    <div className={memoizedClasses.header}>
                        <div
                            className={classnames('tw-flex tw-gap-2 tw-overflow-hidden', {
                                'tw-items-center': icon !== null,
                            })}
                        >
                            {icon && <div>{icon}</div>}
                            <div className="tw-flex tw-w-full tw-flex-col tw-gap-1">
                                <div
                                    className={classnames('tw-font-semibold tw-leading-tight', {
                                        'tw-text-lg': titleSize === 'large',
                                        'tw-text-base': titleSize === 'medium',
                                        'tw-text-sm': titleSize === 'small',
                                    })}
                                >
                                    {truncateHeaderElements ? <Ellipsis>{title}</Ellipsis> : title}
                                </div>
                                {description && (
                                    <div className={memoizedClasses.description}>
                                        {truncateHeaderElements ? <Ellipsis>{description}</Ellipsis> : description}
                                    </div>
                                )}
                            </div>
                        </div>
                        {actions && <div>{actions}</div>}
                    </div>
                )}
                <div className="tw-h-full tw-overflow-auto">{children}</div>
            </div>
        </Paper>
    );
};

export const WidgetPaper = ({
    children,
    loading = false,
    loadingPosition = 'center',
    errorMessage = null,
    truncateHeaderElements = false,
    actions = null,
    title = null,
    description = null,
    icon = null,
    headerless = false,
    color = 'default',
    light = false,
    titleSize = 'large',
    dropShadow = 'small',
    ...rest
}) => {
    if (errorMessage) {
        return (
            <BaseWidgetPaper
                truncateHeaderElements={truncateHeaderElements}
                actions={actions}
                title={title}
                description={description}
                icon={icon}
                headerless={headerless}
                titleSize={titleSize}
                dropShadow={dropShadow}
                {...rest}
                light
                color="default"
            >
                <LayoutCenter direction="column">
                    <TailwindBadge
                        color="red"
                        className="tw-mx-4"
                    >
                        {errorMessage}
                    </TailwindBadge>
                </LayoutCenter>
            </BaseWidgetPaper>
        );
    }
    if (loading) {
        return (
            <BaseWidgetPaper
                truncateHeaderElements={truncateHeaderElements}
                actions={actions}
                title={title}
                description={description}
                icon={icon}
                headerless={headerless}
                color={color}
                light={light}
                titleSize={titleSize}
                dropShadow={dropShadow}
                {...rest}
            >
                {loadingPosition === 'left' ? (
                    <LayoutColumn
                        fill
                        align="center"
                    >
                        <Loading
                            thickness={2}
                            color="inherit"
                            className="tw-ml-4"
                        />
                    </LayoutColumn>
                ) : (
                    <LayoutCenter>
                        <Loading
                            thickness={2}
                            color="inherit"
                            size={24}
                        />
                    </LayoutCenter>
                )}
            </BaseWidgetPaper>
        );
    }
    return (
        <BaseWidgetPaper
            truncateHeaderElements={truncateHeaderElements}
            actions={actions}
            title={title}
            description={description}
            icon={icon}
            headerless={headerless}
            color={color}
            light={light}
            titleSize={titleSize}
            dropShadow={dropShadow}
            {...rest}
        >
            {children}
        </BaseWidgetPaper>
    );
};

WidgetPaper.propTypes = {
    title: PropTypes.any,
    description: PropTypes.any,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    descriptionClassName: PropTypes.string,
    errorMessage: PropTypes.string,
    loading: PropTypes.bool,
    truncateHeaderElements: PropTypes.bool,
    actions: PropTypes.any,
    icon: PropTypes.any,
    headerless: PropTypes.bool,
    dropShadow: PropTypes.oneOf(['none', 'small']),
    light: PropTypes.bool,
    color: PropTypes.oneOf([
        'default',
        'custom',
        'red',
        'orange',
        'amber',
        'blue',
        'emerald',
        'green',
        'sky',
        'slate',
        'cyan',
        'teal',
        'indigo',
        'yellow',
        'gray',
    ]),
    loadingPosition: PropTypes.oneOf(['left', 'center']),
    titleSize: PropTypes.oneOf(['large', 'medium', 'small']),
};

export const PageNotFound = () => {
    const { appendBaseURL } = useNavigate();
    return (
        <LayoutCenter>
            <div>
                <Heading>The requested page was not found</Heading>
                <div className="tw-mt-6">
                    <NavLink
                        to={appendBaseURL('')}
                        variant={BUTTON.RAISED}
                        color={BUTTON.PRIMARY}
                    >
                        Back
                    </NavLink>
                </div>
            </div>
        </LayoutCenter>
    );
};

export const WidgetsContainer = ({ className, children, ...rest }) => {
    return (
        <div
            className={classnames(className, {
                'tw-mx-auto tw-w-full tw-py-4': true,
                'tw-max-w-screen-2xl': false,
            })}
        >
            {children}
        </div>
    );
};

WidgetsContainer.propTypes = {
    size: PropTypes.string,
};

export const FormatPeriod = ({ period }) => {
    return <div className={'tw-text-tiny tw-font-semibold tw-text-neutral-500'}>{formatPeriodDetailed(period)}</div>;
};

export const CollectedOnTimestamp = ({ timestamp, className, ...rest }) => {
    return (
        <div
            className={classnames(className, 'tw-flex tw-items-center tw-justify-center tw-text-tiny tw-font-semibold tw-text-neutral-600')}
            {...rest}
        >
            {timestamp ? (
                <>
                    <span>Collected on&nbsp;</span>
                    {formatPeriodDetailed({ start: timestamp, end: timestamp })}
                </>
            ) : (
                <span>&nbsp;</span>
            )}
        </div>
    );
};

export const SaveEndpointFilterAs = ({ open, onCancel, onSuccess, query = {} }) => {
    const api = useApi();
    const { organizationId } = useParams();
    const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [, setRecentlyUsedDeviceFilters] = useLocalStorageState(LOCAL_STORAGE_ID.RECENTLY_USED_DEVICE_FILTERS(organizationId), []);

    const saveFilter = () => {
        api.createDeviceFilter(name, query)
            .then((createdFilter) => {
                setRecentlyUsedDeviceFilters((current) => [createdFilter.id, ...current.filter((id) => id !== createdFilter.id)].slice(0, KEEP_FILTERS_COUNT)); // Keep last 5 only.
                onSuccess(createdFilter);
            })
            .catch(() => {
                setErrorMessage(`Filter names must be unique.`);
            });
    };

    useEffect(() => {
        if (open) {
            setName('');
            setErrorMessage(null);
        }
    }, [open]);

    const onChange = ({ target }) => {
        setErrorMessage(null);
        setName(target.value);
    };

    const onKeyDown = ({ target, key }) => {
        if (key === 'Enter' && target.value.trim() !== '') {
            saveFilter();
        }
    };

    return (
        <Drawer
            open={open}
            onClose={onCancel}
            anchor="right"
            PaperProps={drawerPaperProps}
        >
            <DialogTitle>Enter a filter name</DialogTitle>
            <DialogContent>
                <Input
                    value={name}
                    onChange={onChange}
                    callToAction
                    error={errorMessage !== null}
                    placeholder="Name"
                    onKeyDown={onKeyDown}
                    className="tw-mb-4"
                    helperText={errorMessage && <b>{errorMessage}</b>}
                    autoFocus
                />
                <LayoutRow align="end">
                    <Button
                        color={BUTTON.PRIMARY}
                        variant={BUTTON.RAISED}
                        disabled={name.trim() === ''}
                        onClick={saveFilter}
                    >
                        Save filter
                    </Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </LayoutRow>
            </DialogContent>
        </Drawer>
    );
};

SaveEndpointFilterAs.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    query: PropTypes.shape(),
};

export const JsonViewer = React.forwardRef(({ json, className, colorControlled = false, square = false, preElementClassName, ...rest }, ref) => {
    return (
        <div
            className={classnames(
                {
                    'tw-bg-slate-900 tw-text-white': !colorControlled,
                    'tw-overflow-auto': true,
                    'tw-rounded-lg': !square,
                },
                className
            )}
            ref={ref}
            {...rest}
        >
            <pre className={classnames('tw-m-4', preElementClassName)}>{JSON.stringify(json, null, 4)}</pre>
        </div>
    );
});

export const RenamingDialog = ({ open, onCancel, onSubmit, currentName, error = false, setError = () => null, errorMessage = '' }) => {
    const [newName, setNewName] = useState('');

    const handleSubmit = () => {
        if (newName.trim() !== '' && currentName.trim() !== newName.trim()) {
            onSubmit(newName);
        } else {
            onCancel();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            onEntering={() => setNewName(currentName)}
        >
            <DialogContent>
                <PageTitle
                    category="Renaming"
                    className="tw-mb-4"
                >
                    {currentName}
                </PageTitle>
                <Input
                    callToAction
                    placeholder="Enter a new name"
                    value={newName}
                    onChange={(e) => {
                        setError(null);
                        setNewName(e.target.value);
                    }}
                    autoFocus
                    onSubmit={handleSubmit}
                    error={error}
                    helperText={errorMessage}
                />
            </DialogContent>
            <DialogActions className="tw-mt-2 tw-pr-4">
                <Button
                    onClick={handleSubmit}
                    color={BUTTON.PRIMARY}
                    variant={BUTTON.RAISED}
                    disabled={newName.trim() === '' || currentName.trim() === newName.trim()}
                >
                    Save changes
                </Button>
                <Button
                    onClick={onCancel}
                    variant={BUTTON.RAISED}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const BooleanBadge = ({ value, size = 'medium', fallback = null, useSignalColor = false }) => {
    if (isBoolean(value)) {
        return (
            <Tooltip
                content={value ? 'Yes' : 'No'}
                extraPadding
                dark
                bold
            >
                <Icon
                    type={value ? 'checkmarkOutlined' : 'dashOutlined'}
                    size={size}
                    className={classnames({
                        'tw-text-emerald-500': useSignalColor && value,
                        'tw-text-red-500': useSignalColor && !value,
                    })}
                />
            </Tooltip>
        );
    }
    return fallback;
};

BooleanBadge.propTypes = {
    value: PropTypes.bool,
    useSignalColor: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'inherit']),
    fallback: PropTypes.any,
};

const standardUnitConverter = ({ value, unit }) => `${value} ${unit}`;

const unitConverters = {
    inch: ({ value }) => `${value} ″`,
    date: ({ value }) => formatDate(value),
    bytes: ({ value }) => formatBytes({ value, asText: true }),
    Hz: ({ value }) => formatHertz({ value, asText: true }),
    bps: ({ value }) => formatBitsPerSecond({ value, asText: true }),
    Bps: ({ value }) => formatBytesPerSecond({ value, asText: true }),
    percent: ({ value }) => formatPercentage({ value, asText: true }),
    ms: ({ value }) => formatMilliseconds({ value, asText: true }),
    locale: ({ value }) => getLanguageName(value),
    integer: ({ value }) => value,
    url: ({ value, name }) => (
        <a
            className="cs-link cs-link-primary"
            href={value}
            target="_BLANK"
            rel="noreferrer"
            key={value}
        >
            {name || value}
        </a>
    ),
    SecurityScore: ({ name = NOT_AVAILABLE, compact = true }) => {
        return (
            <Tooltip
                fullWidth
                content={
                    // Create a tooltip with a heading, a short description and an explnation for each of the security score colors.
                    <div>
                        {/* <div className="tw-w-112">
                            <PageTitle
                                subtitle
                                color="inherit"
                            >
                                Security Score
                            </PageTitle>
                        </div> */}
                        <div className="tw-flex tw-flex-col tw-gap-2 tw-font-medium">
                            <div className="tw-flex tw-items-center tw-gap-2">
                                <div className="tw-h-8 tw-w-1 tw-rounded-md tw-bg-emerald-400"></div>
                                <div className="tw-flex tw-flex-col">
                                    <b>{SECURITY_SCORE_NAME.GOOD}</b>
                                    <span>Indicates that everything is fine and further attention is not needed.</span>
                                </div>
                            </div>
                            <div className="tw-flex tw-items-center tw-gap-2">
                                <div className="tw-h-8 tw-w-1 tw-rounded-md tw-bg-amber-400"></div>
                                <div className="tw-flex tw-flex-col">
                                    <b>{SECURITY_SCORE_NAME.FAIR}</b>
                                    <span>Indicates that something might be wrong and attention is advised.</span>
                                </div>
                            </div>
                            <div className="tw-flex tw-items-center tw-gap-2">
                                <div className="tw-h-8 tw-w-1 tw-rounded-md tw-bg-red-400"></div>
                                <div className="tw-flex tw-flex-col">
                                    <b>{SECURITY_SCORE_NAME.POOR}</b>
                                    <span>Indicates that something is wrong and action is required.</span>
                                </div>
                            </div>
                            <div className="tw-flex tw-items-center tw-gap-2">
                                <div className="tw-h-8 tw-w-1 tw-rounded-md tw-bg-gray-400"></div>
                                <div className="tw-flex tw-flex-col">
                                    <b>{SECURITY_SCORE_NAME.NONE}</b>
                                    <span>Indicates that necessary information cannot be collected</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                extraPadding
                dark
            >
                <TailwindBadge
                    size={compact ? 'small' : 'default'}
                    color="custom"
                    noShadow
                    className={SECURITY_SCORE_NAME_BASED_STYLING[name] || 'tw-bg-neutral-100 tw-text-neutral-900'}
                    style={{ minWidth: compact ? 0 : 64 }}
                >
                    {name}
                </TailwindBadge>
            </Tooltip>
        );
    },
};

export const UnitConverter = ({ data, fallback = null }) => {
    if (isObject(data)) {
        if ('unit' in data && 'value' in data) {
            const handler = unitConverters[data.unit] || standardUnitConverter;
            return handler(data);
        }
        console.error(`Malformed data: ${JSON.stringify(data)}. Expected unit and value keys.`);
        return fallback;
    }
    return fallback || data;
};

UnitConverter.propTypes = {
    data: PropTypes.oneOfType([PropTypes.any, PropTypes.PropTypes.shape({ unit: PropTypes.string, value: PropTypes.any })]),
    fallback: PropTypes.any,
};

export const ActionsDialog = ({
    title,
    category,
    description,
    pages = [],
    actions = [],
    minWidth = 240,
    maxWidth = 300,
    disableEllipsis = false,
    onActionClick = (rowData) => null,
    onClose,
    visibleBackdrop = true,
    onExited = () => null,
    paperProps = { className: 'tw-shadow' },
    anchorEl = null,
    ...rest
}) => {
    const navigate = useNavigate();

    const handleNavigate = (page) => () => {
        if (page.target) {
            window.open(page.url, page.target);
            onClose();
        } else {
            navigate.to(page.url);
        }
    };

    return (
        <ContextDialog
            onClose={onClose}
            visibleBackdrop={visibleBackdrop}
            onExited={onExited}
            paperProps={paperProps}
            anchorEl={anchorEl}
            {...rest}
        >
            <DialogContent
                className="tw-p-0"
                style={{ minWidth, maxWidth }}
            >
                <div className="tw-px-8 tw-py-4">
                    <PageTitle
                        category={category}
                        description={description}
                        subtitle
                    >
                        {disableEllipsis ? title : <Ellipsis>{title}</Ellipsis>}
                    </PageTitle>
                </div>
                {pages.length > 0 && (
                    <div className="tw-px-2 tw-pb-2">
                        {pages.map((page, index) => (
                            <div key={index}>
                                <Button
                                    fullWidth
                                    onClick={handleNavigate(page)}
                                    className={classnames({
                                        'tw-justify-between tw-px-6 tw-py-1.5': true,
                                    })}
                                    {...page}
                                >
                                    <span className="tw-truncate">{page.name}</span>
                                    <span>
                                        <Icon
                                            type={page.icon || 'arrowRight'}
                                            size="small"
                                            className="tw-ml-4"
                                        />
                                    </span>
                                </Button>
                            </div>
                        ))}
                    </div>
                )}
                <div
                    className={classnames('tw-px-2 tw-pb-2', {
                        'tw-pt-2': pages.length > 0,
                    })}
                >
                    {actions.map((action, index) => {
                        if (isArray(action.actions)) {
                            return (
                                <div key={index}>
                                    <Tooltip
                                        disableHoverListener={!rest.open}
                                        position={TOOLTIP.POSITION.RIGHT_START}
                                        interactive
                                        light
                                        noPadding
                                        PopperProps={{
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [-8, 0],
                                                    },
                                                },
                                            ],
                                        }}
                                        content={
                                            <div className="tw-p-2">
                                                {action.actions.map((subAction) => (
                                                    <ActionsDialogButton
                                                        key={subAction.id}
                                                        {...subAction}
                                                        onClick={() => {
                                                            onActionClick(subAction);
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                        }
                                        fullWidth
                                    >
                                        <div>
                                            <ActionsDialogButton
                                                {...action}
                                                icon="arrowRight"
                                                disableRipple
                                                style={{ pointerEvents: 'none' }} // Needed for interactive tooltips when clicking the hovered element.
                                            />
                                        </div>
                                    </Tooltip>
                                </div>
                            );
                        }
                        return (
                            <div key={index}>
                                <ActionsDialogButton
                                    {...action}
                                    onClick={() => onActionClick(action)}
                                />
                            </div>
                        );
                    })}
                </div>
            </DialogContent>
        </ContextDialog>
    );
};

const ActionsDialogButton = React.forwardRef(({ color, className, name, id, icon, tooltipProps, data, ...rest }, ref) => (
    <Tooltip
        content=""
        extraPadding
        dark
        bold
        {...tooltipProps}
    >
        <div>
            <Button
                {...rest}
                key={id}
                color={color || BUTTON.PRIMARY}
                fullWidth
                className={classnames(className, {
                    'tw-justify-between tw-px-6 tw-py-1.5': true,
                })}
                ref={ref}
            >
                <span className="tw-truncate">{name}</span>
                <span>
                    {icon && (
                        <Icon
                            type={icon}
                            size="small"
                            className="tw-ml-4"
                        />
                    )}
                </span>
            </Button>
        </div>
    </Tooltip>
));

RenamingDialog.propTypes = {
    error: PropTypes.bool,
    setError: PropTypes.func,
    errorMessage: PropTypes.string,
};

ActionsDialog.propTypes = {
    title: PropTypes.string.isRequired,
    category: PropTypes.string,
    description: PropTypes.any,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    disableEllipsis: PropTypes.bool,
    visibleBackdrop: PropTypes.bool,
    paperProps: PropTypes.shape(),
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func,
    onActionClick: PropTypes.func,
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            icon: PropTypes.string,
        })
    ),
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            icon: PropTypes.string,
            data: PropTypes.any, // Allows you to pass custom data to onActionClick
            function: PropTypes.func,
        })
    ),
};

const priorityIdPrefix = 'priority-id-';

export const PriorityController = ({
    open,
    onCancel,
    onSubmit,
    items,
    onChange,
    rowRenderer = (item) => <span className="tw-font-semibold">{item.name}</span>,
    rowHeight = 48,
}) => {
    const forceRefresh = useForceRefresh();
    const [gridItems, setGridItems] = useState([]);
    const [draggedItems, setDraggedItems] = useState([]);
    const [saveIsDisabled, setSaveIsDisabled] = useState(undefined);
    const [searchTerm, setSearchTerm] = useState('');

    const scrollRef = useRef({
        index: 0,
        results: [],
    });

    useEffect(() => {
        setDraggedItems([]);
    }, [open]);

    const onLayoutChange = (layout) => {
        setGridItems(
            items.map((item, index) => {
                return {
                    ...item,
                    priority: layout[index].y + 1,
                };
            })
        );
        if (isUndefined(saveIsDisabled)) {
            setSaveIsDisabled(true);
        } else {
            setSaveIsDisabled(false);
        }
    };

    const onDragComplete = (layout, oldItem, newItem) => {
        const tempDraggedItems = draggedItems;
        const findItem = items.find((item) => item.id === newItem.i);
        if (findItem.priority !== newItem.y + 1) {
            const foundIndex = tempDraggedItems.findIndex((x) => x === findItem.id);
            if (foundIndex !== -1) {
                tempDraggedItems[foundIndex] = newItem.i;
            } else {
                tempDraggedItems.push(newItem.i);
            }
            setDraggedItems(tempDraggedItems);
        }
    };

    const showPreviousSearchResult = () => {
        if (scrollRef.current.index === 0) {
            scrollRef.current.index = scrollRef.current.results.length - 1;
        } else {
            scrollRef.current.index -= 1;
        }
        const container = document.getElementById(`${priorityIdPrefix}${scrollRef.current.results[scrollRef.current.index]?.id}`);
        if (container) {
            container.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
        forceRefresh();
    };

    const showNextSearchResult = () => {
        if (scrollRef.current.index === scrollRef.current.results.length - 1) {
            scrollRef.current.index = 0;
        } else {
            scrollRef.current.index += 1;
        }
        const container = document.getElementById(`${priorityIdPrefix}${scrollRef.current.results[scrollRef.current.index]?.id}`);
        if (container) {
            container.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
        forceRefresh();
    };

    const onSearch = (e) => {
        const term = e.target.value;
        scrollRef.current.results = term
            ? gridItems.sort((a, b) => a.priority - b.priority).filter((item) => item.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()))
            : [];
        scrollRef.current.index = scrollRef.current.results.length - 1;
        setSearchTerm(term);
        if (scrollRef.current.results.length > 0) {
            setTimeout(showNextSearchResult);
        }
    };

    const handleSubmit = () => {
        onSubmit(
            gridItems.filter((item, index) => item.priority !== items[index].priority),
            draggedItems
        );
    };

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            confirm
            onEntering={() => {
                scrollRef.current.index = 0;
                scrollRef.current.results = [];
                setSearchTerm('');
                setSaveIsDisabled(true);
                setGridItems(items);
            }}
            onExited={() => {
                setSaveIsDisabled(undefined);
                setGridItems([]);
            }}
        >
            <DialogTitle>
                <PageTitle description="Drag and drop to change a configuration's priority. 1 is the highest priority a configuration can achieve.">
                    Priority management
                </PageTitle>
                <LayoutCentered>
                    <Input
                        className="tw-w-96"
                        callToAction
                        rounded
                        light
                        placeholder="Search"
                        value={searchTerm}
                        onChange={onSearch}
                        endAdornment={
                            scrollRef.current.results.length > 0 ? (
                                <>
                                    <span className="tw-mr-2 tw-text-neutral-400">
                                        {scrollRef.current.index + 1}/{scrollRef.current.results.length}
                                    </span>
                                    <IconButton
                                        onClick={showPreviousSearchResult}
                                        noMargin
                                    >
                                        <Icon
                                            type="chevronUp"
                                            size="small"
                                        />
                                    </IconButton>
                                    <IconButton
                                        onClick={showNextSearchResult}
                                        noMargin
                                    >
                                        <Icon
                                            type="chevronDown"
                                            size="small"
                                        />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => onSearch({ target: { value: '' } })}
                                        noMargin
                                    >
                                        <Icon
                                            type="clear"
                                            size="small"
                                        />
                                    </IconButton>
                                </>
                            ) : searchTerm ? (
                                <IconButton
                                    onClick={() => onSearch({ target: { value: '' } })}
                                    noMargin
                                >
                                    <Icon
                                        type="clear"
                                        size="small"
                                    />
                                </IconButton>
                            ) : undefined
                        }
                    />
                </LayoutCentered>
            </DialogTitle>
            <DialogContent className="tw-bg-gradient-to-btw-from-emerald-500tw-to-sky-100 tw-overflow-x-hidden tw-px-0">
                <GridLayout
                    cols={1}
                    autoSize
                    isResizable={false}
                    rowHeight={rowHeight}
                    margin={[0, 0]}
                    containerPadding={[0, 0]}
                    onLayoutChange={onLayoutChange}
                    onDragStop={onDragComplete}
                >
                    {gridItems.map((item) => {
                        return (
                            <div
                                key={item.id}
                                data-grid={{
                                    x: 0,
                                    y: item.priority,
                                    i: item.id,
                                    w: 1,
                                    h: 1,
                                }}
                            >
                                <div className="tw-grid tw-h-full tw-cursor-grab tw-grid-cols-auto-1fr-auto  tw-items-center tw-gap-4 tw-px-4 hover:tw-bg-slate-50">
                                    <div>
                                        <TailwindBadge
                                            color="amber"
                                            className="tw-h-8 tw-w-8"
                                        >
                                            <LayoutCentered className="tw-font-bold">{item.priority}</LayoutCentered>
                                        </TailwindBadge>
                                    </div>
                                    <div id={priorityIdPrefix + item.id}>
                                        {searchTerm ? (
                                            <div className="tw-font-semibold">
                                                <Highlighter
                                                    searchWords={[searchTerm]}
                                                    textToHighlight={item.name}
                                                    highlightClassName="tw-font-bold"
                                                />
                                            </div>
                                        ) : (
                                            rowRenderer(item)
                                        )}
                                    </div>
                                    <div>
                                        {item.priority === 1 && (
                                            <TailwindBadge
                                                color="purple"
                                                size="small"
                                            >
                                                Highest Priority
                                            </TailwindBadge>
                                        )}
                                        {item.priority === gridItems.length && (
                                            <TailwindBadge
                                                color="sky"
                                                size="small"
                                            >
                                                Lowest Priority
                                            </TailwindBadge>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </GridLayout>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit}
                    noMargin
                    variant={BUTTON.RAISED}
                    color={BUTTON.PRIMARY}
                    disabled={saveIsDisabled}
                >
                    Save changes
                </Button>
                <Button
                    onClick={onCancel}
                    noMargin
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

PriorityController.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            priority: PropTypes.number.isRequired,
        })
    ).isRequired,
    rowRenderer: PropTypes.func,
    rowHeight: PropTypes.number,
};

export const PriorityControllerButton = (props) => {
    return (
        <Tooltip
            content="Change priority for configurations"
            extraPadding
            bold
            dark
        >
            <IconButton
                color={BUTTON.PRIMARY}
                {...props}
                className="tw--scale-y-100 tw-transition-transform tw-duration-300 hover:tw-rotate-180"
            >
                <Icon type="lowPriority" />
            </IconButton>
        </Tooltip>
    );
};

export const PriorityVisualization = ({ percentage, priority }) => {
    return (
        <LayoutColumn className="tw-w-full">
            <LoadingLinear
                variant="determinate"
                value={percentage}
                classes={{
                    root: 'tw-h-2 tw-rounded-full',
                }}
            />
            <LayoutRow
                align="space-between"
                className="tw-mt-1 tw-text-tiny"
            >
                <b>Lowest</b>
                <span className="tw-w-2"></span>
                <b>Highest</b>
            </LayoutRow>
        </LayoutColumn>
    );
};

export const WindowsEndpointsFilterPicker = ({ open, onClose, groups, filterPick }) => {
    const api = useApi();
    const navigate = useNavigate();
    const [savedFilters, setSavedFilters] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const { organizationId } = useParams();

    const [recentlyUsedDeviceFilters, setRecentlyUsedDeviceFilters] = useLocalStorageState(LOCAL_STORAGE_ID.RECENTLY_USED_DEVICE_FILTERS(organizationId), []);

    const applyFilter = (filter, editMode) => () => {
        if (groups) {
            filterPick(filter);
        } else {
            if (editMode) {
                navigate.to(`windows/device/filter/${filter.id}/edit`);
            } else {
                navigate.to(`windows/device/filter/${filter.id}/apply`);
            }
        }
    };

    useEffect(() => {
        if (open) {
            setSavedFilters([]);
            setLoading(true);
            api.getDeviceFilters()
                .then((data) => {
                    setLoading(false);
                    setSavedFilters(data);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [open]);

    const hideSavedFilters = () => {
        api.cancel();
        onClose();
    };

    const onSearch = ({ target: { value } }) => {
        setSearchText(value);
    };

    const searchFilter = ({ name }) => {
        if (searchText) {
            return name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
        }
        return true;
    };

    return (
        <Drawer
            open={open}
            anchor="right"
            PaperProps={drawerPaperProps}
            onClose={hideSavedFilters}
        >
            <DialogContent>
                <Input
                    onChange={onSearch}
                    placeholder="Search"
                    type="search"
                    callToAction
                    light
                    rounded
                    value={searchText}
                    endAdornment={loading && <Loading size={14} />}
                />
                {recentlyUsedDeviceFilters.length > 0 && (
                    <>
                        <LayoutRow
                            align="space-between"
                            className="tw-mt-4"
                        >
                            <Heading
                                className="tw-m-0"
                                bold
                                subheading
                            >
                                Recently used
                            </Heading>
                            <div>
                                <Button
                                    onClick={() => setRecentlyUsedDeviceFilters([])}
                                    noMargin
                                >
                                    Clear recent
                                </Button>
                            </div>
                        </LayoutRow>

                        {recentlyUsedDeviceFilters.map((filterId) => {
                            const found = savedFilters.find((filter) => filter.id === filterId);
                            if (found && searchFilter(found)) {
                                return (
                                    <WindowsEndpointsFilterPickerRow
                                        {...found}
                                        key={found.id}
                                        onApply={applyFilter(found, false)}
                                        onEdit={applyFilter(found, true)}
                                        groups={groups}
                                    />
                                );
                            }
                            return null;
                        })}
                    </>
                )}
                <LayoutRow
                    align="space-between"
                    className="tw-mt-4"
                >
                    <Heading
                        className="tw-m-0"
                        bold
                        subheading
                    >
                        Saved filters
                    </Heading>
                </LayoutRow>
                {savedFilters
                    .filter(searchFilter)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((filter) => {
                        if (recentlyUsedDeviceFilters.includes(filter.id)) {
                            return null;
                        }
                        return (
                            <WindowsEndpointsFilterPickerRow
                                {...filter}
                                key={filter.id}
                                onApply={applyFilter(filter, false)}
                                onEdit={applyFilter(filter, true)}
                                groups={groups}
                            />
                        );
                    })}
            </DialogContent>
        </Drawer>
    );
};

const WindowsEndpointsFilterPickerRow = ({ name, id, onEdit, onApply, groups }) => (
    <LayoutRow
        verticalAlign="center"
        className="tw-mt-4"
        align="space-between"
    >
        <LayoutRow
            verticalAlign="center"
            className="tw-mr-8"
        >
            <IconButton
                color={BUTTON.PRIMARY}
                variant={BUTTON.RAISED}
                onClick={onApply}
                className="tw-mr-4"
            >
                <Icon type="add"></Icon>
            </IconButton>
            <b>{name}</b>
        </LayoutRow>
        {!groups && (
            <IconButton
                color={BUTTON.PRIMARY}
                onClick={onEdit}
                noMargin
            >
                <Icon
                    type="edit"
                    size="small"
                ></Icon>
            </IconButton>
        )}
    </LayoutRow>
);

export const BatteryLevel = ({ percent = null }) => {
    const getBatteryStatus = () => {
        if (percent < 2) {
            return {
                type: 'battery',
                className: 'tw-text-slate-300',
            };
        }
        if (percent <= 20) {
            return {
                type: 'battery20',
                className: 'tw-text-red-400',
            };
        }
        if (percent <= 30) {
            return {
                type: 'battery30',
                className: 'tw-text-amber-400',
            };
        }
        const status = {
            type: 'battery',
            className: 'tw-text-emerald-400',
        };
        if (percent <= 50) {
            status.type = 'battery50';
        } else if (percent < 60) {
            status.type = 'battery60';
        } else if (percent < 80) {
            status.type = 'battery80';
        } else if (percent < 90) {
            status.type = 'battery90';
        }
        return status;
    };
    if (percent === null || !isNumber(percent)) {
        return <i className="tw-text-neutral-300">{NONE}</i>;
    }
    return (
        <LayoutRow verticalAlign="center">
            <Icon {...getBatteryStatus()} />
            <b className="tw-ml-1 tw-text-tiny tw-text-xs tw-font-semibold">{percent.toFixed(0)}%</b>
        </LayoutRow>
    );
};

export const WithExperimentalFeatures = ({ children, tooltipPosition = TOOLTIP.POSITION.TOP, disableHoverListener = false }) => {
    const { permissions } = useAuthContext();
    if (permissions.canViewExperimentalFeatures) {
        return (
            <Tooltip
                dark
                extraPadding
                bold
                position={tooltipPosition}
                classes={{
                    tooltip: 'tw-bg-rose-500',
                }}
                disableHoverListener={disableHoverListener}
                content={
                    <div className="tw-flex tw-items-center tw-gap-2">
                        <Icon
                            type="tipsAndUpdatesOutlined"
                            size="small"
                        />
                        <span>Restricted to CapaSystems</span>
                    </div>
                }
            >
                {children}
            </Tooltip>
        );
    }
    return null;
};

export const CapaOneLink = React.forwardRef(({ to, className, color = 'primary', replace = false, ...otherProps }, ref) => {
    const { organizationId } = useParams();
    return (
        <ReactRouterDomLink
            ref={ref}
            to={
                isString(to)
                    ? `/organization/${organizationId}/${to}`
                    : {
                          ...to,
                          pathname: `/organization/${organizationId}/${to.pathname}`,
                      }
            }
            className={classnames(className, {
                'tw-text-sky-600': color === 'primary',
            })}
            replace={replace}
            {...otherProps}
        />
    );
});

CapaOneLink.propTypes = {
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            search: PropTypes.string,
        }),
    ]).isRequired,
    replace: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'inherit']),
};
