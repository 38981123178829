export const AndroidEnrollmentConfiguration = {
    schema: {
        title: 'Set up your enrollment configuration',
        description: 'An enrollment configuration represents a group of settings that control the behavior of the enrollment process of managed devices.',
        type: 'object',
        schemaId: 'enrollmentConfiguration',
        properties: {
            name: {
                title: 'Name',
                description: 'Give your configuration a short, descriptive name that describes its purpose. For example, Sales team or Students.',
                type: 'string',
                required: true,
            },
            description: {
                title: 'Description',
                description: 'Describe your enrollment configuration',
                type: 'string',
                multiline: true,
            },
            allowPersonalUsage: {
                title: 'Enable work profile',
                description:
                    "Sets up a work profile on the device to separate work apps and data from personal apps and data. With a work profile you can securely and privately use the same device for work and personal purposes.\nYour organization manages work apps and data while personal apps, data, and usage remain private.\nWork apps are marked with a briefcase icon so you can distinguish them from personal apps.<div class='tw-font-bold'>Work profile is required for personally-owned devices.</div>",
                type: 'boolean',
                required: true,
                disabled: false,
                tooltipEnabled: true,
            },
            relations: {
                title: 'Relations',
                description: `Assign groups, configurations, applications and user info to endpoints during enrollment.`,
                type: 'sectionheader',
            },
            groupIds: {
                title: 'Groups',
                description: 'Assigned during enrollment.',
                multiple: true,
                type: 'array',
                items: {
                    type: 'string',
                    options: [], // Lazy loaded
                },
            },
            configurationIds: {
                title: 'Configurations',
                description: 'Assigned during enrollment.',
                type: 'array',
                multiple: true,
                items: {
                    type: 'string',
                    options: [], // Lazy loaded
                },
            },
            applicationIds: {
                title: 'Applications',
                description: 'Assigned during enrollment.',
                type: 'array',
                multiple: true,
                items: {
                    type: 'string',
                    options: [], // Lazy loaded
                },
                minColumnWidth: 350,
                primaryContentClassName: 'tw-font-semibold',
                secondaryContentClassName: 'tw-text-tiny',
            },
            userAuthenticationMethod: {
                title: 'Authentication',
                description:
                    'Authenticate users during the enrollment process.<div><b>Microsoft authentication requires Azure AD integration (Management &rarr; Integrations &rarr; Azure AD)</b></div>',
                type: 'string',
                required: true,
                disabled: false,
                tooltipEnabled: true,
                options: [
                    {
                        id: 'NONE',
                        name: 'No user authentication',
                        default: true,
                    },
                    {
                        id: 'MICROSOFT',
                        name: 'Microsoft authentication',
                    },
                ],
            },
            /*
            allowedDomains: {
                title: "Allowed domains for authenticated enrollment",
                description: "Only fully qualified domain names (FQDN) can be used. Leave the field blank to allow all domains.\nIf you have multiple domains, they must be separated by a comma",
                help: "If you have multiple domains, they must be separated by a comma",
                placeholder: "Example: mydomain.com",
                type: "string",
                shouldRender: ( siblings ) => {
                    if ( siblings.userAuthenticationMethod && siblings.userAuthenticationMethod !== "NONE" ){
                        if ( shouldScroll ){
                            setTimeout(() => {
                                const container = document.querySelector('[data-schema-property-id="allowedDomains"]');
                                if ( container ){
                                    container.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'start'
                                    });
                                }
                            }, 300);
                            shouldScroll = false;
                        }
                        return true;
                    }
                    delete siblings.allowedDomains;
                    shouldScroll = true;
                    return false;
                }
            }, */
            customization: {
                title: 'Customization',
                description: 'Control the behavior of the enrollment process of managed devices.',
                type: 'sectionheader',
            },
            duration: {
                title: 'Length of time the enrollment token is valid',
                description: 'The amount of time the enrollment token will be active.',
                type: 'string',
                required: true,
                disabled: false,
                tooltipEnabled: true,
                options: [
                    {
                        id: '315576000000s',
                        name: 'No expiration',
                        default: true,
                    },
                    {
                        id: `${60 * 60}s`,
                        name: '1 hour',
                    },
                    {
                        id: `${60 * 60 * 24}s`,
                        name: '1 day',
                    },
                    {
                        id: `${60 * 60 * 24 * 7}s`,
                        name: '7 days',
                    },
                    {
                        id: `${60 * 60 * 24 * 30}s`,
                        name: '30 days',
                    },
                    {
                        id: `${60 * 60 * 24 * 90}s`,
                        name: '90 days',
                    },
                    {
                        id: `${60 * 60 * 24 * 365}s`,
                        name: '1 year',
                    },
                ],
            },
            enrollDevicesUsingMobileData: {
                title: 'Use mobile data during provisioning',
                description:
                    'Only supported on devices running Android 10 and above. If a wifi network SSID is also specified, wifi network will be used instead.',
                type: 'boolean',
                required: true,
            },
            skipEncryption: {
                title: 'Skip encryption',
                description: 'Indicating whether device encryption can be skipped as part of device owner or managed profile provisioning.',
                type: 'boolean',
                required: true,
            },
            enableSystemApplications: {
                title: 'Enable system apps during provisioning',
                description: 'Will skip the disabling of system apps during provisioning.',
                type: 'boolean',
                required: true,
                defaultValue: true,
            },
            oneTimeOnly: {
                title: 'One-time only',
                description: 'Indicate whether the QR code can be used more than one time.',
                type: 'boolean',
                required: true,
                disabled: false,
                tooltipEnabled: true,
            },
            keepScreenOnDuringProvisioning: {
                title: 'Keep screen on during provisioning',
                description: 'Allow the device screen to be active during provisioning.',
                type: 'boolean',
                required: true,
                defaultValue: true,
            },
            useWifiDuringProvisioning: {
                title: 'Use wifi network during provisioning',
                description:
                    'Connect the device to a corporate wifi network during provisoning.\nWifi configuration is not supported on zero-touch enrollment.',
                type: 'boolean',
                required: true,
            },
            wifiSSID: {
                title: 'Network name (SSID)',
                description: 'The SSID of the network.',
                type: 'string',
                required: true,
                shouldRender: (siblings: any) => {
                    return siblings.useWifiDuringProvisioning;
                },
            },
            wifiPassword: {
                title: 'Network password',
                description: '',
                type: 'string',
                required: true,
                isPassword: true,
                shouldRender: (siblings: any) => {
                    return siblings.useWifiDuringProvisioning;
                },
            },
            wifiSecurityType: {
                title: 'Security type',
                description: 'The type of security which is used by the WiFi.',
                type: 'string',
                required: true,
                options: [
                    {
                        id: 'NONE',
                        name: 'NONE',
                    },
                    {
                        id: 'WPA',
                        name: 'WPA',
                        default: true,
                    },
                    {
                        id: 'WEP',
                        name: 'WEP',
                    },
                    {
                        id: 'EAP',
                        name: 'EAP',
                    },
                ],
                shouldRender: (siblings: any) => {
                    return siblings.useWifiDuringProvisioning;
                },
            },
            allowOfflineEnrollment: {
                title: 'Allow offline enrollment',
                description:
                    "In Android 13 (API level 33) and higher, internet connectivity is required by default to provision company-owned devices. Only use this flag if a device is expected to be provisioned offline or in a closed-network environment.\nProvisioning without internet connectivity results in a loss of functionality, so you should only use this flag when devices can't access the internet as part of deployment requirements.",
                type: 'boolean',
                required: true,
                defaultValue: false,
            },
        },
    },
};
