export const coreAndroidManagement = {
    configurationType: {
        android: 'android',
        application: 'application',
        webapp: 'webApp',
    },
};

export const userFacingMessageProperties = {
    defaultMessage: {
        title: 'Default Message',
        description:
            "The default message displayed if no localized message is specified or the user's locale doesn't match with any of the localized messages.",
        help: 'Note: A default message must be provided if any localized messages are provided.<br/>The maximum message length is 4096 characters.',
        type: 'string',
        multiline: true,
        required: (siblings: any) => siblings.localizedMessages && Object.keys(siblings.localizedMessages).length > 0,
    },
    localizedMessages: {
        title: 'Localized Messages',
        description: 'A map containing &lt;locale, message&gt; pairs, where locale is a well-formed BCP 47 language code, such as en-US, es-ES, or fr.',
        type: 'map',
        properties: {
            key: {
                title: 'Locale',
                description: 'A well-formed BCP 47 language code, such as en-US, es-ES, or fr.',
                type: 'string',
                required: true,
            },
            value: {
                title: 'Message',
                description: 'The maximum message length is 4096 characters.',
                type: 'string',
                required: true,
                multiline: true,
            },
        },
    },
};
