import { windowsApplication } from '@thirdparty/constants';

// todo: Use the correct one when the type is known
type TTempCustomApp = {
    fileInfo: {
        fileUploaded: boolean;
        failed: boolean;
    };
    type: string;
};

const windowsApplicationUtils = {
    fileUploadCompleted: (application: TTempCustomApp) => {
        if (application.type === windowsApplication.type.customApp.id) {
            return application.fileInfo?.fileUploaded === true; // Do not display custom apps until content is uploaded
        }
        return true;
    },
    fileUploadCompletedOrFailed: (application: TTempCustomApp) => {
        if (application.type === windowsApplication.type.customApp.id) {
            return application.fileInfo?.fileUploaded === true || application.fileInfo?.failed === true;
        }
        return true;
    },
    fileUploadInProgress: (application: TTempCustomApp) => {
        if (application.type === windowsApplication.type.customApp.id) {
            return application.fileInfo?.fileUploaded === false && application.fileInfo?.failed !== true;
        }
        return false;
    },
};

export { windowsApplicationUtils };
