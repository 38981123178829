export const AndroidConnectivityManagementConfiguration = {
    schema: {
        title: 'Connectivity Management',
        description: 'Covers controls for device connectivity such as Wi-Fi, USB data access, keyboard/mouse connections, and more.',
        type: 'object',
        category: 'Restrictions',
        schemaId: 'connectivityManagementConfiguration',
        properties: {
            deviceConnectivityManagement: {
                title: '',
                type: 'object',
                properties: {
                    usbDataAccess: {
                        title: 'USB Data Access',
                        description: 'Controls what files and/or data can be transferred via USB. Supported only on company-owned devices.',
                        type: 'string',
                        help: 'See documentation for more details.',
                        setUrl: 'UsbDataAccess',
                        options: [
                            {
                                id: 'ALLOW_USB_DATA_TRANSFER',
                                name: 'Allow USB Data Transfer',
                            },
                            {
                                id: 'DISALLOW_USB_FILE_TRANSFER',
                                name: 'Disallow USB File Transfer',
                            },
                            {
                                id: 'DISALLOW_USB_DATA_TRANSFER',
                                name: 'Disallow USB Data Transfer',
                            },
                        ],
                    },
                    configureWifi: {
                        title: 'Configure Wi-Fi',
                        description:
                            'Controls Wi-Fi configuring privileges. Based on the option set, user will have either full or limited or no control in configuring Wi-Fi networks.',
                        type: 'string',
                        help: 'See documentation for more details.',
                        setUrl: 'ConfigureWifi',
                        options: [
                            {
                                id: 'ALLOW_CONFIGURING_WIFI',
                                name: 'Allow Configuring Wi-Fi',
                            },
                            {
                                id: 'DISALLOW_ADD_WIFI_CONFIG',
                                name: 'Disallow Add Wi-Fi Configurations',
                            },
                            {
                                id: 'DISALLOW_CONFIGURING_WIFI',
                                name: 'Disallow Configuring Wi-Fi',
                            },
                        ],
                    },
                    wifiDirectSettings: {
                        title: 'Configure Wi-Fi',
                        description: 'Controls Wi-Fi direct settings.',
                        type: 'string',
                        help: 'Supported on company-owned devices running Android 13 and above. See documentation for more details.',
                        setUrl: 'WifiDirectSettings',
                        options: [
                            {
                                id: 'ALLOW_WIFI_DIRECT',
                                name: 'Allow Wi-Fi Direct',
                            },
                            {
                                id: 'DISALLOW_WIFI_DIRECT	',
                                name: 'Disallow Wi-Fi Direct',
                            },
                        ],
                    },
                    tetheringSettings: {
                        title: 'Tethering Settings',
                        description:
                            'Controls the extent to which the user is allowed to use different forms of tethering like Wi-Fi tethering, bluetooth tethering, etc.',
                        type: 'string',
                        help: 'See documentation for more details.',
                        setUrl: 'TetheringSettings',
                        options: [
                            {
                                id: 'ALLOW_ALL_TETHERING',
                                name: 'Allow All Tethering',
                            },
                            {
                                id: 'DISALLOW_WIFI_TETHERING',
                                name: 'Disallow Wi-Fi Tethering',
                            },
                            {
                                id: 'DISALLOW_ALL_TETHERING',
                                name: 'Disallow All Tethering',
                            },
                        ],
                    },
                    wifiSsidPolicy: {
                        title: 'Wi-Fi SSID Policy',
                        description: 'Restrictions on which Wi-Fi SSIDs the device can connect to.',
                        type: 'object',
                        help: 'Note that this does not affect which networks can be configured on the device. Supported on company-owned devices running Android 13 and above.',
                        properties: {
                            wifiSsidPolicyType: {
                                title: 'Wi-Fi SSID Policy Type',
                                description: 'Type of the Wi-Fi SSID policy to be applied.',
                                help: 'See documentation for more details.',
                                setUrl: 'WifiSsidPolicyType',
                                type: 'string',
                                options: [
                                    {
                                        id: 'WIFI_SSID_DENYLIST',
                                        name: 'Blocklist',
                                    },
                                    {
                                        id: 'WIFI_SSID_ALLOWLIST',
                                        name: 'Allowlist',
                                    },
                                ],
                            },
                            wifiSsids: {
                                title: 'Wi-Fi SSIDs',
                                description: 'Optional. List of Wi-Fi SSIDs that should be applied in the policy.',
                                help: 'This field is required when "Wi-Fi Ssid Policy Type" is set to "Allowlist". If this is set to a non-empty list, then a nonComplianceDetail detail with API_LEVEL is reported if the Android version is less than 13 and a nonComplianceDetail with MANAGEMENT_MODE is reported for non-company-owned devices.',
                                setUrl: 'WifiSsid',
                                shouldRender: (siblings: any) => {
                                    if (siblings.wifiSsidPolicyType === 'WIFI_SSID_ALLOWLIST') {
                                        return true;
                                    }
                                    delete siblings.wifiSsids;
                                    return false;
                                },
                                required: (siblings: any) => siblings.wifiSsidPolicyType === 'WIFI_SSID_ALLOWLIST',
                                type: 'array',
                                items: {
                                    type: 'object',
                                    properties: {
                                        ssid: {
                                            title: 'SSID',
                                            description: 'SSID of the Wi-Fi network.',
                                            type: 'string',
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};
