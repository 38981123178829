import { useState } from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import { patchInstallOrUninstallFailed, patchIsAvailable, patchIsInstalled, patchIsInProgress, patchIsInstalledButRestartRequired } from '@thirdparty/utils';
import useLocalStorageState from '../useLocalStorageState/useLocalStorageState';
import { Url } from '@capasystems/utils';
import { LOCAL_STORAGE_ID } from '@thirdparty/constants';

export const useDriverPatchStatusTabs = () => {
    const [selectedTab, setSelectedTab] = useLocalStorageState(LOCAL_STORAGE_ID.ACTIVE_DRIVER_PATCH_STATUS_TAB, 0, Url.getNumber, Url.set);
    const [searchString, setSearchString] = useState('');

    const onTabChange = (e, newSelectedTab) => {
        setSelectedTab(newSelectedTab);
    };

    const getFilter = () => {
        switch (selectedTab) {
            case 0:
                return patchIsAvailable;
            case 1:
                return patchIsInProgress;
            case 2:
                return patchIsInstalledButRestartRequired;
            case 3:
                return patchIsInstalled;
            case 4:
                return patchInstallOrUninstallFailed;
            default:
                return () => null;
        }
    };

    return {
        onTabChange,
        selectedTab,
        patchStatusFilter: getFilter(),
        searchString,
        setSearchString,
        showUninstallButton: selectedTab === 0 || selectedTab === 3 || selectedTab === 4,
        showingUpdateAvailable: selectedTab === 0,
        showingNotInstalled: selectedTab === 1,
        showingInProgress: selectedTab === 2,
        showingRestartRequired: selectedTab === 3,
        showingUpToDate: selectedTab === 4,
        showingFailed: selectedTab === 5,
        showPrimaryAction: selectedTab === 0 || selectedTab === 1 || selectedTab === 5,
        showReinstallButton: selectedTab >= 3,
        showInstallDateColumn: selectedTab === 3 || selectedTab === 4,
        showStatusColumn: selectedTab === 2 || selectedTab === 5,
        showRequestedDate: selectedTab === 2,
    };
};
