import { useApi } from '../hooks/useApi/useApi';
import { useState, useEffect, useMemo, useRef } from 'react';
import { WidgetPaper } from '../thirdparty-components/thirdparty-components';
import { PieChart, eSize } from '../charts/pie-chart';

export const DriverUpdateCategory = ({ className }) => {
    const api = useApi();

    const textRef = useRef();

    const [chartData, setChartData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.getDevicesDriverCompliance()
            .then(setChartData)
            .catch(() => setChartData())
            .finally(() => setLoading(false));
    }, []);

    const calculatedData = useMemo(() => {
        if (!chartData) {
            return [];
        }

        const { patches } = chartData;

        const details = {};

        for (const patch of patches) {
            const { updates } = patch;
            [
                ...new Set(
                    updates.reduce((p, c) => {
                        return [...p, c.category];
                    }, [])
                ),
            ].forEach((category) => {
                if (!details[category]) {
                    details[category] = 1;
                } else {
                    details[category]++;
                }
            });
        }

        return Object.entries(details)
            .map(([key, value]) => ({
                name: key,
                y: value,
            }))
            .sort((a, b) => b.y - a.y);
    }, [chartData]);

    textRef.current = calculatedData?.reduce((prev, d) => prev + 1, 0);

    return (
        <WidgetPaper
            title="Driver Update Categories"
            description="Percentage of out of date drivers by category"
            className={className}
            loading={loading}
        >
            <PieChart
                data={calculatedData}
                size={eSize.large}
            />
        </WidgetPaper>
    );
};
