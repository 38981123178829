import { CustomEvent, UserManagement } from '@piwikpro/react-piwik-pro';
import useAuthContext from '../useAuthContext/useAuthContext';
import { BASE_ORGANIZATION_ROUTE } from '@thirdparty/constants';

// const doNotTrack = navigator.doNotTrack === "1" || window.doNotTrack === "1";

const useAnalytics = () => {
    const authContext = useAuthContext();

    const sendPageView = () => {
        const { pathname, search } = window.location;
        const updatedPathname = pathname.replace(BASE_ORGANIZATION_ROUTE.replace(':organizationId', authContext.selectedOrganization.id), '');
        // Piwik
        CustomEvent.trackEvent('PageView', updatedPathname + search, authContext.selectedOrganization.name, authContext.selectedOrganization.id);
    };

    const initialize = (debug = false) => {
        // Piwik
        UserManagement.setUserId(authContext.user.id);
    };

    return {
        sendPageView,
        initialize,
    };
};

export { useAnalytics };
