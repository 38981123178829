import { BUTTON } from '@capasystems/constants';
import { Button, Checkbox, Dialog, UploadButton } from '@capasystems/ui';
import { useTheme } from '@mui/material';
import { useState } from 'react';
import ConfirmDialog from '../../../confirm-dialog/confirm-dialog';
import { DrawerStepRow } from '../../../drawer-step-row/drawer-step-row';
import { useAppleApi } from '../../../hooks/useApi/useApi';
import useAuthContext from '../../../hooks/useAuthContext/useAuthContext';
import { useCoreContext } from '../../../hooks/useCoreContext/useCoreContext';
import useMessageContext from '../../../hooks/useMessageContext/useMessageContext';
import { useAppleClusterEvent } from '../../../hooks/useSocket/useSocket';
import { AppleCertificatePaper } from '../AppleCertificatePaper';

const AppleSettingsDEP = () => {
    const [confirm, setConfirm] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [depCertificateFile, setDepCertificateFile] = useState(null);
    const [uploadDialog, setUploadDialog] = useState(false);
    const { appleEnterpriseAccount } = useCoreContext();
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const { selectedOrganization } = useAuthContext();
    const appleApi = useAppleApi();

    const { showErrorMessage } = useMessageContext();

    const hasDEPCertificateExpired =
        appleEnterpriseAccount?.hasDepCertificate && appleEnterpriseAccount?.depTokenExpireDate
            ? appleEnterpriseAccount?.depTokenExpireDate < new Date()
            : false;

    const api = useAppleApi();

    const theme = useTheme();

    useAppleClusterEvent(async (data) => {
        if (data?.operationType === 'update') {
            if (data.fullDocument?.depIsReady && confirm) {
                setConfirm(false);
                setUploadDialog(true);
                setConfirmLoading(false);
            }
        }
    });

    const onCertificateFileChange = (fileDetails) => {
        if (fileDetails.files.length !== 0) {
            if (fileDetails.filename.endsWith('.p7m')) {
                setDepCertificateFile(fileDetails.files[0]);
            } else {
                showErrorMessage('The file name must be dep_token.p7m', { autoHideDuration: 600 });
            }
        }
    };

    const handleCheckboxChange = (event) => {
        setCheckboxChecked(event.target.checked);
    };

    return (
        <>
            <AppleCertificatePaper
                title="DEP Certificate"
                description="Device Enrollment Program certificate"
                expireDate={appleEnterpriseAccount?.depTokenExpireDate}
                onUpdate={() => setConfirm(true)}
            >
                A Device Enrollment Program certificate (DEP) is a digital credential used to enroll and manage Apple devices in your organization's device
                management solution.
                <br />
                <br /> By uploading your DEP certificate, you can streamline the process of provisioning and configuring iOS and macOS devices for your
                organization. This certificate ensures secure and seamless device deployment, simplifying the setup and management of your Apple devices.
                <ConfirmDialog
                    disabled={!checkboxChecked}
                    open={confirm}
                    onCancel={() => {
                        setConfirm(false);
                        if (checkboxChecked) {
                            setCheckboxChecked(false);
                        }
                    }}
                    onConfirm={() => {
                        api.updateDepRequest()
                            .then(() => {
                                setConfirmLoading(true);
                            })
                            .catch((error) => {
                                showErrorMessage(error.message, { autoHideDuration: 600 });
                                setConfirmLoading(false);
                            });
                    }}
                    isProcessing={confirmLoading}
                >
                    <p>Are you sure you want to update the DEP Certificate?</p>
                    <p>Read these steps carefully to create your DEP certificate</p>
                    <DrawerStepRow badgeContent={1}>
                        <p>
                            Make sure that you can log in to your{' '}
                            <a
                                href="https://business.apple.com/#/main/preferences/myprofile"
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: theme.palette.primary.main }}
                            >
                                <br />
                                <b>Apple Business Account</b>
                                <Checkbox
                                    checked={checkboxChecked}
                                    onChange={handleCheckboxChange}
                                />
                            </a>
                        </p>
                    </DrawerStepRow>
                    <DrawerStepRow badgeContent={2}>
                        <p>
                            Click <b>Confirm</b>. Then just follow the steps on screen.
                        </p>
                    </DrawerStepRow>
                </ConfirmDialog>
                <Dialog
                    open={uploadDialog}
                    onClose={() => {
                        setUploadDialog(false);
                        if (checkboxChecked) {
                            setCheckboxChecked(false);
                        }
                        setDepCertificateFile(null);
                    }}
                >
                    <div className="tw-grid tw-w-full tw-max-w-3xl tw-grid-rows-1fr-auto tw-p-4">
                        <div className="tw-flex tw-flex-col tw-items-center">
                            <h1 className="tw-mb-2">DEP Certificate</h1>
                            <p>We need your Apple DEP certificate, you can always update this later on. If you do not have a DEP certificate do these steps</p>
                        </div>
                        <DrawerStepRow badgeContent={1}>
                            Download this{' '}
                            <a
                                href={`${window.location.protocol}//${window.location.host}/api/organizations/${selectedOrganization?.id}/apple/enrollment/deprequest/download`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: theme.palette.primary.main }}
                            >
                                <b>Public Key</b>
                            </a>
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={2}>
                            <p>
                                Log in to your{' '}
                                <a
                                    href="https://business.apple.com/#/main/preferences/myprofile"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: theme.palette.primary.main }}
                                >
                                    <b>Apple Business Account</b>
                                </a>
                            </p>
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={3}>
                            Press <b>Add</b> by your MDM Servers, and upload the Public Key to generate the MDM server.
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={4}>
                            Navigate to your newly created server and click on <b>Download Token</b>.
                        </DrawerStepRow>
                        <DrawerStepRow badgeContent={5}>
                            Click the <b>Upload DEP Token</b> button and confirm.
                        </DrawerStepRow>
                        <div className="tw-mt-4 tw-grid tw-w-full tw-grid-cols-1 tw-gap-4">
                            <UploadButton
                                variant={BUTTON.RAISED}
                                color={BUTTON.PRIMARY}
                                htmlId="upload-depToken-button"
                                accept=".p7m"
                                onChange={onCertificateFileChange}
                                hideSelectedFiles
                                size="large"
                                disabled={isUploading}
                                fullWidth
                            >
                                {depCertificateFile?.name ?? 'Select DEP Token'}
                            </UploadButton>

                            <Button
                                className="tw-col-span-1"
                                variant={BUTTON.RAISED}
                                color={BUTTON.PRIMARY}
                                noMargin
                                fullWidth
                                disabled={isUploading || !depCertificateFile}
                                onClick={async () => {
                                    setIsUploading(true);
                                    const formData = new FormData();
                                    formData.append('dep_token', new Blob([depCertificateFile], { type: 'application/octet-stream' }));
                                    try {
                                        await appleApi.uploadDepCertificate(formData);
                                    } catch (error) {
                                        showErrorMessage(error.data?.message ?? error.message, { autoHideDuration: 600 });
                                        setIsUploading(false);
                                    }
                                    setUploadDialog(false);
                                    setDepCertificateFile(null);
                                    setIsUploading(false);
                                }}
                            >
                                {isUploading ? 'Uploading...' : 'Upload'}
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </AppleCertificatePaper>
        </>
    );
};
export { AppleSettingsDEP };
