import { useRef, useState } from 'react';
import { LayoutCentered, Loading, Button, Icon, Tooltip, Dialog, IconButton } from '@capasystems/ui';
import { Heading } from '@thirdparty/ui';
import { coreAndroidManagement } from '@thirdparty/constants';
import { useAndroidApi } from '../../hooks/useApi/useApi';
import { isUndefined } from '@capasystems/utils';
import { BUTTON, TOOLTIP } from '@capasystems/constants';
import classnames from 'classnames';

export const AndroidApplicationManagedConfigurationController = ({ application }) => {
    const [validateManagedConfiguration, setValidateManagedConfiguration] = useState(false);

    const [applicationProperties] = application.data?.applications || [];

    const onExited = (isDirty) => {
        setValidateManagedConfiguration(false);
    };

    const onClick = () => setValidateManagedConfiguration(true);

    if (application.configurationType !== coreAndroidManagement.configurationType.application || isUndefined(applicationProperties)) {
        return null;
    }
    return (
        <>
            {validateManagedConfiguration && (
                <ValidateManagedConfiguration
                    onExited={onExited}
                    applicationProperties={applicationProperties}
                    application={application}
                />
            )}
            {applicationProperties.managedConfigurationTemplate?.templateId ? (
                <Tooltip
                    content="Edit or delete managed configuration"
                    dark
                    extraPadding
                    position={TOOLTIP.POSITION.LEFT}
                    className="tw-font-bold"
                >
                    <IconButton
                        color={BUTTON.PRIMARY}
                        onClick={onClick}
                    >
                        <Icon type="configureOutlined" />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip
                    // content="Create managed configuration"
                    content={
                        <div className="tw-leading-loose">
                            <p>
                                Some apps designed for enterprises include built-in settings called <b>managed configurations</b> that IT admins can configure.
                            </p>
                            <p className="tw-mt-4">For example, an app may have the option to only sync data when a device is connected to Wi-Fi.</p>
                        </div>
                    }
                    dark
                    extraPadding
                    position={TOOLTIP.POSITION.LEFT}
                >
                    <IconButton onClick={onClick}>
                        <Icon type="configureOutlined" />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

const ValidateManagedConfiguration = ({ onExited, applicationProperties, application }) => {
    const iframeContainerRef = useRef();
    const isDirty = useRef(false);
    const androidApi = useAndroidApi();
    const [open, setOpen] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    const onEntering = () => {
        if (applicationProperties.managedConfigurationTemplate?.templateId) {
            androidApi
                .getGooglePlayStoreWebToken()
                .then((webToken) => {
                    window.gapi.load('gapi.iframes', () => {
                        const iframe = window.gapi.iframes.getContext().openChild({
                            url: `https://play.google.com/managed/mcm?token=${webToken.value}&packageName=${applicationProperties.packageName}&mcmId=${applicationProperties.managedConfigurationTemplate.templateId}&canDelete=true`,
                            where: iframeContainerRef.current,
                            attributes: { style: 'height: 100%', scrolling: 'yes' },
                        });

                        iframe.register(
                            'onconfigdeleted',
                            (configurationDetails) => {
                                androidApi
                                    .updateAndroidApplication(application.id, {
                                        data: {
                                            applications: application.data.applications.map(({ managedConfigurationTemplate, ...rest }) => ({ ...rest })), // Remove managedConfigurationTemplate, but keep all other properties.
                                        },
                                    })
                                    .then(() => {
                                        isDirty.current = true;
                                        setOpen(false);
                                    })
                                    .catch((response) => {
                                        setErrorMessage(response.data?.message || 'Failed to update android application');
                                    });
                            },
                            window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER
                        );

                        iframe.register(
                            'onconfigupdated',
                            () => {
                                setOpen(false);
                            },
                            window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER
                        );
                    });
                })
                .catch((error) => {
                    console.log(error);
                    setErrorMessage('Could not generate web token');
                });
        } else {
            androidApi
                .getGooglePlayStoreApplicationInfo(applicationProperties.packageName)
                .then((response) => {
                    if (response.managedProperties) {
                        androidApi
                            .getGooglePlayStoreWebToken()
                            .then((webToken) => {
                                window.gapi.load('gapi.iframes', () => {
                                    const iframe = window.gapi.iframes.getContext().openChild({
                                        url: `https://play.google.com/managed/mcm?token=${webToken.value}&packageName=${applicationProperties.packageName}`,
                                        where: iframeContainerRef.current,
                                        attributes: { style: 'height: 100%', scrolling: 'yes' },
                                    });
                                    iframe.register(
                                        'onconfigupdated',
                                        ({ mcmId }) => {
                                            androidApi
                                                .updateAndroidApplication(application.id, {
                                                    data: {
                                                        applications: application.data.applications.map((app) => ({
                                                            ...app,
                                                            managedConfigurationTemplate: {
                                                                templateId: mcmId,
                                                            },
                                                        })), // Remove managedConfigurationTemplate, but keep all other properties.
                                                    },
                                                })
                                                .then(() => {
                                                    isDirty.current = true;
                                                    setOpen(false);
                                                })
                                                .catch((response) => {
                                                    setErrorMessage(response.data?.message || 'Failed to update android application');
                                                });
                                        },
                                        window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER
                                    );
                                });
                            })
                            .catch((error) => {
                                console.log(error);
                                setErrorMessage('Could not generate web token');
                            });
                    } else {
                        // setErrorMessage(`'${response.title}' can't be managed`);
                        setErrorMessage(`'${response.title}' does not have any configurable settings`);
                    }
                })
                .catch(() => {
                    setErrorMessage('An unknown error occurred');
                });
        }
    };

    return (
        <Dialog
            open={open}
            onExited={() => onExited(isDirty.current)}
            onClose={() => setOpen(false)}
            onEntering={onEntering}
            PaperProps={{ style: { minWidth: '80vw', height: '80vh' } }}
        >
            <div className="full tw-absolute tw-right-5 tw-top-2 tw-z-50 tw-ml-auto">
                <Tooltip
                    fullWidth
                    interactive
                    dark
                    extraPadding
                    content={
                        <>
                            <Heading
                                subheading
                                bold
                                className="tw-mt-0 tw-mb-2 tw-text-current"
                            >
                                Supported Macros
                            </Heading>
                            <MacroInfoSection heading="Device imei number">$hwi.imei$</MacroInfoSection>
                            <MacroInfoSection heading="Device serial number">$hwi.serialNumber$</MacroInfoSection>
                            <MacroInfoSection heading="Device user full name">$user.fullName$</MacroInfoSection>
                            <MacroInfoSection heading="Device user principal name">$user.userPrincipalName$</MacroInfoSection>
                            <MacroInfoSection heading="Device user email">$user.email$</MacroInfoSection>
                        </>
                    }
                >
                    <Icon type="helpOutlined" />
                </Tooltip>
            </div>
            <div
                className="tw-h-full tw-w-full"
                ref={iframeContainerRef}
            >
                <LayoutCentered>
                    {errorMessage ? (
                        <div>
                            <h2>{errorMessage}</h2>
                            <Button
                                className="tw-mt-4"
                                color={BUTTON.PRIMARY}
                                variant={BUTTON.RAISED}
                                onClick={() => setOpen(false)}
                            >
                                Close
                            </Button>
                        </div>
                    ) : (
                        <Loading />
                    )}
                </LayoutCentered>
            </div>
        </Dialog>
    );
};

export const MacroInfoSection = ({ heading, last = false, children }) => (
    <>
        <p className="tw-mt-0 tw-mb-1">{heading}</p>
        <p
            className={classnames({
                'tw-mt-0 tw-text-xs tw-font-semibold': true,
                'tw-mb-4': !last,
                'tw-mb-0': last,
            })}
        >
            {children}
        </p>
    </>
);
