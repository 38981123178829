import { isBeta } from '@capasystems/constants';
import { api, getUniqueId, now } from '@capasystems/utils';
import { ApplicationInstallTypeEnum, coreAndroidManagement } from '@thirdparty/constants';

// const CAPAONE_API_ROOT = isBeta ? "https://apibeta.capaone.com" : "https://api.capaone.com";

/* const CAPAONE_OVERRIDES = {
    withCredentials: false,
    baseURL: CAPAONE_API_ROOT,
    transformRequest: (data, headers) => {
        // Handle CORS.
        delete headers['cache-control'];
        return data;
    }
}; */

let expirationTimestamps = {};

export const streamlineApplicationDocument = (app) => {
    if (app.configurationType === coreAndroidManagement.configurationType.application) {
        const installTypeId = app.data.applications[0]?.installType || 'INSTALL_TYPE_UNSPECIFIED';
        return {
            ...app,
            installTypeName: ApplicationInstallTypeEnum[installTypeId]?.split('.')[0],
            installTypeId,
        };
    }
    return {
        ...app,
        installTypeName: ApplicationInstallTypeEnum[app.installType || 'INSTALL_TYPE_UNSPECIFIED']?.split('.')[0],
        installTypeId: app.installType,
    };
};

class AndroidConnection {
    constructor(enterpriseId) {
        this.baseUrl = `${api.defaults.baseURL}/android`;
        this.baseEnterpriseUrl = `${this.baseUrl}/enterprise/${enterpriseId}`;
        this.abortController = new AbortController();
        this.connectionID = getUniqueId('AndroidConnectionID');
    }

    /** GET */
    getDevices = (params) => this.get(`endpoint`, params); // In use!

    getDevice = (id) => this.get(`endpoint/${id}`); // In use!

    getPolicies = () => this.get(`policy`); // In use!

    getPolicy = (id) => this.get(`policy/${id}`); // In use!

    getAndroidConfigurations = () => this.get(`configuration`); // In use!

    getEnrollmentConfigurations = () => this.get(`enrollmentConfiguration`); // In use!

    getEnrollmentConfiguration = (id) => this.get(`enrollmentConfiguration/${id}`); // In use!

    getAndroidConfiguration = (id) => this.get(`configuration/${id}`); // In use!

    getEnterprises = () =>
        this.get(
            'enterprise',
            {},
            {
                baseURL: this.baseUrl,
            }
        ); // In use!

    getEnterpriseDetails = () => this.get(''); // In use!

    getApplications = () =>
        this.get(`application`).then((applications) => {
            return applications.map(streamlineApplicationDocument);
        }); // In use!

    getSingleApplication = (id) =>
        this.get(`application/${id}`).then((app) => {
            return streamlineApplicationDocument(app);
        }); // In use!

    getTemplate = (orgId, osType, templateType, params) => this.get(`${osType}/template/${templateType}`, params); // In use!

    getGooglePlayStoreApplicationInfo = (packageName = '') => this.get(`playstore/application/${packageName}`, {}); // packageName like "com.evernote" or "com.google.android.youtube". Also returns managed fields (maybe put on seperate tab on application conf. edit page)

    getGooglePlayStoreWebToken = () =>
        this.post('webToken', {
            parentFrameUrl: window.location.origin,
            enabledFeatures: ['MANAGED_CONFIGURATIONS'],
        }); // See https://developers.google.com/android/work/play/emm-api/managed-play-iframe#web-apps & https://developers.google.com/android/management/reference/rest/v1/enterprises.webTokens#iframefeature

    getGooglePlayStoreSearchWebToken = () =>
        this.post('webToken', {
            parentFrameUrl: window.location.origin,
            enabledFeatures: [
                'PLAY_SEARCH',
                'PRIVATE_APPS', // Adds suppport for selecting "Private apps" in Google Play Store.
            ],
        }); // See https://developers.google.com/android/management/apps#step_3_handle_iframe_events

    getZeroTouchWebToken = () =>
        this.post('webToken', {
            parentFrameUrl: window.location.origin,
            enabledFeatures: ['ZERO_TOUCH_CUSTOMER_MANAGEMENT'],
        }); // See https://developers.google.com/android/management/zero-touch-iframe#add_the_iframe_to_your_console

    get = (endpoint, params = {}, overrides = {}) => {
        const requestedTimestamp = now();
        return new Promise((resolve, reject) => {
            api.get(endpoint, {
                signal: this.abortController.signal,
                cache: false,
                withCredentials: true,
                baseURL: this.baseEnterpriseUrl,
                ...overrides,
                params,
            })
                .then((response) => {
                    if (requestedTimestamp >= (expirationTimestamps[this.connectionID] || 0)) {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    if (error.status?.cancelled === false) {
                        // Always send the error.
                        reject(error);
                    }
                });
        });
    };

    /** DELETE */
    deletePolicy = (id) => this.delete(`policy/${id}`); // In use!

    deleteApplication = (id) => this.delete(`application/${id}`); // In use!

    deleteAndroidConfiguration = (id) => this.delete(`configuration/${id}`); // In use!

    removeEndpointFromApplications = (endpointId, applicationRefIds) =>
        this.delete(`endpoint/${endpointId}/application`, {
            applicationRefIds,
        });

    removeEndpointFromConfigurations = (endpointId, configurationRefIds) =>
        this.delete(`endpoint/${endpointId}/configuration`, {
            configurationRefIds,
        });

    removeEndpointsFromApplication = (applicationId, endpointRefIds) =>
        this.delete(`application/${applicationId}/endpoint`, {
            endpointRefIds,
        });

    removeEndpointsFromConfiguration = (configurationId, endpointRefIds) =>
        this.delete(`configuration/${configurationId}/endpoint`, {
            endpointRefIds,
        });

    deleteDevice = (endpointId) => this.delete(`endpoint/${endpointId}`);

    deleteEnrollmentConfiguration = (id) => this.delete(`enrollmentConfiguration/${id}`); // In use!

    delete = (endpoint, data = {}, overrides = {}) =>
        api.delete(endpoint, {
            baseURL: this.baseEnterpriseUrl,
            ...overrides,
            data,
        });

    updateAndroidApplication = (id, applicationObject) => this.update(`application/${id}`, applicationObject); // In use!

    updateAndroidApplications = (updatedApplications = []) =>
        this.update(
            'application',
            updatedApplications.map(({ id, priority }) => ({ id, priority }))
        );

    updateAndroidConfiguration = (configurationId, configurationObject) => this.update(`configuration/${configurationId}`, configurationObject); // In use!

    updateAndroidConfigurations = (updatedConfigurations = []) =>
        this.update(
            'configuration',
            updatedConfigurations.map(({ id, priority }) => ({ id, priority }))
        );

    updateDevice = (id, data = {}) => this.update(`endpoint/${id}`, data); // In use!

    updateAndroidEnrollmentConfiguration = (id, params = {}) => this.update(`enrollmentConfiguration/${id}`, params);

    syncDevice = (id) => this.update(`endpoint/${id}/sync`);

    syncDeviceList = () => this.update('endpoint/sync');

    update = (endpoint, data) => {
        return new Promise((resolve, reject) => {
            api.put(endpoint, data, {
                baseURL: this.baseEnterpriseUrl,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /** POST */
    createEnrollmentToken = (data = {}) => this.post(`enrollmentToken`, data);

    createSignupUrl = () =>
        this.post(
            `enterprise/generateSignupUrl?hostname=${isBeta ? 'dashbeta.capaone.com' : 'dash.capaone.com'}`,
            {},
            {
                baseURL: this.baseUrl,
            }
        );

    createAndroidConfiguration = (name = '', configurationObject = {}) =>
        this.post(`configuration`, {
            name,
            configurationType: coreAndroidManagement.configurationType.android,
            data: configurationObject,
        }); // In use!

    createAndroidApplication = (applicationObject) => this.post(`application`, applicationObject); // In use!

    addEndpointToApplications = (endpointId, applicationRefIds) =>
        this.post(`endpoint/${endpointId}/application`, {
            applicationRefIds,
        });

    addEndpointToConfigurations = (endpointId, configurationRefIds) =>
        this.post(`endpoint/${endpointId}/configuration`, {
            configurationRefIds,
        });

    addEndpointsToApplication = (applicationId, endpointRefIds) =>
        this.post(`application/${applicationId}/endpoint`, {
            endpointRefIds,
        });

    addEndpointsToConfiguration = (configurationId, endpointRefIds) =>
        this.post(`configuration/${configurationId}/endpoint`, {
            endpointRefIds,
        });

    createEnrollmentConfigurations = (configurationObject) => this.post(`enrollmentConfiguration`, configurationObject); // In use!

    // https://developers.google.com/android/management/reference/rest/v1/enterprises.devices/issueCommand#Command
    sendLockDeviceCommand = (endpointId) =>
        this.post(`endpoint/${endpointId}/issueCommand`, {
            type: 'LOCK',
        });

    sendRebootDeviceCommand = (endpointId) =>
        this.post(`endpoint/${endpointId}/issueCommand`, {
            type: 'REBOOT',
        });

    sendClearApplicationDataCommand = (endpointId, packageNames = []) =>
        this.post(`endpoint/${endpointId}/issueCommand`, {
            type: 'CLEAR_APP_DATA',
            clearAppsDataParams: {
                packageNames,
            },
        });

    sendResetPasswordCommand = (endpointId, data = {}) =>
        this.post(`endpoint/${endpointId}/issueCommand`, {
            type: 'RESET_PASSWORD',
            newPassword: data.newPassword, // type string
            resetPasswordFlags: data.resetPasswordFlags, // Available flags ["REQUIRE_ENTRY", "DO_NOT_ASK_CREDENTIALS_ON_BOOT", "LOCK_NOW"]
        });

    sendStartLostModeCommand = (endpointId, data = {}) =>
        this.post(`endpoint/${endpointId}/issueCommand`, {
            type: 'START_LOST_MODE',
            startLostModeParams: {
                lostMessage: data.lostMessage, // type: object
                lostPhoneNumber: data.lostPhoneNumber, // type object
                lostEmailAddress: data.lostEmailAddress, // type string
                lostStreetAddress: data.lostStreetAddress, // type object
                lostOrganization: data.lostOrganization, // type object
            },
        });

    sendStopLostModeCommand = (endpointId) =>
        this.post(`endpoint/${endpointId}/issueCommand`, {
            type: 'STOP_LOST_MODE',
            stopLostModeParams: {},
        });

    post = (endpoint, data, overrides = {}) => {
        return new Promise((resolve, reject) => {
            api.post(endpoint, data, {
                baseURL: this.baseEnterpriseUrl,
                ...overrides,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /** PUT */

    renameAndroidEnrollmentConfiguration = (id, data) => this.update(`enrollmentConfiguration/${id}/rename`, data);

    /** PATCH */
    patchPolicy = (policyId, policy) => this.patch(`policy/${policyId}`, policy); // In use!

    // patchEnterprise = (enterpriseDataToPatch = { signinDetails: [{ signinUrl: "https://dashbeta.capaone.com/api/whatever", allowPersonalUsage: PERSONAL_USAGE_ENUM.ALLOWED }]}) => this.patch("", enterpriseDataToPatch);

    patch = (endpoint, data) => {
        return new Promise((resolve, reject) => {
            api.patch(endpoint, data, {
                baseURL: this.baseEnterpriseUrl,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /** MISC */
    cancel = () => {
        expirationTimestamps[this.connectionID] = now();
    };

    abort = () => {
        this.abortController.abort();
        delete expirationTimestamps[this.connectionID];
    };
}

export default AndroidConnection;
