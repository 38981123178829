import { WidgetPaper } from '../thirdparty-components/thirdparty-components';
import TWC from 'tailwindcss/colors';
import { useMemo, useState, useEffect } from 'react';
import useNavigate from '../hooks/useNavigate/useNavigate';
import { useApi } from '../hooks/useApi/useApi';
import { PieChart, eSize } from '../charts/pie-chart';

export const DriverCompliance = ({ className }) => {
    const api = useApi();

    const navigate = useNavigate();

    const [chartData, setChartData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.getDevicesDriverCompliance()
            .then(setChartData)
            .catch(() => setChartData())
            .finally(() => setLoading(false));
    }, []);

    const calculatedData = useMemo(() => {
        if (!chartData) {
            return [];
        }

        const compliant = {
            name: 'Driver Up-to-date',
            color: TWC.emerald[400],
            y: chartData.compliant,
            isSupported: true,
        };

        const nonCompliant = {
            name: 'Driver Updates Available',
            color: TWC.red[400],
            y: chartData.nonCompliant,
            isSupported: false,
        };

        return [compliant, nonCompliant];
    }, [chartData]);

    return (
        <WidgetPaper
            title="Driver Compliance"
            description="Percentage of supported devices with drivers that are up to date"
            className={className}
            loading={loading}
        >
            <PieChart
                data={calculatedData}
                size={eSize.large}
                onClick={(e) => {
                    if (e.isSupported) {
                        navigate.to(`windows/device/list?driver.compliant=true&driver.isSupported=true`);
                    } else {
                        navigate.to(`windows/device/list?driver.compliant=false&driver.isSupported=true`);
                    }
                }}
            />
        </WidgetPaper>
    );
};
