import { SORT_DIRECTION } from '@capasystems/constants';
import { Avatar, Column, EmptyState, Paper, VirtualizedTable, virtualizedTableColumnPropTypes } from '@capasystems/ui';
import { getSortingFunction } from '@capasystems/utils';
import { Portal } from '@mui/base';
import { formatBytes, getDefaultQueryBuilderConfiguration } from '@thirdparty/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { QueryBuilder, TailwindBadge, TwoLineCellRenderer, useAppleApi } from '../../../../index';

const AppleEndpointSoftwareTab = ({ queryBuilderContainerRef, appleData: { applications } }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const queryBuilderRef = useRef({});
    const queryBuilderEndpointSoftware = getDefaultQueryBuilderConfiguration({ placeholder: 'Name' });
    const rowHeight = 56;

    const [managedApplications, setManagedApplications] = useState(null);

    const [sortingState, setSortingState] = useState({
        sortBy: 'Name',
        sortDirection: SORT_DIRECTION.ASC,
    });
    const api = useAppleApi();

    const onSubmit = ([activeLeaf]) => {
        setSearchTerm(activeLeaf ? activeLeaf.value : '');
    };

    useEffect(() => {
        api.getAppleApplications().then((applicationsResponse) => {
            setManagedApplications(applicationsResponse);
        });
    }, [api]);
    const memoizedApplications = useMemo(() => {
        const sortingFunction = getSortingFunction(sortingState, ['BundleSize'].includes(sortingState.sortBy));

        return (
            applications?.sort(sortingFunction).filter((c) => c.Name?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) && c.BundleSize > 1) || []
        );
    }, [sortingState, searchTerm, applications]);

    return (
        <div className="tw-h-full tw-pb-4">
            <Paper className="tw-h-full">
                <Portal container={queryBuilderContainerRef?.current}>
                    <QueryBuilder
                        defaultConfiguration={queryBuilderEndpointSoftware}
                        onInit={onSubmit}
                        onSubmit={onSubmit}
                        className="tw-w-full"
                        ref={queryBuilderRef}
                    />
                </Portal>
                <VirtualizedTable
                    items={memoizedApplications}
                    showRowCount
                    totalRowCount={memoizedApplications.length}
                    entity="application"
                    isLoading={managedApplications === null}
                    noRowsRenderer={() => (
                        <EmptyState
                            isSearching={searchTerm !== ''}
                            searchTerm={searchTerm}
                            entity="application"
                            onClearSearch={queryBuilderRef.current.clearFiltersAndApplyChanges}
                        />
                    )}
                    sort={setSortingState}
                    sortBy={sortingState.sortBy}
                    sortDirection={sortingState.sortDirection}
                >
                    <Column
                        minWidth={32}
                        maxWidth={32}
                        dataKey="iconUrl"
                        label=""
                        shouldRender={({ tableWidth }) => tableWidth >= 600}
                        cellRenderer={({ rowData }) => {
                            const app = managedApplications?.find((a) => {
                                return a.data?.Identifier === rowData?.Identifier;
                            });
                            return app?.iconUrl ? (
                                <Avatar
                                    src={app.iconUrl}
                                    alt={rowData.Name}
                                    variant="rounded"
                                    className="tw-h-8 tw-w-8"
                                />
                            ) : (
                                <Avatar
                                    variant="rounded"
                                    className="tw-h-8 tw-w-8"
                                >
                                    {rowData.Name.split('')[0]}
                                </Avatar>
                            );
                        }}
                    />
                    <Column
                        dataKey="Name"
                        label="Name"
                        minWidth={240}
                        cellRenderer={({ rowData, cellData }) => (
                            <TwoLineCellRenderer
                                main={cellData}
                                secondary={rowData.Identifier}
                            />
                        )}
                    />
                    <Column
                        dataKey="ShortVersion" // The app version as displayed to the user.
                        disableSort
                        label="Version"
                        minWidth={120}
                        maxWidth={160}
                    />
                    <Column
                        dataKey="BundleSize"
                        label="Size"
                        minWidth={120}
                        maxWidth={120}
                        justify={virtualizedTableColumnPropTypes.justify.end}
                        cellRenderer={({ rowData }) => formatBytes({ value: rowData.BundleSize, asText: true, powersOf1000: true })}
                        defaultSortDirection={SORT_DIRECTION.DESC}
                    />
                    <Column
                        dataKey="DeviceBasedVPP"
                        label=""
                        disableSort
                        minWidth={240}
                        justify={virtualizedTableColumnPropTypes.justify.end}
                        cellRenderer={({ rowData }) => {
                            return (
                                <div className="tw-flex tw-items-center tw-gap-x-2">
                                    {rowData.HasUpdateAvailable && (
                                        <TailwindBadge
                                            color="amber"
                                            size="small"
                                        >
                                            Update available
                                        </TailwindBadge>
                                    )}
                                    {rowData.DeviceBasedVPP && (
                                        <TailwindBadge
                                            color="teal"
                                            size="small"
                                        >
                                            VPP
                                        </TailwindBadge>
                                    )}
                                    {rowData.managed && (
                                        <TailwindBadge
                                            color="indigo"
                                            size="small"
                                        >
                                            Managed
                                        </TailwindBadge>
                                    )}
                                </div>
                            );
                        }}
                    />
                </VirtualizedTable>
            </Paper>
        </div>
    );
};

export { AppleEndpointSoftwareTab };
