import { WidgetPaper } from '../thirdparty-components/thirdparty-components';
import TWC from 'tailwindcss/colors';
import { Highcharts } from '@capasystems/ui';
import { useMemo, useState, useEffect, useRef } from 'react';
import useNavigate from '../hooks/useNavigate/useNavigate';
import { useApi } from '../hooks/useApi/useApi';
import { chartDefaults, chartFormatters } from '../constants-with-dependencies/constants-with-dependencies';
import { PieChart, eSize } from '../charts/pie-chart';

export const DriverSupportedDevices = ({ className }) => {
    const api = useApi();

    const navigate = useNavigate();

    const [chartData, setChartData] = useState();
    const [loading, setLoading] = useState(true);

    const textRef = useRef();

    useEffect(() => {
        api.getDevicesDriverSupported()
            .then(setChartData)
            .catch(() => setChartData())
            .finally(() => setLoading(false));
    }, []);

    const calculatedData = useMemo(() => {
        if (!chartData) {
            return [];
        }

        const supported = {
            name: 'Supported Devices',
            color: TWC.emerald[400],
            y: chartData.supported,
            isSupported: true,
        };

        const unsupported = {
            name: 'Unsupported Devices',
            color: TWC.red[400],
            y: chartData.unsupported,
            isSupported: false,
        };

        return [supported, unsupported];
    }, [chartData]);

    textRef.current = calculatedData.reduce((p, c) => p + c.y, 0);

    return (
        <WidgetPaper
            title="Driver Supported Devices"
            description="Percentage of devices supported by driver updater"
            className={className}
            loading={loading}
        >
            <PieChart
                data={calculatedData}
                size={eSize.large}
                onClick={(e) => {
                    if (e.isSupported) {
                        navigate.to(`windows/device/list?driver.isSupported=true`);
                    } else {
                        navigate.to(`windows/device/list?driver.isSupported=false`);
                    }
                }}
            />
        </WidgetPaper>
    );
};
