import { Avatar, Ellipsis, Icon, LayoutRow, Tooltip } from '@capasystems/ui';
import { TwoLineCellRenderer } from '../../../../index';

const applicationIsRemovable = (application) => {
    if (application.data?.Attributes?.Removable || application.data?.Attributes?.Removable === undefined || application.data?.Attributes?.Removable === null) {
        return true;
    } else if (application.data?.Attributes?.Removable === false) {
        return false;
    }
};

const ApplicationCellRenderer = ({ iconUrl, name, dimensions, verified, state, rowData }) => {
    if (dimensions.width < 400) {
        return (
            <TwoLineCellRenderer
                main={name}
                callToAction
            />
        );
    }
    return (
        <LayoutRow verticalAlign="center">
            {iconUrl ? (
                <Avatar
                    src={iconUrl}
                    alt={name}
                    variant="rounded"
                    className="tw-h-8 tw-w-8"
                />
            ) : (
                <Icon
                    type="app"
                    className="tw-h-8 tw-w-8"
                />
            )}
            <div className="tw-ml-4 tw-flex-1 tw-overflow-auto">
                <TwoLineCellRenderer
                    main={
                        <div className="tw-flex tw-items-center tw-gap-2">
                            <Ellipsis>{name}</Ellipsis>
                            {verified === true && !['Installing', 'Queued'].includes(state) && (
                                <Tooltip
                                    content="Application is applied to endpoint"
                                    extraPadding
                                    className="tw-font-semibold"
                                    arrow
                                >
                                    <Icon
                                        type="badgeCheckmark"
                                        className="tw-h-4 tw-w-4 tw-text-teal-400"
                                    />
                                </Tooltip>
                            )}
                            {verified === true && ['Installing', 'Queued'].includes(state) && (
                                <Tooltip
                                    content="Application is trying to install"
                                    extraPadding
                                    className="tw-font-semibold"
                                    arrow
                                >
                                    <Icon
                                        type="badgeCheckmark"
                                        className="tw-h-4 tw-w-4 tw-text-amber-400"
                                    />
                                </Tooltip>
                            )}
                            {verified === false && (
                                <Tooltip
                                    content="Application is not applied to endpoint"
                                    extraPadding
                                    className="tw-font-semibold"
                                    arrow
                                >
                                    <Icon
                                        type="badgeExclamation"
                                        className="tw-h-4 tw-w-4 tw-text-red-400"
                                    />
                                </Tooltip>
                            )}
                        </div>
                    }
                    disableEllipsis
                    callToAction
                    secondary={(applicationIsRemovable(rowData) ? 'Removable' : 'Not Removable') + ' · ' + rowData.supportedPlatforms?.join(', ')}
                />
            </div>
        </LayoutRow>
    );
};

export { ApplicationCellRenderer };
