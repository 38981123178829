export const USER = {
    TYPE: {
        LOCAL: 'LOCAL',
        AZURE: 'AZURE',
    },
    DESCRIPTION: {
        LOCAL: 'Local user',
        AZURE: 'Azure user',
    },
};
