import { Icon, LayoutColumn, LayoutRow, LoadingLinear, Page, Tooltip } from '@capasystems/ui';
import { isDefined, isUndefined } from '@capasystems/utils';

import { NONE } from '@thirdparty/constants';
import { TailwindBadge } from '@thirdparty/ui';
import { formatBytes, getAppleOsSystemStatus } from '@thirdparty/utils';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { PageTitle, WidgetPaper, WidgetsContainer } from '../../../../index';

const AppleEndpointDashboardTab = ({ endpoint, appliedPolicy }) => {
    const batteryLevelConverter = useMemo(() => {
        return (endpoint.data.BatteryLevel * 100).toFixed(0);
    }, [endpoint]);
    const percentage = ((endpoint.data.DeviceCapacity - endpoint.data.AvailableDeviceCapacity) / endpoint.data.DeviceCapacity) * 100;
    let batteryEnable;
    if (endpoint.data.BatteryLevel <= 0 || endpoint.data.BatteryLevel === undefined) {
        batteryEnable = '';
    } else {
        batteryEnable = (
            <LayoutColumn className="tw-mr-10">
                <b className="tw-text-tiny tw-opacity-75">Battery level</b>
                <LoadingLinear
                    variant="determinate"
                    value={batteryLevelConverter}
                    classes={{
                        root: classNames({
                            'tw-h-2 tw-rounded-full': true,
                            'tw-bg-red-100': batteryLevelConverter < 20,
                            'tw-bg-emerald-100': batteryLevelConverter >= 20,
                        }),
                        bar: classNames({
                            'tw-bg-red-600': batteryLevelConverter < 20,
                            'tw-bg-emerald-400': batteryLevelConverter >= 20,
                        }),
                    }}
                />
                <b>{batteryLevelConverter} %</b>
            </LayoutColumn>
        );
    }
    if (endpoint.data.ModelName.includes('Mac')) {
        return (
            <WidgetsContainer className="tw-mt-4">
                <div className="tw-grid tw-grid-cols-1 tw-gap-6">
                    <WidgetPaper headerless>
                        <CoreInfo
                            softwareInfo={endpoint.data}
                            endpoint={endpoint}
                        />

                        <div className="tw-px-6 tw-pb-6">
                            <div className="tw-flex tw-items-center tw-justify-between tw-text-sm tw-font-bold">
                                <LayoutColumn>
                                    <b className="tw-text-tiny tw-opacity-75">Last policy sync.</b>
                                    <b>{endpoint.lastCheckin ? dayjs(endpoint?.lastCheckin).fromNow() : 'never'}</b>
                                </LayoutColumn>
                                <LayoutColumn>
                                    <b className="tw-text-tiny tw-opacity-75">HostName</b>
                                    <b>{endpoint.data.HostName}</b>
                                </LayoutColumn>
                                <LayoutColumn>
                                    <b className="tw-text-tiny tw-opacity-75">Wifi Mac address</b>
                                    <b>{endpoint.data.WiFiMAC}</b>
                                </LayoutColumn>
                                <LayoutColumn className="tw-w-28">
                                    <b className="tw-text-tiny tw-opacity-75">Storage available</b>
                                    <LoadingLinear
                                        variant="determinate"
                                        value={percentage}
                                        classes={{
                                            root: 'tw-h-2 tw-rounded-full',
                                        }}
                                    />
                                    <LayoutRow align="space-between">
                                        <b>
                                            {formatBytes({
                                                value: (endpoint.data.DeviceCapacity - endpoint.data.AvailableDeviceCapacity) * 1024 * 1024 * 1024,
                                                decimalPoints: 1,
                                                asText: true,
                                            })}
                                        </b>
                                        <b>{formatBytes({ value: endpoint.data.DeviceCapacity * 1073741824, asText: true })}</b>
                                    </LayoutRow>
                                </LayoutColumn>

                                {batteryEnable}
                                <LayoutColumn className="tw-text-right">
                                    <b className="tw-text-tiny tw-opacity-75">Enrollment time</b>
                                    <b>{dayjs(endpoint.createdAt).fromNow()}</b>
                                </LayoutColumn>
                            </div>
                        </div>
                    </WidgetPaper>
                </div>
            </WidgetsContainer>
        );
    } else {
        return (
            <WidgetsContainer className="tw-mt-4">
                <div className="tw-grid tw-grid-cols-1 tw-gap-6">
                    <WidgetPaper headerless>
                        <div className="tw-flex tw-flex-col">
                            <CoreInfo
                                softwareInfo={endpoint.data}
                                endpoint={endpoint}
                            />
                        </div>
                        <div className="tw-px-6 tw-pb-6">
                            <div className="tw-grid tw-grid-cols-5 tw-gap-4">
                                <LayoutColumn>
                                    <b className="tw-text-tiny tw-opacity-75">Last policy sync.</b>
                                    <b>{dayjs(endpoint.updatedAt).fromNow()}</b>
                                </LayoutColumn>
                                <LayoutColumn>
                                    <b className="tw-text-tiny tw-opacity-75">Phone number</b>
                                    <b>
                                        {endpoint.data.ServiceSubscriptions?.find((ServiceSubscriptions) => isDefined(ServiceSubscriptions.PhoneNumber))
                                            ?.PhoneNumber || <i className="tw-font-normal tw-text-gray-300">{NONE}</i>}
                                    </b>
                                </LayoutColumn>
                                <LayoutColumn className="tw-mr-10">
                                    <b className="tw-text-tiny tw-opacity-75">Storage available</b>
                                    <LoadingLinear
                                        variant="determinate"
                                        value={percentage}
                                        classes={{
                                            root: 'tw-h-2 tw-rounded-full',
                                        }}
                                    />
                                    <LayoutRow align="space-between">
                                        <b>
                                            {formatBytes({
                                                value: (endpoint.data.DeviceCapacity - endpoint.data.AvailableDeviceCapacity) * 1024 * 1024 * 1024,
                                                decimalPoints: 1,
                                                asText: true,
                                            })}
                                        </b>
                                        <b>{formatBytes({ value: endpoint.data.DeviceCapacity * 1073741824, asText: true })}</b>
                                    </LayoutRow>
                                </LayoutColumn>

                                {batteryEnable}
                                <LayoutColumn className="tw-text-right ">
                                    <b className="tw-text-tiny tw-opacity-75">Enrollment time</b>
                                    <b>{dayjs(endpoint.createdAt).fromNow()}</b>
                                </LayoutColumn>
                            </div>
                        </div>
                    </WidgetPaper>
                </div>
            </WidgetsContainer>
        );
    }
};

const CoreInfo = ({ softwareInfo, endpoint }) => {
    const appleOsSystemStatus = getAppleOsSystemStatus(endpoint);

    if (isUndefined(softwareInfo)) {
        return <div className="tw-p-6 tw-font-semibold">No software info available</div>;
    }
    return (
        <Page title="Endpoints · Dashboard">
            <div className="tw-px-6 tw-py-6">
                <div className="tw-grid tw-grid-cols-5 tw-gap-4">
                    <PageTitle
                        category={endpoint.modelName}
                        description={softwareInfo.TimeZone}
                    >
                        {softwareInfo.OSVersion}
                    </PageTitle>
                    <LayoutColumn className="tw-col-span-1">
                        <b className="tw-text-tiny tw-opacity-75">Serial number</b>
                        <b>{endpoint.serial_number || <i className="tw-font-normal tw-text-gray-300">{NONE}</i>}</b>
                    </LayoutColumn>
                    <LayoutColumn className="tw-col-span-1">
                        <b className="tw-text-tiny tw-opacity-75">IMEI</b>
                        <b>{softwareInfo.IMEI || <i className="tw-font-normal tw-text-gray-300">{NONE}</i>}</b>
                    </LayoutColumn>
                    <LayoutColumn></LayoutColumn>
                    <div className="tw-col-span-1 tw-text-right ">
                        <Tooltip content={appleOsSystemStatus?.tooltip}>
                            {endpoint.osUpdateStatus.some((osStatus) => osStatus.Status === 'Downloading') ? (
                                <TailwindBadge
                                    size="small"
                                    className="tw-relative"
                                >
                                    <LoadingLinear
                                        className={'tw-absolute tw-h-9 tw-w-full'}
                                        size={24}
                                        value={appleOsSystemStatus.process}
                                        classes={{
                                            root: 'tw-bg-blue-100 tw-rounded-full',
                                            bar: 'tw-bg-blue-300',
                                        }}
                                        variant="determinate"
                                    />
                                    <div className="tw-z-10 tw-pr-1 tw-text-blue-900">
                                        <Icon
                                            type="infoOutlined"
                                            size="small"
                                            color="inherit"
                                            className="tw-mr-1"
                                        />
                                        {appleOsSystemStatus.name}
                                    </div>
                                </TailwindBadge>
                            ) : (
                                <TailwindBadge
                                    size="small"
                                    color={appleOsSystemStatus.badgeColor}
                                    className="tw-gap-1"
                                >
                                    <Icon
                                        type={appleOsSystemStatus.isUpToDate ? 'checkmark' : 'infoOutlined'}
                                        size="small"
                                        color="inherit"
                                    />
                                    {appleOsSystemStatus.name}
                                </TailwindBadge>
                            )}
                        </Tooltip>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export { AppleEndpointDashboardTab };
