import { SORT_DIRECTION } from '@capasystems/constants';
import { Avatar, Ellipsis, Icon, LayoutCentered, LayoutRow, Tooltip } from '@capasystems/ui';
import { getSortingFunction } from '@capasystems/utils';
import { coreAndroidManagement } from '@thirdparty/constants';
import pluralize from 'pluralize';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    AppliedType,
    CapaOneLink,
    TransferList,
    TwoLineCellRenderer,
    useAndroidApi,
    useAndroidApplicationsSocket,
    useGroupsSocket,
    useManagementApi,
} from '../../../../index';
import { streamlineApplicationDocument } from '../../../hooks/useApi/android-connection';

const NON_COMPLIANCE_REASON = {
    NON_COMPLIANCE_REASON_UNSPECIFIED: 'This value is disallowed',
    API_LEVEL: 'The setting is not supported in the API level of the Android version running on the device',
    MANAGEMENT_MODE: 'The management mode (profile owner, device owner, etc.) does not support the setting',
    USER_ACTION: 'The user has not taken required action to comply with the setting',
    INVALID_VALUE: 'The setting has an invalid value',
    APP_NOT_INSTALLED: 'The app required to implement the policy is not installed',
    UNSUPPORTED: 'The policy is not supported by the version of Android Device Policy on the device',
    APP_INSTALLED: 'A blocked app is installed',
    PENDING: 'The setting has not been applied at the time of the report, but is expected to be applied shortly',
    APP_INCOMPATIBLE:
        'The setting can not be applied to the app because the app does not support it, for example because its target SDK version is not high enough',
    APP_NOT_UPDATED: 'The app is installed, but it has not been updated to the minimum version code specified by policy',
    DEVICE_INCOMPATIBLE: 'The device is incompatible with the policy requirements',
};

const INSTALLATION_FAILURE_REASON = {
    INSTALLATION_FAILURE_REASON_UNSPECIFIED: 'This value is disallowed',
    INSTALLATION_FAILURE_REASON_UNKNOWN:
        'An unknown condition is preventing the app from being installed. Some potential reasons are that the device does not have enough storage, the device network connection is unreliable, or the installation is taking longer than expected. The installation will be retried automatically',
    IN_PROGRESS: 'The installation is still in progress',
    NOT_FOUND: 'The app was not found in Play',
    NOT_COMPATIBLE_WITH_DEVICE: 'The app is incompatible with the device',
    NOT_APPROVED: 'The app has not been approved by the admin',
    PERMISSIONS_NOT_ACCEPTED: 'The app has new permissions that have not been accepted by the admin',
    NOT_AVAILABLE_IN_COUNTRY: "The app is not available in the user's country",
    NO_LICENSES_REMAINING: 'There are no licenses available to assign to the user',
    NOT_ENROLLED: 'The enterprise is no longer enrolled with Managed Google Play or the admin has not accepted the latest Managed Google Play Terms of Service',
    USER_INVALID: 'The user is no longer valid. The user may have been deleted or disabled',
    NETWORK_ERROR_UNRELIABLE_CONNECTION:
        "A network error on the user's device has prevented the install from succeeding. This usually happens when the device's internet connectivity is degraded, unavailable or there's a network configuration issue. Please ensure the device has access to full internet connectivity on a network that meets Android Enterprise Network Requirements. App install or update will automatically resume once this is the case",
    INSUFFICIENT_STORAGE:
        "The user's device does not have sufficient storage space to install the app. This can be resolved by clearing up storage space on the device. App install or update will automatically resume once the device has sufficient storage",
};

const SPECIFIC_NON_COMPLIANCE_REASON = {
    SPECIFIC_NON_COMPLIANCE_REASON_UNSPECIFIED: 'Specific non-compliance reason is not specified. Fields in specificNonComplianceContext are not set',
    PASSWORD_POLICIES_USER_CREDENTIALS_CONFIRMATION_REQUIRED:
        'User needs to confirm credentials by entering the screen lock. Fields in specificNonComplianceContext are not set. nonComplianceReason is set to USER_ACTION',
    PASSWORD_POLICIES_PASSWORD_EXPIRED: 'The device or profile password has expired. passwordPoliciesContext is set. nonComplianceReason is set to USER_ACTION',
    PASSWORD_POLICIES_PASSWORD_NOT_SUFFICIENT:
        'The device password does not satisfy password requirements. passwordPoliciesContext is set. nonComplianceReason is set to USER_ACTION',
    ONC_WIFI_INVALID_VALUE:
        'There is an incorrect value in ONC Wi-Fi configuration. fieldPath specifies which field value is incorrect. oncWifiContext is set. nonComplianceReason is set to INVALID_VALUE',
    ONC_WIFI_API_LEVEL:
        'The ONC Wi-Fi setting is not supported in the API level of the Android version running on the device. fieldPath specifies which field value is not supported. oncWifiContext is set. nonComplianceReason is set to API_LEVEL',
    ONC_WIFI_INVALID_ENTERPRISE_CONFIG:
        'The enterprise Wi-Fi network is missing either the root CA or domain name. nonComplianceReason is set to INVALID_VALUE',
    ONC_WIFI_USER_SHOULD_REMOVE_NETWORK:
        'User needs to remove the configured Wi-Fi network manually. This is applicable only on work profiles on personally-owned devices. nonComplianceReason is set to USER_ACTION',
    ONC_WIFI_KEY_PAIR_ALIAS_NOT_CORRESPONDING_TO_EXISTING_KEY:
        'Key pair alias specified via ClientCertKeyPairAlias field in openNetworkConfiguration does not correspond to an existing key installed on the device. nonComplianceReason is set to INVALID_VALUE',
};

export const EndpointApplicationsTab = ({ endpoint }) => {
    const [applicationsList, setApplicationsList] = useState([]);
    const [groupsList, setGroupsList] = useState([]);
    const androidApi = useAndroidApi();
    const managementApi = useManagementApi();
    const [errorMessage, setErrorMessage] = useState(null);
    const [dimensions, setDimensions] = useState({});
    const [viewMode, setViewMode] = useState(true);
    const [transferListProps, setTransferListProps] = useState({
        loading: true,
    });

    useAndroidApplicationsSocket(
        useCallback(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation }) => {
            const streamlinedApplicationDocument = streamlineApplicationDocument(fullDocument);
            if (updateOperation) {
                setApplicationsList((currentConfigurations) => {
                    return currentConfigurations.map((configuration) => {
                        if (configuration.id === documentId) {
                            return {
                                ...streamlinedApplicationDocument,
                                id: documentId,
                            };
                        }
                        return configuration;
                    });
                });
            } else if (insertOperation) {
                setApplicationsList((currentConfigurations) => {
                    return [
                        ...currentConfigurations,
                        {
                            ...streamlinedApplicationDocument,
                            id: documentId,
                        },
                    ];
                });
            }
        }, [])
    );

    useGroupsSocket(
        useCallback(({ updateDescription, documentId, fullDocument }, { insertOperation, updateOperation, deleteOperation }) => {
            if (updateOperation) {
                setGroupsList((currentGroups) => {
                    return currentGroups.map((group) => {
                        if (group.id === documentId) {
                            return {
                                ...group,
                                ...updateDescription.updatedFields,
                            };
                        }
                        return group;
                    });
                });
            } else if (insertOperation) {
                setGroupsList((currentGroups) => {
                    return [
                        ...currentGroups,
                        {
                            ...fullDocument,
                            id: documentId,
                        },
                    ];
                });
            } else if (deleteOperation) {
                setGroupsList((currentList) => {
                    return currentList.filter((group) => group.id !== documentId);
                });
            }
        }, [])
    );

    const memoizedApplications = useMemo(() => {
        const sortingFunction = getSortingFunction({
            sortDirection: SORT_DIRECTION.ASC,
            sortBy: 'name',
        });
        const filteredGroups = groupsList.filter((group) => group.endpointRefs.some(({ refId }) => refId === endpoint.id));
        return applicationsList
            .map((application) => {
                const [currentApp] = application?.data?.applications || [{ packageName: null, installType: null }];
                const appliedDirect = application.endpointRefIds.includes(endpoint.id);
                const appliedViaGroups = filteredGroups.filter((group) => group.applicationRefs.some(({ refId }) => refId === application.id));
                const appliedViaGroup = appliedViaGroups.length > 0;
                const error = endpoint.androidData?.nonComplianceDetails?.find((error) => error.packageName === currentApp.packageName);

                return {
                    ...application,
                    error:
                        (error?.nonComplianceReason === 'INVALID_VALUE' && NON_COMPLIANCE_REASON[error?.nonComplinceReason]) ||
                        (error?.nonComplianceReason === 'APP_NOT_INSTALLED' &&
                            error?.installationFailureReason !== 'IN_PROGRESS' &&
                            INSTALLATION_FAILURE_REASON[error?.installationFailureReason]) ||
                        error?.nonComplianceReason === SPECIFIC_NON_COMPLIANCE_REASON[error?.specificNonComplianceReason],
                    isPending: error?.nonComplianceReason === 'APP_NOT_INSTALLED' && error?.installationFailureReason === 'IN_PROGRESS',
                    applied: viewMode ? appliedDirect || appliedViaGroup : appliedDirect,
                    appliedViaGroup,
                    appliedViaGroups,
                    appliedDirect,
                };
            })
            .sort(sortingFunction);
    }, [applicationsList, endpoint, groupsList, viewMode]);

    useEffect(() => {
        Promise.all([androidApi.getApplications(), managementApi.getGroups()])
            .then(([applicationsResponse, groupsResponse]) => {
                setApplicationsList(applicationsResponse);
                setGroupsList(groupsResponse);
                setTransferListProps({});
            })
            .catch(() => {
                setErrorMessage('Could not get applications or groups');
            });
    }, [androidApi]);

    const onAdd = (applicationIds) => {
        setTransferListProps({
            savingChanges: true,
        });
        androidApi
            .addEndpointToApplications(endpoint.id, applicationIds)
            .then(() => {
                setTransferListProps({});
            })
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not apply ${pluralize('configuration', applicationIds.length)}`,
                });
            });
    };

    const onRemove = (applicationIds) => {
        setTransferListProps({
            savingChanges: true,
        });
        androidApi
            .removeEndpointFromApplications(endpoint.id, applicationIds)
            .then(() => {
                setTransferListProps({});
            })
            .catch(() => {
                setTransferListProps({
                    savingChanges: false,
                    errorMessage: `Could not remove ${pluralize('application', applicationIds.length)}`,
                });
            });
    };

    const cellRenderer = ({ rowData }) => {
        if (viewMode) {
            let verified = false;
            if (rowData.configurationType === coreAndroidManagement.configurationType.webapp) {
                verified = endpoint.resultingPolicy.applications?.some(
                    (application) => rowData.googlePlayId && rowData.googlePlayId.endsWith(application.packageName)
                );
            } else {
                const [currentApp] = rowData.data.applications || [{ packageName: null, installType: null }];
                verified =
                    currentApp.installType === 'FORCE_INSTALLED'
                        ? endpoint.androidData?.applicationReports?.some((application) => application.packageName === currentApp.packageName)
                        : endpoint.resultingPolicy?.applications?.some(
                              (application) => application.installType === currentApp.installType && application.packageName === currentApp.packageName
                          );
            }
            return (
                <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto tw-items-center">
                    <CapaOneLink
                        to={`android/application/${rowData.id}/membership?tab=endpoints`}
                        className="tw-overflow-auto"
                    >
                        <ApplicationCellRenderer
                            dimensions={dimensions}
                            verified={verified}
                            error={rowData.error}
                            isPending={rowData.isPending}
                            installType={rowData.installType}
                            {...rowData}
                        />
                    </CapaOneLink>
                    <AppliedType {...rowData} />
                </div>
            );
        }
        return (
            <div className="tw-grid tw-w-full tw-grid-cols-1fr-auto tw-items-center">
                <ApplicationCellRenderer
                    dimensions={dimensions}
                    {...rowData}
                />
                <AppliedType {...rowData} />
            </div>
        );
    };

    if (errorMessage) {
        return (
            <LayoutCentered>
                <h2>{errorMessage}</h2>
            </LayoutCentered>
        );
    }
    return (
        <TransferList
            items={memoizedApplications}
            onAdd={onAdd}
            onRemove={onRemove}
            entity="application"
            className="tw-py-4"
            onResize={setDimensions}
            viewMode={viewMode}
            setViewMode={setViewMode}
            cellRenderer={cellRenderer}
            totalRowCount={memoizedApplications.filter((a) => !a.applied).length}
            totalRowCountAssigned={memoizedApplications.filter((a) => a.applied === true).length}
            {...transferListProps}
        />
    );
};

const ApplicationCellRenderer = ({ iconUrl, name, installTypeName, dimensions, verified, isPending, error, installType }) => {
    if (dimensions.width < 400) {
        return (
            <TwoLineCellRenderer
                main={name}
                callToAction
                secondary={installTypeName}
            />
        );
    }
    return (
        <LayoutRow verticalAlign="center">
            {iconUrl ? (
                <Avatar
                    src={iconUrl}
                    alt={name}
                    variant="rounded"
                    className="tw-h-8 tw-w-8"
                />
            ) : (
                <Icon
                    type="app"
                    className="tw-h-8 tw-w-8"
                />
            )}
            <div className="tw-ml-4 tw-flex-1 tw-overflow-auto">
                <TwoLineCellRenderer
                    main={
                        <div className="tw-flex tw-items-center tw-gap-2">
                            <Ellipsis>{name}</Ellipsis>
                            {verified === true && (
                                <Tooltip
                                    content="Application is applied to endpoint"
                                    extraPadding
                                    className="tw-font-semibold"
                                    arrow
                                >
                                    <Icon
                                        type="badgeCheckmark"
                                        className="tw-h-4 tw-w-4 tw-text-teal-400"
                                    />
                                </Tooltip>
                            )}
                            {verified === false && !isPending && !error && (
                                <Tooltip
                                    content="Application is not applied to endpoint"
                                    extraPadding
                                    className="tw-font-semibold"
                                    arrow
                                >
                                    <Icon
                                        type="badgeExclamation"
                                        className="tw-h-4 tw-w-4 tw-text-amber-400"
                                    />
                                </Tooltip>
                            )}
                            {verified === false && isPending && (
                                <Tooltip
                                    content="App is pending on device"
                                    extraPadding
                                    className="tw-font-semibold"
                                    arrow
                                >
                                    <Icon
                                        type="badgeExclamation"
                                        className="tw-h-4 tw-w-4 tw-text-amber-400"
                                    />
                                </Tooltip>
                            )}
                            {verified === false && !isPending && !error && (
                                <Tooltip
                                    content={error}
                                    extraPadding
                                    className="tw-font-semibold"
                                    arrow
                                >
                                    <Icon
                                        type="badgeExclamation"
                                        className="tw-h-4 tw-w-4 tw-text-red-400"
                                    />
                                </Tooltip>
                            )}
                            {verified === false && error && error !== '' && (
                                <Tooltip
                                    content={error}
                                    extraPadding
                                    className="tw-font-semibold"
                                    arrow
                                >
                                    <Icon
                                        type="badgeExclamation"
                                        className="tw-h-4 tw-w-4 tw-text-red-400"
                                    />
                                </Tooltip>
                            )}
                            {verified === false && error && (
                                <Tooltip
                                    content={'An unknown error occurred trying to link the application to the endpoint.'}
                                    extraPadding
                                    className="tw-font-semibold"
                                    arrow
                                >
                                    <Icon
                                        type="badgeExclamation"
                                        className="tw-h-4 tw-w-4 tw-text-red-400"
                                    />
                                </Tooltip>
                            )}
                        </div>
                    }
                    disableEllipsis
                    callToAction
                    secondary={installTypeName}
                />
            </div>
        </LayoutRow>
    );
};
