import { BUTTON } from '@capasystems/constants';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Loading } from '@capasystems/ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const ConfirmDialog = ({
    children,
    onConfirm,
    title = 'Confirmation required',
    subtitle,
    onCancel,
    isProcessing = false,
    aditionalActions,
    disabled,
    dialogContentClassName,
    ...rest
}) => (
    <Dialog
        onClose={onCancel}
        confirm
        {...rest}
    >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent className={dialogContentClassName}>{children}</DialogContent>
        <DialogActions>
            {aditionalActions}
            {onConfirm && (
                <Button
                    onClick={onConfirm}
                    variant={BUTTON.RAISED}
                    color={BUTTON.PRIMARY}
                    className={classNames({
                        'tw-invisible': isProcessing,
                    })}
                    disabled={disabled}
                    noMargin
                >
                    Confirm
                </Button>
            )}
            <Button
                onClick={onCancel}
                className={classNames({
                    'tw-invisible': isProcessing,
                })}
            >
                {onConfirm ? 'Cancel' : 'Close'}
            </Button>
            {isProcessing && (
                <Loading
                    size={16}
                    className="tw-mr-4"
                />
            )}
        </DialogActions>
    </Dialog>
);

ConfirmDialog.propTypes = {
    children: PropTypes.any,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.any,
    isProcessing: PropTypes.bool,
};

export default ConfirmDialog;
